import { normalizeCurrency, normalizeCpfCnpj } from './utils';

export default function(data) {
  var mywindow = window.open('', 'PRINT');
  const html = `<!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${data.company || data.doc} | Protesto</title>
  </head>
  <style>
    * {
      padding: 0;
      margin:0;
      box-sizing: border-box;
      font-family: sans-serif;
    }
    .d-flex {
      display: flex;
    }
    .justify-content-between {
      justify-content: space-between;
    }
    .align-items-center {
      align-items: center;
    }
    table {
      border-collapse: collapse;
      padding: 10px;
    }
    table th, table td {
      padding: 10px;
      font-size: 9px;
      text-align: center;
    }
    table td {
      border-bottom: 1px solid #c9c9c9;
    }
    table tbody tr:nth-child(odd) {
      background: #f9f9f9;
    }
    table th:first-child, table td:first-child {
      text-align: left;
    }
    table th:last-child, table td:last-child {
      text-align: right;
    }
    #logo div{
      margin-left: 6px;
      color: #00174e;
    }
    #logo {
      margin-bottom: 16px;
    }
  </style>
  <body>
  <div>
    <div class="d-flex align-items-center" id="logo">
        <img src="/media/logos/logo-4-blue.png" alt="BFC" />
      <div>
        <h4>BFC Capital Partners</h4>
        <h6>Relato de Protesto</h6>
      </div>
    </div>
  <div class="d-flex justify-content-between">
    <h5>${data.company}<br />CPF/CNPJ: ${normalizeCpfCnpj(data.doc)}</h5>
    <h5>
      Total Protestado: R$ ${normalizeCurrency(data.total)}
    </h5>
    </div>
    <br />
    <hr />
    <br />
    <div class="d-flex justify-content-between">
      <h5>Títulos: ${data.qtdTitulos}</h5>
      <h5>Data da Consulta: ${data.dataConsulta}</h5>
    </div>
    </div>
    ${Object.keys(data).length > 0 &&
      data.qtdTitulos > 0 &&
      data.cartorio.length > 0 ?
      data.cartorio.map((cartorio, cIndex) => {
        if (
          cartorio.dadosCartorio.find((c) =>
            c.titulos.find(
              (t) => typeof t.valorProtestado !== "undefined"
            )
          )
        ) {
          return `
            <div
              style="
                width: 100%;
                margin: 20px 0px;
              "
            >
              <div
                class="d-flex justify-content-between align-items-center"
                style="width: 100%"
              >
                <h5>UF:</h5>
                <h5>${cartorio.uf}</h5>
              </div>
              <br />
              <hr />
              <br />
              ${cartorio.dadosCartorio.length > 0 ?
                cartorio.dadosCartorio.map((dadosCartorio, dIndex) => {
                  if (
                    dadosCartorio.titulos.find(
                      (titulo) =>
                        typeof titulo.valorProtestado !== "undefined"
                    )
                  ) {
                    return `
                      <div style="width: 100%">
                        <table style="width: 100%;">
                          <thead style="background: #f0f0f0"
                            <tr>
                              <th>${dadosCartorio.nomeCartorio}</th>
                              <th>${dadosCartorio.telefone}</th>
                              <th>
                                ${dadosCartorio.qtdTitulos} Título(s)
                              </th>
                            </tr>
                          </thead>
                        </table>
                        <table
                          style="
                            width: 100%; margin-bottom: 16px;
                          "
                        >
                          <thead style="background: #fff">
                            <tr>
                              <th>Data Protesto</th>
                              <th>Data Vencimento</th>
                              <th>Valor Protestado</th>
                              <th>Apresentante</th>
                              <th>Protestante</th>
                            </tr>
                          </thead>
                          <tbody>
                            ${dadosCartorio.titulos.length > 0 ?
                              dadosCartorio.titulos
                                .filter(
                                  (titulo) =>
                                    typeof titulo.valorProtestado !==
                                    "undefined"
                                )
                                .map((titulo, tIndex) => `
                                  <tr>
                                    <td>${titulo.dataProtesto}</td>
                                    <td>${titulo.dataVencimento}</td>
                                    <td>R$ ${titulo.valorProtestado}</td>
                                    <td>${titulo.nomeApresentante}</td>
                                    <td>${titulo.nomeCedente}</td>
                                  </tr>
                                `).join('') : ''}
                          </tbody>
                        </table>
                      </div>
                    `;
                  }
                  return "";
                }).join('') : ''}
            </div>
          `;
        }
        return "";
      }).join('') : ''}
  </body>
  </html>
  `;
  mywindow.document.write(html);
  mywindow.document.close();
  mywindow.focus(); 
  
  setTimeout(() => {
    mywindow.print();
    mywindow.close();
  }, 1500);
}