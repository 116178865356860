import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { toast } from "react-toastify";
import { format, isPast } from "date-fns";
import ListBorderoItem from "../components/ListBorderoItem";
import SelectBorderoItem from "../components/SelectBorderoItem";

import verifyProfiles from "../../utils/verifyProfiles";

import axios from "axios";

export default function ConfirmationView({ open, setOpen }) {
  const [user, setUser] = useState({});
  const [borderoDetail, setBorderoDetail] = useState({});
  const [nfes, setNfes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [approve, setApprove] = useState(false);
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [percentageToConfirm, setPercentageToConfirm] = useState(0);
  const [percentageInProgess, setPercentageInProgress] = useState(0);
  const [hasEcnpj, setHasEcnpj] = useState({});

  // const [loadingPdf, setLoadingPdf] = useState(false);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get("auth");
      setUser(data);
    }
    loadUser();
  }, []);

  function handleSelectApprove(item) {
    const alreadyHave = selected.find((i) => i.index === item.index);
    if (!alreadyHave) {
      setSelected([...selected, item]);
    } else {
      const reproved = selected.filter((i) => i.index !== item.index);
      setSelected(reproved);
    }
  }

  async function loadBordero() {
    setLoading(true);
    let response;
    let cnpj_cedente;
    // let pdfInfo;
    // try {
    //   pdfInfo = await axios.get(
    //     `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-nfe/pdf/status/${open.bordero}`
    //   );
    // } catch (err) {
    //   pdfInfo = {
    //     data: {
    //       pdf: undefined,
    //     },
    //   };
    // }
    try {
      response = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/bordero-report/bordero/${open.bordero}`
      );
      setBorderoDetail({
        ...response.data.bordero,
        // pdf: pdfInfo.data.pdf,
        // count_pdf: pdfInfo.data.count,
      });
      cnpj_cedente = response.data.bordero.cnpj.replace(/\D/g, "");
      setNfes(response.data.nfes);
      let countSuccess = 0;
      response.data.nfes.forEach((nfe) => {
        if (nfe.status === "a confirmar" || nfe.status === "nao confirmado") {
          countSuccess += 1;
        }
      });
      let percent = (countSuccess / response.data.nfes.length) * 100;
      setPercentageToConfirm(Math.round(percent));
      let countInProgress = 0;
      response.data.nfes.forEach((nfe) => {
        if (nfe.status === "") {
          countInProgress += 1;
        }
      });
      let inProgess = (countInProgress / response.data.nfes.length) * 100;
      setPercentageInProgress(Math.round(inProgess));
    } catch (err) {
      toast.error("Nenhuma NFe encontrada.");
    }
    if (
      (response.data.bordero &&
        response.data.bordero.cedente &&
        response.data.bordero.cedente === "-") ||
      (response.data.bordero &&
        response.data.bordero.ufCedente &&
        response.data.bordero.ufCedente === "-") || (response.data.bordero &&
          response.data.bordero.cnpj &&
          response.data.bordero.cnpj === "-")
    ) {
      try {
        const detail = await axios.get(
          `${process.env.REACT_APP_API_URL}/bordero-nfe/${open.bordero}`
        );
        setBorderoDetail({
          cedente: detail.data.borderoData.cedente,
          cnpj: detail.data.borderoData.cnpj,
          ufCedente: detail.data.borderoData.uf_cedente,
          // pdf: pdfInfo.data.pdf,
          // count_pdf: pdfInfo.data.count,
        });
        cnpj_cedente = detail.data.borderoData.cnpj.replace(/\D/g, "");
      } catch (err) {
        console.log(err);
      }
    }
    try {
      const { data } = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/customer-register/e-cnpj/file/${cnpj_cedente}`
      );
      setHasEcnpj(data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setHasEcnpj({});
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores ao buscar e-CNPJ, contate a equipe técnica"
        );
      } else {
        toast.error(
          "Ocorreu um erro em nossa aplicação ao buscar e-CNPJ, contate a equipe técnica"
        );
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    if (open.bordero !== "") {
      loadBordero();
    } else {
      setOpen({ active: false, bordero: "" });
    }
  }, [open.bordero]); // eslint-disable-line

  function resetForm() {
    setOpen({ active: false, bordero: "" });
    setNfes([]);
    setSelected([]);
    setApprove(false);
  }

  // async function handleSubmitPdf() {
  //   setLoadingPdf(true);
  //   try {
  //     const { data } = await axios.get("auth");
  //     await axios.post(
  //       `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-nfe/list`,
  //       {
  //         bordero: open.bordero,
  //         nfes,
  //         user: data.id,
  //         borderoData: borderoDetail,
  //       }
  //     );
  //     toast.info(
  //       "Notas foram enviadas para geramento de PDF, notificaremos quando estiver pronto"
  //     );
  //     setBorderoDetail({ ...borderoDetail, pdf: "unlocking" });
  //   } catch (err) {
  //     toast.error("Ocorreu um erro ao tentar iniciar processo");
  //   }
  //   setLoadingPdf(false);
  // }
  // async function handleUnlockPdf() {
  //   setLoadingPdf(true);
  //   try {
  //     const { data } = await axios.get("auth");
  //     await axios.post(
  //       `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-nfe/unlock`,
  //       {
  //         bordero: open.bordero,
  //         nfes,
  //         user: data.id,
  //         borderoData: borderoDetail,
  //       }
  //     );
  //     toast.info(
  //       "Notas foram reenviadas para geramento de PDF, notificaremos quando estiver pronto"
  //     );
  //     setBorderoDetail({ ...borderoDetail, pdf: "unlocking" });
  //   } catch (err) {
  //     toast.error("Ocorreu um erro ao tentar iniciar processo");
  //   }
  //   setLoadingPdf(false);
  // }

  async function handleSubmit() {
    try {
      const { data } = await axios.get("auth");
      await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/request-list/list`,
        {
          bordero: String(open.bordero),
          nfes: selected,
          user: data.id,
          borderoData: borderoDetail,
          force: true,
        }
      );
      loadBordero();
      setApprove(false);
      setSelected([]);
      setNfes([]);
      toast.info(
        "Notas foram enviadas para análise. Notificaremos quando todas forem analisadas"
      );
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar realizar análise");
    }
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "800px",
      height: "90%",
      overflow: "auto",
    },
    box: {
      flex: "1",
      border: "1px solid #eee",
      background: "#f9f9f9",
      padding: "10px",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.active}
      onClose={resetForm}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open.active}>
        <div className={classes.fade}>
          <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Detalhamento do Borderô NFe</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen({ active: false, bordero: "" });
                    setNfes([]);
                    setSelected([]);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <h3
              className="mt-4"
              style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Borderô NFe: {open.bordero}
            </h3>
            {nfes.length > 0 && (
              <table className="table table-bordered">
                <thead>
                  <tr className="bg-secondary text-dark">
                    <td>Cedente</td>
                    <td>UF</td>
                    <td>N° de Notas</td>
                    <td>Data de Processamento</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{(borderoDetail && borderoDetail.cedente) || "-"}</td>
                    <td>
                      {(borderoDetail && borderoDetail.ufCedente) ||
                        (borderoDetail && borderoDetail.uf_cedente) ||
                        "-"}
                    </td>
                    <td>{nfes.length}</td>
                    <td>
                      {format(
                        new Date(nfes[0].createdAt),
                        "dd/MM/yyyy 'às' HH:mm"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {!loading && (
              <div
              className="d-flex justify-content-start align-items-center mt-2"
              style={{ padding: "15px", borderRadius: "4px", background: "#f9f9f9" }}
            >
              <i className="fas fa-key" style={{ fontSize: '28px' }}></i>
              {Object.keys(hasEcnpj).length > 0 ? (
                <div className={`ml-3 d-flex flex-column ${isPast(new Date(hasEcnpj.expires_in)) ? 'text-danger' : ''}`}>
                  <strong>e-CNPJ {isPast(new Date(hasEcnpj.expires_in)) ? 'Expirado' : 'Instalado'}</strong>
                  <small>Expira em: {format(new Date(hasEcnpj.expires_in), 'dd/MM/yyyy')}</small>
                </div>
              ) : (
                <div className="ml-3">
                  <strong>Nenhum e-CNPJ instalado</strong>
                </div>
              )}
            </div>
            )}
            <div>
              <h4 className="mt-4">NFe's</h4>
              <div className="mt-4"></div>
              <hr />
              <div className="row mb-4">
                {!approve &&
                  nfes.length > 0 &&
                  percentageToConfirm > 0 &&
                  percentageInProgess === 0 &&
                  Object.keys(user).length > 0 &&
                  !verifyProfiles(user, "analist_confirmation") &&
                  /* Object.keys(hasEcnpj).length > 0 || */
                  (nfes.find(nfe => nfe.status === 'nao confirmado') || true) && 
                  (
                    <div className="col-md-3">
                      <button
                        type="button"
                        onClick={() => setApprove(true)}
                        className="btn btn-primary btn-upper btn-sm"
                      >
                        Analisar Novamente
                      </button>
                    </div>
                  )}
              </div>
              {approve && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-upper btn-sm mr-2"
                      disabled={selected.length === 0}
                      onClick={handleSubmit}
                    >
                      Enviar para Análise
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-upper btn-sm"
                      onClick={() => {
                        setApprove(false);
                        setSelected([]);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                  {selected.length > 0 ? (
                    <button
                      type="button"
                      className="btn btn-light btn-sm btn-upper"
                      onClick={() => {
                        setSelected([]);
                      }}
                    >
                      <span
                        className={"fa fa-check mr-4"}
                        style={{
                          width: "18px",
                          height: "18px",
                          border: "0",
                          background: "#dee2ea",
                          display: "inline-block",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></span>
                      Esvaziar Seleção
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-light btn-sm btn-upper"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        const list = [];
                        nfes.forEach((item, index) => {
                          if (
                            (item.status === "a confirmar" ||
                            item.status === "nao confirmado")// &&
                            // Object.keys(hasEcnpj).length > 0
                          ) {
                            list.push({ ...item, index });
                          }
                          // else if (Object.keys(hasEcnpj).length === 0 && item.status === 'nao confirmado') {
                          //   list.push({ ...item, index });
                          // }
                        });
                        setSelected(list);
                      }}
                    >
                      <span
                        className={"mr-4"}
                        style={{
                          width: "18px",
                          height: "18px",
                          border: "0",
                          background: "#dee2ea",
                          display: "inline-block",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></span>
                      Selecionar Tudo
                    </button>
                  )}
                </div>
              )}
              <div className="row mt-4">
                {loading ? (
                  <>
                    <CircularProgress /> Buscando
                  </>
                ) : (
                  <>
                    {nfes.length > 0 ? (
                      nfes.map((item, index) => {
                        if (approve) {
                          const select = selected.find(
                            (i) => i.index === index
                          );
                          return (
                            <SelectBorderoItem
                              key={index}
                              index={index}
                              item={item}
                              select={select}
                              classes={classes}
                              handleSelectApprove={(e) =>
                                handleSelectApprove(e)
                              }
                              hasEcnpj={Object.keys(hasEcnpj).length > 0}
                            />
                          );
                        } else {
                          const expanded = detail === index;
                          return (
                            <ListBorderoItem
                              key={index}
                              index={index}
                              item={item}
                              expanded={expanded}
                              classes={classes}
                              setDetail={(e) => setDetail(e)}
                              hasEcnpj={Object.keys(hasEcnpj).length > 0}
                            />
                          );
                        }
                      })
                    ) : (
                      <h6>Nenhuma NFe encontrada...</h6>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options"
                onClick={resetForm}
              >
                Fechar
              </button>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
