import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
  LinearProgress,
  makeStyles,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Visibility } from "@material-ui/icons";
import NotificationDetail from "./NotificationDetail";
import NotificationSearch from "./NotificationSearch";
import { Dropdown, Pagination } from "react-bootstrap";

export default function NotificationIndex() {
  const [openDetail, setOpenDetail] = useState({
    active: false,
    bordero: ""
  });
  const [openSearch, setOpenSearch] = useState(false);
  const [rows, setRows] = useState([]);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total, setTotal] = useState(0);

  async function loadNotification() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-nfe-serpro/notifications/list`
      );
      setRows(response.data);
    } catch (err) {
      toast.error("Ocorreu um erro na listagem das notificações");
    }
    setLoading(false);
  }

  async function searchNotification({ value }) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-nfe-serpro/notifications/get/${value}`
      );
      if (response.data.nfes.length === 0) {
        toast.info("NFe não encontrada");
      } else {
        let rowsResponse = [];
        response.data.nfes.forEach((item) => {
          rowsResponse.push({
            ...item,
            dataCriacao: format(
              new Date(item.dataCriacao),
              "dd/MM/yyyy 'às' HH:mm"
            ),
            dataEnvio: format(
              new Date(item.dataEnvio),
              "dd/MM/yyyy 'às' HH:mm"
            ),
          });
        });
        setFilter(true);
        setRows(rowsResponse);
        setTotal(response.data.total);
        setPage(0);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.info("NFe não encontrada");
      } else {
        toast.error("Ocorreu um erro ao tentar pesquisar");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    try {
      loadNotification();
    } catch (err) {
      history.goBack();
    }
  }, [page, rowsPerPage]); // eslint-disable-line

  const headRows = [
    {
      id: "bordero",
      numeric: false,
      disablePadding: true,
      align: "left",
      label: "Borderô",
    },
    {
      id: "cedente",
      numeric: false,
      disablePadding: true,
      align: "left",
      label: "Cedente",
    },
    {
      id: "nfes",
      numeric: false,
      disablePadding: true,
      align: "center",
      label: "Nfes",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      align: "right",
      label: "Ações",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy /* onRequestSort */ } = props;
    /*
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    */
    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={() => {}}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleNextPage() {
    const nextPage = page + 1;
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1;
    setPage(previousPage);
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Notificação SERPRO</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/agents`}>
                Notificação SERPRO
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
              {filter && (
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={() => {
                    setFilter();
                    loadNotification();
                  }}
                >
                  Esvaziar Filtro
                </button>
              )}
              <Dropdown alignRight>
                <Dropdown.Toggle variant="light" id="dropdown-basic">
                  Ações
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as="button"
                    onClick={() => setOpenSearch(true)}
                  >
                    Buscar
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <NotificationSearch
        open={openSearch}
        setOpen={(e) => setOpenSearch(e)}
        getConsult={(e) => {
          setOpenSearch(false);
          searchNotification(e);
        }}
      />
      <NotificationDetail open={openDetail} setOpen={(e) => setOpenDetail(e)} />
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    <TableCell align="left">{row.bordero}</TableCell>
                    <TableCell align="left">{row.cedente}</TableCell>
                    <TableCell align="center">{row.nfes.length}</TableCell>
                    <TableCell align="right">
                      <button
                        type="button"
                        className="btn btn-sm pl-2 pr-2"
                        onClick={() =>
                          setOpenDetail({
                            active: true,
                            bordero: row.bordero,
                          })
                        }
                      >
                        <Visibility />
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && <CircularProgress size={15} className="mr-2" />}
              <Pagination>
                {page > 0 && (
                  <>
                    <Pagination.First
                      onClick={() => setPage(0)}
                      disabled={page === 0 || loading}
                    />
                    <Pagination.Prev
                      onClick={() => handlePreviousPage()}
                      disabled={loading}
                    />
                  </>
                )}
                <Pagination.Item disabled>{page + 1}</Pagination.Item>
                {page < Math.ceil(total / rowsPerPage) - 1 && (
                  <>
                    <Pagination.Next
                      onClick={() => handleNextPage()}
                      disabled={loading}
                    />
                    <Pagination.Last
                      onClick={() =>
                        setPage(Math.ceil(total / rowsPerPage) - 1)
                      }
                      disabled={loading}
                    />
                  </>
                )}
              </Pagination>
            </div>
            <FormControl
              style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
              disabled={loading}
            >
              <InputLabel htmlFor="results_per_page">
                Result. p/Página
              </InputLabel>
              <Select
                id="results_per_page"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
                disabled={loading}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
