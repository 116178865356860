import React from "react";
import { Route, Switch } from "react-router-dom";
import TemplateIndex from "./TemplateIndex";
import TemplateAdd from "./TemplateAdd";
import TemplateEdit from "./TemplateEdit";

export default function TemplatePage() {
  return (
    <Switch>
      <Route exact path="/templates/" component={TemplateIndex} />
      <Route exact path="/templates/add" component={TemplateAdd} />
      <Route exact path="/templates/:id" component={TemplateEdit} />
    </Switch>
  );
}
