import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ErrorPage } from "./ErrorPage";

export default function ErrorsPage() {
  return (
      <Switch>
        <Redirect from="/errors" exact={true} to="/error" />
        <Route path="/error" component={ErrorPage} />
      </Switch>
  );
}
