import { format } from 'date-fns';

import { normalizeCnpj } from './normalize';

const normalizeCurrency = (value) => {
  const stringOfNumber = String(value);
  let cents = stringOfNumber[stringOfNumber.length-2] + stringOfNumber[stringOfNumber.length-1];
  let reals = stringOfNumber.slice(0, stringOfNumber.length - 2);
  let pointValue = "";

  let arrayNumber = reals.split("");
  arrayNumber.reverse();
  arrayNumber.forEach((value, index) => {
    if((index + 1)%3 === 0) {
      if(index === arrayNumber.length - 1) {
        pointValue = value + pointValue;
      }else{
        pointValue = "." + value + pointValue;
      }
    }else{
      pointValue = value + pointValue;
    }
  });
  return pointValue + "," + cents;
}

export default function printChargeEmail({ sacado, events }) {
  var mywindow = window.open('', 'PRINT');
  mywindow.document.write(`
  <html>
<style>
  * {
    font-family: sans-serif;
    color: #2e3557;
  }

  h1 {
    font-weight: normal;
  }

  #grid_boletos {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
    border-left: 4px solid #e4c147;
    padding-left: 20px;
  }

  #grid_boletos span {
    font-size: 12px;
  }

  #about_tr h1 {
    margin: 0;
  }

  #about_tr p {
    text-align: right;
    margin: 0;
    margin-right: 15px;
    margin-top: 10px;
  }

  #about_tr img {
    margin-top: 35px;
  }
</style>
<table cellpadding="0" cellspacing="0"
  style="width: 100%;display: block;border-collapse: separate;background: #fbfbfb;">
  <tbody>
    <tr>
      <td colspan="2" style=" text-align: center;">
        <img src="https://www.grupobfc.com.br/assets/images/header.jpg" alt="Header" style="width:100%;">
      </td>
    </tr>
    <tr>
      <td colspan="2" style="background:#eaeffa; text-align:center; padding:10px;">
         NEGOCIADO COM ${sacado.cedente}/${normalizeCnpj(sacado.cedente_id)}, PARA ${sacado.name}/${sacado.saca_id}
       </td>
    </tr>
    <tr>
      <td colspan="2" style="padding:30px 40px 10px;">
        <h1>Prezados,</h1>
        <p>Vimos dar conhecimento a V. Sas. que o(s) título(s) abaixos relacionado(s) foi(ram) transferido(s) por
          endosso em preto, para o <strong>${sacado.cedente}</strong>, que se tornou
          o único e
          legítimo proprietário. Em face da transferência havida, informamos que, em seu(s) respectivo(s) vencimento(s), 
          o(s) título(s) deverá(ão) ser pago(s) exclusivamente com o(s) boleto(s) de cobrança enviado(s) em anexo.
        </p>
        <p>Em tempo: Atendendo a legislação vigente (artigo 7, Lei 5.474/68), pedimos a gentileza de nos retornar
          informando qualquer objeção quanto a vício, atraso, quantidade, ou defeitos dos produtos e/ou serviços
          que os tornaram impróprios ou inadequados.
        </p>
        <p>
          Caso não consiga abrir o(s) anexo(s), clique aqui para acessar a 2° via do boleto no Portal da BFC.
        </p>
        <p>Colocamos-nos à inteira disposição de V. Sas, através dos fones: <br />
          (81) 2119.9595 / 4007.2614</p>
        <p>Atenciosamente.</p>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding:20px 40px 30px;">
        <h4>Referente aos seguintes títulos negociados:</h4>
        <div id="grid_boletos">
          ${events.map(title => (
            `
            <div>
                <span>
                  VENCIMENTO: ${format(
                    new Date(title.title_date),
                    'dd/MM/yyyy'
                  )}<br />
                  VALOR: R$ ${normalizeCurrency(title.title_value)}<br />
                  DOCUMENTO: ${title.document_number}<br />
                </span>
              </div>
            `
          )).join("")}
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding:10px 30px 0px;">
        <span style="width:100%; height:2px; background:#c9c9c9; display:block;">
        </span>
      </td>
    </tr>
    <tr id="about_tr">
      <td style="padding:40px 0px; padding-left:30px;">
        <h1>Quem Somos</h1>
        <p>Há mais de 25 anos, transformamos <br />suas vendas a prazo em dinheiro <br />vivo,
          para você ficar no azul!
        </p>
      </td>
      <td style="padding-right:30px;">
        <img src="https://www.grupobfc.com.br/assets/images/about_money.png" alt="Money" style="width:100%;" />
      </td>
    </tr>
    <tr>
      <td colspan="2"
        style="background:#2a3460; border-top:4px solid #e4c147; padding:30px 30px; color:#fff; text-align:center;">
        Dúvidas? <strong style="color:#fff;">Entre em contato conosco.</strong></br>
        Ligue para <strong style="color:#fff;">(81) 2119-9595 / 4007-2614</strong></br>de segunda
        a sexta ou envie e-mail para <strong style="color:#fff;">bfc@grupobfc.com.br</strong>
        <br />
        Estamos à disposição.
      </td>
    </tr>
    <tr>
      <td style="padding:30px 0px; padding-left:15px;">
        <h4>Acompanhe a BFC nas redes sociais</h4>
        <div>
          <img src="https://www.grupobfc.com.br/assets/images/social.png" />
        </div>
      </td>
      <td style="padding:30px 0px; padding-right:15px;">
        <p>
          Empresarial Alexandre de Castro e Silva,<br />
          Av. Domingos Ferreira, 2589 - Sala 1001<br />
          CEP: 51.020-031 - Boa Viagem -- Recife -- PE
        </p>
        <p>
          + 55 81 2119-9595 / 4007.2614
        </p>
        <p>
          bfc@grupobfc.com.br
        </p>
      </td>
    </tr>
  </tbody>
</table>

</html>
  `);
  mywindow.document.close();
  mywindow.focus(); 
  setTimeout(() => {
    mywindow.print();
    mywindow.close();
  }, 1500)
}