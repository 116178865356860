import React, { memo, useState } from "react";

import { makeStyles } from "@material-ui/core/";
import { Modal, Fade, Backdrop, TextField } from "@material-ui/core";
import { Close, Edit, Check } from "@material-ui/icons";

import axios from "axios";
import { toast } from "react-toastify";

import { normalizeCnpj } from "./utils";
import { format } from 'date-fns';

function ModalCreate({ open, setOpen, item }) {
  const [newEmail, setNewEmail] = useState({
    active: false,
    value: "",
    error: false,
  });
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "800px",
      maxHeight: "90%",
      overflow: "auto",
    },
  }));
  const classes = useStyles();

  function resetModal() {
    setNewEmail({
      active: false,
      value: "",
      error: false,
    })
    setOpen(!open);
  }
  async function sendAgain() {
    try {
      await axios.post(`/e-cnpj/send_again/${item.ecnpj.id}`);
      toast.success("E-mail de ativação reenviado");
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.error("Cadastro não encontrado");
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
  }
  async function changeEmail() {
    setNewEmail({ ...newEmail, error: false });
    if(newEmail.value.length === 0 || newEmail.value.indexOf('@') === -1 || newEmail.value.indexOf('.') === -1) {
      return setNewEmail({
        ...newEmail,
        error: true,
      });
    }
    try {
      await axios.put(`/e-cnpj/email/${item.ecnpj.id}`, {
        email: newEmail.value,
      });
      item.ecnpj.email = newEmail.value;
      setNewEmail({
        active: false,
        value: "",
        error: false,
      })
      toast.success("E-mail alteração e reenviado link de ativação");
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.error("Cadastro não encontrado");
      } else {
        toast.error("Ocorreu um erro, tente novamente mais tarde");
      }
    }
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => resetModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2>e-CNPJ</h2>
            <button className="btn" onClick={() => resetModal()}>
              <Close />
            </button>
          </div>
          <div
            className="d-flex mt-4"
            style={{
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              padding: '10px',
              background: '#f9f9f9',
              borderRadius: '6px',
            }}
          >
            <i className="fas fa-building mr-4" style={{ padding: '10px', fontSize: '46px', background: '#eee', borderRadius: '6px' }}></i>
            <div>
            <h4>
              CNPJ: {(item.ecnpj && normalizeCnpj(item.ecnpj.cnpj)) || "-"}
            </h4>
          <p className="mb-0 d-flex justify-content-start align-items-center"><i className="fas fa-envelope mr-1"></i> {newEmail.active ? (
            <>
              <TextField value={newEmail.value} onChange={e => setNewEmail({
                ...newEmail,
                value: e.target.value
              })} error={newEmail.error} style={{ width: '100%', maxWidth: '350px', minWidth: '250px' }} className="mr-4"/>
              <button type="button" className="btn btn-success btn-sm mt-2" onClick={changeEmail} style={{ padding: '5px', borderRadius: '50%', margin: '0px' }}>
                <Check />
              </button>
              <button type="button" className="btn btn-danger btn-sm mt-2 ml-2" onClick={() => setNewEmail({
                active: false,
                value: "",
                error: false,
              })} style={{ padding: '5px', borderRadius: '50%', margin: '0px', marginLeft: '10px' }}>
                <Close />
              </button>
            </>
          ) : (
            <>
            {(item.ecnpj && item.ecnpj.email) || "-"}
            {item.ecnpj &&
              item.ecnpj.documents &&
              item.ecnpj.documents.length === 0 && (
                <button type="button" className="ml-4 d-flex justify-content-center align-items-center" style={{ width: '22px', height: '22px', background: 'none', borderRadius: '50%' }} onClick={() => setNewEmail({
                  ...newEmail,
                  active: true,
                  value: item.ecnpj.email,
                })}>
                  <Edit style={{ fontSize: '14px' }}/>
                </button>
              )}
            </>
          )}</p>
            </div>
          </div>
          <hr />
          <div>
            {item.ecnpj &&
              item.ecnpj.documents &&
              item.ecnpj.documents.length === 0 && (
                <div className="text-center">
                  <h6>Nenhum e-CNPJ instalado até o momento...</h6>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm btn-upper"
                    onClick={sendAgain}
                  >
                    Reenviar Link de Ativação
                  </button>
                </div>
              )}
              
            {item.ecnpj &&
              item.ecnpj.documents &&
              item.ecnpj.documents.length > 0 && (
                <>
                <h6 className="d-block text-center">
                  {item.ecnpj.documents.length} Arquivo(s) instalado(s)
                </h6>
                <div className="mt-4" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px', fontSize: '12px' }}>
                  {item.ecnpj.documents.map((e_cnpj, index) => (
                <div
                  key={index}
                  className="d-flex justify-content-start align-items-center"
                  style={{
                    background: "#eee",
                    borderRadius: '6px',
                    padding: "10px",
                  }}
                >
                  <i className="fas fa-key mr-2" style={{ fontSize: '24px', padding: '10px' }}></i>
                  <div>
                    <p className="mb-1">CNPJ: {normalizeCnpj(e_cnpj.cnpj)}</p>
                    <p className="mb-1">Arquivo: {e_cnpj.name}</p>
                    <p className="mb-1">Expira em: {e_cnpj.expires_in !== '0000-00-00 00:00:00' ? format(new Date(e_cnpj.expires_in), 'dd/MM/yyyy') : '-'}</p>
                  </div>
                </div>
              ))}
                </div>
                </>
              )}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default memo(ModalCreate);
