import React from "react";

export default function statusRegister(register) {
  let perc = 0;
  register.documents &&
    register.documents.map(doc => {
      if (doc.status === "A" || doc.status === "I") {
        return (perc += 33 / register.documents.length);
      } else {
        return perc;
      }
    });
  register.members &&
    register.members.map(mem => {
      if (mem.finished === "A") {
        return (perc += 33 / register.members.length);
      } else {
        return perc;
      }
    });
  if (register.finished === "A") {
    perc += 33;
  }
  if (perc === 99) {
    perc = 100;
  }
  perc = Math.round(perc);
  return (
    <div className="text-center">
      <div className="progress">
        <div
          className={`progress-bar ${
            perc < 100 ? "kt-bg-info" : "kt-bg-success"
          }`}
          style={{ width: `${perc}%` }}
        ></div>
      </div>
      <div className="kt-widget__stats mt-1">{perc}%</div>
    </div>
  );
}
