import React, { memo, useState } from 'react';
import { Close } from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";
import {
  makeStyles,
  Modal,
  Backdrop,
} from "@material-ui/core";
import { viewContract } from "../RegisterContract";

function SelectContractExceptions({ open, onClose, register }) {
  const [selected, setSelected] = useState({ fiel_depositario: [], fiadores: [], outorgantes: [] });
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "700px",
      height: "85%",
      overflow: "auto"
    },
    list: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    box: {
      border: '1px solid #eee',
      margin: '10px 0',
      padding: '0px 10px',
    },
  }));
  const classes = useStyles();

  function handleSelectSpouses(spouses, type) {
    const alreadyHave = selected[type].findIndex(s => s.id === spouses.id);
    if (alreadyHave !== -1) {
      let oldArray = selected[type];
      const newArray = [];
      for(let i = 0; i < oldArray.length; i++) {
        if(i !== alreadyHave) {
          newArray.push(oldArray[i]);
        }
      }
      setSelected({ ...selected, [type]: newArray });
    } else {
      setSelected({...selected, [type]: [...selected[type], spouses]});
    }
  }

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fade}>
        <Row>
          <Col lg={11}>
            <h2>Opções do Contrato</h2>
            <p>Selecione os Cônjuges dos Sócios que serão impressos no contrato.</p>
          </Col>
          <Col lg={1}>
            <button 
              type="button" 
              style={{ background: 'none', border: 'none' }} 
              onClick={onClose}>
              <Close />
            </button>
          </Col>
        </Row>
        <div className={classes.box}>
        <h4 style={{ padding: '10px' }}>FIEL DEPOSITÁRIO</h4>
        <Row>
          {register.members && register.members.map(member => {
            if (member.memberSpouses !== null) {
              const checked = selected.fiel_depositario.find(s => s.id === member.memberSpouses.id);
              return (
                <Col lg={12} key={member.id} style={{ 
                    borderTop: '1px solid #c9c9c9', 
                    background: checked && '#eee',
                    padding: '10px 5px'
                  }} >
                <button 
                  type="button" 
                  style={{ border: 'none', background: 'none' }}
                  onClick={() => handleSelectSpouses(member.memberSpouses, 'fiel_depositario')}
                  className={classes.list}
                >
                  <span
                    className={
                      checked
                        ? "fa fa-check"
                        : ""
                    }
                    style={{
                      width: "18px",
                      height: "18px",
                      border: "0",
                      background: "#dee2ea",
                      display: "inline-block",
                      borderRadius: "5px",
                      marginRight: "10px"
                    }}
                  ></span>
                  <div style={{ textAlign: 'right' }}>
                    <strong>{member.memberSpouses.fullname}</strong><br />
                    <small>{member.fullname}</small>
                  </div>
                </button>
                </Col>
              )
            } else {
              return '';
            }
          })}
        </Row>
        </div>
        <div className={classes.box}>
        <h4 style={{ padding: '10px' }}>DEVEDORES SOLIDÁRIOS / FIADORES</h4>
        <Row>
          {register.members && register.members.map(member => {
            if (member.memberSpouses !== null) {
              const checked = selected.fiadores.find(s => s.id === member.memberSpouses.id);
              return (
                <Col lg={12} key={member.id} style={{ 
                    borderTop: '1px solid #c9c9c9', 
                    background: checked && '#eee',
                    padding: '10px 5px'
                  }} >
                <button 
                  type="button" 
                  style={{ border: 'none', background: 'none' }}
                  onClick={() => handleSelectSpouses(member.memberSpouses, 'fiadores')}
                  className={classes.list}
                >
                  <span
                    className={
                      checked
                        ? "fa fa-check"
                        : ""
                    }
                    style={{
                      width: "18px",
                      height: "18px",
                      border: "0",
                      background: "#dee2ea",
                      display: "inline-block",
                      borderRadius: "5px",
                      marginRight: "10px"
                    }}
                  ></span>
                  <div style={{ textAlign: 'right' }}>
                    <strong>{member.memberSpouses.fullname}</strong><br />
                    <small>{member.fullname}</small>
                  </div>
                </button>
                </Col>
              )
            } else {
              return '';
            }
          })}
        </Row>
        </div>
        <div className={classes.box}>
        <h4 style={{ padding: '10px' }}>OUTORGANTE UXÓRIOS DOS DEVEDORES SOLIDÁRIOS</h4>
        <Row>
          {register.members && register.members.map(member => {
            if (member.memberSpouses !== null) {
              const checked = selected.outorgantes.find(s => s.id === member.memberSpouses.id);
              return (
                <Col lg={12} key={member.id} style={{ 
                    borderTop: '1px solid #c9c9c9', 
                    background: checked && '#eee',
                    padding: '10px 5px'
                  }} >
                <button 
                  type="button" 
                  style={{ border: 'none', background: 'none' }}
                  onClick={() => handleSelectSpouses(member.memberSpouses, 'outorgantes')}
                  className={classes.list}
                >
                  <span
                    className={
                      checked
                        ? "fa fa-check"
                        : ""
                    }
                    style={{
                      width: "18px",
                      height: "18px",
                      border: "0",
                      background: "#dee2ea",
                      display: "inline-block",
                      borderRadius: "5px",
                      marginRight: "10px"
                    }}
                  ></span>
                  <div style={{ textAlign: 'right' }}>
                    <strong>{member.memberSpouses.fullname}</strong><br />
                    <small>{member.fullname}</small>
                  </div>
                </button>
                </Col>
              )
            } else {
              return '';
            }
          })}
        </Row>
        </div>
        <button type="button" 
          className="btn btn-primary" 
          onClick={() => 
            viewContract(register, selected.fiel_depositario, selected.fiadores, selected.outorgantes)}
          >
          Gerar Contrato
        </button>
      </div>
    </Modal>
  );
}

export default memo(SelectContractExceptions);