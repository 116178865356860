import React, { useState } from "react";

import { toast } from "react-toastify";
import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
} from "@material-ui/core";

export default function ConfirmationNew({ open, setOpen, setView, borderos, setUpDetail }) {
  const [bordero, setBordero] = useState("");
  async function consultBordero() {
    const finded = borderos.find(item => item._id === Number(bordero));
    if(finded) {
      toast.info("Borderô NFe já enviado para análise");
      setBordero("");
      setUpDetail({ active: true, bordero });
      return setOpen(false);
    }
    setView({ active: true, bordero });
    setOpen(false);
    setBordero("");
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "600px",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => {
        setOpen(false);
        setBordero("");
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          <h2 id="transition-modal-title">Novo Borderô NFe</h2>
          <input
            placeholder="Número do Borderô NFe"
            className="form-control mt-4"
            value={bordero}
            onChange={(e) => {
              setBordero(e.target.value.replace(/\D/g, ""));
            }}
          />
          <div className="mt-4 center">
            <button
              type="button"
              className="btn btn-primary kt-subheader__btn-options mr-4"
              onClick={consultBordero}
              disabled={bordero.length === 0}
            >
              Buscar Notas
            </button>
            <button
              type="button"
              className="btn btn-light kt-subheader__btn-options"
              onClick={() => {
                setOpen(false);
                setBordero("");
              }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
