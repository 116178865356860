import React, { memo } from 'react';

import loadPendencia from '../../utils/loadPendencia';
import { Modal, Backdrop, makeStyles, Fade } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Row, Col } from 'react-bootstrap';

function ModalPendencie({ 
  pendencie,
  setPendencie
 }) {
  const useStyles = makeStyles(theme => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      overflow: "auto"
    },
    close: {
      background: "#fff",
      fontSize: "20px",
      border: 0,
      textAlign: "right",
      width: "100%"
    },
  }));
  const classes = useStyles();

  return (
    <Modal
        className={classes.modal}
        open={pendencie.open}
        onClose={() => setPendencie({ open: false, pendencie: {} })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={pendencie.open}>
          <div className={classes.fade}>
            <Row>
              <Col lg={10}>
                <h2 id="transition-modal-title">
                  Pendência:{" "}
                  {pendencie.pendencie.title && pendencie.pendencie.title}
                </h2>
              </Col>
              <Col lg={2}>
                <button
                  className={classes.close}
                  onClick={() => setPendencie({ open: false, pendencie: {} })}
                >
                  <Close />
                </button>
              </Col>
            </Row>
            <hr />
            {pendencie.pendencie.description && pendencie.pendencie.description}
            <hr />
            <Row>{loadPendencia(pendencie)}</Row>
          </div>
        </Fade>
      </Modal>
  );
}

export default memo(ModalPendencie);