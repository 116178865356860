import React from "react";

export default function SelectItem({
  select,
  index,
  item,
  classes,
  handleSelectApprove,
}) {
  return (
    <button
      type="button"
      className="col-md-12"
      style={{
        padding: "10px",
        background: 'none',
        border: 'none'
      }}
      key={index}
      onClick={() => handleSelectApprove({ ...item, index })}
    >
      <div className={classes.box} style={{ 
        display: 'flex', 
        flexDirection: 'flex', 
        textAlign: 'left',
        alignItems: 'center',
        justifyContent: 'flex-start'
       }}>
      <span
        className={select ? "fa fa-check" : ""}
        style={{
          width: "20px",
          height: "20px",
          border: "0",
          background: "#dee2ea",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "5px",
          marginRight: "30px",
          marginBottom: "10px",
        }}
      ></span>
        <h4 style={{ color: '#5867dd' }}>NFe: {item.nfe}</h4>
      </div>
    </button>
  );
}
