import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import { toast } from "react-toastify";

import { normalizeCnpj, normalizePhone } from "./utils/normalize";
import { format } from "date-fns";

import RegisterSearch from "./RegisterSearch";
import statusRegisterCompleted from "./utils/statusRegisterCompleted";
import progressRegister from "./utils/progressRegister";

export default function RegisterIndex() {
  const [user, setUser] = useState({ roles: [] });
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filter, setFilter] = useState(false);
  const [gerentes, setGerentes] = useState([]);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updated_at");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [searchStatus, setSearchStatus] = useState(null);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('auth');
      setUser(data);
    }
    loadUser();
  }, []);

  async function loadRegisters() {
    setLoading(true);
    try {
      const query = [];
      if(user.gerente_id) {
        query.push(`gerente_id=${user.gerente_id}`)
      }
      const response = await axios.get(`/registers?${query.map(q => q).join('&')}`);
      const registers = [];
      response.data.forEach((r) => {
        registers.push(r);
      });
      setRows(registers);
    } catch (err) {
      history.goBack();
    }
    setLoading(false);
  }

  useEffect(() => {
    async function loadGerente() {
      try {
        const { data } = await axios.get("facta/gerente");
        setGerentes(data);
      } catch (error) {}
    }
    loadGerente();
  }, []);

  useEffect(() => {
    if(user.roles.length > 0) {
      const filter = localStorage.getItem("filter@registers");
      if (filter && filter !== "Todos") {
        setSearchStatus(filter);
        submitSearchStatus();
        setFilter(true);
      } else {
        loadRegisters();
      }
    }
  }, [user]); // eslint-disable-line

  async function submitSearchCnpj(value, by) {
    const query = [];
    if (by === 2) {
      query.push(`user_id=${value}`);
    } else if (value) {
      query.push(`cnpj=${value.replace(/\D/g, "")}`);
      let cnpj = value.replace(/\D/g, "");
      if (cnpj.length < 14) {
        setLoading(false);
        return toast.error("Digite um CNPJ Válido");
      }
    }else if(user.gerente_id) {
      query.push(`gerente_id=${user.gerente_id}`)
    }
    setLoading(true);
    const { data } = await axios.get(
      `/registers?${query.map((q) => q).join("&")}`
    );
    setLoading(false);
    setPage(0);
    setRows(data);
    setFilter(true);
  }

  function formatName(name) {
    if (name.length < 20) {
      return name;
    } else if (name.length > 50) {
      return (
        <>
          {name.slice(0, 20)}
          <br />
          {name.slice(20, 40)}...
        </>
      );
    }
    return (
      <>
        {name.slice(0, Math.floor(name.length / 2))}
        <br />
        {name.slice(Math.floor(name.length / 2), name.length)}
      </>
    );
  }

  async function submitSearchStatus(value) {
    const query = [];
    if(user.gerente_id) {
      query.push(`gerente_id=${user.gerente_id}`)
    }
    if (value && value !== "Todos") {
      setLoading(true);
      query.push(`completed=${value}`)
      const { data } = await axios.get(`/registers?${query.map(q => q).join('&')}`);
      setLoading(false);
      setRows(data);
      setPage(0);
      setFilter(true);
    } else {
      loadRegisters();
      setFilter(false);
    }
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const headRows = [
    {
      id: "razao_social",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "Empresa",
    },
    {
      id: "cnpj",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "CNPJ",
    },
    {
      id: "contact",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Contato",
    },
    {
      id: "manager/agent",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Gerente/Agente",
    },
    {
      id: "perc",
      numeric: false,
      align: "center",
      disablePadding: false,
      label: "Progresso",
    },
    {
      id: "status",
      numeric: false,
      align: "center",
      disablePadding: false,
      label: "Status",
    },
    {
      id: "created_at",
      numeric: false,
      align: "center",
      disablePadding: false,
      label: "Atualização",
    },
    {
      id: "actions",
      numeric: true,
      align: "right",
      disablePadding: false,
      label: "Ações",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastros</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/registers`}
              >
                Cadastros
              </Link>
            </div>
          </div>
          <RegisterSearch
            open={openSearch}
            setOpen={(e) => setOpenSearch(e)}
            getConsult={(e) => submitSearchCnpj(e.value, e.by)}
          />
          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
              <div
                style={{
                  background: "#fff",
                  borderRadius: "5px",
                  padding: "5px 10px 10px",
                  display: "flex",
                  alignItems: "flex-end",
                }}
              >
                <FormControl>
                  <InputLabel>Filtrar Status</InputLabel>
                  <Select
                    value={searchStatus || "Todos"}
                    placeholder="Todos"
                    onChange={(event) => {
                      setSearchStatus(event.target.value);
                      localStorage.setItem(
                        "filter@registers",
                        event.target.value
                      );
                      submitSearchStatus(event.target.value);
                    }}
                    label="Filtrar Status"
                    style={{ width: "150px" }}
                  >
                    <MenuItem value="Todos">Todos</MenuItem>
                    <MenuItem value="0">Em análise</MenuItem>
                    <MenuItem value="1">Finalizado</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                <button
                  type="button"
                  onClick={() => setOpenSearch(true)}
                  className="btn btn-light"
                  style={{ width: "100%" }}
                >
                  Buscar{" "}
                  <i
                    className="fa fa-search"
                    style={{ marginLeft: "10px" }}
                  ></i>
                </button>
                {filter && (
                  <button
                    type="button"
                    onClick={() => {
                      loadRegisters();
                      setFilter(false);
                      setSearchStatus("Todos");
                      localStorage.setItem("filter@registers", "Todos");
                      submitSearchStatus("Todos");
                    }}
                    className="btn btn-warning"
                    style={{ width: "100%" }}
                  >
                    Remover Filtro{" "}
                    <i
                      className="fas fa-times"
                      style={{ marginLeft: "10px" }}
                    ></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  let data = JSON.parse(row.data);
                  let { value: razao_social } = data.find(
                    (e, i) => e.name === "razao_social"
                  );
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="left">
                        {formatName(razao_social)}
                      </TableCell>
                      <TableCell align="left">
                        {normalizeCnpj(row.cnpj)}
                      </TableCell>
                      <TableCell align="left">
                        {row.email
                          ? row.email
                          : row.customer && row.customer.email}
                        <br />
                        {row.phone
                          ? normalizePhone(row.phone)
                          : row.customer &&
                            row.customer.phone &&
                            normalizePhone(row.customer.phone)}
                      </TableCell>
                      <TableCell align="left">
                        {gerentes.find(g => g.GERE_ID === Number(row.gerente_id))?.NOME || 'SEM GERENTE'}
                        /
                        {row.user?.fullname || 'SEM AGENTE'}
                      </TableCell>
                      <TableCell align="center">
                        {progressRegister(row)}
                      </TableCell>
                      <TableCell align="center">
                        {statusRegisterCompleted(row.completed)}
                      </TableCell>
                      <TableCell align="center">
                        {format(new Date(row.updated_at), "dd/MM/yyyy")}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/registers/${row.id}`}
                          className="btn btn-sm pl-2 pr-2"
                        >
                          <VisibilityIcon />
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
