import { format } from 'date-fns';
import pt_BR from 'date-fns/locale/pt-BR';

import { normalizeCnpj } from './utils/normalize';

export function documentPf(member) {
  var mywindow = window.open('', 'PRINT');
  const publicPerson = member.in_politically_exposed_person === 'sim' ? true : false;
  mywindow.document.write(`
  <!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${member.fullname} - Formulário PF</title>
    <style>
      body {
        font-family: sans-serif;
      }
      .wrap {
        display:flex;
        justify-content: space-between;
        align-items: flex-end;
        text-align: left;
        margin:16px 0;
      }
      .wrap p {
        flex:1;
        margin:0 5px;
        border-bottom:1px solid #555;
        font-size:14px;
      }
      strong {
        color:#555;
        display: block;
        font-size:12px;
      }
      .center {
        text-align: center;
        display:block;
      }
      .border {
        border-bottom: 1px solid #000;
        text-indent: 10px;
      }
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size:26px;
      }
    </style>
  </head>
  <body>
    <h2 class="border">FICHA CADASTRAL – PESSOA FÍSICA</h2>
    <div style="padding:0 15px;">
      <h3 class="border">1. IDENTIFICAÇÃO</h3>
      <div class="wrap">
        <p>
          <strong>Nome Completo:</strong>
          ${member.fullname ? member.fullname : '-'}
        </p>
        <p>
          <strong>Data de Nascimento:</strong>
          ${member.date_of_birth ? member.date_of_birth : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>CPF:</strong>
          ${member.cpf ? member.cpf : '-'}
        </p>
        <p>
          <strong>Doc. Identidade:</strong>
          ${member.identity_doc ? member.identity_doc : '-'}
        </p>
        <p>
          <strong>Órgão Expedidor:</strong>
          ${member.issuing_agency ? member.issuing_agency : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Nacionalidade:</strong>
          ${member.nationality ? member.nationality : '-'}
        </p>
        <p>
          <strong>Profissão:</strong>
          ${member.profession ? member.profession : '-'}
        </p>
        <p>
          <strong>UF:</strong>
          ${member.uf ? member.uf : '-'}
        </p>
        <p>
          <strong>Estado Civil:</strong>
          ${member.marital_status ? member.marital_status : '-'}
        </p>
        ${member.marital_status === "Casado(a)" ? (
          `<p>
            <strong>Reg. Casamento:</strong>
            ${member.wedding_registry}
          </p>`
        ) : ""}
      </div>
      <div class="wrap">
        <p>
          <strong>Endereço:</strong>
          ${member.address ? member.address : '-'}
        </p>
        <p>
          <strong>Número:</strong>
          ${member.number ? member.number : '-'}
        </p>
        <p>
          <strong>Complemento:</strong>
          ${member.complement ? member.complement : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Bairro:</strong>
          ${member.neighborhood ? member.neighborhood : '-'}
        </p>
        <p>
          <strong>Cidade:</strong>
          ${member.city ? member.city : '-'}
        </p>
        <p>
          <strong>CEP:</strong>
          ${member.zipcode ? member.zipcode : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>E-mail:</strong>
          ${member.email ? member.email : '-'}
        </p>
        <p>
          <strong>Fone:</strong>
          ${member.phone ? member.phone : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Possui união estável neste momento:</strong>
          ${member.in_stable_union ? (member.in_stable_union === 'sim' ? 'Sim' : 'Não') : '-'}
        </p>
        <p>
          <strong>Enquadramento como pessoa politicamente exposta:</strong>
          ${member.in_politically_exposed_person ? (member.in_politically_exposed_person === 'sim' ? 'Sim' : 'Não') : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Nome da Mãe:</strong>
          ${member.mother_name ? member.mother_name : '-'}
        </p>
      </div>
      ${member.marital_status === "Casado(a)" && member.memberSpouses ? (
        `
        <h3 class="border">2. CÔNJUGE</h3>
      <div class="wrap">
        <p>
          <strong>Nome:</strong>
          ${member.memberSpouses.fullname ? member.memberSpouses.fullname : '-'}
        </p>
        <p>
          <strong>Data de Nascimento:</strong>
          ${member.memberSpouses.date_of_birth ? member.memberSpouses.date_of_birth : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>CPF:</strong>
          ${member.memberSpouses.cpf ? member.memberSpouses.cpf : '-'}
        </p>
        <p>
          <strong>Doc. Identidade</strong>
          ${member.memberSpouses.identity ? member.memberSpouses.identity : '-'}
        </p>
        <p>
          <strong>Órgão Expedidor:</strong>
          ${member.memberSpouses.identity_oe ? member.memberSpouses.identity_oe : '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Profissão/Atividade:</strong>
          ${member.memberSpouses.profession ? member.memberSpouses.profession : '-'}
        </p>
        <p>
          <strong>Celular:</strong>
          ${member.memberSpouses.phone ? member.memberSpouses.phone : '-'}
        </p>
        <p>
          <strong>E-mail:</strong>
          ${member.memberSpouses.email ? member.memberSpouses.email : '-'}
        </p>
      </div>
        `
      ) : ""}
      <hr class="border"/>
      <strong>
        Declaramos, sob as penas da lei, que as informações prestadas são verdadeiras, e nos comprometemos a informar, no prazo de 10 (dez) dias, quaisquer alterações que vierem a ocorrer nos dados cadastrais da empresa, bem como autorizamos o BFC FIDC / BFC Capital Partners a consultar as fontes de referência informadas, (clientes, fornecedores e bancos) e inserir / solicitar informações relacionadas com nossa empresa e coligadas, junto ao mercado financeiro e entidades cadastrais em geral, inclusive junto ao SCR-Sistema de Informações de Crédito do Banco Central  do Brasil (Res. 3.658 do Conselho Monetário Nacional), SERASA ou quaisquer outros órgãos ou entidades assemelhadas.
      </strong>
      <br />
      <div class="wrap">
        <span class="center" style="margin:0 5px;">
          _____________________________<br />
          Local
        </span>
        <span>
          ${format(new Date(), 'dd/MM/yyyy')}<br />
          <span class="center">Data</span>
        </span>
        <span class="center" style="margin:0 5px;">
          _____________________________<br />
          Assinatura
        </span>
      </div>
      <strong class="center">Av. Eng. Domingos Ferreira, 2589 / Sala 1001, Boa Viagem, Recife-PE – CEP: 51.020-031
        <br/>PABX: +55 (81) 2119-9595 / 4007-2614 / www.grupobfc.com.br</strong>
    </div>
    <style>
  .wrap-dec {
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
  }
  .wrap-dec div {
    text-align: center;
    padding:5px;
  }
  .wrap-dec div hr {
    border-color:#000;
    border:0px;
    border-top:1px solid #000;
  }
  .item-dec {
    display:flex;
    margin:15px 5px;
  }
  .item-dec span {
    display:flex;
    justify-content: center;
    align-items: center;
    width:15px !important;
    height:15px !important;
    margin-right:5px;
    border:1px solid #000;
  }
  .container-dec {
    border: 1px solid #000;
  }
  .container-dec h2 {
    border-bottom: 1px solid #000;
    padding:5px;
    margin: 0;
  }
  .item-dec p {
    margin:0;
    padding:0px 5px;
    width:95%;
    font-size:14px;
  }
</style>
      <div class="container-dec" style="margin-top:300px;">
  <h2>Declaração / Autorização</h2>
  <div class="item-dec">
    <span>
      ${publicPerson ? 'x' : ''}
    </span>
    <p>Declaro a minha condição de enquadramento como pessoa politicamente exposta (PPE), ou 
    a esta relacionado, a que se refere a Resolução n° 016, de 28/03/2007</p>
  </div>
  <div class="item-dec">
    <span>
      ${!publicPerson ? 'x' : ''}
    </span>
    <p>Declaro minha condição de não enquadramento na condição de PPE, ou a esta relacionado.
     Estou ciente da obrigação de comunicar, incontinente, qualquer alteração dessa condição atual.</p>
  </div>
  <div class="item-dec">
    <span>
      ${publicPerson ? 'x' : ''}
    </span>
    <p>Declaro manter ligação com as pessoas / entidades a que se refere a Resolução n° 015, de 
    28/03/2007.</p>
  </div>
  <div class="item-dec">
    <span>
      ${!publicPerson ? 'x' : ''}
    </span>
    <p>Declaro não manter ligação com pessoas / entidades a que se refere a Resolução 015, 
    de 28/03/2007, do COAF.<br />
    Estou ciente da obrigação de comunicar, incontinenti, caso venha a manter ligação com 
    aquelas pessoas / entidades.</p>
  </div>
  <div class="item-dec">
    <span>
      x
    </span>
    <p>Declaro que as informações prestadas acima, inclusive as cadastrais, são expressão da verdade.</p>
  </div>
  <div class="item-dec">
    <span>
      x
    </span>
    <p>Declaro sob pena de responder Civil e Criminalmente que as informações prestadas são expressão da 
    verdade e autorizo o envio dos dados à SERASA - Centralização dos Serviços dos Bancos.</p>
  </div>
  <br />
  <br />
  <div class="wrap-dec">
    <div style="width:100%;">
      <hr />
      ${member.fullname}
    </div>
    <div style="width:100%;">
      <hr />
      Local e data
    </div>
  </div>
</div>
  </body>
  </html>
  `);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}
export function printPjDocument(register) {
  var mywindow = window.open('', 'PRINT');
  function findByName(name) {
    const value = register.dados.find(item => item.name === name);
    if(value) {
      return value.value;
    }else{
      return "";
    }
  }
  mywindow.document.write(`
  <!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${register.cnpj} - Ficha PJ</title>
    <style>
      body {
        font-family: sans-serif;
      }
      .wrap {
        display:flex;
        justify-content: flex-start;
        align-items: flex-end;
        text-align: left;
        margin:16px 0;
      }
      .wrap p {
        flex:1;
        margin:0 5px;
        border-bottom:1px solid #555;
        font-size:14px;
      }
      strong {
        color:#555;
        display: block;
        font-size:12px;
        text-align: left;
        flex:1;
      }
      .wrap span {
        flex: 1;
      }
      .center {
        text-align: center;
        display:block;
      }
      .border {
        border-bottom: 1px solid #000;
        text-indent: 10px;
      }
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size:26px;
      }
    </style>
  </head>
  <body>
    <h2 class="border">FICHA CADASTRAL – PESSOA JURÍDICA</h2>
    <div style="padding:0 15px;">
      <h3 class="border">1. EMPRESA</h3>
      <div class="wrap">
        <p>
          <strong>Razão Social:</strong>
          ${findByName('razao_social')}
        </p>
        <p>
          <strong>Nome Fantasia:</strong>
          ${findByName('nome_fantasia')}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>CNPJ:</strong>
          ${register.cnpj}
        </p>
        <p>
          <strong>Insc. Estadual:</strong>
          ${findByName('insc_estadual')}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Endereço:</strong>
          ${findByName('endereco')}
        </p>
        <p>
          <strong>Número:</strong>
          ${findByName('numero')}
        </p>
        <p>
          <strong>Complemento:</strong>
          ${findByName('complemento') || '-'}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Bairro:</strong>
          ${findByName('bairro')}
        </p>
        <p>
          <strong>Cidade:</strong>
          ${findByName('cidade')}
        </p>
        <p>
          <strong>CEP:</strong>
          ${findByName('cep')}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>E-mail da empresa:</strong>
          ${findByName('email_empresa')}
        </p>
        <p>
          <strong>Telfone da empresa:</strong>
          ${findByName('telefone_da_empresa')}
        </p>
        <p>
          <strong>Site:</strong>
          ${findByName('site')}
        </p>
        <p>
          <strong>Data Fundação:</strong>
          ${findByName('data_fundacao')}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Contato</strong>
          ${register.name}
        </p>
        <p>
          <strong>Email</strong>
          ${register.email}
        </p>
        <p>
          <strong>Telefone</strong>
          ${register.phone}
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Faturamento Mensal:</strong>
          ${findByName('faturamento_mensal')}
        </p>
        <p>
          <strong>Nº Funcionários:</strong>
          ${findByName('n_funcionarios')}
        </p>
      </div>
      <h3 class="border">2. DADOS BANCÁRIOS PARA CRÉDITO</h3>
      <div class="wrap">
          <strong>Banco:</strong>
          <strong>Agência:</strong>
          <strong>Conta Corrente:</strong>
          <strong>Dígito Conta Corrente:</strong>
      </div>
      ${findByName('bancos').map(forn => (
        `<div class="wrap">
        ${forn.map(banco => (
          `
            <p>
              ${banco.value}
            </p>
          `)).join('')}
        </div>`
      )).join('')}
      <h3 class="border">4. SÓCIOS</h3>
      <div class="wrap">
          <strong>Nome:</strong> 
          <strong>CPF/CNPJ:</strong>
          <strong>Representante:</strong>
      </div>

      ${register.members.map(member => {
        if(member.type !== 3) {
          return `<div class="wrap">
          <p>${member.fullname}</p>
          <p>${member.cpf}</p>
          <p>${member.type === 2 ? 'Sim' : 'Não'}</p>
        </div>`;
        }else{
          return '';
        }
      }).join('')}
      <h3 class="border">5. FIADORES / AVALISTAS</h3>
      <div class="wrap">
          <strong>Nome:</strong>
          <strong>CPF:</strong>
      </div>

      ${register.members.find(member => member.type === 3) ? register.members.map(member => {
        if(member.type === 3) {
          return `<div class="wrap">
          <p>${member.fullname}</p>
          <p>${member.cpf}</p>
        </div>`;
        }else{
          return '';
        }
      }).join('') : (
        `
        <div class="wrap">
        <p>-</p>
        <p>-</p>
        </div>
        `
      )}
      <h3 class="border">6. INFORMAÇÕES FINANCEIRAS</h3>
      <div class="wrap">
          <strong>Empresa:</strong>
          <strong>Tipo de negócio:</strong>
          <strong>Fone:</strong>
      </div>
      ${findByName('referencias_comerciais').map(forn => (
        `<div class="wrap">
        ${forn.map(fornecedor => (
          `
            <p>
              ${fornecedor.value}
            </p>
          `)).join('')}
        </div>`
      )).join('')}
      <hr class="border"/>
      <strong>
        Declaramos, sob as penas da lei, que as informações prestadas são verdadeiras, e nos comprometemos a informar, no prazo de 10 (dez) dias, quaisquer alterações que vierem a ocorrer nos dados cadastrais da empresa, bem como autorizamos o BFC FIDC / BFC Capital Partners a consultar as fontes de referência informadas, (clientes, fornecedores e bancos) e inserir / solicitar informações relacionadas com nossa empresa e coligadas, junto ao mercado financeiro e entidades cadastrais em geral, inclusive junto ao SCR-Sistema de Informações de Crédito do Banco Central  do Brasil (Res. 3.658 do Conselho Monetário Nacional), SERASA ou quaisquer outros órgãos ou entidades assemelhadas.
      </strong>
      <br />
      <div class="wrap">
        <span class="center" style="margin:0 5px;">
          _____________________________<br />
          Local
        </span>
        <span class="center">
          ${format(new Date(), 'dd/MM/yyyy')}<br />
          Data
        </span>
        <span class="center" style="margin:0 5px;">
          _____________________________<br />
          Assinatura
        </span>
      </div>
      <strong class="center">Av. Eng. Domingos Ferreira, 2589 / Sala 1001, Boa Viagem, Recife-PE – CEP: 51.020-031
        <br/>PABX: +55 (81) 2119-9595 / 4007-2614 / www.grupobfc.com.br</strong>
    </div>
  </body>
  </html>
`);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}
export function viewContract(register, fiel_depositario, fiadores, outorgantes) {
  var mywindow = window.open('', 'PRINT');

  function findByName(name) {
    const value = register.dados.find(item => item.name === name);
    if(value) {
      return value.value;
    }else{
      return "-";
    }
  }

  const { members } = register;

  let memberFielDepositario = null
  members.map(e => {
    if (e.type === 2){
        return memberFielDepositario = e
    }else {
      return '';
    }
  })
  
  const representantes = members.filter(member => member.type === 2);

  mywindow.document.write(`
    <!DOCTYPE html>
      <html lang="pt-BR">
      <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>${register.name} - CONTRATO</title>
      <style>
        .wrap {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          wrap: wrap;
        }
        p {
          text-align: left;
        }
        .wrap p {
          margin:5px;
        }
      </style>
      </head>
      <div class="page">
      <strong>
      <p style="text-align:center">&nbsp;</p>

      <p style="text-align:center">CONTRATO DE CESS&Atilde;O E AQUISI&Ccedil;&Atilde;O</p>

      <p style="text-align:center">DE DIREITOS DE CR&Eacute;DITO E OUTRAS AVEN&Ccedil;AS</p>

      <p style="text-align:center">COM COOBRIGA&Ccedil;&Atilde;O</p>


      <p style="text-align:center">N&ordm;. ${register.clie_id}</p>


      <p style="text-align:center">Entre</p>

      <p><br />
      &nbsp;</p>

      <p style="text-align:center">${findByName('razao_social')}</p>

      <p style="text-align:center"><em>como Cedente,</em></p>

      <p><br />
      &nbsp;</p>

      <p style="text-align:center">BFC FUNDO DE INVESTIMENTO EM DIREITOS CREDIT&Oacute;RIOS NP</p>

      <p style="text-align:center"><em>como Cession&aacute;rio,</em></p>

      <p><br />
      &nbsp;</p>

      <p style="text-align:center">SOCOPA SOCIEDADE CORRETORA PAULISTA SA</p>

      <p style="text-align:center"><em>como Administradora,</em></p>

      <p><br />
      &nbsp;</p>
      ${members.map(member => ( `<p style="text-align:center">${member.fullname}</p>` )).join("")}

      <p style="text-align:center"><em>como Devedores Solid&aacute;rios, Fiadores e Fi&eacute;is Deposit&aacute;rios.</em></p>


      <p style="text-align:center">e</p>


      <p style="text-align:center">BFC CAPITAL PARTNERS</p>


      <p style="text-align:center">TERCON INVESTIMENTOS LTDA</p>


      <p style="text-align:center"><em>como intervenientes,</em></p>

      <p><br />
      &nbsp;</p>

      <p style="text-align:center">________________________</p>


      <p style="text-align:center">Data</p>


      <p style="text-align:center">${format(new Date(), "d 'de' MMMM 'de' yyyy", { locale: pt_BR })}</p>

      <p style="text-align:center">________________________</p>

      </div>

      <p style="text-align:center">&nbsp;</p>

      <p style="text-align:center">CONTRATO DE CESS&Atilde;O E AQUISI&Ccedil;&Atilde;O</p>

      <p style="text-align:center">DE DIREITOS DE CR&Eacute;DITO E OUTRAS AVEN&Ccedil;AS</p>

      <p><br />
      &nbsp;</p>
      </strong>
      <p style="text-align:justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pelo presente instrumento particular:</p>


      <p style="text-align:justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(i) como Partes:</p>


      <p style="text-align:justify">(a) <strong>${findByName('razao_social')}</strong>, sociedade limitada, com sede na ${findByName('endereco')},${findByName('numero')},${findByName('bairro')}, Cep: ${findByName('cep')}, na Cidade de ${findByName('cidade')}, inscrita no CNPJ sob o n&deg; ${findByName('cnpj')}, neste ato representada na forma de seu contrato social (a &ldquo;Cedente&rdquo;);</p>


      <p style="text-align:justify">(b) <strong>BFC FUNDO DE INVESTIMENTO EM DIREITOS CREDIT&Oacute;RIOS NP</strong>, fundo de investimento em direitos credit&oacute;rios, inscrito no CNPJ sob o n&uacute;mero 11.507.489/0001-09, constitu&iacute;do sob a forma de condom&iacute;nio fechado, neste ato representado na forma do seu Regulamento pela <strong>Administradora, SOCOPA Sociedade Corretora Paulista S.A.</strong>, institui&ccedil;&atilde;o financeira devidamente autorizada para tanto, com sede na cidade de S&atilde;o Paulo, na Av. Brigadeiro Faria Lima n&ordm; 1355, 3&ordm;. andar, inscrita no CNPJ/MF sob o n.&ordm; 62.285.390/0001-40, neste ato representada na forma de seu estatuto social (a &ldquo;Administradora&rdquo;);&nbsp;</p>

      <p style="text-align:justify">(c) ${representantes.map(representante => (
        `<strong>${representante.fullname}</strong>, ${representante.nationality}, ${representante.marital_status}, ${representante.profession}, portador(a) da cédula de identidade n&deg; ${representante.identity_doc} ${representante.issuing_agency}/${representante.issuing_agency_uf}, inscrito no CPF/MF sob o n&deg; ${representante.cpf}, residente e domiciliado(a) ${representante.address} &agrave; ${representante.res} ano(s), ${representante.number}, ${representante.neighborhood}, ${representante.city}`
      ))}
      .</p>

      <p style="text-align:justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(ii) &nbsp; &nbsp; e, como Intervenientes:</p>


      <p style="text-align:justify">(d) <strong>BFC CAPITAL PARTNERS</strong>, sociedade limitada com sede na cidade de Recife, Estado de Pernambuco, na Avenida Engenheiro Domingos Ferreira, 2589, Sala 1001 - Boa Viagem, Recife - PE, 51020-030, inscrita no CNPJ/MF sob o n&ordm;. 01.410.785/0001-33, neste ato representada na forma de seu contrato social; Empresa Especializada de An&aacute;lise, doravante denominada como interveniente, e</p>


      <p style="text-align:justify">(e) <strong>TERCON INVESTIMENTOS LTDA</strong>, devidamente autorizada pela CVM, para o exerc&iacute;cio profissional de administra&ccedil;&atilde;o de carteira de valores mobili&aacute;rios, inscrita no CNPJ/MF sob o n&ordm; 09.121.454/0001-95, com sede na Rua Am&eacute;rico Brasiliense, 1765, 5&ordm; andar, CEP 04715-005, Cidade de S&atilde;o Paulo, Estado de S&atilde;o Paulo, devidamente representada de acordo com seu Contrato Social. &ldquo;Gestora&rdquo;, doravante denominada Interveniente Anuente e em conjunto com a Cedente, o Cession&aacute;rio, a Administradora e os Devedores Solid&aacute;rios e Fiadores, a Interveniente, as &ldquo;Partes&rdquo; e, individualmente, a &ldquo;Parte&rdquo;;</p>

      <p style="text-align:justify">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

      <p style="text-align:justify">CONSIDERANDO QUE o Cession&aacute;rio &eacute; um fundo de investimento em direitos credit&oacute;rios constitu&iacute;do sob a forma de condom&iacute;nio fechado, nos termos da Resolu&ccedil;&atilde;o CMN 2.907, de 29 de novembro de 2001, do Conselho Monet&aacute;rio Nacional, e da Instru&ccedil;&atilde;o CVM 356, de 17 de dezembro de 2001, cujo funcionamento encontra-se devidamente autorizado conforme registro n&ordm; 394-8, concedido pela CVM em 02/03/2010;</p>


      <p style="text-align:justify">CONSIDERANDO QUE a Cedente atua no ramo empresarial: <strong>DIVERSOS</strong>; e por meio da explora&ccedil;&atilde;o de suas atividades &eacute; capaz de originar direitos de cr&eacute;dito contra seus clientes (os &ldquo;<u>Devedores</u>&rdquo;), representados por cheques, duplicatas, notas promiss&oacute;rias, c&eacute;dulas de cr&eacute;dito banc&aacute;rio, certificado de receb&iacute;vel imobili&aacute;rio, boletos de cart&atilde;o de cr&eacute;dito, c&eacute;dula de produto rural financeira e outros t&iacute;tulos de cr&eacute;dito ou instrumentos contratuais representativos de cr&eacute;dito (os &ldquo;<u>Direitos de Cr&eacute;dito</u>&rdquo;);</p>


      <p style="text-align:justify">CONSIDERANDO QUE o Regulamento prev&ecirc; emiss&otilde;es de Quotas Seniores e Quotas Subordinadas, e parte ou a totalidade dos recursos captados pelo Cession&aacute;rio com as referidas emiss&otilde;es ser&atilde;o utilizados para a aquisi&ccedil;&atilde;o de direitos de cr&eacute;dito, de acordo com a Pol&iacute;tica de Investimento prevista no Regulamento;</p>


      <p style="text-align:justify">CONSIDERANDO QUE o Cession&aacute;rio considera adquirir Direitos de Cr&eacute;dito da Cedente; e</p>


      <p style="text-align:justify">CONSIDERANDO QUE o Cession&aacute;rio contratou a Consultoria Especializada, para a presta&ccedil;&atilde;o de servi&ccedil;os de apoio na an&aacute;lise, sele&ccedil;&atilde;o de Direitos de Cr&eacute;dito para auxili&aacute;-lo nas referidas atividades.</p>


      <p style="text-align:justify">ISTO POSTO, resolvem as Partes celebrar o presente Contrato de Cess&atilde;o e Aquisi&ccedil;&atilde;o de Direitos de Cr&eacute;dito e Outras Aven&ccedil;as (o &ldquo;<u>Contrato de Cess&atilde;o</u>&rdquo;), que ser&aacute; regido pelas cl&aacute;usulas e condi&ccedil;&otilde;es a seguir dispostas.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA I<br />DEFINI&Ccedil;&Otilde;ES</h2>


      <p style="text-align:justify">1.1 Os termos iniciados em letras mai&uacute;sculas e utilizados neste Contrato (estejam no singular ou no plural), que n&atilde;o sejam diversamente definidos neste Contrato, ter&atilde;o os significados que lhes s&atilde;o atribu&iacute;dos no regulamento do Fundo, elaborado nos termos na Resolu&ccedil;&atilde;o CMN 2.907 e da Instru&ccedil;&atilde;o CVM 356 (o &ldquo;<u>Regulamento</u>&rdquo;).</p>

      <br /><br />
      <h2 style="text-align:center">CL&Aacute;USULA II

      <br />OBJETO E QUALIFICA&Ccedil;&Atilde;O&nbsp;

      <br />DOS DIREITOS DE CR&Eacute;DITO CEDIDOS</h2>


      <p style="text-align:justify">2.1 A Cedente e /ou suas filiais conforme Anexo I, neste ato, nos termos deste Contrato, de acordo com sua conveni&ecirc;ncia poder&aacute; ceder e transferir de tempos em tempos ao Cession&aacute;rio, que observado os termos de seu Regulamento poder&aacute; adquirir Direitos de Cr&eacute;dito do Cedente.&nbsp;</p>
      <p style="text-align:justify">2.2 Cada cess&atilde;o de Direitos de Cr&eacute;dito nos termos do presente Contrato ser&aacute; realizada mediante a celebra&ccedil;&atilde;o de um Termo de Cess&atilde;o (o &ldquo;<u>Termo de Cess&atilde;o</u>&rdquo;), conforme modelo anexo a este Contrato de Cess&atilde;o (Anexo I), a ser celebrado entre o Fundo e a Cedente, observando o procedimento previsto nas Cl&aacute;usulas V e VI abaixo:</p>
      <p style="text-align:justify">2.2.1 Em cada Termo de Cess&atilde;o dever&aacute; constar a rela&ccedil;&atilde;o dos Direitos de Cr&eacute;dito cedidos, identificados pelo seu valor nominal, data de vencimento, t&iacute;tulo representativo do Direito de Cr&eacute;dito, nome e Cadastro de Pessoa F&iacute;sica (&ldquo;<u>CPF/MF</u>&rdquo;) ou Cadastro Nacional de Pessoa Jur&iacute;dica (&ldquo;<u>CNPJ/MF</u>&rdquo;) de cada Devedor.</p>

      <p style="text-align:justify">2.3. Cada uma das Cess&otilde;es de Direitos de Cr&eacute;dito realizadas no &acirc;mbito deste Contrato de Cess&atilde;o&nbsp; &nbsp; compreende:</p>
      <p style="text-align:justify">a) Os Documentos Comprobat&oacute;rios (abaixo definido) que lastrearam os Direitos de Cr&eacute;dito; e</p>
      <p style="text-align:justify">b) Todos os direitos decorrentes de qualquer garantia acess&oacute;ria, real ou fidejuss&oacute;ria, vinculada aos Direitos de Cr&eacute;dito, bem como os respectivos instrumentos constitutivos.</p>

      <p style="text-align:justify">2.4 A realiza&ccedil;&atilde;o de cada cess&atilde;o de Direitos de Cr&eacute;dito prevista neste Contrato de Cess&atilde;o est&aacute; sujeita ao cumprimento, cumulativamente, das seguintes condi&ccedil;&otilde;es precedentes:</p>

      <p style="text-align:justify">(a) an&aacute;lise e sele&ccedil;&atilde;o pr&eacute;via e aprova&ccedil;&atilde;o dos Direitos de Cr&eacute;dito pela Gestora e pela Consultoria Especializada;</p>

      <p style="text-align:justify">(b) os Direitos de Cr&eacute;dito dever&atilde;o atender aos Crit&eacute;rios de Elegibilidade e Condi&ccedil;&otilde;es de Cess&atilde;o estabelecidos no Regulamento;</p>

      <p style="text-align:justify">(c) o objetivo do Fundo, sua politica de investimento e os crit&eacute;rios de composi&ccedil;&atilde;o da carteira previstos no Regulamento;</p>

      <p style="text-align:justify">(d) exist&ecirc;ncia de Disponibilidades pelo Cession&aacute;rio; e</p>

      <p style="text-align:justify">(e) os termos e condi&ccedil;&otilde;es previstas neste Contrato de Cess&atilde;o.</p>


      <p style="text-align:justify">2.5. Nenhum Direito de Cr&eacute;dito poder&aacute; ser adquirido pelo Fundo sem a aprova&ccedil;&atilde;o da Gestora e da Consultoria Especializada, devendo estas proceder &agrave; an&aacute;lise dos direitos credit&oacute;rios ofertados ao Fundo de acordo com os prazos e condi&ccedil;&otilde;es estabelecidos no Regulamento.</p>


      <p style="text-align:justify">2.6&nbsp; &nbsp; Cada cess&atilde;o de Direitos de Cr&eacute;dito nos termos do presente Contrato ser&aacute; realizada em car&aacute;ter irrevog&aacute;vel e irretrat&aacute;vel, ficando o Cession&aacute;rio automaticamente sub-rogado em todos os direitos, garantias, privil&eacute;gios, prefer&ecirc;ncias e prerrogativas conferidas aos Direitos de Cr&eacute;dito cedidos.</p>


      <p style="text-align:justify">2.7 A Cedente responder&aacute;, civil e criminalmente, pela origina&ccedil;&atilde;o, exist&ecirc;ncia e correta formaliza&ccedil;&atilde;o dos Direitos de Cr&eacute;dito e dos t&iacute;tulos a eles relativos, bem como pela liquidez, certeza, exigibilidade e pagamento dos valores a eles referentes, na qualidade de devedora solid&aacute;ria dos Devedores dos Direitos de Cr&eacute;dito, na forma da Cl&aacute;usula XI abaixo, nos termos do Artigo 295 do C&oacute;digo Civil Brasileiro&nbsp;</p>


      <p style="text-align:justify">2.8 O presente Contrato n&atilde;o constitui obriga&ccedil;&atilde;o ou promessa de cess&atilde;o pela Cedente ou de aquisi&ccedil;&atilde;o pelo Cession&aacute;rio de Direitos de Cr&eacute;dito, ficando cada cess&atilde;o sujeita, al&eacute;m do atendimento &agrave;s demais condi&ccedil;&otilde;es estabelecidas neste Contrato, &agrave; fixa&ccedil;&atilde;o de comum acordo pelo Cession&aacute;rio e pela Cedente do respectivo Pre&ccedil;o de Aquisi&ccedil;&atilde;o, conforme previsto na Cl&aacute;usula V abaixo.</p>

      <br /><br />
      <h2 style="text-align:center">CL&Aacute;USULA III

      <br />DO ENDOSSO</h2>


      <p>3.1. Para cada cess&atilde;o de Direitos de Cr&eacute;dito representados por t&iacute;tulos de cr&eacute;dito com a cl&aacute;usula &agrave; ordem, al&eacute;m da celebra&ccedil;&atilde;o entre as Partes de um Termo de Cess&atilde;o na forma prevista no item 2.1 acima, a Cedente dever&aacute; realizar o endosso pleno e em preto, de cada t&iacute;tulo de cr&eacute;dito em favor do Cession&aacute;rio, que passa a ser seu &uacute;nico e leg&iacute;timo propriet&aacute;rio.</p>

      <p>3.1.1. A Cedente responde por todas as obriga&ccedil;&otilde;es inerentes ao endosso, nos termos da legisla&ccedil;&atilde;o aplic&aacute;vel &agrave; esp&eacute;cie.&nbsp;</p>

      <br /><br />
      <h2 style="text-align:center">CL&Aacute;USULA IV

      <br />NOTIFICA&Ccedil;&Atilde;O DO DEVEDOR</h2>
      <p style="text-align:justify">4.1. A Cedente se obriga a dar ci&ecirc;ncia aos respectivos Devedores de cada cess&atilde;o realizada nos termos da Cl&aacute;usula II acima, em at&eacute; 5 (cinco) dias &uacute;teis anteriores &agrave; data do respectivo vencimento de cada Direito de Cr&eacute;dito, informando-lhes que os pagamentos dever&atilde;o ser realizados exclusivamente ao Cession&aacute;rio, ou &agrave; sua ordem.</p>
      <p style="text-align:justify">4.1.1. Sem preju&iacute;zo do previsto no item 4.1 acima, o Cession&aacute;rio fica autorizado a notificar os Devedores, a qualquer tempo, das cess&otilde;es realizadas nos termos deste Contrato de Cess&atilde;o.</p>
      <p style="text-align:justify">4.2. Caso a Cedente receba quaisquer valores relativos aos Direitos de Cr&eacute;dito de titularidade do Cession&aacute;rio, dever&aacute; transferir tais valores ao Cession&aacute;rio no prazo de 24 (vinte e quatro) horas do seu recebimento, sem qualquer dedu&ccedil;&atilde;o ou desconto, a qualquer t&iacute;tulo, sendo certo que em caso de recebimento de tais valores, a Cedente aceita sua nomea&ccedil;&atilde;o como fiel deposit&aacute;ria at&eacute; a sua efetiva transfer&ecirc;ncia ao Cession&aacute;rio.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA V

      <br />PAGAMENTO DA CESS&Atilde;O</h2>


      <p style="text-align:justify">5.1 Para cada uma das cess&otilde;es realizadas nos termos da Cl&aacute;usula II acima, o respectivo Termo de Cess&atilde;o estabelecer&aacute; (i) o valor a ser pago pelo Cession&aacute;rio &agrave; Cedente (o &ldquo;<u>Pre&ccedil;o de Aquisi&ccedil;&atilde;o</u>&rdquo;), (ii) a conta-corrente de titularidade da Cedente para dep&oacute;sito do pagamento e (iii) as demais condi&ccedil;&otilde;es previstas no Anexo I deste Contrato de Cess&atilde;o</p>

      <p style="text-align:justify">5.1.1. O Pre&ccedil;o de Aquisi&ccedil;&atilde;o ser&aacute; integralmente pago &agrave; Cedente, em moeda corrente nacional, nos prazos e de acordo com as demais condi&ccedil;&otilde;es previstas no respectivo Termo de Cess&atilde;o.</p>


      <p style="text-align:justify">5.2. O Fundo tornar-se-&aacute; titular dos Direitos de Cr&eacute;dito e pagar&aacute; ao Cedente, em contrapartida &agrave; cess&atilde;o dos Direitos de Cr&eacute;dito, o pre&ccedil;o apurado na forma do item 5.1 acima, em raz&atilde;o do que o Cedente dar&aacute; ao Fundo a mais ampla, geral, irrevog&aacute;vel quita&ccedil;&atilde;o, servindo os comprovantes de dep&oacute;sito, via transfer&ecirc;ncia eletr&ocirc;nica de recursos ou outra autorizada pelo BACEN, como recibo de pagamento e quita&ccedil;&atilde;o.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA VI

      <br />DOCUMENTOS COMPROBAT&Oacute;RIOS</h2>


      <p style="text-align:justify">6.1 Observado o previsto neste Contrato, a Cedente dever&aacute; entregar ao Cession&aacute;rio, ou a quem este indicar, previamente a celebra&ccedil;&atilde;o de cada Termo de Cess&atilde;o, toda a documenta&ccedil;&atilde;o necess&aacute;ria &agrave; comprova&ccedil;&atilde;o do lastro dos Direitos de Cr&eacute;ditos cedidos, incluindo, mas n&atilde;o se limitando, aos contratos, instrumentos, t&iacute;tulos de cr&eacute;dito representativos dos respectivos Direitos de Cr&eacute;dito, anexos, seguros, garantias&nbsp; e quaisquer outros documentos relacionados aos Direitos de Cr&eacute;dito (os &ldquo;<u>Documentos Comprobat&oacute;rios</u>&rdquo;).</p>

      <p style="text-align:justify">6.1.1. Todos os documentos relacionados aos Direitos de Cr&eacute;dito, especialmente os originais das notas fiscais e dos comprovantes de entrega das mercadorias ou dos servi&ccedil;os que deram origem a emiss&atilde;o dos t&iacute;tulos de cr&eacute;dito ser&atilde;o entregues ao Custodiante para guarda, de acordo com a legisla&ccedil;&atilde;o vigente, e este dar&aacute; acesso aos documentos para as Auditorias, Empresas de Cobran&ccedil;a e outras interessadas, atrav&eacute;s de solicita&ccedil;&atilde;o pr&eacute;via.</p>

      <p style="text-align:justify">6.1.2. Os Termos de Cess&atilde;o, assim como os T&iacute;tulos de Cr&eacute;ditos, poder&atilde;o ser encaminhados de forma eletr&ocirc;nica/digital desde que produzidos com a utiliza&ccedil;&atilde;o de processos de certifica&ccedil;&atilde;o disponibilizados pela ICP-BRASIL.</p>

      <p style="text-align:justify">6.1.3. As notas fiscais poder&atilde;o ser transmitidas eletronicamente ao Cession&aacute;rio, ou a quem este indicar, atrav&eacute;s do arquivo XML.</p>


      <p style="text-align:justify">6.2. &nbsp; &nbsp; &nbsp; &nbsp; A Cedente, neste ato, nomeia como Fiel Deposit&aacute;rio o[s] seu[s] s&oacute;cio,&nbsp;</p>

      ${
        representantes.map(representante => (
          `<p style="text-align:justify"><strong>${representante.fullname}</strong>, ${representante.nationality}, ${representante.marital_status}, ${representante.profession}, portador(a) da cédula de identidade n&deg; ${representante.identity_doc} ${representante.issuing_agency}/${representante.issuing_agency_uf}, inscrito no CPF/MF sob o n&deg; ${representante.cpf}, residente e domiciliado(a) ${representante.adress} &agrave; ${representante.res} ano(s), ${representante.number}, ${representante.neighborhood}, ${representante.city}, que se obriga a manter sob sua guarda e responsabilidade os documentos referidos no item 6.1.1. acima, observado o disposto no artigo 638 do C&oacute;digo Civil e no artigo 168 do C&oacute;digo penal.</p>`
        ))
      }

      <p style="text-align:justify">6.3 Na hip&oacute;tese de pagamento pela Cedente ou pelo Devedor Solid&aacute;rio de qualquer Direito de Cr&eacute;dito vencido e n&atilde;o pago, o Cession&aacute;rio disponibilizar&aacute;, em at&eacute; 30 (trinta) dias do recebimento do pagamento, &agrave; Cedente ou ao Devedor Solid&aacute;rio, conforme o caso, os respectivos Documentos Comprobat&oacute;rios do Direito de Cr&eacute;dito liquidado.&nbsp;&nbsp;</p>

      <br /><br />
      <h2 style="text-align:center">CL&Aacute;USULA VII

      <br />PRAZO DE VIG&Ecirc;NCIA</h2>


      <p style="text-align:justify">7.1 O presente Contrato vigorar&aacute; por prazo indeterminado, a partir desta data, podendo ser resilido pela Cedente ou pelo Cession&aacute;rio mediante notifica&ccedil;&atilde;o pr&eacute;via de 30 (trinta) dias &agrave;s demais Partes e &agrave; Consultoria Especializada,&nbsp; ficando certo que, caso ocorra a rescis&atilde;o deste Contrato de Cess&atilde;o nos termos da Cl&aacute;usula X abaixo ou por algum outro motivo, o Cession&aacute;rio e o Devedor Solid&aacute;rio permanecer&atilde;o respons&aacute;veis pelo cumprimento de todas as obriga&ccedil;&otilde;es assumidas nos termos deste Contrato de Cess&atilde;o at&eacute; sua integral liquida&ccedil;&atilde;o.</p>

      <br /><br />
      <h2 style="text-align:center">CL&Aacute;USULA VIII

      <br />INADIMPLEMENTO</h2>


      <p style="text-align:justify">8.1 O inadimplemento, por qualquer das Partes, de qualquer obriga&ccedil;&atilde;o de pagamento prevista neste Contrato de Cess&atilde;o caracterizar&aacute;, de pleno direito, e independentemente de qualquer aviso ou notifica&ccedil;&atilde;o, a mora de tal parte, sujeitando-se ao pagamento dos seguintes encargos:</p>

      <p style="text-align:justify">(a) juros de mora de 1% (um por cento) ao m&ecirc;s, calculados <em>pro rata temporis desde a data em que o pagamento tornou-se exig&iacute;vel at&eacute; o seu integral recebimento pelo respectivo credor;</em></p>

      <p style="text-align:justify">(b) multa convencional, n&atilde;o compensat&oacute;ria, de 2% (dois por cento); e</p>

      <p style="text-align:justify">(c) em qualquer hip&oacute;tese, o valor devido ser&aacute; corrigido monetariamente a partir da data de seu vencimento original com atualiza&ccedil;&atilde;o monet&aacute;ria pela taxa Selic,&nbsp; e apropriado, se for o caso, <em>pro rata temporis, </em>ou por outro &iacute;ndice que vier a substitu&iacute;-lo, no caso de sua extin&ccedil;&atilde;o.</p>


      <p style="text-align:justify">8.2 O inadimplemento, por qualquer das Partes, de quaisquer das obriga&ccedil;&otilde;es de dar, fazer ou n&atilde;o fazer assumidas nos termos deste Contrato de Cess&atilde;o, do qual tenha sido notificada para regularizar e n&atilde;o o fa&ccedil;a no prazo assinado na respectiva notifica&ccedil;&atilde;o e/ou aviso, prazo este que, em nenhuma hip&oacute;tese, ser&aacute; inferior a 03 (tr&ecirc;s) dias consecutivos, obrigar&aacute; a parte que der causa ao inadimplemento ao pagamento, &agrave; parte prejudicada, de uma multa convencional, n&atilde;o compensat&oacute;ria, de R$.1.000,00 (um mil reais) por dia de atraso no cumprimento de tais obriga&ccedil;&otilde;es, sem preju&iacute;zo da cobran&ccedil;a de perdas e danos incorridos pela respectiva parte em decorr&ecirc;ncia de tal inadimplemento ou da eventual resili&ccedil;&atilde;o do Contrato de Cess&atilde;o.</p>


      <p style="text-align:justify">8.3 As Partes responsabilizam-se, ainda, pelos danos patrimoniais diretos, devidamente comprovados, que venham a causar decorrentes da presta&ccedil;&atilde;o de declara&ccedil;&otilde;es falsas, imprecisas ou incorretas no &acirc;mbito do presente Contrato de Cess&atilde;o e, em especial, daquelas constantes da Cl&aacute;usula XIII abaixo. A obriga&ccedil;&atilde;o de indenizar estabelecida nesta Cl&aacute;usula 8.3 permanecer&aacute; em vigor enquanto prevalecerem os efeitos deste Contrato de Cess&atilde;o. </p>

      <br />
      <h2 style="text-align:center; ">CL&Aacute;USULA IX

      <br />TUTELA ESPEC&Iacute;FICA</h2>

      <p style="text-align:justify; ">9.1 O Cedente e o Fundo reconhecem, desde j&aacute;, que este Contrato de Cess&atilde;o constitui t&iacute;tulo executivo extrajudicial, para todos os fins e efeitos dos Artigos 497 e seguintes do C&oacute;digo de Processo Civil, bem como do Artigo 771 e seguintes do mesmo diploma.</p>

      <p style="text-align:justify; ">9.2 As obriga&ccedil;&otilde;es de fazer e n&atilde;o fazer, previstas neste Contrato de Cess&atilde;o, ser&atilde;o exig&iacute;veis, se n&atilde;o houver estipula&ccedil;&atilde;o de prazo espec&iacute;fico, no prazo de 05 (cinco) dias consecutivos contados do recebimento da respectiva notifica&ccedil;&atilde;o enviada pela parte prejudicada. Ser&aacute; facultada &agrave; parte prejudicada, ainda, a ado&ccedil;&atilde;o das medidas judiciais necess&aacute;rias (i) &agrave; tutela espec&iacute;fica ou (ii) &agrave; obten&ccedil;&atilde;o do resultado pr&aacute;tico equivalente, por meio das medidas a que se refere o Artigo 499, do C&oacute;digo de Processo Civil.</p>

      <p style="text-align:justify; ">9.3 Caso as Partes descumpram qualquer das obriga&ccedil;&otilde;es de dar, fazer ou n&atilde;o fazer previstas neste Contrato de Cess&atilde;o e, notificada para sanar tal inadimplemento, deixe de faz&ecirc;-lo no prazo, a parte prejudicada, independentemente de qualquer outro aviso, interpela&ccedil;&atilde;o ou notifica&ccedil;&atilde;o judicial ou extrajudicial, e sem preju&iacute;zo da faculdade de resilir este Contrato, bem como da aplica&ccedil;&atilde;o da multa prevista na Cl&aacute;usula 8.2 acima ou, a seu ju&iacute;zo, promover execu&ccedil;&atilde;o da obriga&ccedil;&atilde;o de fazer, com fundamento no Artigo 815 e seguintes do C&oacute;digo de Processo Civil.</p>

      <p style="text-align:justify; ">9.3.1 As obriga&ccedil;&otilde;es de n&atilde;o fazer do Cedente decorrentes do presente Contrato de Cess&atilde;o dever&atilde;o ser integralmente observadas, sob pena de execu&ccedil;&atilde;o judicial, na forma do Artigo 822 e seguintes do C&oacute;digo de Processo Civil, sendo nulos quaisquer atos praticados em desacordo com o estabelecido no presente Contrato.</p>

      <p style="text-align:justify; ">9.4 As Partes desde j&aacute; expressamente reconhecem que o comprovante de recebimento da notifica&ccedil;&atilde;o mencionada nesta Cl&aacute;usula, acompanhado dos documentos que a tenham fundamentado, ser&aacute; bastante para instruir o pedido de tutela espec&iacute;fica da obriga&ccedil;&atilde;o.</p>


      <br />
      <h2 style="text-align:center; ">CL&Aacute;USULA X

      <br />RESCIS&Atilde;O</h2>

      <p style="text-align:justify; ">10.1 Sem preju&iacute;zo dos demais direitos do Cession&aacute;rio previstos no presente Contrato, este Contrato de Cess&atilde;o poder&aacute; ser rescindido pelo Cession&aacute;rio, mediante notifica&ccedil;&atilde;o por escrito para a Cedente e o Devedor Solid&aacute;rio, na ocorr&ecirc;ncia de quaisquer das hip&oacute;teses abaixo relacionadas:</p>

      <p style="text-align:justify; ">(a)&nbsp; &nbsp; &nbsp; &nbsp; descumprimento pela Cedente ou pelo Devedor Solid&aacute;rio de qualquer obriga&ccedil;&atilde;o assumida nos termos do presente Contrato;</p>

      <p style="text-align:justify; ">(b) &nbsp; &nbsp; &nbsp; &nbsp; n&atilde;o apresenta&ccedil;&atilde;o ou aprova&ccedil;&atilde;o das garantias previstas no subitem 12.4.1 abaixo;</p>

      <p style="text-align:justify; ">(c)&nbsp; &nbsp; &nbsp; in&iacute;cio de procedimento de dissolu&ccedil;&atilde;o e/ou liquida&ccedil;&atilde;o ou decreta&ccedil;&atilde;o de fal&ecirc;ncia da Cedente ou do Devedor Solid&aacute;rio ou apresenta&ccedil;&atilde;o de pedido de autofal&ecirc;ncia por qualquer um deles;</p>

      <p style="text-align:justify; ">(d)&nbsp; &nbsp; &nbsp; &nbsp; homologa&ccedil;&atilde;o de processamento de recupera&ccedil;&atilde;o judicial ou in&iacute;cio de plano de recupera&ccedil;&atilde;o extrajudicial da Cedente ou do Devedor Solid&aacute;rio.</p>

      <p style="text-align:justify; ">10.2 Ocorrendo qualquer das hip&oacute;teses do item 10.1 acima, o Cession&aacute;rio ter&aacute; o direito de resolver todas as cess&otilde;es de Direitos de Cr&eacute;dito que tenham sido realizadas nos termos deste Contrato, cujas respectivas obriga&ccedil;&otilde;es dos Devedores, da Cedente e/ou do Devedor Solid&aacute;rio n&atilde;o tenham sido integralmente liquidadas, e exigir da Cedente e/ou do Devedor Solid&aacute;rio a restitui&ccedil;&atilde;o, imediatamente, em moeda corrente nacional, do saldo correspondente &agrave; diferen&ccedil;a entre o valor total do(s) Pre&ccedil;o(s) da Aquisi&ccedil;&atilde;o recebido(s) pela Cedente e o valor equivalente aos Direitos de Cr&eacute;dito cedidos ao Cession&aacute;rio que tenham sido efetivamente liquidados at&eacute; a data da rescis&atilde;o, devendo tais valores serem reajustados pela varia&ccedil;&atilde;o do Selic at&eacute; a data da rescis&atilde;o. O saldo apurado conforme acima previsto ter&aacute; corre&ccedil;&atilde;o monet&aacute;ria anual pela varia&ccedil;&atilde;o do Selic ou em menor periodicidade se admitido por lei, e acrescido de juros de mora correspondente a 1% (um por cento) ao m&ecirc;s, ou fra&ccedil;&atilde;o, desde a data da rescis&atilde;o at&eacute; a sua integral e efetiva restitui&ccedil;&atilde;o ao Cession&aacute;rio.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XI

      <br />COOBRIGA&Ccedil;&Atilde;O</h2>


      <p style="text-align:justify">11.1 A Cedente se responsabiliza, solidariamente, com os Devedores, nos termos do Artigo 296 do C&oacute;digo Civil, pela pontual e total liquida&ccedil;&atilde;o de todos os Direitos de Cr&eacute;dito cedidos ao Cession&aacute;rio nos termos deste Contrato, obrigando-se pelo pagamento do principal, juros, multas e demais encargos relativos a cada Direito de Cr&eacute;dito.</p>

      <br />
      <h2 style="text-align:center; ">CL&Aacute;USULA XII

      <br />DEVEDOR SOLID&Aacute;RIO</h2>

      <p style="text-align:justify; ">12.1 O Devedor Solid&aacute;rio neste ato se obriga, em car&aacute;ter irrevog&aacute;vel e irretrat&aacute;vel, como devedor e principal pagador, garantindo em favor do Cession&aacute;rio, o pontual e integral pagamento de todos os Direitos de Cr&eacute;dito cedidos nos termos deste Contrato, incluindo o principal, os encargos e os juros incidentes sobre tais Direitos de Cr&eacute;dito, quando e conforme devidos e exigidos.</p>


      <p style="text-align:justify; ">12.2 A obriga&ccedil;&atilde;o prevista nesta Cl&aacute;usula XII constitui uma obriga&ccedil;&atilde;o de pagamento, e n&atilde;o est&aacute; sujeita, em quaisquer circunst&acirc;ncias, a qualquer restri&ccedil;&atilde;o, redu&ccedil;&atilde;o, limita&ccedil;&atilde;o, extin&ccedil;&atilde;o, impugna&ccedil;&atilde;o, compensa&ccedil;&atilde;o, reconven&ccedil;&atilde;o (todos os respectivos direitos s&atilde;o neste ato expressamente renunciados pelo Devedor Solid&aacute;rio, inclusive no que tange a ren&uacute;ncia do benef&iacute;cio de ordem).</p>


      <p style="text-align:justify; ">12.3 Sem preju&iacute;zo da garantia do Devedor Solid&aacute;rio ora prestada e de todas as obriga&ccedil;&otilde;es do Devedor Solid&aacute;rio, o Cession&aacute;rio, por interm&eacute;dio da Consultoria Especializada, poder&aacute; exigir do Devedor Solid&aacute;rio aval nos t&iacute;tulos de cr&eacute;dito sujeitos a tal garantia que representem Direitos de Cr&eacute;dito cedidos nos termos deste Contrato.</p>

      <p style="text-align:justify">12.3.1 Cada aval dado pelo Devedor Solid&aacute;rio e Fiadores em favor do Cession&aacute;rio, conforme previsto no item 12.3.2 &nbsp; Acima, ser&aacute; v&aacute;lido at&eacute; a integral liquida&ccedil;&atilde;o do t&iacute;tulo de cr&eacute;dito em que o aval foi dado, incluindo o principal, os encargos e os juros incidentes sobre tais t&iacute;tulos de cr&eacute;dito, inclusive juros de mora, multa convencional, n&atilde;o compensat&oacute;ria e atualiza&ccedil;&otilde;es monet&aacute;rias com base na varia&ccedil;&atilde;o do &iacute;ndice indicado.</p>


      <p style="text-align:justify; ">12.4 Na hip&oacute;tese de falecimento ou insolvabilidade do Devedor Solid&aacute;rio, a Cedente se compromete a substitu&iacute;-lo, no prazo de 02 (dois) dias &uacute;teis, por pessoa id&ocirc;nea, que dever&aacute; ser aprovada pelo Cession&aacute;rio por interm&eacute;dio da Consultoria Especializada.</p>

      <p style="text-align:justify">12.4.1 &nbsp; Na impossibilidade de substitui&ccedil;&atilde;o do Devedor Solid&aacute;rio, a Cedente dever&aacute; apresentar ao Cession&aacute;rio, em at&eacute; 5 (cinco) dias &uacute;teis do decurso do prazo previsto no item 12.4 acima, outras garantias, que sejam suficientes para a assegurar o integral cumprimento das obriga&ccedil;&otilde;es assumidas pela Cedente nos termos deste Contrato, sujeitas &agrave; aprova&ccedil;&atilde;o do Cession&aacute;rio por interm&eacute;dio da Consultoria Especializada.</p>


      <p style="text-align:justify">12.5 &nbsp; O Devedor solid&aacute;rio, neste ato, nomeia e constitui seu bastante procurador o representante &nbsp; legal da Cedente para assinar no Contrato de Cess&atilde;o e nos Termos de Cess&atilde;o de Direitos de que trata a CL&Aacute;USULA II deste contrato, bem como para conceder aval em seu nome, na hip&oacute;tese prevista no item 12.3, da CL&Aacute;USULA XII, e ainda como Fiel Deposit&aacute;rio, conforme CL&Aacute;USULA VI, caso o CEDENTE deixe de entregar ao CESSION&Aacute;RIO alguma documenta&ccedil;&atilde;o, no dia da efetiva&ccedil;&atilde;o da opera&ccedil;&atilde;o e conforme &iacute;tem 4.2 da CL&Aacute;USULA IV.&nbsp;</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XIII

      <br />DECLARA&Ccedil;&Otilde;ES E OBRIGA&Ccedil;&Otilde;ES DA CEDENTE</h2>


      <p style="text-align:justify">13.1 A Cedente declara e garante ao Cession&aacute;rio, neste ato, que:</p>

      <p style="text-align:justify">(a)&nbsp; &nbsp; &nbsp; o presente Contrato de Cess&atilde;o constitui obriga&ccedil;&atilde;o l&iacute;cita, v&aacute;lida e exeq&uuml;&iacute;vel em conformidade com &nbsp; seus termos contra a Cedente (observadas as leis de fal&ecirc;ncia, recupera&ccedil;&atilde;o judicial e extrajudicial, insolv&ecirc;ncia, e leis similares aplic&aacute;veis que afetem direitos de credores de modo geral); e</p>

      <p style="text-align:justify">(b)&nbsp; &nbsp; todos os Direitos de Cr&eacute;dito s&atilde;o de sua exclusiva titularidade, estando livres e desembara&ccedil;ados de quaisquer &ocirc;nus, gravames ou restri&ccedil;&otilde;es de qualquer natureza, que, de qualquer modo, possam obstar a cess&atilde;o e o pleno exerc&iacute;cio, pelo Cession&aacute;rio, das prerrogativas decorrentes da titularidade dos Direitos de Cr&eacute;dito.</p>

      <p style="text-align:justify">(c) manter sempre v&aacute;lidas, em vigor e em perfeita ordem todas as autoriza&ccedil;&otilde;es necess&aacute;rias &agrave; execu&ccedil;&atilde;o deste Contrato de Cess&atilde;o;</p>

      <p style="text-align:justify">(d) n&atilde;o proceder a altera&ccedil;&otilde;es, aditamentos, renegocia&ccedil;&otilde;es, repactua&ccedil;&otilde;es, aliena&ccedil;&otilde;es ou novas cess&otilde;es relacionadas aos Direitos de Cr&eacute;dito cedidos ao Cession&aacute;rio;</p>

      <p style="text-align:justify">(e) cumprir as obriga&ccedil;&otilde;es previstas nos Documentos Comprobat&oacute;rios dos Direitos Credit&oacute;rios cedidos para o Fundo, dentro dos prazos ali estabelecidos;</p>

      <p style="text-align:justify">(f) utilizar seus melhores esfor&ccedil;os a fim de colaborar com o Fundo, para garantir o recebimento pelo Fundo da totalidade dos valores referentes aos Direitos de Cr&eacute;dito a estes cedidos;</p>

      <p style="text-align:justify">(g) tomar todas as medidas necess&aacute;rias para que os Direitos de Cr&eacute;dito, bem como suas garantias, sejam efetiva e validamente cedidos e transferidos ao Fundo;</p>

      <p style="text-align:justify">(h) manter padr&otilde;es cont&aacute;beis m&iacute;nimos de acordo com a legisla&ccedil;&atilde;o aplic&aacute;vel;</p>

      <p style="text-align:justify">(i) informar a Consultora Especializada e ao Gestor a respeito de quaisquer opera&ccedil;&otilde;es de fus&atilde;o, cis&atilde;o, incorpora&ccedil;&atilde;o, transforma&ccedil;&atilde;o, aquisi&ccedil;&atilde;o, aliena&ccedil;&atilde;o, reestrutura&ccedil;&atilde;o societ&aacute;ria ou financeira que tome parte, em at&eacute; 24 (vinte e quatro) horas ap&oacute;s o t&eacute;rmino das negocia&ccedil;&otilde;es;</p>

      <p style="text-align:justify">(j) permitir ao Administrador, ao Gestor do Fundo e/ou a Consultoria Especializada, acesso a todos os dados e informa&ccedil;&otilde;es relacionados aos Direitos de Cr&eacute;dito cedidos ao Fundo, podendo o Fundo extrair c&oacute;pias ou solicitar c&oacute;pias autenticadas, assim como permitir acesso por meio eletr&ocirc;nico &agrave;s informa&ccedil;&otilde;es relativas aos Direitos de Cr&eacute;dito, constantes das bases de dados do Cedente ou de terceiros, de forma a garantir o acesso &agrave;s informa&ccedil;&otilde;es necess&aacute;rias para a realiza&ccedil;&atilde;o pelo Fundo de cobran&ccedil;a judicial ou extrajudicial dos Direito de Cr&eacute;dito a eles cedidos;</p>

      <p style="text-align:justify">(k) transferir para conta corrente do Fundo, no dia &uacute;til imediatamente subsequente ao recebimento, todo e qualquer montante relativo ao pagamento dos Direitos de Cr&eacute;dito eventualmente recebido dos devedores, comunicando tal fato &agrave; Consultoria Especializada e ao Gestor;</p>


      <p style="text-align:justify">13.2 &nbsp; As declara&ccedil;&otilde;es aqui prestadas pela Cedente subsistir&atilde;o at&eacute; a integral liquida&ccedil;&atilde;o das obriga&ccedil;&otilde;es da Cedente decorrentes deste Contrato.</p>


      <p style="text-align:justify">13.3&nbsp; &nbsp; Sem preju&iacute;zo das demais obriga&ccedil;&otilde;es assumidas nos termos deste Contrato, a Cedente expressamente obrigar-se-&aacute;:</p>

      <p style="text-align:justify">(a)&nbsp; &nbsp; encaminhar ao Cession&aacute;rio, conforme aplic&aacute;vel, c&oacute;pia da peti&ccedil;&atilde;o contendo pedido de decreta&ccedil;&atilde;o de fal&ecirc;ncia, deferimento de procedimento de recupera&ccedil;&atilde;o judicial ou de homologa&ccedil;&atilde;o de plano de recupera&ccedil;&atilde;o extrajudicial relativa &agrave; pr&oacute;pria Cedente, no prazo de 24 (vinte e quatro) horas do seu recebimento, acompanhada de c&oacute;pia de todos os documentos que embasem ou fundamentem tal pedido;</p>

      <p style="text-align:justify">(b) &nbsp; &nbsp; praticar todos os atos necess&aacute;rios, inclusive mediante envio de notifica&ccedil;&atilde;o aos Devedores, na forma prevista na Cl&aacute;usula XVII, com o objetivo de fazer com que os Devedores efetuem o pagamento dos Direitos de Cr&eacute;dito ao Cession&aacute;rio diretamente na conta a ser indicada pelo Cession&aacute;rio, evitando, assim, o descasamento entre o pagamento do pre&ccedil;o de aquisi&ccedil;&atilde;o estabelecido entre as Partes e o recebimento dos valores decorrentes dos Direitos de Cr&eacute;dito;</p>

      <p style="text-align:justify">(c) &nbsp; &nbsp; &nbsp; informar e/ou entregar c&oacute;pia ao Cession&aacute;rio de qualquer altera&ccedil;&atilde;o em seus controles internos e na sua pol&iacute;tica de cobran&ccedil;a, no prazo de 05 (cinco) dias, contados do inicio de sua vig&ecirc;ncia, e indicar os fundamentos que levaram a tal altera&ccedil;&atilde;o;</p>

      <p style="text-align:justify">(d)&nbsp; &nbsp; &nbsp; aceitar a imediata devolu&ccedil;&atilde;o dos Direitos de Cr&eacute;dito ofertados ao Cession&aacute;rio que n&atilde;o atenderem aos Crit&eacute;rios de Elegibilidade estabelecidos no Cap&iacute;tulo III do Regulamento;</p>

      <p style="text-align:justify">(e)&nbsp; &nbsp; indenizar o Cession&aacute;rio em raz&atilde;o do descumprimento, incorre&ccedil;&atilde;o ou falsidade das declara&ccedil;&otilde;es e obriga&ccedil;&otilde;es previstas neste Contrato;</p>

      <p style="text-align:justify">(f)&nbsp; &nbsp; &nbsp; &nbsp; cumprir fiel e tempestivamente todas as suas obriga&ccedil;&otilde;es definidas neste Contrato;</p>

      <p style="text-align:justify">(g)&nbsp; &nbsp; &nbsp; a Cedente compromete-se, ainda, a n&atilde;o ofertar Direitos de Cr&eacute;dito ao Fundo se tal fato:&nbsp; (i) caracterizar fraude contra credores, conforme previsto nos Artigos 158 a 165 do C&oacute;digo Civil Brasileiro; (ii) for pass&iacute;vel de revoga&ccedil;&atilde;o, nos termos dos Artigos 129 a 138 da Lei de Fal&ecirc;ncias (Lei n&ordm; 11.101, de 09 de fevereiro de 2005); (iii) caracterizar fraude de execu&ccedil;&atilde;o, na hip&oacute;tese do Artigo 593 do C&oacute;digo de Processo Civil; ou (iv)caracterizar a aliena&ccedil;&atilde;o ou onera&ccedil;&atilde;o fraudulenta de bens ou rendas, na hip&oacute;tese do Artigo 185 do C&oacute;digo Tribut&aacute;rio Nacional.</p>

      <p style="text-align:justify">(h) &nbsp; &nbsp; &nbsp; permitir ao Fundo ou a quem este indicar, por escrito, em dias &uacute;teis e no hor&aacute;rio das 09 horas at&eacute; as 18 horas, sem qualquer custo adicional para o Cession&aacute;rio, acesso a todos os dados e informa&ccedil;&otilde;es relativos aos Direitos de Cr&eacute;dito, especialmente, Documentos relativos aos Direitos de Cr&eacute;dito, no prazo improrrog&aacute;vel de at&eacute; 05 (cinco) dias &uacute;teis, contados da data de solicita&ccedil;&atilde;o; e</p>

      <p style="text-align:justify">(i) &nbsp; &nbsp; &nbsp; celebrar e entregar ao Fundo, durante o prazo de vig&ecirc;ncia deste Contrato, &agrave;s suas expensas, todos e quaisquer instrumentos, contratos, declara&ccedil;&otilde;es e informa&ccedil;&otilde;es, assim como praticar todos os atos adicionais que o Cession&aacute;rio venha a solicitar por escrito &agrave; Cedente, com a finalidade de proteger, salvaguardar e assegurar a validade e efic&aacute;cia dos direitos, interesses e prerrogativas do Cession&aacute;rio e dos Quotistas com rela&ccedil;&atilde;o aos Direitos de Cr&eacute;dito, conforme definidos neste Contrato.</p>


      <p style="text-align:justify">13.4 A Cedente ser&aacute; respons&aacute;vel por todos e quaisquer danos e preju&iacute;zos causados ao Cession&aacute;rio decorrentes da inveracidade ou inexatid&atilde;o das declara&ccedil;&otilde;es acima prestadas.</p>


      <p style="text-align:justify">13.5 Na eventualidade de serem opostas exce&ccedil;&otilde;es quanto &aacute; legitimidade, legalidade ou veracidade dos t&iacute;tulos negociados entre as partes, bem como quanto &agrave; evic&ccedil;&atilde;o, a Cedente, sem preju&iacute;zo das san&ccedil;&otilde;es penais cab&iacute;veis, assumir&aacute; integral responsabilidade pela recompra dos referidos t&iacute;tulos do Cession&aacute;rio, na forma e com os acr&eacute;scimos previstos na Cl&aacute;usula XVI deste contrato.</p>


      <p style="text-align:justify">13.6 As obriga&ccedil;&otilde;es para as quais n&atilde;o tenha sido estabelecido prazo espec&iacute;fico para seu cumprimento ser&atilde;o exig&iacute;veis no prazo de 72 (setenta e duas) horas, contados do recebimento de notifica&ccedil;&atilde;o exigindo o seu cumprimento, nos termos da Cl&aacute;usula XVII deste Contrato.</p>


      <p style="text-align:justify">13.7 As Partes se comprometem a sempre manter o presente Contrato de Cess&atilde;o, a todo o momento, em conson&acirc;ncia&nbsp; com o Regulamento, com o objetivo de n&atilde;o prejudicar o funcionamento do Fundo. Caso ocorra qualquer altera&ccedil;&atilde;o no Regulamento que conflite com as disposi&ccedil;&otilde;es deste Contrato, as Partes desde logo se comprometem a negociar amigavelmente e, na maior brevidade poss&iacute;vel, alterar este Contrato, de modo a refletir as altera&ccedil;&otilde;es feitas no Regulamento.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XIV

      <br />DECLARA&Ccedil;&Otilde;ES E OBRIGA&Ccedil;&Otilde;ES DA ADMINISTRADORA</h2>


      <p style="text-align:justify">14.1 A Administradora, devidamente autorizada na forma do Regulamento, declara e garante, em nome do Fundo e, conforme o caso, devidamente autorizada na forma de seus atos constitutivos, em seu pr&oacute;prio nome, neste ato, que:</p>

      <p style="text-align:justify">(a)&nbsp; &nbsp; &nbsp; &eacute; uma institui&ccedil;&atilde;o financeira, validamente constitu&iacute;da e em funcionamento de acordo com a legisla&ccedil;&atilde;o aplic&aacute;vel e devidamente habilitada e autorizada, nos termos das normas legais e regulamentares vigentes, a administrar fundos de investimento, possuindo todas as condi&ccedil;&otilde;es t&eacute;cnicas e operacionais para prestar os servi&ccedil;os de administra&ccedil;&atilde;o do Fundo;</p>

      <p style="text-align:justify">(b)&nbsp; &nbsp; todos os alvar&aacute;s, licen&ccedil;as, autoriza&ccedil;&otilde;es ou aprova&ccedil;&otilde;es necess&aacute;rias ao seu funcionamento foram regularmente obtidos e encontram-se atualizados e seus livros cont&aacute;beis est&atilde;o regularmente abertos e registrados na Junta Comercial do Estado de S&atilde;o Paulo, estando, tamb&eacute;m, devidamente atualizados;</p>

      <p style="text-align:justify">(c)&nbsp; &nbsp; &nbsp; a celebra&ccedil;&atilde;o deste Contrato de Cess&atilde;o e a assun&ccedil;&atilde;o e o cumprimento das obriga&ccedil;&otilde;es deste decorrentes est&atilde;o devidamente autorizados pelo Regulamento e/ou pelos atos constitutivos da Administradora, conforme o caso; e</p>

      <p style="text-align:justify">(d) &nbsp; &nbsp; &nbsp; o Cession&aacute;rio &eacute; um fundo de investimento em direitos credit&oacute;rios constitu&iacute;do sob a forma de condom&iacute;nio fechado, e est&aacute; validamente em funcionamento mediante o seu registro na CVM de acordo com a legisla&ccedil;&atilde;o e regulamenta&ccedil;&atilde;o em vigor.</p>


      <p style="text-align:justify">14.2 A Administradora se obriga a obter e manter atualizadas todas as autoriza&ccedil;&otilde;es ou aprova&ccedil;&otilde;es necess&aacute;rias, nos termos da regulamenta&ccedil;&atilde;o aplic&aacute;vel, &agrave; constitui&ccedil;&atilde;o e ao funcionamento do Cession&aacute;rio como um fundo de investimento em direitos credit&oacute;rios.</p>


      <p style="text-align:justify">14.3 As declara&ccedil;&otilde;es aqui prestadas pela Administradora, em nome pr&oacute;prio e em nome do Fundo, subsistir&atilde;o at&eacute; a integral liquida&ccedil;&atilde;o das obriga&ccedil;&otilde;es da Cedente e da Administradora decorrentes deste Contrato.</p>


      <p style="text-align:justify">14.4 A administradora ser&aacute; respons&aacute;vel por todos e quaisquer danos e preju&iacute;zos causados ao Cession&aacute;rio decorrentes da inveracidade ou inexatid&atilde;o das declara&ccedil;&otilde;es acima prestadas.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XV

      <br />COBRAN&Ccedil;A</h2>


      <p style="text-align:justify">15.1 Sem preju&iacute;zo das obriga&ccedil;&otilde;es da Cedente e do Devedor Solid&aacute;rio previstas nas Cl&aacute;usulas XI e XII deste Contrato, os Direitos de Cr&eacute;dito vencidos e n&atilde;o pagos poder&atilde;o ser objeto de cobran&ccedil;a pelo Cession&aacute;rio, por interm&eacute;dio do Agente de Cobran&ccedil;a, de acordo com a Pol&iacute;tica de Cobran&ccedil;a estabelecida no Regulamento.</p>

      <p style="text-align:justify">15.1.1 O Cession&aacute;rio se compromete a interromper qualquer procedimento de cobran&ccedil;a que tenha iniciado contra qualquer Devedor, seja judicial ou extrajudicial, caso receba o pagamento integral do respectivo valor sujeito &agrave; cobran&ccedil;a, acrescido dos respectivos juros, multas e demais encargos.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XVI

      <br />RECOMPRA DOS DIREITOS DE CR&Eacute;DITO</h2>


      <p style="text-align:justify">16.1 A Cedente compromete-se a recomprar os Direitos de Cr&eacute;dito adquiridos pelo Cession&aacute;rio nos termos deste Contrato, estejam eles vencidos ou n&atilde;o, dentro do prazo de 2(dois) dias &uacute;teis da solicita&ccedil;&atilde;o pelo Cession&aacute;rio, nas seguintes hip&oacute;teses:</p>

      <p style="text-align:justify">(a)&nbsp; &nbsp; &nbsp; caso o Devedor se recuse a efetuar o pagamento do Direito de Cr&eacute;dito na sua respectiva data de vencimento, em decorr&ecirc;ncia de qualquer v&iacute;cio, defeito, ou reclama&ccedil;&atilde;o de qualquer outra natureza, no cumprimento pela Cedente de sua respectiva obriga&ccedil;&atilde;o no contrato ou instrumento que tenha dado origem ao Direito de Cr&eacute;dito;</p>

      <p style="text-align:justify">(b)&nbsp; &nbsp; &nbsp; &nbsp; caso tenha ocorrido a altera&ccedil;&atilde;o ou o cancelamento, total ou parcial, por qualquer motivo, da venda de mercadorias ou da presta&ccedil;&atilde;o dos servi&ccedil;os no(s) contrato(s) que deram origem ao respectivo Direito de Cr&eacute;dito;</p>

      <p style="text-align:justify">(c)&nbsp; &nbsp; &nbsp; &nbsp; caso o Devedor tenha apresentado qualquer exce&ccedil;&atilde;o, defesa ou outra esp&eacute;cie de embargo ou obje&ccedil;&atilde;o, judicial ou extrajudicial, ao pagamento, total ou parcial, do Direito de Cr&eacute;dito, com fundamento em qualquer ato ou fato de responsabilidade da Cedente; ou</p>

      <p style="text-align:justify">(d)&nbsp; &nbsp; &nbsp; caso, por qualquer evento decorrente de caso fortuito ou for&ccedil;a maior, o devedor se recuse a efetuar o pagamento do respectivo Direito de Cr&eacute;dito na sua respectiva data de vencimento.</p>


      <p style="text-align:justify">16.2 Sobrevindo as situa&ccedil;&otilde;es previstas nos par&aacute;grafos primeiro e segundo do item 16.1 acima, fica a Cedente obrigada a recomprar do Cession&aacute;rio os t&iacute;tulos negociados. O valor da recompra ser&aacute; estabelecido pelo Cession&aacute;rio e pela Consultoria Especializada, com base na taxa utilizada para c&aacute;lculo do des&aacute;gio aplicado na aquisi&ccedil;&atilde;o dos respectivos Direitos de Cr&eacute;dito pelo Cession&aacute;rio, podendo ser aplicada multa de at&eacute; 10 % (dez por cento) sobre o valor da referida taxa, a crit&eacute;rio exclusivo do Cession&aacute;rio, acrescido de juros morat&oacute;rios de 1% (um por cento) ao m&ecirc;s, al&eacute;m de corre&ccedil;&atilde;o monet&aacute;ria.&nbsp;</p>

      <p style="text-align:justify">16.2.1 A Cedente ter&aacute; o prazo de 72(setenta e duas) horas para efetuar a recompra prevista nesta Cl&aacute;usula, contadas da notifica&ccedil;&atilde;o escrita a ser providenciada pelo Cession&aacute;rio, nos termos da Cl&aacute;usula XVII, abaixo. N&atilde;o sendo respeitado o prazo estipulado neste item, acrescer-se-&aacute;, ainda, multa no percentual de 10% (dez por cento).</p>


      <p style="text-align:justify">16.3 O Cession&aacute;rio compromete-se a devolver &agrave; Cedente os Documentos Comprobat&oacute;rios referentes aos Direitos de Cr&eacute;dito que sejam objeto da recompra em at&eacute; 10(dez) dias &uacute;teis da data do pagamento integral do valor da recompra.</p>
      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XVII

      <br />COMUNICA&Ccedil;&Otilde;ES</h2>


      <p style="text-align:justify">17.1 Todos os documentos e as comunica&ccedil;&otilde;es a serem enviados por qualquer das Partes nos termos deste Contrato dever&atilde;o ser sempre feitos por escrito, assim como os meios f&iacute;sicos que contenham documentos ou comunica&ccedil;&otilde;es, e dever&atilde;o ser encaminhados para os seguintes endere&ccedil;os:</p>


      <p>a) se para a Cedente:</p>


      <p><strong>${findByName('razao_social')}</strong></p>

      <p>${findByName('endereco')}, ${findByName('bairro')}, ${findByName('cep')}, ${findByName('cidade')}, ${findByName('uf')}</p>

      <div class="wrap">
      ${
        representantes.map(representante => (
          `<div>
            <p>At.: ${representante.fullname}</p>
      
            <p>Telefone: ${representante.phone}</p>
      
            <p>e-mail: ${representante.email}</p>
          </div>`
        ))
      }
      </div>

      <p>b) se para o Cession&aacute;rio ou para a Administradora:</p>


      <p><strong>SOCOPA Sociedade Corretora Paulista SA</strong></p>

      <p>Av. Brigadeiro Faria Lima, 1355, 3&ordm;. Andar S&atilde;o Paulo - SP</p>

      <p>At.: Daniel Doll Lemos</p>

      <p>Telefone: (11) 3299 2166</p>

      <p>Facsimile: (11) 3299 2385</p>

      <p>Email: daniel@socopa.com.br</p>


      <p style="text-align:justify">c).se para a Empresa Especializada:</p>


      <p style="text-align:justify"><strong>BFC CAPITAL PARTNERS.</strong></p>

      <p style="text-align:justify">Av. Eng. Domingos Ferreira, 2589, Sl 1001-Boa Viagem, Recife - PE, 51020-030</p>

      <p style="text-align:justify">At.: Marlene de Souza Canazart</p>

      <p style="text-align:justify">Telefone: (81) 2119-9595 / 4007-2614</p>

      <p style="text-align:justify">Fac-s&iacute;mile: (81).2119-9586</p>

      <p style="text-align:justify">Email:&nbsp; marlene@grupobfc.com.br</p>


      <p style="text-align:justify">d) se para a Gestora:</p>


      <p style="text-align:justify"><strong>TERCON INVESTIMENTOS LTDA.</strong></p>

      <p style="text-align:justify">Rua Am&eacute;rico Brasiliense 1765, 5&ordm; andar, Ch&aacute;cara Sto Antonio &ndash; S&atilde;o Paulo - SP</p>

      <p style="text-align:justify">At.: Marcelo Alberto Couto</p>

      <p style="text-align:justify">Telefone: (11) 5181-5841</p>

      <p style="text-align:justify">Fac-s&iacute;mile:&nbsp;</p>

      <p style="text-align:justify">Email:&nbsp; marcelo.couto@terconbr.com.br</p>


      <p style="text-align:justify">17.2 Todos os documentos e as comunica&ccedil;&otilde;es da opera&ccedil;&atilde;o dever&atilde;o ser encaminhados &agrave; Consultoria Especializada em ate 03 (tr&ecirc;s) dias &uacute;teis na forma do disposto no &iacute;tem 17.3 abaixo.</p>


      <p style="text-align:justify">17.3 Os documentos e as comunica&ccedil;&otilde;es, assim como os meios f&iacute;sicos que contenham documentos ou comunica&ccedil;&otilde;es, ser&atilde;o considerados recebidos quando entregues, sob protocolo ou mediante &ldquo;Aviso de Recebimento&rdquo; expedido pela Empresa Brasileira de Correios e Tel&eacute;grafos, nos endere&ccedil;os acima, ou quando da confirma&ccedil;&atilde;o do recebimento da transmiss&atilde;o via fac-s&iacute;mile, via e-mail ou outro meio de transmiss&atilde;o eletr&ocirc;nica.</p>

      <p style="text-align:justify">17.3.1 Para os fins do item 17.2 acima, ser&aacute; considerada v&aacute;lida a confirma&ccedil;&atilde;o do recebimento via fac-s&iacute;mile ou via e-mail ainda que emitida pela Parte que tenha transmitido a mensagem, desde que o comprovante tenha sido expedido a partir do equipamento utilizado na transmiss&atilde;o e que do mesmo constem informa&ccedil;&otilde;es suficientes &agrave; identifica&ccedil;&atilde;o do emissor e do destinat&aacute;rio da comunica&ccedil;&atilde;o.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XVIII

      <br />DISPOSI&Ccedil;&Otilde;ES FINAIS</h2>


      <p style="text-align:justify">18.1 Toda e qualquer modifica&ccedil;&atilde;o, altera&ccedil;&atilde;o ou aditamento ao presente Contrato somente ser&aacute; v&aacute;lido se feito por instrumento escrito, assinado por todas as Partes.</p>


      <p style="text-align:justify">18.2 As Partes celebram este Contrato de Cess&atilde;o em car&aacute;ter irrevog&aacute;vel e irretrat&aacute;vel, obrigando-se ao seu fiel, pontual e integral cumprimento por si e por seus sucessores e cession&aacute;rios, a qualquer t&iacute;tulo.</p>


      <p style="text-align:justify">18.3 Todos os Termos de Cess&atilde;o celebrados nos termos deste Contrato de Cess&atilde;o constituir&atilde;o parte integrante e insepar&aacute;vel do presente Contrato, para todos os fins.</p>


      <p style="text-align:justify">18.4 A toler&acirc;ncia e as concess&otilde;es rec&iacute;procas ter&atilde;o car&aacute;ter eventual e transit&oacute;rio e n&atilde;o configurar&atilde;o, em qualquer hip&oacute;tese, ren&uacute;ncia, transig&ecirc;ncia, remi&ccedil;&atilde;o, perda, modifica&ccedil;&atilde;o, redu&ccedil;&atilde;o ou amplia&ccedil;&atilde;o de qualquer direito, faculdade, privil&eacute;gio, prerrogativa ou poderes conferidos a qualquer das Partes nos termos deste Contrato, assim como, quando havidas, o ser&atilde;o, expressamente, sem o intuito de novar as obriga&ccedil;&otilde;es previstas neste Contrato.</p>


      <p style="text-align:justify">18.5 O presente Contrato de Cess&atilde;o constitui o &uacute;nico e integral acordo entre as Partes com rela&ccedil;&atilde;o aos assuntos aqui tratados, substituindo todos os outros documentos, cartas, memorandos ou propostas entre as Partes, bem como os entendimentos orais mantidos entre as mesmas, anteriores &agrave; presente data.</p>


      <p style="text-align:justify">18.6 &Eacute; expressamente vedada a cess&atilde;o a terceiros, por qualquer das Partes, dos direitos e obriga&ccedil;&otilde;es nele previstos.</p>


      <p style="text-align:justify">18.7 Toda e qualquer quantia devida a qualquer das Partes por for&ccedil;a deste Contrato poder&aacute; ser cobrada via processo de execu&ccedil;&atilde;o, visto que as Partes desde j&aacute; reconhecem tratar-se de quantia l&iacute;quida e certa, atribuindo ao presente a qualidade de t&iacute;tulo executivo extrajudicial nos termos e para os efeitos do artigo 784, do C&oacute;digo de Processo Civil.</p>


      <p style="text-align:justify">18.8 Todas as disposi&ccedil;&otilde;es contidas neste Contrato, que se caracterizem como obriga&ccedil;&atilde;o de fazer ou n&atilde;o fazer a ser cumprida pelo Cession&aacute;rio, dever&atilde;o ser consideradas, salvo refer&ecirc;ncia expressa em contr&aacute;rio, como de responsabilidade exclusiva da Administradora.</p>


      <p style="text-align:justify">18.9 Para os efeitos do disposto neste Contrato, entende-se por &ldquo;dia &uacute;til&rdquo; segunda a sexta-feira, exceto (i) feriados ou dias em que, por qualquer motivo, n&atilde;o houver expediente comercial ou banc&aacute;rio no Estado ou na Cidade de S&atilde;o Paulo; e (ii) feriados de &acirc;mbito nacional.</p>


      <p style="text-align:justify">18.10 Se, em decorr&ecirc;ncia de qualquer decis&atilde;o judicial irrecorr&iacute;vel, qualquer disposi&ccedil;&atilde;o ou termo deste Contrato de Cess&atilde;o for declarada nula ou for anulada, tal nulidade ou anulabilidade n&atilde;o prejudicar&aacute; a vig&ecirc;ncia das demais cl&aacute;usulas deste Contrato de Cess&atilde;o n&atilde;o atingidas pela declara&ccedil;&atilde;o de nulidade ou pela anula&ccedil;&atilde;o.</p>


      <p style="text-align:justify">18.11 A Cedente autoriza a Cession&aacute;ria a fornecer todas as informa&ccedil;&otilde;es sobre os direitos credit&oacute;rios que forem objeto de negocia&ccedil;&atilde;o no &acirc;mbito deste instrumento e dos que dele forem derivados a &oacute;rg&atilde;os destinados &agrave; prote&ccedil;&atilde;o do cr&eacute;dito, tais como &ldquo;centrais de riscos&rdquo;, assumindo obriga&ccedil;&atilde;o de dar ci&ecirc;ncia dessa circunst&acirc;ncia aos DEVEDORES e respondendo regressivamente perante a Cession&aacute;ria por quaisquer indeniza&ccedil;&otilde;es que tiverem causa na utiliza&ccedil;&atilde;o dessas informa&ccedil;&otilde;es pela Cession&aacute;ria ou pelos &oacute;rg&atilde;os que as utilizarem.</p>

      <br />
      <h2 style="text-align:center">CL&Aacute;USULA XVI

      <br />FORO</h2>


      <p style="text-align:justify">19.1 Fica eleito o foro da Comarca de Recife, Estado de Pernambuco, para dirimir quaisquer d&uacute;vidas ou controv&eacute;rsias oriundas deste Contrato, com ren&uacute;ncia a qualquer outro por mais privilegiado que seja.</p>


      <p style="text-align:justify">E, por estarem justas e contratadas, firmam o presente Contrato de Cess&atilde;o em 4 (quatro) vias de igual teor e forma, para os mesmos fins e efeitos de direito, na presen&ccedil;a das 02 (duas) testemunhas abaixo assinadas.</p>


      <p><br />
      &nbsp;</p>

      <p style="text-align:center">Recife, 06 de Agosto de 2019</p>

      <p><br />
      <br />
      &nbsp;</p>

      <p>CEDENTE:</p>

      <div class="wrap">
      ${
        representantes.map(representante => (
          `<div><p>____________________________________</p>

          <p><strong>${findByName('razao_social')}</strong></p>

          <p>${representante.fullname}</p></div>`
        )).join("")
      }
      </div>

      <p>S&oacute;cios / Administradores</p>

      <p><br />
      <br />
      &nbsp;</p>

      <p><strong>CESSION&Aacute;RIO:</strong></p>
      <p><br />
      &nbsp;</p>

      <p>_______________________________________________________________________</p>

      <p>BFC FUNDO DE INVESTIMENTO EM DIREITOS CREDIT&Oacute;RIOS NP</p>


      <p>&nbsp;</p>


      <p><strong>INTERVENIENTE:</strong></p>

      <p><br />
      &nbsp;</p>

      <p>________________________________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

      <p>BFC CAPITAL PARTNERS</p>

      <p><br />
      &nbsp;</p>

      <p><strong>INTERVENIENTE ANUENTE:</strong></p>

      <p><br />
      &nbsp;</p>

      <p>_____________________________________________&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

      <p>TERCON INVESTIMENTOS LTDA&nbsp;</p>

      <p><br />
      &nbsp;</p>

      <p><strong>DEVEDORES SOLID&Aacute;RIOS E FIADORES</strong></p>
      
      ${representantes.map(representante => (
        `
        <div class="wrap">
        <p>
        <br/><br/>
        ____________________________________<br />
        ${representante.fullname}<br />
        ${representante.cpf}
        </p>
        ${representante.memberSpouses && fiadores.find(item => item.id === representante.memberSpouses.id) ?
          `<p>
          <br/><br/>
            ____________________________________<br />
            ${representante.memberSpouses.fullname} <br />
            ${representante.memberSpouses.cpf} <br />
            <em>Regime bens na const&acirc;ncia do casamento</em>
            </p>
          `: `<p><br/><br/>&nbsp;</p>`
          }
        </div>
        `
      )).join("")}
      

      <p><br/>&nbsp;</p>

      <p><strong>FI&Eacute;L DEPOSIT&Aacute;RIOS:</strong></p>
      <p><br /><br /> &nbsp;</p>
      <div class="wrap">
        <p>
        ________________________________________<br />
        ${memberFielDepositario.fullname}<br />
        ${memberFielDepositario.cpf}
        </p>
      </div>
      ${fiel_depositario.length > 0 && fiel_depositario.map(item => (
        `<div class="wrap">
        <p>
        ________________________________________<br />
        ${item.fullname}<br />
        ${item.cpf}
        </p>
        </div>`
      )).join('')}
      
      <p><br/>&nbsp;</p>
      
      <p style="text-align:justify">Na qualidade de outorgante ux&oacute;rio dos DEVEDORES SOLID&Aacute;RIOS acima:</p>

      ${outorgantes.length > 0 && outorgantes.map(item => (
        `<div class="wrap">
        <p>
          <br/><br/>
          <em>____________________________________</em>
          <br />
          ${members.find(member => member.memberSpouses.id === item.id).fullname}<br />
          ${members.find(member => member.memberSpouses.id === item.id).cpf}
        </p>
        <p>
          <br/><br/>
          <em>____________________________________</em><br />
            ${item.fullname} <br />
            ${item.cpf} <br />
            <em>Regime bens na const&acirc;ncia do casamento</em>
        </p>
      </div>`
      )).join('')}

      <p>
      &nbsp;</p>

      <p style="text-align:justify"><em>Pela presente, outorgo, de forma irrevog&aacute;vel e irretrat&aacute;vel, para os fins do Artigo 1.647, incisos I, II e III, do C&oacute;digo Civil Brasileiro (Lei 10.406 de 10 de janeiro de 2002), autoriza&ccedil;&atilde;o para o meu c&ocirc;njuge prestar garantia de fian&ccedil;a neste Contrato de Cess&atilde;o, bem como em todos os Termos de Cess&atilde;o a este vinculados, na condi&ccedil;&atilde;o de Devedor Solid&aacute;rio.&nbsp;</em></p>

      <p><br />
      <br />
      <br />
      <br />
      <br />
      &nbsp;</p>

      <p>TESTEMUNHAS:</p>
      <p><br />
      <br />
      &nbsp;</p>
      <div class="wrap">
      <p>
        ____________________________________
        <br />
        Assinatura
      </p>
      <p>
        ____________________________________
        <br />
        Assinatura
      </p>
      </div>

      <p><br />
      <br />
      <br />
      <br />
      &nbsp;</p>
      <u>
      <h2 style="text-align:center">ANEXO I</p>

      <h4 style="text-align:center">Modelo de Termo de Cess&atilde;o</p>
      </u>

      <p><strong>CEDENTE:&nbsp;&nbsp;&nbsp;</strong></p>

      <p><strong>CESSION&Aacute;RIO:</strong> BFC FUNDO DE INVESTIMENTO EM DIREITOS CREDIT&Oacute;RIOS NP&nbsp;</p>
      <strong>
      <p>NR. BORDER&Ocirc;: </p>


      <p style="text-align:justify">Pelo presente Termo de Cess&atilde;o, a Cedente cede e transfere ao Cession&aacute;rio os Direitos de Cr&eacute;dito dos t&iacute;tulos relacionados abaixo, nos termos do item 2.1.1 do Contrato de Cess&atilde;o (termo abaixo definido), no valor total demonstrado abaixo, de acordo com o Contrato de Cess&atilde;o e Aquisi&ccedil;&atilde;o de Direitos de Cr&eacute;dito e Outras Aven&ccedil;as No, firmado entre a Cedente, o Cession&aacute;rio e a Administradora (o &ldquo;Contrato de Cess&atilde;o&rdquo;). Pela presente cess&atilde;o, o Cession&aacute;rio pagar&aacute; &agrave; Cedente o Pre&ccedil;o de Aquisi&ccedil;&atilde;o previsto no demonstrativo da opera&ccedil;&atilde;o abaixo, mediante dep&oacute;sito na(s) conta(s)-corrente(s) identificada(s) abaixo,&nbsp; observadas as demais condi&ccedil;&otilde;es estabelecidas na Cl&aacute;usula IV do Contrato de Cess&atilde;o.</p>


      <p style="text-align:center">DEMONSTRATIVO DA OPERA&Ccedil;&Atilde;O</p>


      <p>I. Valor de face dos t&iacute;tulos---------------------------------------------------------------- R$&nbsp;</p>

      <p>II. Custo/Des&aacute;gio----------------------------------------------------------------------------- R$&nbsp;</p>

      <p>III. Valor L&iacute;quido Parcial--------------------------------------------------------------------- R$&nbsp;</p>

      <p>IV. Recompra ----------------------------------------------------------------------------------- R$&nbsp;</p>

      <p>V. Pend&ecirc;ncias Financeiras (+/-)---------------------------------------------------------- R$&nbsp;</p>

      <p>VI. Valor Liquido Final/PAGO--------------------------------------------------------------- R$&nbsp;</p>


      <p style="text-align:justify">Al&eacute;m da responsabilidade pela exist&ecirc;ncia e correta formaliza&ccedil;&atilde;o dos Direitos de Cr&eacute;dito e dos t&iacute;tulos a eles relativos, liquidez e certeza dos valores a eles referentes, e pela liquida&ccedil;&atilde;o dos Direitos de Cr&eacute;dito, a Cedente ser&aacute; respons&aacute;vel tamb&eacute;m pela recompra dos Direitos de Cr&eacute;dito ora negociados, caso estes estejam vencidos ou n&atilde;o, dentro do prazo de 2 (dois) dias &uacute;teis da solicita&ccedil;&atilde;o pela Cession&aacute;rio, nas hip&oacute;teses previstas na Cl&aacute;usula XIII do Contrato de Cess&atilde;o. Declaramos ainda que possu&iacute;mos o fundamento legal e econ&ocirc;mico referente aos t&iacute;tulos ora negociados neste Termo de Cess&atilde;o.</p>


      <p style="text-align:center">FORMA DE PAGAMENTO</p>


      <p>Tipo de Pagamento:&nbsp;</p>

      <p>Valor Pago: R$&nbsp;</p>

      <p>CNPJ / Favorecido:&nbsp;</p>

      <p>Banco &ndash; Agencia &ndash; Conta :&nbsp;</p>


      <p style="text-align:justify">Os termos utilizados neste Termo de Cess&atilde;o, iniciados em letras mai&uacute;sculas (estejam no singular ou no plural), que n&atilde;o sejam aqui definidos de outra forma, ter&atilde;o os significados que lhes s&atilde;o atribu&iacute;dos no Contrato de Cess&atilde;o.&nbsp; Este Termo de Cess&atilde;o &eacute; parte integrante do Contrato de Cess&atilde;o para todos os fins e efeitos.&nbsp;</p>

      <p>Recife,&nbsp;&nbsp;</p>


      <p>_____________________________<br />CEDENTE</p>

      <p>REPRESENTANTE(S)</p>

      <div class="wrap">
      <p>
        _____________________________
        <br />
        Nome:<br />
        CPF:
      </p>
      <p>
        _____________________________
        <br />
        Nome:<br />
        CPF:
      </p>
      </div>
      <p><strong>DEVEDOR(ES) SOLIDÁRIO(S)/FIADOR(ES)/FIEL(EIS) DEPOSITÁRIO(S)</strong></p>
      <div class="wrap">
      <p>
        _____________________________
        <br /><br />
        INTERVENIENTE
        <br />
        BFC CAPITAL PARTNERS/ TERCON INVESTIMENTOS
      </p>
      <p>
        _____________________________
        <br /><br />
        CESSION&Aacute;RIO
        <br />
        BFC FUNDO DE INVEST DIREITOS CRED NP <br />ADMINISTRADORA: SOCOPA
      </p>
      </div>

      </strong>
      <p><br />
      &nbsp;</p>

      <p>BFC FIDC MULTISSETORIAL &nbsp; &nbsp; <strong>* &nbsp; Rela&ccedil;&atilde;o&nbsp; Anexo de&nbsp; T&iacute;tulos *</strong></p>

      <strong>
      <p>Cedente: </p>

      <p>Filial: Data: Border&ocirc;: Termo de Cess&atilde;o:</p>

      </strong>

      <p>ID. T&iacute;tulo &nbsp; &nbsp; Oper. Dt. Venc. &nbsp; Tipo Valor %VF&nbsp; &nbsp; Nr. Documento Sacado</p>


      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <strong>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;Total&nbsp; dos T&iacute;tulos: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Qtd. de T&iacute;tulos: Valor Face: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Qtd. T&iacute;tulos Info:&nbsp;</p>

      <p><br />
      &nbsp;</p>

      <p style="text-align:right">_____________________________________</p>

      <p style="text-align:center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CEDENTE</p>

      </strong>
      </body>
      </html>
  `);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}
export function singleDeclaration(member) {
  var mywindow = window.open('', 'PRINT');
  mywindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Document</title>
      <style>
        h2 {
          text-align: center;
        }
      </style>
    </head>
    <body style="font-family: sans-serif;">
    <h2>DECLARA&Ccedil;&Atilde;O DE ESTADO CIVIL</h2>
    
    <p>&nbsp;</p>
    
    <p>Pela presente, eu ${member.fullname}, nacionalidade: ${member.nationality}, profiss&atilde;o: ${member.profession}, CPF: ${member.cpf}, RG n&ordm; ${member.identity_doc} &Oacute;rg&atilde;o Emissor: ${member.issuing_agency}, com domicilio residencial: ${member.address}, bairro: ${member.neighborhood}, cidade: ${member.city}, CEP: ${member.zipcode}.</p>
    
    <p>Declaro ser Solteiro(a), motivo pelo qual &eacute; inexig&iacute;vel a outorga conjugal quando do meu comparecimento como avalista e/ou devedor(a) solid&aacute;rio(a) , em Opera&ccedil;&otilde;es de Cr&eacute;dito..</p>
    
    <p>Outrossim, comprometo-me, em car&aacute;ter irrevog&aacute;vel e irretrat&aacute;vel, a dar conhecimento de imediato a BFC, sobre quaisquer altera&ccedil;&otilde;es que venham a ocorrer em meu Estado Civil, sob pena de responder, inclusive criminalmente pelas declara&ccedil;&otilde;es aqui prestadas.</p>
    
    <p>Por ser a express&atilde;o da verdade, firmo a presente.</p>
    
    <p>&nbsp;</p>
    
    <p style="text-align: center;">_______________, ${format(new Date(), "d 'de' MMMM 'de' yyyy", { locale: pt_BR })}.</p>
    
    <p>&nbsp;</p>
    
    <p style="text-align: center;">______________________________________________________<br />
    ${member.fullname}
    </p>
    
    </body>
    </html>
    `);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}
export function bankDeclaration(register) {
  function findByName(name) {
    const value = register.dados.find(item => item.name === name);
    if(value) {
      return value.value;
    }else{
      return "";
    }
  }

  const bancos = register.dados.find(item => item.name === "bancos");
  const referencias_comerciais = register.dados.find(item => item.name === "referencias_comerciais");
  console.log("bankDeclaration -> bancos", bancos)
  var mywindow = window.open('', 'PRINT');
  mywindow.document.write(`
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>DECLARACÃO_BANCÁRIA_${findByName('razao_social')}</title>
      <style>
        h2 {
          text-align: center;
        }
      </style>
    </head>
    <body style="font-family: sans-serif;">
    <h2>DECLARA&Ccedil;&Atilde;O DE RELACIONAMENTO COM OUTRAS INSTITUIÇÕES</h2>
    
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    
    <p>${findByName('razao_social')}</p>

    <p>&nbsp;</p>

    <p>Ref.: DECLARAÇÃO ACERCA DAS INSTITUIÇÕES COM AS QUAIS MANTÉM RELACIONAMENTO DE CRÉDITO</p>

    <p>${findByName('razao_social')}, com sede na ${findByName('endereco')},${findByName('numero')},${findByName('bairro')}, Cep: ${findByName('cep')}, na Cidade de ${findByName('cidade')}, inscrita no CNPJ/MF sob n&deg; ${normalizeCnpj(register.cnpj)}, declara para os devidos fins que mantém
    relacionamento de crédito com as instituições abaixo relacionadas:</p>

    <p>&nbsp;</p>

    <table border="1" cellspacing="0" cellpadding="10">
      <tr>
        <td>
          Instituição de Crédito e CNPJ
        </td>
        <td>
          Contato na instituição
        </td>
        <td>
          Dados bancários do Cedente (se aplicável)
        </td>
      </tr>
      ${
        bancos.value.map((banco,index)=>(
          `<tr key=${index}>
            <td>${banco[0].value}</td>
            <td></td>
            <td>
              Ag: ${banco[1].value} <br/>
              Cc: ${banco[2].value}-${banco[3].value} <br/>
            </td>
          </tr>`
        )).join('')}
        ${
          referencias_comerciais.value.map((item,index)=>(
            `<tr key=${index}>
              <td>${item[0].value}</td>
              <td>${item[2].value}</td>
              <td></td>
            </tr>`
          )).join('')}
    </table>
    
    <p>&nbsp;</p>

    <p>${format(new Date(), "d 'de' MMMM 'de' yyyy", { locale: pt_BR })}</p>

    <p>&nbsp;</p>

    <p>Atenciosamente,</p>

    <p>&nbsp;</p>

    <p>___________________________________<br/>
      ${findByName('razao_social')}</p>
    
    </body>
    </html>
    `);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}
export function printPjDocumentMember(member) {
  var mywindow = window.open('', 'PRINT');
  mywindow.document.write(`
  <!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>
    <style>
      body {
        font-family: sans-serif;
      }
      .wrap {
        display:flex;
        justify-content: flex-start;
        align-items: flex-end;
        text-align: left;
        margin:16px 0;
      }
      .wrap p {
        flex:1;
        margin:0 5px;
        border-bottom:1px solid #555;
        font-size:14px;
      }
      strong {
        color:#555;
        display: block;
        font-size:12px;
        text-align: left;
        flex:1;
      }
      .wrap span {
        flex: 1;
      }
      .center {
        text-align: center;
        display:block;
      }
      .border {
        border-bottom: 1px solid #000;
        text-indent: 10px;
      }
      h2 {
        font-size: 30px;
      }
      h3 {
        font-size:26px;
      }
    </style>
  </head>
  <body>
    <h2 class="border">FICHA CADASTRAL – PESSOA JURÍDICA</h2>
    <div style="padding:0 15px;">
      <h3 class="border">1. EMPRESA</h3>
      <div class="wrap">
        <p>
          <strong>Razão Social:</strong>
          ${member.fullname}
        </p>
        <p>
          <strong>Nome Fantasia:</strong>
          .
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>CNPJ:</strong>
          ${member.cpf}
        </p>
        <p>
          <strong>Insc. Estadual:</strong>
          .
        </p>
        <p>
          <strong>Insc. Municipal:</strong>
          .
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Endereço:</strong>
          .
        </p>
        <p>
          <strong>Número:</strong>
          .
        </p>
        <p>
          <strong>Complemento:</strong>
          .
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Bairro:</strong>
          .
        </p>
        <p>
          <strong>Cidade:</strong>
          .
        </p>
        <p>
          <strong>Estado:</strong>
          .
        </p>
        <p>
          <strong>CEP:</strong>
          .
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>E-mail/Site:</strong>
          .
        </p>
        <p>
          <strong>Fone:</strong>
          .
        </p>
        <p>
          <strong>Data Fundação:</strong>
          .
        </p>
        <p>
          <strong>Pessoa do Contato:</strong>
          .
        </p>
      </div>
      <div class="wrap">
        <p>
          <strong>Faturamento Mensal:</strong>
          R$ .
        </p>
        <p>
          <strong>Capital Social:</strong>
          R$ .
        </p>
        <p>
          <strong>Nº Funcionários:</strong>
          .
        </p>
        <p>
          <strong>Sede Própria:</strong>
          .
        </p>
      </div>
      <h3 class="border">2. DADOS BANCÁRIOS PARA CRÉDITO</h3>
      <div class="wrap">
        <p>
          <strong>Banco:</strong>
          .
        </p>
        <p>
          <strong>Agência:</strong>
          .
        </p>
        <p>
          <strong>Conta Corrente:</strong>
          .
        </p>
      </div>
      <h3 class="border">3. ATIVIDADE</h3>
      <div class="wrap">
        <p>
          <strong>Ramo de Atividade:</strong>
          .
        </p>
        <p>
          <strong>Importa</strong>
          .
        </p>
        <p>
          <strong>Exporta:</strong>
          .
        </p>
      </div>
      <h3 class="border">4. ACIONISTAS/SÓCIOS/QUOTITAS</h3>
      <div class="wrap">
          <strong>Nome:</strong>
          <strong>CPF/CNPJ:</strong>
      </div>
        <div class="wrap">
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        </div>
        <div class="wrap">
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        </div>
        <div class="wrap">
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        </div>
        <div class="wrap">
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        </div>
        <div class="wrap">
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        </div>

      <h3 class="border">5. DIRETORES/REPRESENTANTES LEGAIS</h3>
      <div class="wrap">
          <strong>Nome:</strong>
          <strong>CPF/CNPJ:</strong>
          <strong>Cargo:</strong>
      </div>
        <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
        </div>
        <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
        </div>
        <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
        </div>
        <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
        </div>
        <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
        </div>
      <h3 class="border">6. PRINCIPAIS FORNECEDORES</h3>
      <div class="wrap">
          <strong>Nome:</strong>
          <strong>CNPJ:</strong>
          <strong>Fone:</strong>
      </div>
      <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
      </div>
      <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
      </div>
      <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
      </div>
      <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
      </div>
      <div class="wrap">
          <p>
            
          </p>
          <p>
            
          </p>
          <p>
            
          </p>
      </div>
      <hr class="border"/>
      <strong>
        Declaramos, sob as penas da lei, que as informações prestadas são verdadeiras, e nos comprometemos a informar, no prazo de 10 (dez) dias, quaisquer alterações que vierem a ocorrer nos dados cadastrais da empresa, bem como autorizamos o BFC FIDC / BFC Capital Partners a consultar as fontes de referência informadas, (clientes, fornecedores e bancos) e inserir / solicitar informações relacionadas com nossa empresa e coligadas, junto ao mercado financeiro e entidades cadastrais em geral, inclusive junto ao SCR-Sistema de Informações de Crédito do Banco Central  do Brasil (Res. 3.658 do Conselho Monetário Nacional), SERASA ou quaisquer outros órgãos ou entidades assemelhadas.
      </strong>
      <br />
      <div class="wrap">
        <span class="center" style="margin:0 5px;">
          _____________________________<br />
          Local
        </span>
        <span class="center">
          ${format(new Date(), 'dd/MM/yyyy')}<br />
          Data
        </span>
        <span class="center" style="margin:0 5px;">
          _____________________________<br />
          Assinatura
        </span>
      </div>
      <strong class="center">Av. Eng. Domingos Ferreira, 2589 / Sala 1001, Boa Viagem, Recife-PE – CEP: 51.020-031
        <br/>PABX: +55 (81) 2119-9595 / 4007-2614 / www.grupobfc.com.br</strong>
    </div>
  </body>
  </html>
`);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}
export function publicPersonDeclaration(member) {
  const publicPerson = member.in_politically_exposed_person === 'sim' ? true : false;
  var mywindow = window.open('', 'PRINT');
  mywindow.document.write(`
  <!DOCTYPE html>
<html lang="pt-BR">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>${member.fullname} - Declaração de Pessoa Pública</title>
</head>
<style>
  * {
    margin:0;
    padding:0;
    box-sizing: border-box;
  }
  .wrap {
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px;
  }
  .wrap div {
    text-align: center;
  }
  .item {
    display: flex;
    margin:15px 5px;
  }
  .item div {
    display:flex;
    justify-content: center;
    align-items: center;
    width:16px !important;
    height: 16px !important;
    margin-right:5px;
    border:1px solid #000;
  }
  .container {
    border: 1px solid #000;
  }
  .container h2 {
    border-bottom: 1px solid #000;
    padding:5px;
    margin: 0;
  }
</style>
<body>
  <div class="container">
  <h2>Declaração / Autorização</h2>
  <div class="item">
    <div>
      ${publicPerson ? 'x' : ''}
    </div>
    Declaro a minha condição de enquadramento como pessoa politicamente exposta (PPE), ou 
    a esta <br/>relacionado, a que se refere a Resolução n° 016, de 28/03/2007
  </div>
  <div class="item">
    <div>
      ${!publicPerson ? 'x' : ''}
    </div>
    Declaro minha condição de não enquadramento na condição de PPE, ou a esta relacionado.
     Estou ciente <br />da obrigação de comunicar, incontinente, qualquer alteração dessa condição atual.
  </div>
  <div class="item">
    <div>
      ${publicPerson ? 'x' : ''}
    </div>
    Declaro manter ligação com as pessoas / entidades a que se refere a Resolução n° 015, de 
    28/03/2007.
  </div>
  <div class="item">
    <div>
      ${!publicPerson ? 'x' : ''}
    </div>
    Declaro não manter ligação com pessoas / entidades a que se refere a Resolução 015, 
    de 28/03/2007, <br />do COAF.<br />
    Estou ciente da obrigação de comunicar, incontinenti, caso venha a manter ligação com 
    aquelas pessoas <br />/ entidades.
  </div>
  <div class="item">
    <div>
      x
    </div>
    Declaro que as informações prestadas acima, inclusive as cadastrais, são expressão da verdade.
  </div>
  <div class="item">
    <div>
      x
    </div>
    Declaro sob pena de responder Civil e Criminalmente que as informações prestadas são expressão da 
    <br />verdade e autorizo o envio dos dados à SERASA - Centralização dos Serviços dos Bancos.
  </div>
  <br />
  <br />
  <br />
  <div class="wrap">
    <div>
      __________________________________<br />
      ${member.fullname}
    </div>
    <div>
      ______________________________________________<br />
      Local e data
    </div>
  </div>
</div>
</body>
</html>
  `);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}