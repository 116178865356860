import React from "react";

export default function ListTransporterNew({ classes, item, index }) {
  return (
    <div className="col-md-12" style={{ padding: "10px" }} key={index}>
      <div className={classes.box}>
        <h5>CTe: {item.cte}</h5>
      </div>
    </div>
  );
}
