/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import axios from "axios";
import { formatDistance, parseISO } from "date-fns";
import pt from "date-fns/locale/pt";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { Notifications } from "@material-ui/icons";
import { toAbsoluteUrl } from "../../../_metronic";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import socketio from 'socket.io-client';

function UserProfile(props) {
  const [notifications, setNotifications] = useState([]);
  const [notRead, setNotRead] = useState([]);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  async function loadNotifications() {
    setLoading(true);
    const response = await axios.get(`notification?page=${page}`);
    setNotifications(response.data.data);
    setNotRead(response.data.data.filter(i => i.read === 0).length);
    setLastPage(response.data.lastPage);
    setLoading(false);
  }

  useEffect(() => {
    if(page > 1) {
      async function loadNextPage() {
        setLoading(true);
        const response = await axios.get(`notification?page=${page}`);
        setNotifications([...notifications, ...response.data.data]);
        const notReadNews = response.data.data.filter(i => i.read === 0);
        setNotRead(notRead+notReadNews.length);
        setLoading(false);
      }
      loadNextPage();
    }
  }, [page]); // eslint-disable-line

  useEffect(() => {
    loadNotifications();
  }, []); // eslint-disable-line

  async function handleUpdateNotification(id) {
    const response = await axios.put(`notification/${id}`);
    setNotRead(notRead-1);
    setNotifications(response.data);
  }

  const { user, showHi, showAvatar, showBadge } = props;

  useEffect(() => {
    const socket = socketio(process.env.REACT_APP_API_BFC_DIGITAL_URL, {
      query: {
        user_id: user.id, 
      }
    });
    socket.on('notification', message => {
      // toast.warn(message, { autoClose: false, });
      loadNotifications();
    })
  }, []) // eslint-disable-line

  return (
    <>
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div
            className="kt-header__topbar-wrapper"
            data-toggle="dropdown"
            data-offset="10px,0px"
          >
            {showHi && (
              <span className="kt-header__topbar-welcome kt-hidden-mobile">
                Olá,
              </span>
            )}

            {showHi && (
              <span className="kt-header__topbar-username kt-hidden-mobile">
                {user.fullname}
              </span>
            )}

            {showAvatar && <img alt="Pic" src={user.pic} />}

            {showBadge && (
              <span className="kt-header__topbar-icon">
                {/* TODO: Should get from currentUser */}
                <b>{user && user.fullname[0]}</b>
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
          {/** ClassName should be 'dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl' */}
          <div
            className="kt-user-card kt-user-card--skin-dark kt-notification-item-padding-x"
            style={{
              backgroundImage: `url(${toAbsoluteUrl("/media/misc/bg-1.jpg")})`
            }}
          >
            <div className="kt-user-card__avatar">
              <img alt="Pic" className="kt-hidden" src={user.pic} />
              <span className="kt-badge kt-badge--lg kt-badge--rounded kt-badge--bold kt-font-success">
                {user && user.fullname[0]}
              </span>
            </div>
            <div className="kt-user-card__name">{user.fullname}</div>
          </div>
          <div className="kt-notification">
            <Link to="/profile">
              <a className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    Meu perfil
                </div>
                </div>
              </a>
            </Link>
            <Link to="/profile/password">
              <a className="kt-notification__item">
                <div className="kt-notification__item-icon">
                  <i className="flaticon2-calendar-3 kt-font-success" />
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    Alterar senha
                </div>
                </div>
              </a>
            </Link>
            <div className="kt-notification__custom">
              <Link
                to="/logout"
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sair
              </Link>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <button
        className="kt-header__topbar-item kt-header__topbar-item--user"
        style={{ 
          background: 'none', 
          border: 'none',
          position: 'relative'
        }}
        onClick={() => setOpen(!open)}
      >
        {notRead > 0 && (
            <div className="counter_notification">{notRead > 9 ? '+9' : notRead}</div>
        )}
        <Notifications
          className="kt-header__topbar-username"
          style={{
            fontSize: '36px'
          }}
        />
      </button>
      {open && (
          <div className="notification_floated">
            {notifications.length > 0 ?
              notifications.map(item => (
                <div key={item.id} style={{ 
                  background: item.read === 0 ? '#f3ac42' : '#fff',
                  color: item.read === 0 && '#444'
                }}>
                  <h4>{item.title}</h4>
                  <small>{item.description}<br />{formatDistance(
                    parseISO(item.created_at),
                    new Date(),
                    { addSuffix: true, locale: pt }
                  )}</small>
                  {item.read === 0 && (
                    <>
                      <hr />
                      <button
                        type="button"
                        onClick={() => handleUpdateNotification(item.id)}
                      >
                        Marcar como lido
                      </button>
                    </>
                  )}

                </div>
              )) : (
                <div style={{ padding: '10px' }}>
                  <h4>Nenhuma notificação...</h4>
                </div>
              )}
              {notifications.length > 0 && (
                <>
                  {page < lastPage && (
                    <button type="button" disabled={loading} className="btn btn-primary text-center" onClick={() => setPage(page+1)} style={{ width: '100%', borderRadius: '0px' }}>
                      Carregar mais
                    </button>
                  )}
                </>
              )}
          </div>
        )}
    </>
  );
}

const mapStateToProps = ({ auth: { user } }) => ({
  user
});

export default connect(mapStateToProps)(UserProfile);
