import React, { memo } from "react";
import axios from "axios";
import iconDocument from "../../utils/iconDocument";
import { formatDistance, parseISO } from "date-fns";
import pt_BR from "date-fns/locale/pt-BR";
import { SettingsBackupRestore, Close } from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { makeStyles, Modal, Backdrop } from "@material-ui/core";

function ModalTrashRepositorie({
  open,
  documentsRepository,
  onReload,
  onClose,
  editable
}) {
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "800px",
      height: "50%",
      overflow: "auto"
    }
  }));

  const handleRestore = async id => {
    try {
      await axios.put(`document_repositories/restore/${id}`);
      onReload();
    } catch (err) {
      toast.error(err.message);
    }
  };

  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fade}>
        <Row style={{ borderBottom: "1px solid #eee" }}>
          <Col lg={11}>
            <h2>Lixeira</h2>
          </Col>
          <Col lg={1}>
            <button
              type="button"
              onClick={() => onClose()}
              style={{ background: "none", border: "0" }}
            >
              <Close />
            </button>
          </Col>
        </Row>

        <div className="col-12">
          <div className="col-12 confirm">
            <Row className="mt-4">
              <Col lg={12} className="box__document_repo">
                {documentsRepository.length > 0 ? (
                  documentsRepository.map((e, i) => {
                    return (
                      <div
                        className="document_repo"
                        key={i}
                        style={{ position: "relative", paddingTop: "20px" }}
                      >
                        {editable && (
                          <button
                            type="button"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: "10px"
                            }}
                            className="restore_document"
                            onClick={() => handleRestore(e.id)}
                          >
                            <SettingsBackupRestore
                              style={{ fontSize: `18px` }}
                            />
                          </button>
                        )}
                        <a
                          href={e.url}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {iconDocument(e.url)}
                        </a>
                        <span>{e.name}</span>
                        {formatDistance(parseISO(e.created_at), new Date(), {
                          addSuffix: true,
                          locale: pt_BR
                        })}
                      </div>
                    );
                  })
                ) : (
                  <h3>Lixeira Vazia...</h3>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalTrashRepositorie);
