import React, { memo } from "react";
import { Modal, Fade, Backdrop, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";

function ModalConfirmReproveCompany(props) {
  return (
    <Modal
      className={props.classes.modal}
      open={props.openReprove}
      onClose={() => {
        props.setOpenReprove(false);
        props.setSelectReprove({ ...props.selectReprove, fields: false });
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.openReprove}>
        <div className={props.classes.fade}>
          <div className={props.classes.wrap}>
            <h2 id="transition-modal-title">Reprovar Campos</h2>
            <button
              className={props.classes.close}
              onClick={() => props.setOpenReprove(false)}
            >
              <Close />
            </button>
          </div>
          <TextField
            label="Título"
            name="title"
            onChange={e => {
              props.setReproved({ ...props.reproved, title: e.target.value });
            }}
            style={{ width: "100%" }}
          />
          <TextField
            multiline={true}
            rows="4"
            label="Motivo"
            name="description"
            onChange={e => {
              props.setReproved({
                ...props.reproved,
                description: e.target.value
              });
            }}
            style={{ width: "100%" }}
          />
          <h4 style={{ marginTop: "10px" }}>Campos Reprovados</h4>
          <Row>
            {props.itemsReproved &&
              props.itemsReproved.map(item => (
                <Col lg={3} key={item.name} style={{ padding: "5px" }}>
                  <div className={props.classes.card}>
                    {item.title || item.document_type.name}
                  </div>
                </Col>
              ))}
          </Row>
          <button
            className="btn btn-success"
            onClick={() => props.onSubmitReproved()}
            type="button"
            disabled={props.loading}
          >
            {props.loading ? "Carregando..." : "Enviar Relatório"}
          </button>
        </div>
      </Fade>
    </Modal>
  );
}

export default memo(ModalConfirmReproveCompany);
