import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  FormControl,
  InputLabel,
  CircularProgress,
  Paper,
  LinearProgress,
  makeStyles,
  Select,
  MenuItem,
} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ModalDetailProtestPayer from "./components/ModalDetailProtestPayer";
import ModalSearch from "./components/ModalSearch";
import ModalByBordero from "./components/ModalByBordero";
import { format } from "date-fns";
import { Pagination, Dropdown } from "react-bootstrap";
import { normalizeCnpj } from "./utils/normalize";
import { toast } from "react-toastify";

export default function ProtestPayerIndex() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(null);
  const [search, setSearch] = useState(false);
  const [byBordero, setByBordero] = useState(false);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [filter, setFilter] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  async function loadProtestPayer() {
    setLoading(true);
    try {
      const response = await axios.get(`https://gateway-ovh.grupobfc.com.br/payerProtest/operations?page=${page + 1}&perPage=${rowsPerPage}`);
      setRows(response.data);
      setTotal(response.data.length);
    } catch (err) {
      toast.error("Ocorreu um erro na listagem");
    }
    setLoading(false);
  }

  async function searchRequest({ by, value }) {
    setLoading(true);
    setFilter(true);
    try {
      if(by === 0) {
        const response = await axios.get(`https://gateway-ovh.grupobfc.com.br/payerProtest/operations?page=1&perPage=${rowsPerPage}&assignorDocumentNumber=${value.replace(/[^\d]/g, '')}`);
        setRows(response.data);
      }else {
        const response = await axios.get(`https://gateway-ovh.grupobfc.com.br/payerProtest/operations?page=1&perPage=${rowsPerPage}&borderoId=${value.replace(/[^\d]/g, '')}`);
        setRows(response.data);
      }
      setPage(0);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.info("Nenhum borderô encontrada");
      } else {
        toast.error("Ocorreu um erro ao buscar borderôs");
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    loadProtestPayer();
  }, [page, rowsPerPage]); // eslint-disable-line

  const headRows = [
    { id: "operation", align: "left", disablePadding: true, label: "Borderô" },
    { id: "cedent", align: "left", disablePadding: false, label: "Cedente" },
    {
      id: "payers",
      align: "right",
      disablePadding: false,
      label: "Sacados",
    },
    {
      id: "createdAt",
      align: "center",
      disablePadding: false,
      label: "Data de Processamento"
    },
    { id: "actions", align: "right", disablePadding: false, label: "Ações" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy /*onRequestSort*/ } = props;
    /* const createSortHandler = property => event => {
      onRequestSort(event, property);
    }; */

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={() => {}}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleNextPage() {
    const nextPage = page + 1;
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1;
    setPage(previousPage);
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Relato Sacado</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/relato/protest-payer`}>
                Relato Sacado
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
            {filter && (
                <button type="button" className="btn btn-warning" onClick={() => {
                  setFilter(false);
                  loadProtestPayer();
                }}>
                  Esvaziar Filtro
                </button>
              )}
              <Dropdown alignRight>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                Ações
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={() => setSearch(true)}>Buscar</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => setByBordero(true)}>Novo Relato por Borderô</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <ModalByBordero open={byBordero} setOpen={() => setByBordero(false)} />
      <ModalSearch
        open={search}
        setOpen={(e) => setSearch(e)}
        getConsult={({ by, value }) => searchRequest({ by, value })}
      />
      <ModalDetailProtestPayer
        open={open}
        setOpen={(e) => { 
          setOpen(e);
          setView(null);
        }}
        item={view}
        onResend={loadProtestPayer}
      />
      <Paper className={classes.paper}>
        <div>
          {loading && <LinearProgress color="primary" />}
          <Table aria-labelledby="tableTitle" size={"medium"}>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row._id}>
                    <TableCell align="left">{row.borderoId}</TableCell>
                    <TableCell align="left">
                      {row.assignorName} <br />
                      {normalizeCnpj(row.assignorDocumentNumber)}
                    </TableCell>
                    <TableCell align="right">
                      {row.payersCount || 0} Sacado(s)
                    </TableCell>
                    <TableCell align="center">
                      {format(new Date(row.createdAt), 'dd/MM/yyyy - HH:mm')}
                    </TableCell>
                    <TableCell align="right">
                      <button
                        type="button"
                        className="btn btn-sm pl-2 pr-2"
                        onClick={() => {
                          setView(row.borderoId);
                          setOpen(true);
                        }}
                      >
                        <VisibilityIcon />
                      </button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && <CircularProgress size={15} className="mr-2" />}
              <Pagination>
                {page > 0 && (
                  <>
                    <Pagination.First
                      onClick={() => setPage(0)}
                      disabled={page === 0 || loading}
                    />
                    <Pagination.Prev
                      onClick={() => handlePreviousPage()}
                      disabled={loading}
                    />
                  </>
                )}
                <Pagination.Item disabled>{page + 1}</Pagination.Item>
                {page < Math.ceil(total / rowsPerPage) - 1 && (
                  <>
                    <Pagination.Next
                      onClick={() => handleNextPage()}
                      disabled={loading}
                    />
                    <Pagination.Last
                      onClick={() =>
                        setPage(Math.ceil(total / rowsPerPage) - 1)
                      }
                      disabled={loading}
                    />
                  </>
                )}
              </Pagination>
            </div>
            <FormControl
              style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
              disabled={loading}
            >
              <InputLabel htmlFor="results_per_page">
                Result. p/Página
              </InputLabel>
              <Select
                id="results_per_page"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(e.target.value)}
                disabled={loading}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
