import React, { useState, useEffect, memo } from 'react';

import { MoneyOff } from "@material-ui/icons";
import { Modal, Button } from 'react-bootstrap';
import { format } from "date-fns";

import { normalizeCurrency } from './normalize';

function ModalMonths({ open, setOpen, nfes }) {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if(open) {
      let count = 0;
      nfes.forEach(nfe => {
        count += nfe.value;
      });
      setTotal(count);
    }
  }, [open, nfes]);

  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Body>
        <h3>NFe's Canceladas</h3>
        <hr />
        <div className="d-flex align-items-center justify-content-start" style={{ padding: "10px" }}>
          <MoneyOff className="mr-2" style={{ fontSize: "60px" }}/>
          <h5 className="text-left">
            Total de<br />
            <strong>
              {nfes.length} Nota(s) cancelada(s)
            </strong><br />
            <small>
              R$ {normalizeCurrency(total)}
            </small>
          </h5>
        </div>
        <hr />
        <div>
          {nfes.map((nfe, index) => (
            <div key={nfe.chNfe} className="d-flex justify-content-between align-items-center" style={{
              padding: '10px',
              borderTop: index > 0 && '1px solid #c9c9c9'
            }}>
              <div className="d-flex flex-column">
                <small>{nfe.chNfe}</small>
                <small><strong>Data de expedição: {nfe.date !== "0000-00-00" &&
                        format(new Date(nfe.date), "dd/MM/yyyy")}</strong></small>
              </div> 
              <strong>R$ {normalizeCurrency(nfe.value)}</strong>
            </div>
          ))}
        </div>
        <hr />
        <Button type="button" variant="primary" className="ml-auto mr-auto" onClick={() => {
          setOpen(false)
        }}>
          Fechar
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default memo(ModalMonths);