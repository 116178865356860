import React, { memo } from "react";
import DocumentsTableRow from "./DocumentsTableRow";

function DocumentsTable(props) {
  function selected() {
    if (props.exempt.select && !props.approve.select && !props.reprove.select) {
      return "exempt";
    } else if (
      !props.exempt.select &&
      props.approve.select &&
      !props.reprove.select
    ) {
      return "approve";
    } else if (
      !props.exempt.select &&
      !props.approve.select &&
      props.reprove.select
    ) {
      return "reprove";
    } else {
      return "nothing";
    }
  }

  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <td>Tipo de documento</td>
          <td>Status</td>
        </tr>
      </thead>
      <tbody>
        {props.register.documents &&
          props.register.documents.map(document => {
            const selectedReproved = props.docsReproved.find(
              i => i.id === document.id
            );
            const selectedApproved = props.docsApproved.find(
              i => i.id === document.id
            );
            const selectExempted = props.docsExempted.find(
              i => i.id === document.id
            );
            return (
              <tr key={document.id}>
                {/* retorna uma linha da tabela para cada elemento do array documento */}
                <td>
                  <DocumentsTableRow
                    selected={selected()}
                    exempt={{
                      ...props.exempt,
                      className: selectExempted
                    }}
                    approve={{
                      ...props.approve,
                      className: selectedApproved
                    }}
                    reprove={{
                      ...props.reprove,
                      className: selectedReproved
                    }}
                    document={document}
                  />
                </td>
                <td className="text-center">
                  {props.statusDocument(document.status)}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

export default memo(DocumentsTable);
