const normalizeCep = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

const normalizePhone = value => {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/\D/g, '');

  if (onlyNums.length === 0) {
    onlyNums = ``;
  }
  else if (onlyNums.length === 1) {
    onlyNums = `(${onlyNums.slice(0, 1)}`;
  }
  else if (onlyNums.length === 2) {
    onlyNums = `(${onlyNums.slice(0, 2)}`;
  }
  else if (onlyNums.length < 7) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2,7)}`;
  }
  else if (onlyNums.length < 11) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2,6)}-${onlyNums.slice(6,11)}`;
  }else {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2,7)}-${onlyNums.slice(7,11)}`;
  }

  return onlyNums;
};

const normalizePercentage = value => {
  var onlyNums = value.replace(/\D/g, '');

  if (onlyNums.length === 0) {
    onlyNums = ``;
  }
  else if (onlyNums.length === 1) {
    onlyNums = `${onlyNums.slice(0, 1)}`;
  }
  else if (onlyNums.length === 2) {
    onlyNums = `${onlyNums.slice(0, 2)}`;
  }
  else if (onlyNums.length === 3) {
    onlyNums = `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,3)}`;
  }
  else if (onlyNums.length === 4) {
    onlyNums = `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,4)}`;
  }else if(onlyNums.length === 5 && onlyNums === '10000') {
    onlyNums = `100.00`;
  }else {
    onlyNums = `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,4)}`;
  }

  return onlyNums;
};

const normalizeCnpj = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}.${onlyNums.slice(5, 8)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

const normalizeDate = value => {
  if (!value) {
    return value;
  }

  const formated = value.replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{4})\d+?$/, '$1');

  return formated;
};

const normalizeNumber = value => {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/\D/g, '');

  return onlyNums;
};

const normalizeCpf = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;
  }
  return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
};

export default function normalizeMember(type, value) {
  switch (type) {
    case 'partic': return normalizePercentage(value);
    case 'date_of_birth': return normalizeDate(value);
    case 'cpf': return normalizeCpf(value);
    case 'cnpj': return normalizeCnpj(value);
    case 'number': return normalizeNumber(value);
    case 'zipcode': return normalizeCep(value);
    case 'phone': return normalizePhone(value);
    default: return value;
  }
}