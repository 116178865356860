const normalizeCep = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 5) {
    return onlyNums;
  }
  return `${onlyNums.slice(0, 5)}-${onlyNums.slice(5, 8)}`;
};

const normalizeCnpj = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

const normalizeCpfCnpj = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}`;
  }
  if (onlyNums.length <= 9) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}`;
  }
  if (onlyNums.length <= 11) {
    return `${onlyNums.slice(0, 3)}.${onlyNums.slice(3, 6)}.${onlyNums.slice(6, 9)}-${onlyNums.slice(9, 11)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || ''

const padDigits = digits => {
  const desiredLength = 3
  const actualLength = digits.length

  if (actualLength >= desiredLength) {
    return digits
  }

  const amountToAdd = desiredLength - actualLength
  const padding = '0'.repeat(amountToAdd)

  return padding + digits
}

const removeLeadingZeros = number => number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = number => {
  const centsStartingPosition = number.length - 2

  const cents = number.substring(centsStartingPosition)
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition))

  return `${dollars},${cents}`
}

const handleThousands = number => {
  const dollarsStartingPosition = number.length - 6

  const dollars = number.substring(dollarsStartingPosition)
  const thousands = removeLeadingZeros(number.substring(0, dollarsStartingPosition))

  return `${thousands}.${dollars}`
}

const handleMillions = number => {
  const thousandsStartingPosition = number.length - 10

  const thousands = number.substring(thousandsStartingPosition)
  const millions = removeLeadingZeros(number.substring(0, thousandsStartingPosition))

  return `${millions}.${thousands}`
}

const toCurrency = value => {
  const digits = getDigitsFromValue(value)
  const digitsWithPadding = padDigits(digits)
  const realNumber = removeLeadingZeros(digitsWithPadding);

  if (realNumber.length <= 5) {
    return `R$ ${addDecimalToNumber(digitsWithPadding)}`
  } else {
    if (realNumber.length > 5 && realNumber.length <= 8) {
      const numberWithDecimals = addDecimalToNumber(digitsWithPadding)
      return `R$ ${handleThousands(numberWithDecimals)}`
    } else {
      const numberWithDecimals = addDecimalToNumber(digitsWithPadding)
      const thousandsWithDecimals = handleThousands(numberWithDecimals)
      return `R$ ${handleMillions(thousandsWithDecimals)}`;
    }
  }
}

const normalizeDate = value => {
  if (!value) {
    return value;
  }

  const formated = value.replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})\d+?$/, '$1');

  return formated;
};

const normalizeNumber = value => {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/\D/g, '');

  return onlyNums;
};

const normalizePhone = value => {
  if (!value) {
    return value;
  }

  var onlyNums = value.replace(/\D/g, '');

  if (onlyNums.length === 0) {
    onlyNums = ``;
  }
  else if (onlyNums.length === 1) {
    onlyNums = `(${onlyNums.slice(0, 1)}`;
  }
  else if (onlyNums.length === 2) {
    onlyNums = `(${onlyNums.slice(0, 2)}`;
  }
  else if (onlyNums.length < 7) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
  }
  else if (onlyNums.length < 11) {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 6)}-${onlyNums.slice(6, 11)}`;
  } else {
    onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}-${onlyNums.slice(7, 11)}`;
  }

  return onlyNums;
};

export default function normalizeCompany(type, value) {
  switch (type) {
    case "cnpj":
      return normalizeCnpj(value);
    case "cep":
      return normalizeCep(value);
    case "telefone_da_empresa":
      return normalizePhone(value);
    case "limite_esperado":
      return toCurrency(value);
    case "ag":
      return normalizeNumber(value);
    case "conta_corrente":
      return normalizeNumber(value);
    case "conta_corrente_digito":
      return normalizeNumber(value);
    case "fone":
      return normalizePhone(value);
    case "telefone":
      return normalizePhone(value);
    case "data_constituicao":
      return normalizeDate(value);
    case "data_fundacao":
      return normalizeDate(value);
    case "n_funcionarios":
      return normalizeNumber(value);
    case "faturamento_mensal":
      return toCurrency(value);
    case "capital_social":
      return toCurrency(value);
    case "document":
      return normalizeCpfCnpj(value);
    case "documento":
      return normalizeCpfCnpj(value);
    case "insc_estadual":
      return normalizeNumber(value);
    case "insc_municipal":
      return normalizeNumber(value);
    default:
      return value;
  }
}