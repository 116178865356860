import React, { memo } from "react";

function DocumentsTableRow(props) {
  var funcOnClick;
  var disabled;
  var className;
  var bool = false;

  switch (props.selected) {
    case "exempt":
      funcOnClick = props.exempt.funcOnClick;
      disabled = props.exempt.select;
      className = props.exempt.className;
      bool = true;
      break;
    case "approve":
      funcOnClick = props.approve.funcOnClick;
      disabled = props.approve.select;
      className = props.approve.className;
      bool = true;
      break;
    case "reprove":
      funcOnClick = props.reprove.funcOnClick;
      disabled = props.reprove.select;
      className = props.reprove.className;
      bool = true;
      break;
    default:
      bool = false;
  }

  return (
    <button
      type="button"
      style={{
        background: "none",
        border: 0,
        marginLeft: 0,
        paddingLeft: 0
      }}
      onClick={() => funcOnClick(props.document)}
      disabled={!disabled}
    >
      <p
        style={{
          padding: "0",
          margin: "0",
          display: "flex",
          alignItems: "center"
        }}
      >
        {bool && (
          <span
            className={className ? "fa fa-check" : ""}
            style={{
              width: "18px",
              height: "18px",
              border: "0",
              background: "#dee2ea",
              display: "inline-block",
              borderRadius: "5px",
              marginRight: "10px"
            }}
          ></span>
        )}

        {props.document.document_type.name}
      </p>
    </button>
  );
}

export default memo(DocumentsTableRow);
