import React from "react";
import { Route, Switch } from "react-router-dom";
import TeamIndex from "./TeamIndex";
import TeamAdd from "./TeamAdd";
import TeamEdit from "./TeamEdit";

export default function TeamPage() {
  return (
    <Switch>
      <Route exact path="/teams/" component={TeamIndex} />
      <Route exact path="/teams/add" component={TeamAdd} />
      <Route exact path="/teams/:id" component={TeamEdit} />
    </Switch>
  );
}
