import React, { useState } from "react";

import { normalizeCnpj } from "../utils/normalize";

import ModalDetail from "./ModalDetail";

function CompanyItem({ company }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <tr>
      <td>{normalizeCnpj(company.cnpj)}</td>
      <td>{company.razao_social}</td>
      <td>{company.nome_fantasia}</td>
      <td>
        {company.municipio && company.uf
          ? `${company.municipio}/${company.uf}`
          : null}
      </td>
      <td>
        {company.ddd_1 && company.telefone_1
          ? `(${company.ddd_1}) ${company.telefone_1}`
          : null}
      </td>
      <td>{company.email}</td>
      <td>
        <ModalDetail
          open={expanded}
          setOpen={(e) => setExpanded(e)}
          cnpj={company.cnpj}
        />
        <button type="button" onClick={() => setExpanded(true)} className="btn">
          <i className="fa fa-eye"></i>
        </button>
      </td>
    </tr>
  );
}

export default CompanyItem;
