import React, { memo } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField
} from "@material-ui/core";

function EditingCompanyData(props) {
  return (
    <>
      {props.e.name === "setor_de_atividade" && (
        <FormControl style={{ width: "100%" }} variant="outlined">
          <InputLabel>{props.e.title}</InputLabel>
          <Select
            style={{ width: "100%" }}
            value={props.e.value || ""}
            onChange={event => {
              let dados = props.register.dados;
              dados[props.ind].value = event.target.value;
              props.setRegister({ ...props.register, dados });
            }}
            error={props.companyError[props.e.name] ? true : false}
          >
            <MenuItem value="INDÚSTRIA">Indústria</MenuItem>
            <MenuItem value="COMÉRCIO">Comércio</MenuItem>
            <MenuItem value="SERVIÇOS">Serviços</MenuItem>
          </Select>
        </FormControl>
      )}
      {props.e.name === "sede_propria" && (
        <FormControl style={{ width: "100%" }} variant="outlined">
          <InputLabel>{props.e.title}</InputLabel>
          <Select
            style={{ width: "100%" }}
            value={props.e.value || ""}
            onChange={event => {
              let dados = props.register.dados;
              dados[props.ind].value = event.target.value;
              props.setRegister({ ...props.register, dados });
            }}
            error={props.companyError[props.e.name] ? true : false}
          >
            <MenuItem value="sim">Sim</MenuItem>
            <MenuItem value="nao">Não</MenuItem>
          </Select>
        </FormControl>
      )}
      {props.e.name === "uf" && (
        <FormControl style={{ width: "100%" }} variant="outlined">
          <InputLabel>{props.e.title}</InputLabel>
          <Select
            style={{ width: "100%" }}
            value={props.e.value || ""}
            onChange={event => {
              let dados = props.register.dados;
              dados[props.ind].value = event.target.value;
              props.setRegister({ ...props.register, dados });
            }}
            error={props.companyError[props.e.name] ? true : false}
          >
            <MenuItem value={"AC"}>Acre</MenuItem>
            <MenuItem value={"AL"}>Alagoas</MenuItem>
            <MenuItem value={"AP"}>Amapá</MenuItem>
            <MenuItem value={"AM"}>Amazonas</MenuItem>
            <MenuItem value={"BA"}>Bahia</MenuItem>
            <MenuItem value={"CE"}>Ceará</MenuItem>
            <MenuItem value={"DF"}>Distrito Federal</MenuItem>
            <MenuItem value={"ES"}>Espírito Santo</MenuItem>
            <MenuItem value={"GO"}>Goiás</MenuItem>
            <MenuItem value={"MA"}>Maranhão</MenuItem>
            <MenuItem value={"MT"}>Mato Grosso</MenuItem>
            <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
            <MenuItem value={"MG"}>Minas Gerais</MenuItem>
            <MenuItem value={"PA"}>Pará</MenuItem>
            <MenuItem value={"PB"}>Paraíba</MenuItem>
            <MenuItem value={"PR"}>Paraná</MenuItem>
            <MenuItem value={"PE"}>Pernambuco</MenuItem>
            <MenuItem value={"PI"}>Piauí</MenuItem>
            <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
            <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
            <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
            <MenuItem value={"RO"}>Rondônia</MenuItem>
            <MenuItem value={"RR"}>Roraima</MenuItem>
            <MenuItem value={"SC"}>Santa Catarina</MenuItem>
            <MenuItem value={"SP"}>São Paulo</MenuItem>
            <MenuItem value={"SE"}>Sergipe</MenuItem>
            <MenuItem value={"TO"}>Tocantis</MenuItem>
          </Select>
        </FormControl>
      )}
      {props.e.name !== "sede_propria" &&
        props.e.name !== "setor_de_atividade" &&
        props.e.name !== "uf" && (
          <TextField
            type="text"
            value={props.e.value || ""}
            onChange={event => {
              let dados = props.register.dados;
              dados[props.ind].value = props.normalizeCompany(
                props.e.name,
                event.target.value
              );
              props.setRegister({ ...props.register, dados });
            }}
            label={props.e.title}
            error={props.companyError[props.e.name] ? true : false}
            helperText={
              props.companyError[props.e.name] &&
              props.companyError[props.e.name]
            }
            variant="outlined"
            disabled={props.e.name === "cnpj"}
            style={{ width: "100%" }}
          />
        )}
    </>
  );
}

export default memo(EditingCompanyData);
