import React, { useState, memo } from "react";

import { makeStyles } from "@material-ui/core/";
import { Modal, Fade, Backdrop, Button, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import { normalizeCnpj, validarCNPJ } from "./utils";

import axios from "axios";
import { toast } from "react-toastify";

function ModalCreate({ open, setOpen, openEcnpj }) {
  const [email, setEmail] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [emailError, setEmailError] = useState("");
  const [cnpjError, setCnpjError] = useState("");
  const [loading, setLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
      maxHeight: "90%",
      overflow: "auto",
    },
    close: {
      background: "#fff",
      fontSize: "20px",
      border: 0,
      textAlign: "right",
      width: "100%",
    },
    wrap: {
      display: "flex",
      justifyContent: "space-beetwen",
      alignItems: "center",
      width: "100%",
    },
  }));
  const classes = useStyles();

  function resetModal() {
    setOpen(!open);
    setEmail("");
    setCnpj("");
    setEmailError("");
    setCnpjError("");
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setCnpjError("");
    setEmailError("");
    let lock_error = false;
    if (!validarCNPJ(cnpj)) {
      setCnpjError("CNPJ não é válido");
      lock_error = true;
    }
    if (email.length === 0 && email.indexOf("@") === -1) {
      setEmailError("Email não é válido");
      lock_error = true;
    }
    let splited_email = email.split("@");
    if (splited_email[1].indexOf(".") === -1) {
      setEmailError("Email não é válido");
      lock_error = true;
    }
    if (lock_error) {
      return;
    }
    setLoading(true);
    try {
      await axios.post("e-cnpj", {
        cnpj: cnpj.replace(/[^\d]+/g, ""),
        email,
      });
      resetModal();
      toast.success("Cadastro Realizado");
    } catch (err) {
      if(err.response && err.response.status === 400) {
        toast.info("CNPJ já cadastrado");
        openEcnpj(cnpj.replace(/[^\d]+/g, ""));
      }else{
        toast.error("Ocorreu um erro, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => resetModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          <div className={classes.wrap}>
            <h2 id="transition-modal-title">Novo Cadastro</h2>
            <button className={classes.close} onClick={() => resetModal()}>
              <Close />
            </button>
          </div>
          <form onSubmit={handleSubmit}>
              <TextField
                label="CNPJ"
                value={cnpj}
                onChange={(e) => {
                  setCnpj(normalizeCnpj(e.target.value));
                }}
                style={{ width: '100%' }}
                className="mb-2"
                error={cnpjError !== ""}
                helperText={cnpjError}
              />
              <TextField
                label="Email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                style={{ width: '100%' }}
                error={emailError !== ""}
                helperText={emailError}
              />
            <div className="d-flex mt-4">
              <Button type="submit" color="primary" variant="contained" disabled={loading || cnpj.length === 0 || email.length === 0}>
                Cadastrar
              </Button>
              <Button color="primary" variant="outlined" disabled={loading} onClick={resetModal} className="ml-2">
                Cancelar
              </Button>
            </div>
          </form>
        </div>
      </Fade>
    </Modal>
  );
}

export default memo(ModalCreate);
