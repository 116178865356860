import React from 'react';

export default function statusDocument(status) {
  switch (status) {
    case 'S':
      return (<span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded">Enviado</span>);
    case 'D':
      return (<span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">Negado</span>);
    case 'A':
      return (<span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded">Aprovado</span>);
    case 'I':
      return (<span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded">Isento</span>);
    default:
      return (<span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded">Não enviado</span>);
  }
}