import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";

export default function PieChart({ title, desc, total, info }) {
  const ref = useRef();

  const data = useMemo(
    () => ({
      labels: [
        "A Confirmar",
        "Rejeitadas",
        "Não Confirmadas",
        "Não Encontradas",
        "Confirmadas",
      ],

      datasets: [{
        data: [info.aConfirmar || 0, info.rejeitadas || 0, info.naoConfirmadas || 0, info.naoEncontradas || 0, info.confirmadas || 0],
        backgroundColor: ['#5867dd', '#fd397a', '#ffb822', '#ea7c01', '#1dc9b7'],
      }]
    }),
    [info.aConfirmar, info.confirmadas, info.naoConfirmadas, info.naoEncontradas, info.rejeitadas]
  );

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data,
      type: "pie",
      options: {
        responsive: true,
        maintainAspectRatio: false,
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">{title}</h3>
          <span className="kt-widget14__desc"></span>
        </div>
      </div>
      <div className="kt-portlet__body" style={{ padding: '10px 10px' }}>
        <canvas ref={ref} style={{ height: '160px' }}/>
      </div>
      <strong style={{ padding: '10px' }}>Total: {total}</strong>
    </div>
  );
}
