export const normalizeCnpj = value => {
  if (!value) {
    return value;
  }

  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 2) {
    return onlyNums;
  }
  if (onlyNums.length <= 5) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}`;
  }
  if (onlyNums.length <= 8) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2,5)}.${onlyNums.slice(5, 8)}`;
  }
  if (onlyNums.length <= 12) {
    return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`;
  }
  return `${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}-${onlyNums.slice(12, 14)}`;
};

  const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || ''

const padDigits = digits => {
  const desiredLength = 3
  const actualLength = digits.length

  if (actualLength >= desiredLength) {
    return digits
  }

  const amountToAdd = desiredLength - actualLength
  const padding = '0'.repeat(amountToAdd)

  return padding + digits
}

const removeLeadingZeros = number => number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = number => {
  const centsStartingPosition = number.length - 2

  const cents = number.substring(centsStartingPosition)
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition))
  
  return `${dollars},${cents}`
}

const handleThousands = number => {
  const dollarsStartingPosition = number.length - 6

  const dollars = number.substring(dollarsStartingPosition)
  const thousands = removeLeadingZeros(number.substring(0, dollarsStartingPosition))

  return `${thousands}.${dollars}`
}

const handleMillions = number => {
  const thousandsStartingPosition = number.length - 10

  const thousands = number.substring(thousandsStartingPosition)
  const millions = removeLeadingZeros(number.substring(0, thousandsStartingPosition))

  return `${millions}.${thousands}`
}

export const normalizeCurrency = value => {
  if(value === 'R$ 0,0') {
    return '';
  }
  const digits = getDigitsFromValue(value)
  const digitsWithPadding = padDigits(digits)
  const realNumber = removeLeadingZeros(digitsWithPadding);

  if (realNumber.length <= 5) {
    return `R$ ${addDecimalToNumber(digitsWithPadding)}`
  } else {
    if (realNumber.length > 5 && realNumber.length <=8) {
      const numberWithDecimals = addDecimalToNumber(digitsWithPadding)
      return `R$ ${handleThousands(numberWithDecimals)}`
    } else {
      const numberWithDecimals = addDecimalToNumber(digitsWithPadding)
      const thousandsWithDecimals = handleThousands(numberWithDecimals)
      return `R$ ${handleMillions(thousandsWithDecimals)}`;
    }
  }
}

export const normalizeDate = value => {
  if (!value) {
    return value;
  }

  const formated = value.replace(/\D/g, '')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{2})(\d)/, '$1/$2')
  .replace(/(\d{4})\d+?$/, '$1');

  return formated;
}