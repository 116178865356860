import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { toast } from "react-toastify";
import SelectTransporterNew from "../components/SelectTransporterNew";
import ListTransporterNew from "../components/ListTransporterNew";

import axios from "axios";

export default function ConfirmationView({ open, setOpen, onReload }) {
  const [ctes, setCtes] = useState([]);
  const [transporterData, setTransporterData] = useState({});
  const [approve, setApprove] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open.transporter !== "") {
      async function loadTransporter() {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/bordero-cte/${open.transporter}`
          );
          console.log("loadTransporter -> response", response)
          if (response.status === 204) {
            toast.warn("Borderô não encontrado no FACTA");
            setOpen({ active: false, transporter: "" });
            setCtes([]);
            setTransporterData({});
            setSelected([]);
          } else {
            setCtes(response.data.ctes);
            setTransporterData({
              ufCedente: response.data.borderoData.uf_cedente,
              cedente: response.data.borderoData.cedente,
              cnpj: response.data.borderoData.cnpj,
            });
            setSelected([]);
          }
        } catch (err) {
          setCtes([]);
          setOpen({ active: false, transporter: "" });
          toast.error("Nenhuma CTe encontrada.");
        }
        setLoading(false);
      }
      loadTransporter();
    }
  }, [open.transporter]); // eslint-disable-line

  function handleSelectApprove(item) {
    const alreadyHave = selected.find((i) => i.index === item.index);
    if (!alreadyHave) {
      setSelected([...selected, item]);
    } else {
      const reproved = selected.filter((i) => i.index !== item.index);
      setSelected(reproved);
    }
  }

  async function handleSubmit() {
    try {
      const { data } = await axios.get("auth");
      await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/request-list-cte/list`,
        {
          bordero: open.transporter,
          ctes: selected,
          borderoData: transporterData,
          user: data.id,
        }
      );
      setOpen({ active: false, transporter: "" });
      toast.info(
        "CTe's foram enviadas para análise. Notificaremos quando todas forem analisadas"
      );
      setCtes([]);
      onReload();
      setSelected([]);
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar realizar análise");
    }
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "800px",
      height: "90%",
      overflow: "auto",
    },
    box: {
      flex: "1",
      border: "1px solid #eee",
      background: "#f9f9f9",
      padding: "10px",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.active}
      onClose={() => {
        setOpen({ active: false, transporter: "" });
        setApprove(false);
        setSelected([]);
        setCtes([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open.active}>
        <div className={classes.fade}>
          <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Analisar CTe - Borderô CTe</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen({ active: false, transporter: "" });
                    setCtes([]);
                    setApprove(false);
                    setSelected([]);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <h3
              className="mt-4"
              style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
              }}
            >
              Borderô CTe: {open.transporter}
            </h3>
            <div>
              <h4 className="mt-4">CTe's</h4>
              <div className="mt-4">
                {approve ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <button
                        type="button"
                        disabled={!selected.length > 0}
                        className="btn btn-primary btn-sm btn-upper mr-2"
                        onClick={handleSubmit}
                      >
                        Enviar para análise
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm btn-upper"
                        onClick={() => {
                          setApprove(false);
                          setSelected([]);
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                    {selected.length === ctes.length ? (
                      <button
                        type="button"
                        className="btn btn-light btn-sm btn-upper"
                        onClick={() => {
                          setSelected([]);
                        }}
                      >
                        <span
                          className={"fa fa-check mr-4"}
                          style={{
                            width: "18px",
                            height: "18px",
                            border: "0",
                            background: "#dee2ea",
                            display: "inline-block",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        ></span>
                        Esvaziar Seleção
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-light btn-sm btn-upper"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          const list = [];
                          ctes.forEach((item, index) => {
                            list.push({ ...item, index });
                          });
                          setSelected(list);
                        }}
                      >
                        <span
                          className={"mr-4"}
                          style={{
                            width: "18px",
                            height: "18px",
                            border: "0",
                            background: "#dee2ea",
                            display: "inline-block",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        ></span>
                        Selecionar Tudo
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm btn-upper"
                    onClick={() => setApprove(true)}
                  >
                    Analisar
                  </button>
                )}
              </div>
              <hr />
              <div className="row">
                {loading ? (
                  <>
                    <CircularProgress /> Buscando
                  </>
                ) : (
                  <>
                    {ctes.length > 0 ? (
                      ctes.map((item, index) => {
                        if (approve) {
                          const select = selected.find(
                            (i) => i.index === index
                          );
                          return (
                            <SelectTransporterNew
                              select={select}
                              key={index}
                              index={index}
                              item={item}
                              classes={classes}
                              handleSelectApprove={(e) =>
                                handleSelectApprove(e)
                              }
                            />
                          );
                        } else {
                          return (
                            <ListTransporterNew
                              classes={classes}
                              item={item}
                              key={index}
                            />
                          );
                        }
                      })
                    ) : (
                      <h6>Nenhuma CTe encontrada...</h6>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options"
                onClick={() => {
                  setOpen({ active: false, transporter: "" });
                  setCtes([]);
                  setApprove(false);
                  setSelected([]);
                }}
              >
                Fechar
              </button>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
