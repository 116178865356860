export default function verifyTitleMemberField(item) {
  switch (item) {
    case 'fullname': return 'Nome Completo';
    case 'date_of_birth': return 'Data de Nascimento';
    case 'cpf': return 'CPF';
    case 'e_cpf': return 'Possui e-CPF?';
    case 'identity_doc': return 'Identidade';
    case 'issuing_agency': return 'Agência Expedidora';
    case 'nationality': return 'Nacionalidade';
    case 'profession': return 'Profissão';
    case 'issuing_agency_uf': return 'Estado do RG';
    case 'marital_status': return 'Estado Civil';
    case 'wedding_registry': return 'Registro de Casamento';
    case 'address': return 'Endereço';
    case 'number': return 'Número';
    case 'complement': return 'Complemento';
    case 'neighborhood': return 'Bairro';
    case 'city': return 'Cidade';
    case 'uf': return 'Estado';
    case 'zipcode': return 'CEP';
    case 'email': return 'Email';
    case 'phone': return 'Telefone';
    case 'in_stable_union': return 'Em união estável';
    case 'in_politically_exposed_person': return 'Pessoa politicamente exposta';
    case 'mother_name': return 'Nome da Mãe';
    case 'memberSpouses': return 'Cônjuge';
    case 'identity': return 'Identidade';
    case 'identity_oe': return 'Agência Expedidora';
    case 'identity_uf': return 'Estado do RG';
    case 'type': return 'Tipo';
    default: return;
  }
}