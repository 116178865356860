import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Paper,
  FormControl,
  TextField,
  makeStyles
} from '@material-ui/core';
import { toast } from 'react-toastify';

export default function UserEdit({ history, match }) {
  const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({});
  useEffect(() => {
    async function loadUser() {
      try {
        const response = await axios.get(`/demonstratives/${match.params.id}`);
        setValues(response.data);
      } catch(err) {
        console.log(err);
      }
    }
    loadUser();
  }, [match.params.id]);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await axios.put(`/demonstratives/${match.params.id}`, values);
      toast.success('Demonstrativo atualizado com sucesso');
      history.push('/demonstratives');
    }catch(err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Demonstrativos
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/demonstratives`}>
                Demonstrativos
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/demonstratives/${match.params.id}`}>
                Editar Demonstrativo
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Título"
              value={values.title || ''}
              onChange={(e) => setValues({ ...values, title: e.target.value })}
              required
            />
          <FormControl>
          </FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Arquivo"
              value={values.path || ''}
              onChange={(e) => setValues({ ...values, path: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              >Atualizar</button>
            <Link
              to="/demonstratives"
              className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}

