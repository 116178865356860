import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Paper,
  makeStyles,
  LinearProgress,
  TextField,
  MenuItem,
} from "@material-ui/core";
import verifyProfile from "../utils/verifyProfiles";
import { toast } from "react-toastify";

export default function RegisterRefuseIndex() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(null);
  const [gerentes, setGerentes] = useState([]);
  const history = useHistory();

  useEffect(() => {
    async function loadGerente() {
      try {
        const { data } = await axios.get("facta/gerente");
        setGerentes(data);
      } catch (error) {}
    }
    loadGerente();
  }, []);

  async function handleGerenteChange(item, id) {
    let gerente_id = item.target.value;
    try {
      const response = await axios.put(
        `/registers/gerente/${id}`,
        {
          gerente_id,
        }
      );
      console.log('response', response)
      setRows(rows.map(r => {
        if (r.id === id) {
          return {
            ...r,
            gerente_id,
          }
        }
        return r;
      }));
    } catch (error) {}
  }

  useEffect(() => {
    async function loadUsers() {
      const { data } = await axios.get(`/roles/users/8`);
      setUsers(data);
      const { data: userData } = await axios.get(`auth`);
      setUser(userData);
      console.log(userData);
    }
    loadUsers();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("id");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  async function loadRegisters() {
    setLoading(true);
    try {
      const query = [];
      if(user) {
        if(user.gerente_id) {
          query.push(`gerente_id=${user.gerente_id}`);
        }
      }
      const response = await axios.get(`/register_refuses?${query.map(q => q).join('&')}`);

      setRows(response.data);
    } catch (err) {
      history.goBack();
    }
    setLoading(false);
  }

  useEffect(() => {
    if(user !== null) {
      loadRegisters();
    }
  }, [user]); // eslint-disable-line

  async function handleReopen(register) {
    setLoading(true);
    try{
      await axios.put(`/register/un_refuse/${register.id}`);
      toast.info(`Cadastro reaberto encontra-se agora na aba de ${register.end === 1 ? 'Cadastros completos' : 'Cadastros incompletos'}`);
      loadRegisters();
    }catch(err) {
      toast.error('Ocorreu um erro ao tentar reabrir o cadastro, tente novamente')
    }
    setLoading(false);
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const headRows = [
    {
      id: "razao_social",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "Empresa",
    },
    {
      id: "cnpj",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "CNPJ",
    },
    {
      id: "contato",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Contato",
    },
    {
      id: "gerent/agent",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Gerente/Agente",
    },
    {
      id: "reason",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Motivo",
    },
    {
      id: "actions",
      numeric: false,
      align: "right",
      disablePadding: false,
      label: "Ações",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  async function handleUpdateAgent(agent_id, company_id) {
    setLoading(true);
    try {
      await axios.put(`/registers/agent/${company_id}`, {
        user_id: agent_id,
      });
      setRows(rows.map(r => {
        if(r.id === company_id) {
          return {
            ...r,
            user_id: agent_id,
          }
        }else{
          return r;
        }
      }));
    }catch(err) {
      if(err.response) {
        toast.error(err.response.data.message);
      }else{
        toast.error('Erro desconhecido, contate a equipe técnica');
      }
    }
    setLoading(false);
  }

  function formatName(name) {
    if(name.length < 40) {
      return name;
    }
    return (
      <>
        {name.slice(0, Math.floor(name.length/2))}
        <br />
        {name.slice(Math.floor(name.length/2), name.length)}
      </>
    )
  }

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function normalizePhone(value) {
    if (!value) {
      return value;
    }

    var onlyNums = value.replace(/\D/g, "");

    if (onlyNums.length === 0) {
      onlyNums = ``;
    } else if (onlyNums.length === 1) {
      onlyNums = `(${onlyNums.slice(0, 1)}`;
    } else if (onlyNums.length === 2) {
      onlyNums = `(${onlyNums.slice(0, 2)}`;
    } else if (onlyNums.length < 7) {
      onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(2, 7)}`;
    } else if (onlyNums.length < 11) {
      onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
        2,
        6
      )}-${onlyNums.slice(6, 11)}`;
    } else {
      onlyNums = `(${onlyNums.slice(0, 2)}) ${onlyNums.slice(
        2,
        7
      )}-${onlyNums.slice(7, 11)}`;
    }

    return onlyNums;
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastros Indeferidos</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/register_refuses`}
              >
                Cadastros Indeferidos
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  let data = JSON.parse(row.data);
                  let razao_social = "";
                  if (
                    data &&
                    data.find((item) => item.name === "razao_social")
                  ) {
                    razao_social = data.find(
                      (item) => item.name === "razao_social"
                    ).value;
                  }
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="left">{formatName(razao_social)}</TableCell>
                      <TableCell align="left">{`${row.cnpj.slice(
                        0,
                        2
                      )}.${row.cnpj.slice(2, 5)}.${row.cnpj.slice(
                        5,
                        8
                      )}/${row.cnpj.slice(8, 12)}-${row.cnpj.slice(
                        12,
                        14
                      )}`}</TableCell>
                      <TableCell align="left">
                        {row.email}<br />
                        {normalizePhone(row.phone)}
                      </TableCell>
                      <TableCell align="left">
                        <div className="d-flex align-items-center">
                        <TextField
                          select
                          label="Gerente"
                          value={row.gerente_id}
                          onChange={(e) =>
                            handleGerenteChange(e, row.id)
                          }
                          variant="outlined"
                          size="small"
                          style={{
                            minWidth: "100px",
                          }}
                          disabled={
                            (user &&
                              !verifyProfile(user, "admin") &&
                                !verifyProfile(user, "analist")) ||
                            loading
                          }
                        >
                          {gerentes.map((option) => (
                            <MenuItem key={option.GERE_ID} value={option.GERE_ID}>
                              {option.NOME}
                            </MenuItem>
                          ))}
                        </TextField>
                        /
                        <TextField
                            select
                            label="Agente"
                            value={row.user_id}
                            onChange={(e) => handleUpdateAgent(Number(e.target.value), row.id)}
                            variant="outlined"
                            size="small"
                            style={{
                              minWidth: '100px',
                            }}
                            disabled={(user && (!verifyProfile(user, 'admin') && !verifyProfile(user, 'analist'))) || loading}
                          >
                            {users.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.fullname}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        {row.refuse_reason}
                      </TableCell>
                      <TableCell align="right">
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => handleReopen(row)}>
                          REABRIR
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
