function validarCPF(strCPF) {
  var soma;
  var resto;
  soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(0))) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== Number(digitos.charAt(1))) return false;

  return true;
}

function emailIsValid(email) {
  if (email.indexOf("@") === -1) {
    return false;
  }
  if (email.indexOf(".") === -1) {
    return false;
  }
  return true;
}

function dateIsValid(value) {
  if (value.length === 10) {
    var date = value.split("/");

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    var _date = `${date[2]}${date[1]}${date[0]}`;
    today = `${yyyy}${mm}${dd}`;
    if (today < _date) {
      return false;
    }

    if (Number(date[0]) > 31) {
      return false;
    }

    if (Number(date[0] > 29 && Number(date[1]) === 2)) {
      return false;
    }

    if (Number(date[1]) > 12) {
      return false;
    }

    if (
      !(
        (Number(date[2]) % 4 === 0 && Number(date[2]) % 100 !== 0) ||
        Number(date[2]) % 400 === 0
      ) &&
      Number(date[1]) === 2
    ) {
      return date[0] <= 28;
    }

    if (
      Number(date[1]) === 1 ||
      Number(date[1]) === 3 ||
      Number(date[1]) === 5 ||
      Number(date[1]) === 7 ||
      Number(date[1]) === 8 ||
      Number(date[1]) === 10 ||
      Number(date[1]) === 12
    ) {
      return date[0] <= 31;
    } else {
      return date[0] <= 30;
    }
  } else {
    return false;
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.fullname) {
    errors.fullname = "PREENCHA ESSE CAMPO.";
  }
  if (!values.type) {
    errors.type = "PREENCHA ESSE CAMPO.";
  }
  if (!values.cpf) {
    errors.cpf = "PREENCHA ESSE CAMPO.";
  } else {
    if (values.cpf.length === 14) {
      if (!validarCPF(values.cpf.replace(/\D/g, ""))) {
        errors.cpf = "PREENCHA UM CPF VÁLIDO.";
      }
    } else if (values.cpf.length > 14) {
      if (!validarCNPJ(values.cpf)) {
        errors.cpf = "PREENCHA UM CNPJ VÁLIDO.";
      }
    } else {
      errors.cpf = "PREENCHA ESSE CAMPO.";
    }
  }

  if (values.cpf && values.cpf.length <= 14) {
    if (!values.e_cpf) {
      errors.e_cpf = "PREENCHA ESSE CAMPO.";
    }
    if (!values.nationality) {
      errors.nationality = "PREENCHA ESSE CAMPO.";
    }
    if (!values.profession) {
      errors.profession = "PREENCHA ESSE CAMPO.";
    }
    if (!values.mother_name) {
      errors.mother_name = "PREENCHA ESSE CAMPO.";
    }
    if (!values.identity_doc) {
      errors.identity_doc = "PREENCHA ESSE CAMPO.";
    }
    if (!values.issuing_agency) {
      errors.issuing_agency = "PREENCHA ESSE CAMPO.";
    }

    if (!values.marital_status) {
      errors.marital_status = "PREENCHA ESSE CAMPO.";
    } else if (values.marital_status === "Casado(a)") {
      if (!values.wedding_registry) {
        errors.wedding_registry = "PREENCHA ESSE CAMPO.";
      }
      if(values.wedding_registry !== 'Separação Total de Bens') {
      if (!values.spouses_identity_uf) {
        errors.spouses_identity_uf = "PREENCHA ESSE CAMPO.";
      }
      if (!values.spouses_fullname) {
        errors.spouses_fullname = "PREENCHA ESSE CAMPO.";
      } else if (values.spouses_fullname === values.fullname) {
        errors.spouses_fullname = "PREENCHA UM CÔNJUGE VÁLIDO";
      }
      if (!values.spouses_date_of_birth) {
        errors.spouses_date_of_birth = "PREENCHA ESSE CAMPO.";
      } else if (values.spouses_date_of_birth.length < 10) {
        errors.spouses_date_of_birth = "PREENCHA ESSE CAMPO.";
      } else if (!dateIsValid(values.spouses_date_of_birth)) {
        errors.spouses_date_of_birth = "PREENCHA UMA DATA VÁLIDA.";
      }
      if (!values.spouses_email) {
        errors.spouses_email = "PREENCHA ESSE CAMPO.";
      } else if (!emailIsValid(values.spouses_email)) {
        errors.spouses_email = "PREENCHA UM EMAIL VÁLIDO.";
      }

      if (!values.spouses_cpf) {
        errors.spouses_cpf = "PREENCHA ESSE CAMPO.";
      } else {
        if (values.spouses_cpf.length === 14) {
          if (!validarCPF(values.spouses_cpf.replace(/\D/g, ""))) {
            errors.spouses_cpf = "PREENCHA UM CPF VÁLIDO.";
          }
        } else {
          errors.spouses_cpf = "PREENCHA ESSE CAMPO.";
        }
      }
      if (!values.spouses_e_cpf) {
        errors.spouses_e_cpf = "PREENCHA ESSE CAMPO.";
      }
      if (!values.spouses_identity) {
        errors.spouses_identity = "PREENCHA ESSE CAMPO.";
      }
      if (!values.spouses_identity_oe) {
        errors.spouses_identity_oe = "PREENCHA ESSE CAMPO.";
      }

      if (!values.spouses_profession) {
        errors.spouses_profession = "PREENCHA ESSE CAMPO.";
      }
    }
    } else {
      if (!values.in_stable_union) {
        errors.in_stable_union = "PREENCHA ESSE CAMPO.";
      }
    }

    if (!values.in_politically_exposed_person) {
      errors.in_politically_exposed_person = "PREENCHA ESSE CAMPO.";
    }
  }

  if (!values.email) {
    errors.email = "PREENCHA ESSE CAMPO.";
  } else if (!emailIsValid(values.email)) {
    errors.email = "PREENCHA UM EMAIL VÁLIDO.";
  }

  if (!values.phone) {
    errors.phone = "PREENCHA ESSE CAMPO.";
  } else if (values.phone.length < 14) {
    errors.phone = "PREENCHA ESSE CAMPO.";
  }

  if (!values.zipcode) {
    errors.zipcode = "PREENCHA ESSE CAMPO.";
  } else if (values.zipcode.length < 9) {
    errors.zipcode = "PREENCHA ESSE CAMPO.";
  }
  if (!values.address) {
    errors.address = "PREENCHA ESSE CAMPO.";
  }
  if (!values.number) {
    errors.number = "PREENCHA ESSE CAMPO.";
  }
  if (!values.neighborhood) {
    errors.neighborhood = "PREENCHA ESSE CAMPO.";
  }
  if (!values.city) {
    errors.city = "PREENCHA ESSE CAMPO.";
  }
  if (!values.uf) {
    errors.uf = "PREENCHA ESSE CAMPO.";
  }

  return errors;
};

export default validate;
