import React from "react";
import { Route, Switch } from "react-router-dom";
import ConfirmationIndexBordero from "./Bordero/ConfirmationIndex";
import ConfirmationIndexTransporter from "./Transporter/ConfirmationIndex";
import ConfirmationIndexNotification from "./Notification/NotificationIndex";
import NfeDetached from "./NfeDetached/NfeDetachedIndex";
import CteDetached from "./CteDetached/CteDetachedIndex";

export default function ProfilePage() {
  return (
    <Switch>
      <Route exact path="/confirmation/notification" component={ConfirmationIndexNotification} />
      <Route exact path="/confirmation/bordero" component={ConfirmationIndexBordero} />
      <Route exact path="/confirmation/transporter" component={ConfirmationIndexTransporter} />
      <Route path="/confirmation/nfe_detached" component={NfeDetached} />
      <Route path="/confirmation/cte_detached" component={CteDetached} />
    </Switch>
  );
}
