import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import { toast } from 'react-toastify';

export default function ModalNewConsult({ open, setOpen, onReload, onDetail }) {
  const [cte, setCte] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  async function consultcte() {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-cte`,{
        cte
      });
      setResult({
        ...response.data,
        open: true,
      });
      onReload();
    }catch(err) {
      if(err.response.data.error !== "") {
        toast.error('CTe informada não é válida')
      }else{
        toast.error('Ocorreu um erro ao consultar esta CTe')
      }
    }
    setLoading(false);
  }
  useEffect(() => {
    if(open.cte !== '') {
      setCte(open.cte);
    }
  }, [open.cte]);

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '600px'
    }
  }));
  const classes = useStyles();
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.active}
        onClose={() => { setOpen({ active: false, cte: '' }); setCte(''); setResult({}) }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open.active}>
          <div className={classes.fade}>
            {result.open ? (
              <>
              <h2 id="transition-modal-title">Resultado Consulta CTe</h2>
              CTe: {result.retConsSitCTe.protCTe.infProt.chCTe}
              <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <td>Status</td>
                  <td>Motivo</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{result.retConsSitCTe.cStat}</td>
                  <td>{result.retConsSitCTe.xMotivo}</td>
                </tr>
                </tbody>
              </table>
              <div className="mt-4 center">
                <button 
                    type="button" 
                    className="btn btn-primary kt-subheader__btn-options mr-4" 
                    onClick={() => { setOpen({ active: false, cte: '' }); onDetail(cte) ;setCte(''); setResult({}); }}
                    disabled={loading}  
                  >
                    Ver Detalhamento
                </button>
                <button 
                  type="button" 
                  className="btn btn-secondary kt-subheader__btn-options" 
                  onClick={() => { setOpen({ active: false, cte: '' }); setCte(''); setResult({}); }}
                  disabled={loading}  
                >
                    Fechar
                </button>
                </div>
                </>
              ) : (
              <>
              <h2 id="transition-modal-title">Nova Consulta CTe Avulsa</h2>
              <input 
                placeholder="Digite aqui a CTe" 
                className="form-control mt-4" 
                value={cte}
                disabled={loading}
                onChange={e => {
                  setCte(e.target.value.replace(/\D/g, ''));
                }} />
              <div className="mt-4 center">
                <button 
                  type="button"
                  className="btn btn-primary kt-subheader__btn-options mr-4"
                  onClick={consultcte}
                  disabled={loading || cte.length === 0}
                >
                  {loading ? (
                    <>
                      <CircularProgress color="secondary" size={14}/> Consultando
                    </>
                  ) : (
                    <>
                      Consultar
                    </>
                  )}
                </button>
                <button 
                  type="button" 
                  className="btn btn-light kt-subheader__btn-options" 
                  onClick={() => { setOpen({ active: false, cte: '' }); setCte(''); setResult({});}}
                  disabled={loading}  
                >
                    Cancelar
                </button>
                </div>
              </>
            )}
          </div>
        </Fade>
      </Modal>
  );
}
