import React, { useEffect, useState, memo } from "react";

import axios from "axios";
import { Modal, Backdrop, makeStyles, LinearProgress } from "@material-ui/core";
import { normalizeCnpj } from "../utils/normalize";
import { toast } from "react-toastify";
import { format } from "date-fns";

function ModalDetail({ open, setOpen, cnpj }) {
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);

  function resetModal() {
    setCompany({});
    setOpen(false);
  }

  useEffect(() => {
    if (open && cnpj) {
      async function loadCompany() {
        setLoading(true);
        try {
          const response = await axios.get(
            `https://bfcdigital-cnpj.grupobfc.com.br/companies/${cnpj}`
          );
          setCompany(response.data);
        }catch(err) {
          if(err.response.status === 404) {
            toast.error('Nenhuma empresa encontrada');
          }else {
            toast.error('Ocorreu um erro ao buscar empresa');
          }
          setOpen(false);
        }
        setLoading(false);
      }
      loadCompany();
    }
  }, [cnpj, open]); // eslint-disable-line

  const useStyles = makeStyles((theme) => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1100px",
      height: "80%",
      overflow: "auto",
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      className={classes.modalsm}
      open={open}
      onClose={() => resetModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fadesm}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>{company.razao_social}</h2>
          <button type="button" onClick={resetModal} className="btn btn-light">
            X
          </button>
        </div>
        {loading || Object.keys(company).length === 0 ? (
          <LinearProgress />
        ) : (
          <div>
            <p style={{ fontSize: '22px' }}>
              Início de Atividade:{" "}
              {company.data_inicio_ativ !== ""
                ? format(
                    new Date(
                      company.data_inicio_ativ.slice(0, 4),
                      Number(company.data_inicio_ativ.slice(4, 6)) - 1,
                      company.data_inicio_ativ.slice(6, 8)
                    ),
                    "dd/MM/yyyy"
                  )
                : "-"}
            </p>
            <p style={{ fontSize: '22px' }}>
              CNPJ: {normalizeCnpj(company.cnpj)}
            </p>
            {company.capital_social !== 0 && (
              <p style={{ fontSize: '22px' }}>
                Capital Social:{" "}
                {new Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(company.capital_social)}
              </p>
            )}
            {company.email && (
              <p style={{ fontSize: '16px' }}>
                Email: {company.email !== "" ? company.email.toLowerCase() : '-'}
              </p>
            )}
            {company.ddd_1 && (
              <p style={{ fontSize: '16px' }}>
                Telefone(s): ({company.ddd_1}) {company.telefone_1} {company.ddd_2 && (
              <>
                / ({company.ddd_2}) {company.telefone_2}
              </>
            )}
              </p>
            )}
            <p style={{ fontSize: '16px' }}>
              Situação: {company.situacao}
            </p>
            <p style={{ fontSize: '16px' }}>
              Endereço: {`${company.logradouro || '-'}, ${company.numero || '-'} ${company.complemento}, ${company.bairro || '-'}, ${company.municipio || '-'} - ${company.uf} | ${`${company.cep.slice(0,5)}-${company.cep.slice(5,8)}` || '-'}`}
            </p>
            {company.matriz_filial && (
              <p style={{ fontSize: '16px' }}>
                Matriz ou filial? {company.matriz_filial}
              </p>
            )}
            {company.cod_nat_juridica && (
              <p style={{ fontSize: '16px' }}>
                Natureza Jurídica: {company.cod_nat_juridica}
              </p>
            )}
            {company.porte && (
              <p style={{ fontSize: '16px' }}>
               Porte: {company.porte}
              </p>
            )}
            {company.opc_simples && (
              <p style={{ fontSize: '16px' }}>
               Opção pelo simples? {company.opc_simples}
              </p>
            )}
            {company.data_opc_simples !== "" && (
              <p style={{ fontSize: '16px' }}>
              Data de inclusão da opção pelo simples:{" "}
              {format(
                    new Date(
                      company.data_opc_simples.slice(0, 4),
                      Number(company.data_opc_simples.slice(4, 6)) - 1,
                      company.data_opc_simples.slice(6, 8)
                    ),
                    "dd/MM/yyyy"
                  )}
            </p>
            )}
            {company.data_exc_simples !== "" && (
              <p style={{ fontSize: '16px' }}>
              Data de exclusão da opção pelo simples:{" "}
              {format(
                    new Date(
                      company.data_exc_simples.slice(0, 4),
                      Number(company.data_exc_simples.slice(4, 6)) - 1,
                      company.data_exc_simples.slice(6, 8)
                    ),
                    "dd/MM/yyyy"
                  )}
            </p>
            )}
            {company.sit_especial && (
              <p style={{ fontSize: '16px' }}>
               Opção pelo simples? {company.sit_especial}
              </p>
            )}
            {company.data_sit_especial !== "" && (
              <p style={{ fontSize: '16px' }}>
                Data de inclusão da opção pelo simples:{" "}
                {format(
                      new Date(
                        company.data_sit_especial.slice(0, 4),
                        Number(company.data_sit_especial.slice(4, 6)) - 1,
                        company.data_sit_especial.slice(6, 8)
                      ),
                      "dd/MM/yyyy"
                    )}
            </p>  
            )}
            {company.opc_mei && (
              <p style={{ fontSize: '16px' }}>
               Opção pelo MEI? {company.opc_mei}
              </p>
            )}
            <hr />
            <div>
                <h3>CNAES</h3>
                <div className="row" style={{ padding: '10px' }}>
                  <div className="col-md-6" style={{ padding: '10px' }}>
                    {company.cnae_fiscal}
                  </div>
                  {company.cnaes_secundario > 0 && company.cnaes_secundario.map((item, index) => (
                    <div className="col-md-6" key={index} style={{ padding: '10px' }}>
                      {item.descricao}
                    </div>
                  ))}
                </div>
            </div>
            <hr />
            <div>
                <h3>Sócios</h3>
                {company.socios && company.socios.length === 0 && (
                  <h5>Nenhum sócio encontrado</h5>
                )}
                <div className="row" style={{ padding: '10px' }}>
                  {company.socios && company.socios.length > 0 && company.socios.map((item, index) => (
                    <div className="col-md-6" key={index} style={{ padding: '10px' }}>
                      <h4>{item.nome_socio}</h4>
                      <p>{item.tipo_socio}</p>
                      <strong>Qualificação: {item.cod_qualificacao}</strong>
                  <p>Data de Entrada: {item.data_entrada ? format(
                    new Date(
                      item.data_entrada.slice(0, 4),
                      Number(item.data_entrada.slice(4, 6)) - 1,
                      item.data_entrada.slice(6, 8)
                    ),
                    "dd/MM/yyyy"
                  ) : '-'}</p>
                    </div>
                  ))}
                </div>
            </div>
          </div>
        )}
        <button
          type="button"
          onClick={resetModal}
          className="btn btn-secondary"
        >
          Fechar
        </button>
      </div>
    </Modal>
  );
}

export default memo(ModalDetail);
