import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link } from "react-router-dom";
import { Visibility } from "@material-ui/icons";
import { Button, Paper, TextField, CircularProgress } from "@material-ui/core";
import { Table, Pagination } from "react-bootstrap";

import printProtest from './printProtest';

import { toast } from "react-toastify";

import {
  normalizeCpfCnpj,
  cnpjIsValid,
  cpfIsValid,
  normalizeCurrency,
} from "./utils";
import { format } from "date-fns";

function ProtestPage() {
  const [consultResult, setConsultResult] = useState({});
  const [loading, setLoading] = useState(false);
  const [doc, setDoc] = useState("");
  const [docError, setDocError] = useState("");

  const [page, setPage] = useState(1);
  const [perPage] = useState(25);

  const [lastConsulted, setLastConsulted] = useState([]);

  useEffect(() => {
    async function loadProtests() {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_GATEWAY_OVH}/protests/list/last?page=${page}&perPage=${perPage}`
        );
        // let arrData = [];
        // for (let i = 0; i < data.length; i += 1) {
          // const { data: companyDataResp } = await axios.get(
          //   `/registers?cnpj=${data[i].cnpj.replace(/[^\d]/g, "")}`
          // );
          // if (companyDataResp.length === 0) {
          //   try {
          //     const { data: baseCnpj } = await axios.get(
          //       `https://bfcdigital-cnpj.grupobfc.com.br/companies/${data[i].cnpj.replace(
          //         /[^\d]/g,
          //         ""
          //       )}`
          //     );
          //     arrData.push({
          //       ...data[i],
          //       company: baseCnpj.razao_social,
          //     });
          //   } catch (err) {
          //   }
          // }else{
          //   arrData.push({
          //     ...data[i],
          //     company: companyDataResp[0].name,
          //   })
          // }
          // arrData.push({
          //   ...data[i],
          //   company: 'companyDataResp[0].name',
          // })
        // }
        setLastConsulted(data);
      } catch (err) {
        if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores ao listar últimas consultas, contate a equipe técnica"
          );
        } else {
          toast.error(
            "Ocorreu um erro em nossa aplicação ao listar últimas consultas, contate a equipe técnica"
          );
        }
      }
      setLoading(false);
    }
    loadProtests();
  }, [page, perPage]);

  function verifyIfIsValid(value) {
    setDocError("");
    if (value.length === 14) {
      if (!cpfIsValid(value)) {
        setDocError("CPF inválido");
      }
    } else if (value.length === 18) {
      if (!cnpjIsValid(value)) {
        setDocError("CNPJ inválido");
      }
    }
  }

  async function handleUpdate() {
    setLoading(true);
    try {
      const { data: consult } = await axios.post(
        `${process.env.REACT_APP_GATEWAY_OVH}/protests`,
        {
          cnpj: doc.replace(/[^\d]/g, ""),
        }
      );
      let count = 0;
      JSON.parse(consult.json).cartorio &&
        JSON.parse(consult.json).cartorio.forEach((c) => {
          c.dadosCartorio &&
            c.dadosCartorio.forEach((dC) => {
              dC.titulos &&
                dC.titulos.forEach((t) => {
                  if (t.valorProtestado) {
                    count += Number(t.valorProtestado.replace(/[^\d]/g, ""));
                  }
                });
            });
        });
      setConsultResult({
        ...JSON.parse(consult.json),
        total: count,
        company: consult.company,
      });
    } catch (err) {}
    setLoading(false);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_GATEWAY_OVH}/protests/${doc.replace(
          /[^\d]/g,
          ""
        )}`
      );
      if (data.length === 0) {
        const { data: consult } = await axios.post(
          `${process.env.REACT_APP_GATEWAY_OVH}/protests`,
          {
            cnpj: doc.replace(/[^\d]/g, ""),
          }
        );
        let count = 0;
        JSON.parse(consult.json).cartorio &&
          JSON.parse(consult.json).json.cartorio.forEach((c) => {
            c.dadosCartorio &&
              c.dadosCartorio.forEach((dC) => {
                dC.titulos &&
                  dC.titulos.forEach((t) => {
                    if (t.valorProtestado) {
                      count += Number(t.valorProtestado.replace(/[^\d]/g, ""));
                    }
                  });
              });
          });
        setConsultResult({
          ...JSON.parse(consult.json),
          total: count,
          company: consult.company,
        });
      } else {
        let count = 0;
        data[0].json.cartorio &&
          data[0].json.cartorio.forEach((c) => {
            c.dadosCartorio &&
              c.dadosCartorio.forEach((dC) => {
                dC.titulos &&
                  dC.titulos.forEach((t) => {
                    if (t.valorProtestado) {
                      count += Number(t.valorProtestado.replace(/[^\d]/g, ""));
                    }
                  });
              });
          });
        setConsultResult({ ...data[0].json, company: data[0].company, total: count });
      }
    } catch (err) {}
    setLoading(false);
  }

  function consultView(consult) {
    setDoc(consult.cnpj);
    let count = 0;
    consult.json.cartorio &&
      consult.json.cartorio.forEach((c) => {
        c.dadosCartorio &&
          c.dadosCartorio.forEach((dC) => {
            dC.titulos &&
              dC.titulos.forEach((t) => {
                if (t.valorProtestado) {
                  count += Number(t.valorProtestado.replace(/[^\d]/g, ""));
                }
              });
          });
      });
    setConsultResult({ ...consult.json, company: consult.company, total: count });
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Relato de Protestos</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/relato/protest`}
              >
                Relato de Protestos
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              {Object.keys(consultResult).length > 0 && (
                <>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={handleUpdate}
                    disabled={loading}
                  >
                    Atualizar Consulta
                  </Button>
                  <Button
                    type="button"
                    className="ml-2"
                    color="secondary"
                    variant="contained"
                    onClick={() => printProtest({
                      ...consultResult,
                      doc,
                    })}
                    disabled={loading}
                  >
                    Imprimir Consulta
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Paper style={{ padding: "20px", marginBottom: "18px", width: "100%" }}>
        {Object.keys(consultResult).length > 0 && (
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={() => {
              setConsultResult({});
              setDoc("");
            }}
            disabled={loading}
          >
            Nova Consulta
          </Button>
        )}
        {loading && (
          <h3 className="text-center mt-4 mb-4">
            <CircularProgress color="primary" size="20px" className="mr-2" />
            ...Aguarde isso pode demorar um pouco...
          </h3>
        )}
        {Object.keys(consultResult).length === 0 && (
          <>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "25px",
            }}
            className="mb-4"
          >
            <TextField
              label="Digite o CNPJ ou CPF"
              value={doc}
              onChange={(e) => {
                verifyIfIsValid(normalizeCpfCnpj(e.target.value));
                setDoc(normalizeCpfCnpj(e.target.value));
              }}
              variant="outlined"
              error={docError !== ""}
              helperText={docError}
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={
                (doc.length > 14
                  ? doc.length < 18
                  : doc.length < 14) ||
                loading ||
                docError !== ""
              }
              className="mt-3"
            >
              {loading && (
                <CircularProgress
                  color="primary"
                  size="14px"
                  className="mr-2"
                />
              )}
              Consultar
            </Button>
          </form>
          <div className="d-block mt-4" style={{ width: '100%' }}>
            <small className="d-block text-center">ÚLTIMAS CONSULTAS</small>
            <hr />
            <Table responsive hover>
                <thead>
                  <tr>
                    {/* <th>Razão Social</th> */}
                    <th>Empresa</th>
                    <th>Data da Consulta</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {lastConsulted.map(i => (
                    <tr key={i._id}>
                      {/* <td>{i.company}</td> */}
                      <td>{i.company || "-"}<br />{normalizeCpfCnpj(i.cnpj)}</td>
                      <td>{format(new Date(i.createdAt), 'dd/MM/yyyy - HH:mm')}</td>
                      <td className="text-right">
                        <button type="button" style={{
                          padding: "0px",
                          margin: "0px",
                          background: "none",
                          border: "none",
                        }} disabled={loading} onClick={() => (consultView({ ...i, json: JSON.parse(i.json) }))}>
                          <Visibility />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
            </Table>
            <hr />
            <div className="d-flex justify-content-center">
              <Pagination>
                <Pagination.Prev as="button" onClick={() => setPage(page - 1)} disabled={page === 1 || loading}/>
                <Pagination.Item active>{page}</Pagination.Item>
                <Pagination.Next as="button" onClick={() => setPage(page + 1)} disabled={lastConsulted.length < perPage || loading}/>
              </Pagination>
              </div>
          </div>
          </>
        )}
        <div
          id="printable"
          style={{ padding: "25px", background: "#fff", width: "100%" }}
        >
          {Object.keys(consultResult).length > 0 && (
            <div>
              <div id="logo_invisible">
                <img src="/media/logos/logo-4-blue.png" alt="BFC" />
                <div>
                  <h4>BFC Capital Partners</h4>
                  <h6>Relato de Protesto</h6>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <h5>{consultResult.company}<br />CPF/CNPJ: {normalizeCpfCnpj(doc)}</h5>
                <h5>
                  Total Protestado: R$ {normalizeCurrency(consultResult.total)}
                </h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <h5>Títulos: {consultResult.qtdTitulos}</h5>
                <h5>Data da Consulta: {consultResult.dataConsulta}</h5>
              </div>
            </div>
          )}
          {Object.keys(consultResult).length > 0 &&
            consultResult.qtdTitulos > 0 &&
            consultResult.cartorio.length > 0 &&
            consultResult.cartorio.map((cartorio, cIndex) => {
              if (
                cartorio.dadosCartorio.find((c) =>
                  c.titulos.find(
                    (t) => typeof t.valorProtestado !== "undefined"
                  )
                )
              ) {
                return (
                  <div
                    key={cIndex}
                    style={{
                      width: "100%",
                      padding: "15px",
                      background: "#f9f9f9",
                      margin: "20px 0px",
                      border: "1px solid #c9c9c9",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      key={cIndex}
                      style={{ width: "100%" }}
                    >
                      <h5>UF:</h5>
                      <h5>{cartorio.uf}</h5>
                    </div>
                    <hr />
                    {cartorio.dadosCartorio.length > 0 &&
                      cartorio.dadosCartorio.map((dadosCartorio, dIndex) => {
                        if (
                          dadosCartorio.titulos.find(
                            (titulo) =>
                              typeof titulo.valorProtestado !== "undefined"
                          )
                        ) {
                          return (
                            <div key={dIndex} style={{ width: "100%" }}>
                              <Table responsive="lg" style={{ width: "100%" }}>
                                <thead style={{ background: "#f0f0f0" }}>
                                  <tr>
                                    <th>{dadosCartorio.nomeCartorio}</th>
                                    <th>{dadosCartorio.telefone}</th>
                                    <th>
                                      {dadosCartorio.qtdTitulos} Título(s)
                                    </th>
                                  </tr>
                                </thead>
                              </Table>
                              <Table
                                responsive="lg"
                                hover
                                style={{
                                  marginTop: "-15px",
                                  marginBottom: "30px",
                                  width: "100%",
                                }}
                              >
                                <thead className="bg-light">
                                  <tr>
                                    <th>Data Protesto</th>
                                    <th>Data Vencimento</th>
                                    <th>Valor Protestado</th>
                                    <th>Apresentante</th>
                                    <th>Protestante</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dadosCartorio.titulos.length > 0 &&
                                    dadosCartorio.titulos
                                      .filter(
                                        (titulo) =>
                                          typeof titulo.valorProtestado !==
                                          "undefined"
                                      )
                                      .map((titulo, tIndex) => (
                                        <tr key={tIndex}>
                                          <td>{titulo.dataProtesto}</td>
                                          <td>{titulo.dataVencimento}</td>
                                          <td>R$ {titulo.valorProtestado}</td>
                                          <td>{titulo.nomeApresentante}</td>
                                          <td>{titulo.nomeCedente}</td>
                                        </tr>
                                      ))}
                                </tbody>
                              </Table>
                            </div>
                          );
                        }
                        return "";
                      })}
                  </div>
                );
              }
              return "";
            })}
        </div>
      </Paper>
    </div>
  );
}

export default ProtestPage;
