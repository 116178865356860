export default function generateMenu() {
  var items = [
    {
      title: "Painel",
      root: true,
      alignment: "left",
      page: "dashboard",
      translate: "MENU.DASHBOARD"
    }
  ];

  const menuItems = [
    {
      id: "registers",
      item: {
        title: "Cadastros",
        root: true,
        alignment: "left",
        submenu: ["register", "register_incompletes"],
      }
    },
    {
      id: "confirmation",
      item: {
        title: "Confirmação",
        root: true,
        alignment: "left",
        submenu: ["nfe_detached", "cte_detached", "bordero", "transporter", "notification"],
      }
    },
    {
      id: "mkt",
      item: {
        title: "Marketing",
        root: true,
        alignment: "left",
        page: "mkt"
      }
    },
    {
      id: "users",
      item: {
        title: "Usuários",
        root: true,
        alignment: "left",
        page: "users"
      }
    },
    {
      id: "site",
      item: {
        title: "Site",
        root: true,
        alignment: "left",
        submenu: ["teams","posts","demonstratives","agents","newsletters","contacts", "works"]
      }
    },
  ];

  const subMenuItems = [
    {
      id: "nfe_detached",
      item: {
        title: "Consulta NFe Avulsa",
        root: true,
        alignment: "left",
        page: "confirmation/nfe_detached"
      }
    },
    {
      id: "cte_detached",
      item: {
        title: "Consulta CTe Avulsa",
        root: true,
        alignment: "left",
        page: "confirmation/cte_detached"
      }
    },
    {
      id: "bordero",
      item: {
        title: "Borderôs NFe",
        root: true,
        alignment: "left",
        page: "confirmation/bordero"
      }
    },
    {
      id: "transporter",
      item: {
        title: "Borderôs CTe",
        root: true,
        alignment: "left",
        page: "confirmation/transporter"
      }
    },
    {
      id: "notification",
      item: {
        title: "Notificação SERPRO",
        root: true,
        alignment: "left",
        page: "confirmation/notification"
      }
    },
    {
      id: "register",
      item: {
        title: "Cadastros completos",
        root: true,
        alignment: "left",
        page: "registers"
      }
    },
    {
      id: "register_incompletes",
      item: {
        title: "Cadastros incompletos",
        root: true,
        alignment: "left",
        page: "register_incompletes"
      }
    },
    {
      id: "contacts",
      item: {
        title: "Fale conosco",
        root: true,
        alignment: "left",
        page: "contacts"
      }
    },
    {
      id: "works",
      item: {
        title: "Trabalhe conosco",
        root: true,
        alignment: "left",
        page: "works"
      }
    },
    {
      id: "teams",
      item: {
        title: "Colaboradores",
        root: true,
        alignment: "left",
        page: "teams"
      }
    },
    {
      id: "posts",
      item: {
        title: "Comunicados",
        root: true,
        alignment: "left",
        page: "posts"
      }
    },
    {
      id: "demonstratives",
      item: {
        title: "Demonstrativos",
        root: true,
        alignment: "left",
        page: "demonstratives"
      }
    },
    {
      id: "agents",
      item: {
        title: "Seja um agente",
        root: true,
        alignment: "left",
        page: "agents"
      }
    },
    {
      id: "newsletters",
      item: {
        title: "Newsletter",
        root: true,
        alignment: "left",
        page: "newsletters"
      }
    }
  ];

  var menuPermissions = [
    { item: "registers", submenu: ["register", "register_incompletes"] },
    { item: "confirmation", submenu: ["bordero", "transporter", "notification"] },
    { item: "users" },
    { item: "site", submenu: ["teams","posts","demonstratives","agents","newsletters","contacts", "works"] },
  ];

  var i = 0;

  while (i < menuItems.length) {
    const _item = menuPermissions.find(e => e.item === menuItems[i].id); // eslint-disable-line
    if (_item !== undefined) {
      const item = menuItems.find(e => e.id === _item.item); // eslint-disable-line
      if (_item.submenu !== undefined) {
        var c = 0;
        var subItens = [];
        while (c < _item.submenu.length) {
          var filterSubMenu = subMenuItems.find(e => e.id === _item.submenu[c]); // eslint-disable-line
          subItens.push(filterSubMenu.item);
          c++;
        }
        items.push({ ...item.item, submenu: subItens });
      } else {
        items.push(item.item);
      }
    }
    i++;
  }

  return items;
}
