import React, { useState, useEffect, memo } from 'react';

import { Modal, Button } from 'react-bootstrap';

import { format } from 'date-fns';
import pt_BR from 'date-fns/locale/pt-BR';

import { normalizeCurrency } from './normalize';

function ModalMonths({ open, setOpen, nfes }) {
  const [months, setMonths] = useState([]);

  useEffect(() => {
    if(open) {
      const monthsArr = [];
      nfes.forEach(nfe => {
        if(nfe.date !== '0000-00-00') {
          const month = format(new Date(nfe.date), 'MMMM', {
            locale: pt_BR
          });
          const finded = monthsArr.findIndex(m => m.month === month);
          if(finded > -1) {
            monthsArr[finded].total += nfe.value;
          }else{
            monthsArr.push({
              month,
              total: nfe.value,
            });
          }
        }
      });
      setMonths(monthsArr);
    }
  }, [open, nfes]);

  return (
    <Modal show={open} onHide={() => setOpen(false)}>
      <Modal.Body>
        <h5 className="text-center">
          Posicionamento por mês
        </h5>
        <hr />
        <div>
          {months.map((m, index) => (
            <div key={m.month} className="d-flex justify-content-between" style={{
              padding: '10px',
              borderTop: index > 0 && '1px solid #c9c9c9'
            }}>
              <span>{m.month.toUpperCase()}</span>
              <strong>R$ {normalizeCurrency(m.total)}</strong>
            </div>
          ))}
        </div>
        <hr />
        <Button type="button" variant="primary" className="ml-auto mr-auto" onClick={() => {
          setOpen(false)
        }}>
          Fechar
        </Button>
      </Modal.Body>
    </Modal>
  );
}

export default memo(ModalMonths);