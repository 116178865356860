import React, { memo } from "react";
import { Row, Col } from "react-bootstrap";
import EditingCompanyData from "./EditingCompanyData";
import VisualizingCompanyData from "./VisualizingCompanyData";

function CompanyData(props) {
  return (
    <Row>
      {props.register.dados &&
        props.register.dados.map((e, ind) => {
          return (
            <>
              {typeof e.value === "string" && (
                <Col lg={6} key={e.name} style={{ padding: "5px" }}>
                  {props.editField.company ? (
                    <EditingCompanyData
                      register={props.register}
                      setRegister={props.setRegister}
                      companyError={props.companyError}
                      normalizeCompany={props.normalizeCompany}
                      e={e}
                      ind={ind}
                    />
                  ) : (
                    <VisualizingCompanyData
                      classes={props.classes}
                      register={props.register}
                      itemsReproved={props.itemsReproved}
                      selectReprove={props.selectReprove}
                      handleSelectReproved={props.handleSelectReproved}
                      e={e}
                      ind={ind}
                    />
                  )}
                </Col>
              )}
            </>
          );
        })}
    </Row>
  );
}

export default memo(CompanyData);
