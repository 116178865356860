import React, { memo } from "react";

import { Modal, Backdrop, makeStyles } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

function ModalConfirmIsentDocument({
  confirmExemptedDocument,
  setConfirmExemptedDocument,
  docsExempted,
  ExemptedDocument
}) {
  const useStyles = makeStyles(theme => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      overflow: "auto"
    }
  }));
  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={confirmExemptedDocument}
      onClose={() => setConfirmExemptedDocument(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div
        className={classes.fade}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "auto"
        }}
      >
        <Row>
          <div className="col-12">
            <div className="col-12 confirm">
              <i className="flaticon2-information"></i>
              <p>Tem certeza que deseja isentar os documentos abaixo?</p>
              <Row>
                {docsExempted &&
                  docsExempted.map(doc => (
                    <Col lg={4} key={doc.id} style={{ padding: "5px" }}>
                      <div className={classes.card}>
                        {doc.document_type.name}
                      </div>
                    </Col>
                  ))}
              </Row>
              <div className="mt-4">
                <button
                  onClick={() => ExemptedDocument()}
                  className="btn btn-outline-success mr-3"
                >
                  Sim
                </button>
                <button
                  onClick={() => setConfirmExemptedDocument(false)}
                  className="btn btn-outline-danger"
                >
                  Não
                </button>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </Modal>
  );
}

export default memo(ModalConfirmIsentDocument);
