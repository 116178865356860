import React from "react";
import { Route, Switch } from "react-router-dom";
import RegisterRefuseIndex from "./RegisterRefuseIndex";

export default function RegisterRefusePage() {
  return (
    <Switch>
      <Route exact path="/register_refuses" component={RegisterRefuseIndex} />
    </Switch>
  );
}
