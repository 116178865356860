import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Paper,
  FormControl,
  TextField,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";
import CheckBox from "./CheckBox";
import { Row, Col } from "react-bootstrap";

let permissionArrayAux;
let previous;
let finalPermissions;

export default function UserEdit({ history, match }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [permissions, setPermissions] = useState([]);
  useEffect(() => {
    async function loadRoles() {
      try {
        const response = await axios.get(`/roles/${match.params.id}`);
        setValues(response.data);
        setPermissions(response.data.permissions);
      } catch (err) {
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    loadRoles();
  }, [match.params.id]);

  function createAux(array) {
    const arrayAux = array.map((item) => {
      const PermissionsIds = permissions.map((item) => {
        return item.id;
      });
      const contain = PermissionsIds.includes(item.id);
      const objAux = { per: item, bol: contain };
      return objAux;
    });
    return arrayAux;
  }

  function standardize(array) {
    array = array.filter((item) => {
      return item.bol;
    });

    array = array.map((item, index) => {
      return item.per.id;
    });

    return array;
  }

  async function loadPermissions() {
    setLoading(true);
    const response = await axios.get(`/permissions`);
    setLoading(false);
    const rowsAux = createAux(response.data);
    setRows(rowsAux);
  }

  useEffect(() => {
    loadPermissions();
  }, [permissions]); // eslint-disable-line

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const array = [values];
      await axios.put(`/roles/${match.params.id}`, array);
      toast.success("Perfil atualizado com sucesso");
      history.push("/roles");
    } catch (err) {
      toast.error('Ocorreu um erro, tente novamente');
    }
  }

  function whatToRender(rows) {
    if (rows === "okay") {
      return previous;
    } else {
      return rows;
    }
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Perfis</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/roles`}>
                Perfis
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/roles/${match.params.id}`}
              >
                Editar Perfil
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        {loading && <LinearProgress color="primary" />}
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Name"
              value={values.name || ""}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Slug"
              value={values.slug || ""}
              onChange={(e) => setValues({ ...values, slug: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="description"
              type="text"
              value={values.description || ""}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
              required
            />
          </FormControl>
          <FormControl>
            Permissões:
            <Row>
              {whatToRender(rows).map((item, index) => {
                return (
                  <Col xs="4" sm="3" key={index}>
                    <CheckBox
                      name={item.per.name}
                      checked={item.bol}
                      index={index}
                      array={whatToRender(rows)}
                      change={(array) => {
                        permissionArrayAux = array;
                        previous = whatToRender(rows);
                        setRows("okay");
                        finalPermissions = standardize(permissionArrayAux);
                        setValues({ ...values, permissions: finalPermissions });
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </FormControl>
          <FormControl>
            <button
              type="submit"
              style={{ marginTop: 20 }}
              className="btn btn-primary mt-3"
            >
              Atualizar
            </button>
            <Link to="/roles" className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}
