import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtestPayerIndex from "./ProtestPayerIndex";

export default function CnpjSearchPage() {
  return (
    <Switch>
      <Route exact path="/relato/protest-payer" component={ProtestPayerIndex} />
    </Switch>
  );
}
