import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { toast } from "react-toastify";
import { format } from "date-fns";

import NotificationSelect from "../components/NotificationSelect";

import axios from "axios";

export default function NotificationView({ open, setOpen }) {

  const history = useHistory();
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);

  const [selected, setSelected] = useState([]);
  const [selectActive, setSelectActive] = useState(false);

  function resetModal() {
    setOpen({ active: false, nfe: "" });
    setSelected([]);
    setSelectActive(false);
  }

  async function loadDetail() {
    if (open.active) {
      setLoading(true);
      try {
        const { data: borderoDetail } = await axios.get(
          `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-nfe-serpro/notifications/bordero/${open.bordero}`
        );
        console.log("loadDetail -> borderoDetail", borderoDetail);
        setDetail(borderoDetail);
        setLoading(true);
      } catch (err) {
        toast.error("Erro ao buscar detalhamento do borderô.");
      }
      setLoading(false);
    }
  }

  useEffect(() => {
    if (open.bordero !== "") {
      loadDetail();
    } else {
      resetModal();
    }
  }, [open.nfe]); // eslint-disable-line

  function handleSelectApprove(e) {
    let already_have = selected.find((i) => i.nfe === e.nfe);
    if (already_have) {
      setSelected(selected.filter((i) => i.nfe !== e.nfe));
    } else {
      setSelected([...selected, e]);
    }
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const { data } = await axios.get("auth");
      const dataRequest = {
        bordero: String(detail.bordero),
        nfes: selected,
        user: data.id,
        force: true,
        borderoData: {
          bordero: detail.bordero,
          cedente: detail.cedente,
          ufCedente: detail.ufCedente,
          cnpj: detail.cnpj,
        },
      };
      await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/request-list/list`,
        dataRequest
      );

      toast.success("Nfe's enviada para análise.");
      setOpen({ active: false, nfe: "", dataCriacao: "" });
      history.push("/confirmation/bordero");
    } catch (err) {
      console.log("handleSubmit -> err", err)
      toast.error("Ocorreu um erro ao tentar realizar análise");
    }
    setLoading(false);
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "800px",
      height: "90%",
      overflow: "auto",
    },
    box: {
      flex: "1",
      border: "1px solid #eee",
      background: "#f9f9f9",
      padding: "10px",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.active}
      onClose={() => {
        resetModal();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open.active}>
        <div className={classes.fade}>
          {loading ? (
            <>
              <CircularProgress /> Buscando
            </>
          ) : (
            detail && (
              <>
                <div className="row">
                  <div className="col-md-11">
                    <h2 id="transition-modal-title">Detalhamento do Borderô</h2>
                  </div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-light btn-sm"
                      type="button"
                      onClick={() => {
                        resetModal();
                      }}
                    >
                      X
                    </button>
                  </div>
                </div>
                <h3
                  className="mt-4"
                  style={{
                    border: "1px solid rgb(100, 108, 154)",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  Borderô: {detail.bordero}
                </h3>
                {detail.bordero && (
                  <table className="table table-bordered">
                    <thead>
                      <tr className="bg-secondary text-dark">
                        <td>Cedente</td>
                        <td>UF</td>
                        <td>Data de Processamento</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{detail.cedente}</td>
                        <td>{detail.ufCedente}</td>
                        <td>
                          {format(
                            new Date(detail.createdAt),
                            "dd/MM/yyyy 'às' HH:mm"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                )}
                <div>
                  <h4 className="mt-4">NFe's</h4>
                  <div className="mt-4"></div>
                  <hr />
                  <div className="row mb-4">
                    <div className="col-md-3">
                      {!selectActive && (
                        <button
                          type="button"
                          onClick={() => setSelectActive(true)}
                          className="btn btn-primary btn-upper btn-sm"
                        >
                          Analisar Novamente
                        </button>
                      )}
                    </div>
                  </div>
                  {detail.nfes && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {selectActive && (
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary btn-upper btn-sm mr-2"
                            disabled={selected.length === 0}
                            onClick={handleSubmit}
                          >
                            Enviar para Análise
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger btn-upper btn-sm"
                            onClick={() => {
                              setSelectActive(false);
                              setSelected([]);
                            }}
                          >
                            Cancelar
                          </button>
                        </div>
                      )}                      
                    </div>
                  )}
                  <div className="row mt-4">
                    {loading ? (
                      <>
                        <CircularProgress /> Buscando
                      </>
                    ) : (
                      <>

                        {selectActive && (
                          <div>
                            {detail.nfes.length > 0 &&
                            selected.length === detail.nfes.length ? (
                              <button
                                type="button"
                                className="btn btn-sm btn-upper mb-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setSelected([]);
                                }}
                              >
                                Esvaziar Seleção
                                <span
                                  className={"fa fa-check mr-4"}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    border: "0",
                                    background: "#dee2ea",
                                    color: "#222",
                                    display: "inline-block",
                                    borderRadius: "5px",
                                    marginLeft: "10px",
                                  }}
                                ></span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-sm btn-upper mb-2"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                onClick={() => {
                                  setSelected(detail.nfes);
                                }}
                              >
                                Selecionar Todos
                                <span
                                  className={"mr-4"}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    border: "0",
                                    background: "#dee2ea",
                                    display: "inline-block",
                                    borderRadius: "5px",
                                    marginLeft: "10px",
                                  }}
                                ></span>
                              </button>
                            )}
                          </div>
                        )}
                        {detail.nfes.length > 0 ? (
                          detail.nfes.map((item, index) => {
                            const select = selected.find(
                              (i) => i.nfe === item.nfe
                            );
                            return (
                              <NotificationSelect
                                key={index}
                                index={index}
                                item={item}
                                handleSelectApprove={(e) =>
                                  handleSelectApprove(e)
                                }
                                activeSelected={selectActive}
                                select={select}
                              />
                            );
                          })
                        ) : (
                          <h6>Nenhuma NFe encontrada...</h6>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </Fade>
    </Modal>
  );
}
