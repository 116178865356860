import React, { useState, useEffect } from "react";

import {
  makeStyles,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import { Dropdown } from "react-bootstrap";
import CompanyItem from "./components/CompanyItem";
import ModalByCnpj from "./components/ModalByCnpj";
import axios from "axios";
import { Autocomplete } from "@material-ui/lab";

import { toast } from "react-toastify";
import { normalizeCurrency, normalizeDate } from "./utils/normalize";
import { Link } from "react-router-dom";
import { isBefore, isEqual } from "date-fns";

function CnpjSearchView() {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({});
  const [page, setPage] = useState(1);
  const [link, setLink] = useState("");
  const [orderName, setOrderName] = useState("razao_social");
  const [orderDirection, setOrderDirection] = useState("ASC");
  const [optionsCnae, setOptionsCnae] = useState([]);
  const [optionsNatJuridica, setOpetionsNatJuridica] = useState([]);
  const [byCnpj, setByCnpj] = useState(false);

  const [cnaes, setCnaes] = useState([]);

  const [actives, setActives] = useState({
    cod_nat_juridica: false,
    unidade: false,
    uf: false,
    porte: false,
    opc_simples: false,
    opc_mei: false,
    cnae: false,
    capital_social: false,
    data_inicial: false,
  });

  const [perPage] = useState("100");
  const [filters, setFilters] = useState({
    situation: "",
    cod_nat_juridica: "",
    uf: "",
    porte: "",
    opc_simples: "",
    opc_mei: "",
    unidade: "",
    cnae: "",
    capital_social_1: "",
    capital_social_2: "",
    data_inicial_1: "",
    data_inicial_2: "",
    phones: "",
    email: "",
  });
  const [error, setError] = useState({
    capital_social: false,
    data_inicial: false,
  });

  useEffect(() => {
    async function loadOptions() {
      const cnae_response = await axios.get(
        "https://bfcdigital-cnpj.grupobfc.com.br/cnaes"
      );
      setOptionsCnae(cnae_response.data);
      const nat_juridica = await axios.get(
        "https://bfcdigital-cnpj.grupobfc.com.br/natureza_juridica"
      );
      setOpetionsNatJuridica(nat_juridica.data);
    }
    loadOptions();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 40,
    },
  }));
  const classes = useStyles();

  async function onSubmitCnpj(currentPage) {
    setError({ capital_social: false, data_inicial: false });
    let lockError = {
      capital_social: false,
      data_inicial: false,
    };
    if(filters.capital_social_1 !== "" && filters.capital_social_2 !== "") {
      if(Number(filters.capital_social_1.replace(/[^\d]/g, "")) > Number(filters.capital_social_2.replace(/[^\d]/g, ""))) {
        lockError.capital_social = true;
      }
    }
    if (filters.data_inicial_1 !== "" && filters.data_inicial_2 !== "") {
      const data_1_arr = filters.data_inicial_1.split('/');
      const data_2_arr = filters.data_inicial_2.split('/');

      if (
        filters.data_inicial_1.length < 10 ||
        filters.data_inicial_2.length < 10
      ) {
        lockError.data_inicial = true;
      } else if (isBefore(new Date(`${data_2_arr[2]}-${data_2_arr[1]}-${data_2_arr[0]}`), new Date(`${data_1_arr[2]}-${data_1_arr[1]}-${data_1_arr[0]}`))) {
        lockError.data_inicial = true;
      } else if (isEqual(new Date(`${data_2_arr[2]}-${data_2_arr[1]}-${data_2_arr[0]}`), new Date(`${data_1_arr[2]}-${data_1_arr[1]}-${data_1_arr[0]}`))) {
        lockError.data_inicial = true;
      }
    }
    if (lockError.data_inicial || lockError.capital_social) {
      return setError(lockError);
    }
    setLoading(true);
    try {
      setResults([]);
      let cnaeFilter = '';
      if(actives.cnae && cnaes.length > 0) {
        cnaeFilter = cnaes.map(i => {
          return i.id;
        }).join(';');
      }
      let data_1;
      let data_2;
      if(filters.data_inicial_1 !== "" || filters.data_inicial_2 !== "") {
        data_1 = filters.data_inicial_1 !== "" ? filters.data_inicial_1.split('/') : '';
        data_2 = filters.data_inicial_2 !== "" ? filters.data_inicial_2.split('/') : '';
      }
      const query = `${
        filters.situation !== "" ? `&situacao=${filters.situation}` : ""
      }${
        filters.cod_nat_juridica !== ""
          ? `&cod_nat_juridica=${filters.cod_nat_juridica}`
          : ""
      }${filters.uf !== "" ? `&uf=${filters.uf}` : ""}${
        filters.porte !== "" ? `&porte=${filters.porte}` : ""
      }${
        filters.opc_simples !== "" ? `&opc_simples=${filters.opc_simples}` : ""
      }${filters.opc_mei !== "" ? `&opc_mei=${filters.opc_mei}` : ""}${
        filters.unidade !== "" ? `&matriz_filial=${filters.unidade}` : ""
      }${filters.cnae !== "" ? `&cnae_fiscal=${filters.cnae}` : ""}${
        filters.capital_social_1 !== "" || filters.capital_social_2 !== ""
          ? `&capital_social=${filters.capital_social_1 !== "" ? (filters.capital_social_1
              .slice(0, filters.capital_social_1.length - 2)
              .replace(/[^\d]/g, "")) : ""}|${filters.capital_social_2 !== "" ? (filters.capital_social_2
              .slice(0, filters.capital_social_1.length - 2)
              .replace(/[^\d]/g, "")) : ""}`
          : ""
      }${
        filters.data_inicial_1 !== "" || filters.data_inicial_2 !== ""
          ? `&data_inicio_ativ=${data_1 !== '' ? data_1[2] + data_1[1] + data_1[0] : ''}|${data_2 !== '' ? data_2[2] + data_2[1] + data_2[0] : ''}`
          : ""
      }${
        filters.phones !== "" ? `&hasTelephone=${filters.phones}` : ''
      }${
        filters.email !== "" ? `&hasEmail=${filters.email}` : ''
      }${cnaeFilter !== '' ? `&cnaes=${cnaeFilter}` : ''}`;
      const response = await axios.get(
        `https://bfcdigital-cnpj.grupobfc.com.br/companies?orderName=${orderName}&orderDirection=${orderDirection}&page=${currentPage}&perPage=${perPage}${query}`
      );

      setPage(currentPage);
      setLink(
        `https://bfcdigital-cnpj.grupobfc.com.br/export/companies?orderName=${orderName}&orderDirection=${orderDirection}${query}`
      );
      setResults(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.error("Nenhuma empresa encontrada");
      } else {
        toast.error("Ocorreu um erro ao listar");
        console.log(err);
      }
    }
    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Busca de Empresas</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/cnpj/search`}
              >
                Busca de Empresas
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setByCnpj(true)}
              >
                Buscar por CNPJ
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalByCnpj open={byCnpj} setOpen={(e) => setByCnpj(e)} />
      <Paper className={classes.paper}>
        <div className="align-items-center d-flex justify-content-between">
          <h5>Filtros de Empresa</h5>
          <Dropdown>
            <Dropdown.Toggle variant="secondary">
              Adicionar Filtro
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {!actives.capital_social && (
                <Dropdown.Item
                  as="button"
                  onClick={() =>
                    setActives({ ...actives, capital_social: true })
                  }
                >
                  Capital Social
                </Dropdown.Item>
              )}
              {!actives.cnae && (
                <Dropdown.Item
                  as="button"
                  onClick={() => setActives({ ...actives, cnae: true })}
                >
                  CNAE
                </Dropdown.Item>
              )}
              {!actives.cod_nat_juridica && (
                <Dropdown.Item
                  as="button"
                  onClick={() =>
                    setActives({ ...actives, cod_nat_juridica: true })
                  }
                >
                  Natureza Jurídica
                </Dropdown.Item>
              )}
              {!actives.data_inicial && (
                <Dropdown.Item
                  as="button"
                  onClick={() => setActives({ ...actives, data_inicial: true })}
                >
                  Data de Início
                </Dropdown.Item>
              )}
              {!actives.opc_mei && (
                <Dropdown.Item
                  as="button"
                  onClick={() => setActives({ ...actives, opc_mei: true })}
                >
                  MEI
                </Dropdown.Item>
              )}
              {!actives.opc_simples && (
                <Dropdown.Item
                  as="button"
                  onClick={() => setActives({ ...actives, opc_simples: true })}
                >
                  Optante por simples
                </Dropdown.Item>
              )}
              {!actives.porte && (
                <Dropdown.Item
                  as="button"
                  onClick={() => setActives({ ...actives, porte: true })}
                >
                  Porte
                </Dropdown.Item>
              )}
              {!actives.uf && (
                <Dropdown.Item
                  as="button"
                  onClick={() => setActives({ ...actives, uf: true })}
                >
                  UF
                </Dropdown.Item>
              )}
              {!actives.unidade && (
                <Dropdown.Item
                  as="button"
                  onClick={() => setActives({ ...actives, unidade: true })}
                >
                  UNIDADE
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <hr />
        <div>
          <table className="table">
            <thead>
              <tr>
                <td>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={loading}
                  >
                    <InputLabel htmlFor="situation">Situação</InputLabel>
                    <Select
                      id="situation"
                      value={filters.situation || ""}
                      onChange={(e) =>
                        setFilters({ ...filters, situation: e.target.value })
                      }
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="01">NULA</MenuItem>
                      <MenuItem value="02">ATIVA</MenuItem>
                      <MenuItem value="03">SUSPENSA</MenuItem>
                      <MenuItem value="04">INAPTA</MenuItem>
                      <MenuItem value="08">BAIXADA</MenuItem>
                    </Select>
                  </FormControl>
                </td>
                {actives.cod_nat_juridica && (
                  <td>
                    <div className="d-flex">
                      <Autocomplete
                        variant="outlined"
                        id="combo-box-demo"
                        options={optionsNatJuridica}
                        getOptionLabel={(option) => option.descricao}
                        style={{ width: "100%" }}
                        onChange={ (event, values) => { 
                          setFilters({
                          ...filters,
                          cod_nat_juridica: values.id,
                        })
                        console.log(values);
                      } }
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            {...params}
                            label="Natureza Jurídica"
                            disabled={loading}
                            style={{ width: "100%" }}
                          />
                        )}
                      />
                      <button
                        type="button"
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                          setActives({ ...actives, cod_nat_juridica: false });
                          setFilters({
                            ...filters,
                            cod_nat_juridica: "",
                          });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </td>
                )}
                {actives.unidade && (
                  <td>
                    <div className="d-flex">
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        disabled={loading}
                      >
                        <InputLabel htmlFor="matriz_filial">UNIDADE</InputLabel>
                        <Select
                          id="matriz_filial"
                          value={filters.unidade || ""}
                          onChange={(e) =>
                            setFilters({ ...filters, unidade: e.target.value })
                          }
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="0">Matriz</MenuItem>
                          <MenuItem value="1">Filial</MenuItem>
                        </Select>
                      </FormControl>
                      <button
                        type="button"
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                          setActives({ ...actives, unidade: false });
                          setFilters({ ...filters, unidade: "" });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </td>
                )}
                <td>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={loading}
                  >
                    <InputLabel htmlFor="email">E-mail</InputLabel>
                    <Select
                      id="email"
                      value={filters.email || ""}
                      onChange={(e) =>
                        setFilters({ ...filters, email: e.target.value })
                      }
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="S">SIM</MenuItem>
                      <MenuItem value="N">NÃO</MenuItem>
                    </Select>
                  </FormControl>
                </td>
              </tr>
              <tr>
                <td>
                  <FormControl
                    variant="outlined"
                    style={{ width: "100%" }}
                    disabled={loading}
                  >
                    <InputLabel htmlFor="phone">Telefone</InputLabel>
                    <Select
                      id="phone"
                      value={filters.phones || ""}
                      onChange={(e) =>
                        setFilters({ ...filters, phones: e.target.value })
                      }
                    >
                      <MenuItem value=""></MenuItem>
                      <MenuItem value="S">SIM</MenuItem>
                      <MenuItem value="N">NÃO</MenuItem>
                    </Select>
                  </FormControl>
                </td>
                {actives.uf && (
                  <td>
                    <div className="d-flex">
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        disabled={loading}
                      >
                        <InputLabel htmlFor="uf">UF</InputLabel>
                        <Select
                          id="uf"
                          value={filters.uf || ""}
                          onChange={(e) =>
                            setFilters({ ...filters, uf: e.target.value })
                          }
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value={"AC"}>Acre</MenuItem>
                          <MenuItem value={"AL"}>Alagoas</MenuItem>
                          <MenuItem value={"AP"}>Amapá</MenuItem>
                          <MenuItem value={"AM"}>Amazonas</MenuItem>
                          <MenuItem value={"BA"}>Bahia</MenuItem>
                          <MenuItem value={"CE"}>Ceará</MenuItem>
                          <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                          <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                          <MenuItem value={"GO"}>Goiás</MenuItem>
                          <MenuItem value={"MA"}>Maranhão</MenuItem>
                          <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                          <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                          <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                          <MenuItem value={"PA"}>Pará</MenuItem>
                          <MenuItem value={"PB"}>Paraíba</MenuItem>
                          <MenuItem value={"PR"}>Paraná</MenuItem>
                          <MenuItem value={"PE"}>Pernambuco</MenuItem>
                          <MenuItem value={"PI"}>Piauí</MenuItem>
                          <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                          <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                          <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                          <MenuItem value={"RO"}>Rondônia</MenuItem>
                          <MenuItem value={"RR"}>Roraima</MenuItem>
                          <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                          <MenuItem value={"SP"}>São Paulo</MenuItem>
                          <MenuItem value={"SE"}>Sergipe</MenuItem>
                          <MenuItem value={"TO"}>Tocantis</MenuItem>
                        </Select>
                      </FormControl>
                      <button
                        type="button"
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                          setActives({ ...actives, uf: false });
                          setFilters({ ...filters, uf: "" });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </td>
                )}
                {actives.opc_mei && (
                  <td>
                    <div className="d-flex">
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        disabled={loading}
                      >
                        <InputLabel htmlFor="opc_mei">MEI</InputLabel>
                        <Select
                          id="opc_mei"
                          value={filters.opc_mei || ""}
                          onChange={(e) =>
                            setFilters({ ...filters, opc_mei: e.target.value })
                          }
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="SIM">SIM</MenuItem>
                          <MenuItem value="NÃO">NÃO</MenuItem>
                        </Select>
                      </FormControl>
                      <button
                        type="button"
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                          setActives({ ...actives, opc_mei: false });
                          setFilters({ ...filters, opc_mei: "" });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </td>
                )}
                {actives.porte && (
                  <td>
                    <div className="d-flex">
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        disabled={loading}
                      >
                        <InputLabel htmlFor="port">PORTE</InputLabel>
                        <Select
                          id="port"
                          value={filters.porte || ""}
                          onChange={(e) =>
                            setFilters({ ...filters, porte: e.target.value })
                          }
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="00">NÃO INFORMADO</MenuItem>
                          <MenuItem value="01">MICRO EMPRESA</MenuItem>
                          <MenuItem value="03">
                            EMPRESA DE PEQUENO PORTE
                          </MenuItem>
                          <MenuItem value="04">MÉDIA</MenuItem>
                          <MenuItem value="05">DEMAIS</MenuItem>
                        </Select>
                      </FormControl>
                      <button
                        type="button"
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                          setActives({ ...actives, porte: false });
                          setFilters({ ...filters, porte: "" });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </td>
                )}
                {actives.opc_simples && (
                  <td>
                    <div className="d-flex">
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%" }}
                        disabled={loading}
                      >
                        <InputLabel htmlFor="opc_simples">
                          OPTANTE POR SIMPLES
                        </InputLabel>
                        <Select
                          id="opc_simples"
                          value={filters.opc_simples || ""}
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              opc_simples: e.target.value,
                            })
                          }
                        >
                          <MenuItem value=""></MenuItem>
                          <MenuItem value="5">OPTANTE PELO SIMPLES</MenuItem>
                          <MenuItem value="0">NÃO OPTANTE</MenuItem>
                          <MenuItem value="6">EXCLUÍDO DO SIMPLES</MenuItem>
                        </Select>
                      </FormControl>
                      <button
                        type="button"
                        style={{ background: "none", border: "none" }}
                        onClick={() => {
                          setActives({ ...actives, opc_simples: false });
                          setFilters({
                            ...filters,
                            opc_simples: "",
                          });
                        }}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    </div>
                  </td>
                )}
              </tr>
            </thead>
          </table>
          {actives.cnae && (
            <div className="d-flex">
              <Autocomplete
                multiple
                variant="outlined"
                id="multiple-cnaes-input"
                options={optionsCnae}
                getOptionLabel={(option) => option.descricao}
                style={{ width: "100%" }}
                onChange={ (event, values) => setCnaes(values) }
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    {...params}
                    label="CNAE Fiscal"
                    disabled={loading}
                    value={filters.cnae}
                    onChange={(e) =>
                      setFilters({ ...filters, cnae: e.target.value })
                    }
                    style={{ width: "100%" }}
                  />
                )}
              />
              <button
                type="button"
                style={{ background: "none", border: "none" }}
                onClick={() => {
                  setActives({ ...actives, cnae: false });
                  setCnaes([]);
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "15px",
            }}
          >
            {actives.capital_social && (
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  border: "1px solid #eee",
                  margin: "0 5px",
                }}
              >
                <TextField
                  variant="outlined"
                  style={{ width: "100%" }}
                  label="Capital Social"
                  error={error.capital_social}
                  disabled={loading}
                  value={filters.capital_social_1}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      capital_social_1: normalizeCurrency(e.target.value),
                    })
                  }
                />
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  até
                </span>
                <TextField
                  variant="outlined"
                  style={{ width: "100%" }}
                  label="Capital Social"
                  error={error.capital_social}
                  disabled={loading}
                  value={filters.capital_social_2}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      capital_social_2: normalizeCurrency(e.target.value),
                    })
                  }
                />
                <button
                  type="button"
                  style={{ background: "none", border: "none" }}
                  onClick={() => {
                    setActives({ ...actives, capital_social: false });
                    setFilters({ ...filters, capital_social_1: "", capital_social_2: "" });
                  }
                  }
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            )}
            {actives.data_inicial && (
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  border: "1px solid #eee",
                  margin: "0 5px",
                }}
              >
                <TextField
                  variant="outlined"
                  style={{ width: "100%" }}
                  label="Data de Início de Atividade"
                  error={error.data_inicial}
                  disabled={loading}
                  value={filters.data_inicial_1}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      data_inicial_1: normalizeDate(e.target.value),
                    })
                  }
                />
                <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                  }}
                >
                  até
                </span>
                <TextField
                  variant="outlined"
                  style={{ width: "100%" }}
                  label="Data de Início de Atividade"
                  error={error.data_inicial}
                  disabled={loading}
                  value={filters.data_inicial_2}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      data_inicial_2: normalizeDate(e.target.value),
                    })
                  }
                />
                <button
                  type="button"
                  style={{ background: "none", border: "none" }}
                  onClick={() => {
                    setActives({ ...actives, data_inicial: false });
                    setFilters({ ...filters, data_inicial_1: "", data_inicial_2: "" });                    
                  }
                  }
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            )}
          </div>
        </div>
        <hr />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "20px",
          }}
          className="mt-5"
        >
          <div className="mr-5">
            <FormControl
              style={{ marginRight: "15px", width: "175px" }}
              disabled={loading}
            >
              <InputLabel htmlFor="orderName">ORDEM</InputLabel>
              <Select
                id="orderName"
                value={orderName}
                onChange={(e) => setOrderName(e.target.value)}
              >
                <MenuItem value="cnpj">CNPJ</MenuItem>
                <MenuItem value="razao_social">Razão Social</MenuItem>
                <MenuItem value="nome_fantasia">Nome Fantasia</MenuItem>
                <MenuItem value="uf">Estado</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ maxWidth: "120px" }} disabled={loading}>
              <InputLabel htmlFor="orderDirection">ORDEM</InputLabel>
              <Select
                id="orderDirection"
                value={orderDirection}
                onChange={(e) => setOrderDirection(e.target.value)}
              >
                <MenuItem value="ASC">CRESCENTE</MenuItem>
                <MenuItem value="DESC">DECRESCENTE</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl
            style={{ width: "100%", maxWidth: "140px", marginLeft: "10px" }}
            disabled={loading}
          >
            <InputLabel htmlFor="results_per_page">Result. p/Página</InputLabel>
            <Select
              id="results_per_page"
              value={perPage}
              onChange={(e) => setPerPage(e.target.value)}
            >
              <MenuItem value="5">5</MenuItem>
              <MenuItem value="10">10</MenuItem>
              <MenuItem value="25">25</MenuItem>
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
          </FormControl> */}
          </div>
          {results.data && results.data.length > 0 && (
            <a
              href={link}
              target="blank"
              className="btn btn-success mr-2"
              disabled={loading}
            >
              Exportar Resultado em CSV
            </a>
          )}
          <button
            type="button"
            onClick={() => onSubmitCnpj(1)}
            className="btn btn-primary btn-upper"
            disabled={loading}
          >
            Pesquisar
          </button>
        </div>
        {results.data && (
          <div className="align-items-center d-flex justify-content-between mt-5">
            <span>{results.total} Total</span>
            <ul className="pagination">
              <li className="page-item">
                <button
                  onClick={() => onSubmitCnpj(page - 1)}
                  className="page-link"
                >
                  <span aria-hidden="true">‹</span>
                  <span className="sr-only">Voltar</span>
                </button>
              </li>
              <li className="page-item">
                <button
                  onClick={() => onSubmitCnpj(page + 1)}
                  className="page-link"
                >
                  <span aria-hidden="true">›</span>
                  <span className="sr-only">Próxima</span>
                </button>
              </li>
            </ul>
          </div>
        )}
        {loading && <LinearProgress color="primary" />}
        <table className="table">
          <thead>
            <tr>
              <th scope="col">CNPJ</th>
              <th scope="col">Razão Social</th>
              <th scope="col">Nome Fantasia</th>
              <th scope="col">Cidade/Estado</th>
              <th scope="col">Telefone</th>
              <th scope="col">Email</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {results.data &&
              results.data.length > 0 &&
              results.data.map((i, index) => (
                <CompanyItem key={index} company={i} />
              ))}
          </tbody>
        </table>
        {results.data && (
          <div className="align-items-center d-flex justify-content-between mt-5">
            <span>{results.total} Total</span>
            <ul className="pagination">
              <li className="page-item">
                <button
                  onClick={() => onSubmitCnpj(page - 1)}
                  className="page-link"
                >
                  <span aria-hidden="true">‹</span>
                  <span className="sr-only">Voltar</span>
                </button>
              </li>
              <li className="page-item">
                <button
                  onClick={() => onSubmitCnpj(page + 1)}
                  className="page-link"
                >
                  <span aria-hidden="true">›</span>
                  <span className="sr-only">Próxima</span>
                </button>
              </li>
            </ul>
          </div>
        )}
      </Paper>
    </div>
  );
}

export default CnpjSearchView;
