import React, { useState, useEffect } from "react";

import { CircularProgress } from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import axios from "axios";

export default function SelectBorderosByCnpj({
  classes,
  item,
  handleSelectApprove,
  selected,
  setSelected,
  index,
}) {
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmado, setConfirmado] = useState(0);
  const [aConfirmar, setAConfirmar] = useState(0);
  const [rejeitado, setRejeitado] = useState(0);
  const [naoConfirmado, setNaoConfirmado] = useState(0);
  const [progresso, setProgresso] = useState(0);
  const [naoEncontrado, setNaoEncontrado] = useState(0);

  const find = selected.find(
    (i) => i.borderoData.bord_id === item.borderoData.bord_id
  );

  useEffect(() => {
    async function handleLoadNFes() {
      setLoading(true);
      if (item.borderoData.status === 1) {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/bordero-report/bordero/${item.borderoData.bord_id}`
        );
        const nfes = [];
        response.data.nfes.forEach((i) => {
          nfes.push({
            ...i,
            uf_sacado: i.ufSacado,
          });
        });
        item.nfes = nfes;
      }
        let percConfirmado = 0;
        let countConfirmado = 0;
        item.nfes.forEach((i) => {
          if (i.status === "confirmado") {
            countConfirmado += 1;
          }
        });
        percConfirmado = (countConfirmado / item.nfes.length) * 100;
        percConfirmado = Math.round(percConfirmado);

        setConfirmado(percConfirmado);
    
        let percAConfirmar = 0;
        let countAConfirmado = 0;
        item.nfes.forEach((i) => {
          if (i.status === "a confirmar") {
            countAConfirmado += 1;
          }
        });
        percAConfirmar = (countAConfirmado / item.nfes.length) * 100;
        percAConfirmar = Math.round(percAConfirmar);

        setAConfirmar(percAConfirmar);
    
        let percRejeitado = 0;
        let countRejeitado = 0;
        item.nfes.forEach((i) => {
          if (i.status === "rejeitado") {
            countRejeitado += 1;
          }
        });
        percRejeitado = (countRejeitado / item.nfes.length) * 100;
        percRejeitado = Math.round(percRejeitado);

        setRejeitado(percRejeitado);
    
        let percNaoConfirmado = 0;
        let countNaoConfirmado = 0;
        item.nfes.forEach((i) => {
          if (i.status === "nao confirmado") {
            countNaoConfirmado += 1;
          }
        });
        percNaoConfirmado = (countNaoConfirmado / item.nfes.length) * 100;
        percNaoConfirmado = Math.round(percNaoConfirmado);

        setNaoConfirmado(percNaoConfirmado);
    
        let percProgress = 0;
        let countProgress = 0;
        item.nfes.forEach((i) => {
          if (i.status === "") {
            countProgress += 1;
          }
        });
        percProgress = (countProgress / item.nfes.length) * 100;
        percProgress = Math.round(percProgress);

        setProgresso(percProgress);

        let percNotFound = 0;
        let countNotFound = 0;
        item.nfes.forEach((i) => {
          if (i.status === "nfe nao encontrada") {
            countNotFound += 1;
          }
        });
        percNotFound = (countNotFound / item.nfes.length) * 100;
        percNotFound = Math.round(percNotFound);

        setNaoEncontrado(percNotFound);
      setLoading(false);
    }
    handleLoadNFes();
  }, []); // eslint-disable-line

  return (
    <div key={item.borderoData.bord_id} style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 20px",
          border: "1px solid #eee",
        }}
      >
        <h5>{item.borderoData.bord_id}</h5>
        <h5>{item.nfes && item.nfes.length}</h5>
        {find ? (
          <button
            type="button"
            className="btn btn-light"
            onClick={() =>
              setSelected(
                selected.filter(
                  (i) => i.borderoData.bord_id !== item.borderoData.bord_id
                )
              )
            }
            style={{ display: "flex" }}
          >
            <span
              className={"fa fa-check"}
              style={{
                width: "20px",
                height: "20px",
                border: "0",
                background: "#dee2ea",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                marginRight: "30px",
              }}
            ></span>
            Remover Seleção
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-light"
            onClick={() =>
              setSelected([
                ...selected,
                { ...item, nfes: item.nfes.filter(n => {
                  if(!n.status) {
                    return true;
                  }else{
                    return n.status !== 'confirmado' && n.status !== "nfe nao encontrada" && n.origin === "CERTIFICADO" 
                  }
                }), bordero: item.borderoData.bord_id },
              ])
            }
            style={{ display: "flex" }}
          >
            Selecionar
            <span
              className={""}
              style={{
                width: "20px",
                height: "20px",
                border: "0",
                background: "#dee2ea",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                marginLeft: "30px",
              }}
            ></span>
          </button>
        )}
        {item.borderoData.status === 1 && (
          <span className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded">
            Já Analisado
          </span>
        )}
        {item.borderoData.status === 0 && (
          <span className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded">
            Não analisado
          </span>
        )}
        <div
        className="text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: '20%',
        }}
      >
        <div className="progress" style={{ width: "100%" }}>
          <div
            className={`progress-bar kt-bg-success`}
            style={{ width: `${confirmado}%` }}
          >
            </div>
            <div
              className={`progress-bar kt-bg-brand`}
              style={{ width: `${aConfirmar}%` }}
            >
            </div>
            <div
              className={`progress-bar kt-bg-danger`}
              style={{ width: `${rejeitado}%` }}
            >
            </div>
            <div
              className={`progress-bar kt-bg-dark`}
              style={{ width: `${naoConfirmado}%` }}
            >
            </div>
            <div
              className={`progress-bar`}
              style={{ width: `${naoEncontrado}%`, background: '#ff7d00' }}
            >
            </div>
            <div
              className={`progress-bar`}
              style={{ width: `${progresso}%`, background: 'none', color: '#444'}}
            >
            </div>
          </div>
          <span>{confirmado}% Confirmada(s)</span>
        </div>
        {
          item.borderoData.status === 1 ? (
            <button
              type="button"
              className="btn btn-light"
              onClick={() => setExpanded(!expanded)}
            >
              {
                loading ? <CircularProgress style={{ width: '20px', height: '20px' }} /> : (expanded ? <ExpandLess /> : <ExpandMore />)
              }
            </button>
          ) :
          (
            <div style={{ width: '47.5px', height: '39.06px' }}></div>
          )
        }
      </div>
      <div className="mb-4">
      {expanded &&
        item.nfes &&
        item.nfes.length > 0 &&
        item.nfes.map((nfe, index) => {
          // const bordero = selected.find((i) => i.bordero === item.borderoData.bord_id);
          // const select = bordero && bordero.nfes.find(i => i.nfe === nfe.nfe);
          return (
            <button
              type="button"
              onClick={() => {
                // handleSelectApprove({ ...item, nfes: [{ ...nfe }], bordero: item.borderoData.bord_id })
              }}
              key={index}
              style={{
                width: "100%",
                background: "#f9f9f9",
                border: "1px solid #ececec",
                padding: "20px",
                margin: "10px 0",
                color: "#646b9a",
                textAlign: "left",
              }}
            >
              <div className="row">
                <div className="col-md-8">
                  <h5>NFe: {nfe.nfe || "-"}</h5>
                </div>
                <div className="col-md-4">
                  {nfe.status === "confirmado" && (
                    <span
                      style={{ flex: "0.7" }}
                      className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded"
                    >
                      Confirmado
                    </span>
                  )}
                  {nfe.status === "a confirmar" && (
                    <span
                      style={{ flex: "0.7" }}
                      className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded"
                    >
                      A Confirmar
                    </span>
                  )}
                  {nfe.status === "error" && (
                    <span
                      style={{ flex: "0.7" }}
                      className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded"
                    >
                      Erro
                    </span>
                  )}
                  {nfe.status === "nao confirmado" && (
                    <span
                      style={{ flex: "0.7" }}
                      className="kt-badge kt-badge--dark kt-badge--inline kt-badge--pill kt-badge--rounded"
                    >
                      Não confirmado
                    </span>
                  )}
                  {nfe.status === "rejeitado" && (
                    <span
                      style={{ flex: "0.7" }}
                      className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
                    >
                      Recusado
                    </span>
                  )}
                  {nfe.status === "inválida" && (
                    <span
                      style={{ flex: "0.7" }}
                      className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
                    >
                      Inválida
                    </span>
                  )}
                  {nfe.status === "" && (
                    <span
                      style={{ flex: "0.7" }}
                      className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded"
                    >
                      Em Análise
                    </span>
                  )}
                </div>
              </div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p>Sacado: {nfe.sacado || "-"}</p>
                <p>UF: {nfe.uf_sacado || "-"}</p>
              </div>

              {/* <span
                className={select ? "fa fa-check" : ""}
                style={{
                  width: "20px",
                  height: "20px",
                  border: "0",
                  background: "#dee2ea",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",
                  marginRight: "30px",
                  marginBottom: "10px",
                }}
              ></span> */}
            </button>
          );
        })}
        </div>
    </div>
  );
}
