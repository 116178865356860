import React, { memo } from 'react';
import { format, parseISO } from "date-fns";

function History({ histories, percentage }) {
  function iconHistory(type) {
    switch (type) {
      case "created":
        return (
          <i className="flaticon2-writing" style={{ color: "#48c0e8" }}></i>
        );
      case "denied":
        return <i className="flaticon2-cross" style={{ color: "#db4040" }}></i>;
      case "approved":
        return (
          <i className="flaticon2-check-mark" style={{ color: "#7ad450" }}></i>
        );
      case "changed":
        return (
          <i className="flaticon-refresh" style={{ color: "#8550d4" }}></i>
        );
      case "finished":
        return (
          <i className="flaticon2-notepad" style={{ color: "#0bdb96" }}></i>
        );
      case "update":
        return (
          <i className="flaticon2-contract" style={{ color: "#327ad9" }}></i>
        );
      case "exempt":
        return (
          <i className="flaticon-folder-1" style={{ color: "#f0980c" }}></i>
        );
      default:
        break;
    }
  }
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">Histórico</h3>
        </div>
      </div>
      <div className="kt-portlet__body">
        <div
          className="kt-notification"
          style={{ height: "340px", overflow: "auto" }}
        >
          {percentage === 100 && (
            <div className="kt-notification__item" style={{ background: '#0bdb96' }}>
            <div className="kt-notification__item-icon">
              <i className="flaticon2-notepad" style={{ color: "#fff" }}></i>
            </div>
            <div className="kt-notification__item-details">
              <div className="kt-notification__item-title" style={{ color: "#fff", fontWeight: 'bold' }}>
                Cadastro Finalizado
              </div>
              <div className="kt-notification__item-time">
              </div>
            </div>
          </div>
          )}
          {histories &&
            histories.map(history => (
              <div className="kt-notification__item" key={history.id} style={{ opacity: percentage === 100 ? 0.6 : 1 }}>
                <div className="kt-notification__item-icon">
                  {iconHistory(history.type)}
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title">
                    {history.title}
                  </div>
                  <div className="kt-notification__item-time">
                    {format(parseISO(history.created_at), "dd'/'MM'/'yyyy 'às' HH':'mm")}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default memo(History);