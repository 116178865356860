import React, { memo } from "react";
import { Modal, Fade, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";

function ModalConfirmReproveMember(props) {
  return (
    <Modal
      className={props.classes.modal}
      open={props.openSocioReproved.active}
      onClose={() => {
        props.setOpenSocioReproved({
          ...props.openSocioReproved,
          active: false
        });
        props.setSelectReprove({
          ...props.selectReprove,
          members_fields: false
        });
      }}
      closeAfterTransition
      BackdropComponent={props.Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.openSocioReproved.active}>
        <div className={props.classes.fade}>
          <div className={props.classes.wrap}>
            <h2 id="transition-modal-title">Reprovar Campos</h2>
            <button
              className={props.classes.close}
              onClick={() => {
                props.setOpenSocioReproved({
                  ...props.openSocioReproved,
                  active: false
                });
                props.setSelectReprove({
                  ...props.selectReprove,
                  members_fields: false
                });
              }}
            >
              <Close />
            </button>
          </div>
          <TextField
            label="Título"
            name="title"
            onChange={e => {
              props.setReprovedMember({
                ...props.reprovedMember,
                title: e.target.value
              });
            }}
            style={{ width: "100%" }}
            value={props.reprovedMember.title || ""}
          />
          <TextField
            multiline={true}
            rows="4"
            label="Motivo"
            name="description"
            onChange={e => {
              props.setReprovedMember({
                ...props.reprovedMember,
                description: e.target.value
              });
            }}
            value={props.reprovedMember.description || ""}
            style={{ width: "100%" }}
          />
          <h4 style={{ marginTop: "10px" }}>Campos Reprovados</h4>
          <Row>
            {props.openSocioReproved.fields &&
              Object.keys(props.openSocioReproved.fields).map(item => {
                if (item !== "memberSpouses") {
                  return (
                    <Col lg={3} key={item} style={{ padding: "5px" }}>
                      <div className={props.classes.card}>
                        {props.openSocio.socio.cpf.length === 18
                          ? props.verifyTitleMemberField(item) === "CPF"
                            ? "CNPJ"
                            : props.verifyTitleMemberField(item)
                          : props.verifyTitleMemberField(item)}
                      </div>
                    </Col>
                  );
                } else {
                  return Object.keys(props.openSocioReproved.fields[item]).map(
                    spouse => (
                      <Col lg={3} key={spouse} style={{ padding: "5px" }}>
                        <div className={props.classes.card}>
                          Cônjuge - {props.verifyTitleMemberField(spouse)}
                        </div>
                      </Col>
                    )
                  );
                }
              })}
          </Row>
          <button
            className="btn btn-success"
            onClick={() => props.onSubmitReprovedMember()}
            type="button"
            disabled={props.loading}
          >
            {props.loading ? "Carregando..." : "Enviar Relatório"}
          </button>
        </div>
      </Fade>
    </Modal>
  );
}

export default memo(ModalConfirmReproveMember);
