import React, { memo } from "react";
import CancelButton from "../CancelButton";

function ApproveSelected(props) {
  return (
    <div>
      <button
        className="btn btn-success mr-3"
        onClick={() => props.setConfirmApprovedDocument(true)}
      >
        Aprovar documentos
      </button>

      <CancelButton
        setSelectApprove={props.setSelectApprove}
        setSelectExempt={props.setSelectExempt}
        setSelectReprove={props.setSelectReprove}
      />
    </div>
  );
}

export default memo(ApproveSelected);
