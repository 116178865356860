import React, { memo, useState } from "react";

import { Modal, Fade, Backdrop, TextField, Button } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";

function AgentView({ classes, open, setOpen, detail }) {
  const [replyActive, setReplyActive] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [expandReplies, setExpandReplies] = useState(false);
  function resetModal() {
    setOpen(!open);
    setReplyActive(false);
    setSubject("");
    setMessage("");
    setExpandReplies(false);
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.post("/reply_agent", {
        subject,
        message,
        agent_id: detail.id,
      });
      resetModal();
      toast.success("Contato respondido");
    } catch (err) {
      toast.error("Ocorreu um erro, tente novamente");
    }
    setLoading(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => setOpen(!open)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          <div className={classes.wrap}>
            <h2 id="transition-modal-title">Detalhes</h2>
            <button className={classes.close} onClick={() => setOpen(!open)}>
              <Close />
            </button>
          </div>
          {detail && (
            <div className="mt-3">
              <div
                className="d-flex justify-content-start align-items-center"
                style={{
                  padding: "10px",
                  background: "#f9f9f9",
                  borderRadius: "6px",
                  position: "relative",
                }}
              >
                <i
                  className="fas fa-user-tie"
                  style={{
                    fontSize: "56px",
                    padding: "10px",
                    background: "#eee",
                    borderRadius: "6px",
                  }}
                ></i>
                <div className="ml-4">
                  <h4>{detail.name}</h4>
                  <div className="d-flex align-items-center">
                    <p className="mb-1">
                      <i className="fas fa-id-card mr-1" />
                      {detail.cpf}
                    </p>
                    <p className="ml-4 mb-1">
                      <i className="fas fa-calendar-day mr-1" />
                      {detail.date_of_birth}
                    </p>
                    <p className="ml-4 mb-1">
                      <i className="fas fa-venus-mars mr-1" />
                      {detail.sex === "M" ? "Masculino" : "Feminino"}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <p className="mb-1">
                      <i className="fas fa-envelope mr-1" />
                      {detail.email}
                    </p>
                    <p className="ml-4 mb-1">
                      <i className="fas fa-phone mr-1" />
                      {detail.phone}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="mt-4"
                style={{
                  background: "#f9f9f9",
                  padding: "10px",
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gridGap: "10px",
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                    <i className="fas fa-ring mr-1"/>
                    Estado Civil:</strong> {detail.marital_status}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                    <i className="fas fa-baby-carriage mr-1"/>
                    Tem Filhos:</strong> {detail.have_children}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                  <i className="fas fa-city mr-1"/>
                    Cidade:</strong> {detail.city}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                  <i className="fas fa-money-bill-wave mr-1"/>
                    Renda Média Mensal:</strong> {detail.wage}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                  <i className="fas fa-graduation-cap mr-1"/>
                    Escolaridade:</strong> {detail.schooling}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                  <i className="fas fa-briefcase mr-1"/>
                    Profissão/Área de Atuação:</strong>{" "}
                  {detail.profession}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                  <i className="fas fa-certificate mr-1"/>
                    Certificado no mercado de inverstimentos:</strong>{" "}
                  {detail.certification}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                  <i className="fas fa-handshake mr-1"/>
                    Exerceu funções comerciais:</strong>{" "}
                  {detail.business_function}
                </p>
                <p
                  className="mb-0"
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <strong>
                  <i className="fas fa-coins mr-1"/>
                    Experiência profissional no mercado financeiro:
                  </strong>{" "}
                  {detail.financial_experience}
                </p>
              </div>
              <div
                className="d-flex justify-content-start align-items-center"
                  style={{
                    padding: "20px",
                    borderRadius: "6px",
                    background: "#eee",
                  }}
                >
                  <i className="fas fa-address-book mr-4" style={{ fontSize: '56px' }} />
                  <div>
                  <strong className="d-block">Carreira Profissional:</strong>{" "}
                  <p>{detail.professional_career}</p>
                  </div>
                </div>
            </div>
          )}
          <hr />
          {replyActive ? (
            <div>
              <TextField
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                label="Assunto"
                disabled={loading}
                style={{ width: "100%" }}
              />
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Mensagem"
                style={{ width: "100%" }}
                disabled={loading}
                multiline
                rows="4"
              />
              <div className="d-flex mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    message.length === 0 || subject.length === 0 || loading
                  }
                  onClick={() => handleSubmit()}
                >
                  Enviar
                </Button>
                <Button
                  className="ml-4"
                  variant="contained"
                  color="default"
                  disabled={loading}
                  onClick={() => {
                    setReplyActive(false);
                    setSubject("");
                    setMessage("");
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-secondary btn-upper btn-sm"
                onClick={() => {
                  setReplyActive(true);
                  setSubject("");
                  setMessage("");
                  setExpandReplies(false);
                }}
              >
                Responder
              </button>
              {detail && detail.replies && detail.replies.length > 0 && (
                <button
                  type="button"
                  className="btn btn-info btn-upper btn-sm"
                  onClick={() => {
                    setExpandReplies(!expandReplies);
                  }}
                >
                  {expandReplies ? (
                    <>Minimizar Respostas</>
                  ) : (
                    <>Ver Respostas ({detail.replies.length})</>
                  )}
                </button>
              )}
            </div>
          )}
          {expandReplies && (
            <div className="mt-4">
              {detail &&
                expandReplies &&
                detail.replies &&
                detail.replies.map((r) => (
                  <div
                    key={r.id}
                    className="bg-secondary d-flex justify-content-start align-items-center"
                    style={{
                      borderRadius: "6px",
                      padding: "10px",
                    }}
                  >
                    <div className="text-center mr-4" style={{ background: '#efeff5', borderRadius: '6px', padding: '10px', minWidth: '150px' }}>
                      <i className="fas fa-user" style={{ padding: '10px', borderRadius: '6px', fontSize: '34px' }}></i>
                      <h5>{r.user.fullname}</h5>
                      <p>{format(new Date(r.created_at), "dd/MM/yyyy")}</p>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <strong style={{ fontSize: '18px' }}>
                        {r.subject}
                      </strong>
                      <p className="mt-2">
                        {r.message}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

export default memo(AgentView);
