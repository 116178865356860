import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import { toast } from 'react-toastify';

export default function ModalNewConsult({ open, setOpen, onReload }) {
  const [nfe, setNfe] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  async function consultNfe() {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-nfe`,{
        nfe
      });
      if(response.data.cStat === '215') {
        toast.error('NFe não é válida')
      }else {
        toast.info('A nota está em Análise. Em breve notificaremos a resposta.');
      }
      /*
      setResult({
        ...response.data,
        open: true,
      });
      */
      onReload();
    }catch(err) {
      toast.error('Ocorreu um erro ao consultar esta NFe')
    }
    setLoading(false);
  }
  useEffect(() => {
    if(open.nfe !== '') {
      setNfe(open.nfe);
    }
  }, [open.nfe]);

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '600px'
    }
  }));
  const classes = useStyles();
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.active}
        onClose={() => { setOpen({ active: false, nfe: '' }); setNfe(''); setResult({}) }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open.active}>
          <div className={classes.fade}>
            {result.open ? (
              <>
              <h2 id="transition-modal-title">Resultado Consulta NFe</h2>
              NFe: {result.chNFe}
              <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <td>CNPJ</td>
                  <td>Status</td>
                  <td>Motivo</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{result.CNPJ}</td>
                  <td>{result.cStat}</td>
                  <td>{result.xMotivo}</td>
                </tr>
                </tbody>
              </table>
              <div className="mt-4 center">
                <button 
                  type="button" 
                  className="btn btn-secondary kt-subheader__btn-options" 
                  onClick={() => { setOpen({ active: false, nfe: '' }); setNfe(''); setResult({}); }}
                  disabled={loading}  
                >
                    Fechar
                </button>
                </div>
                </>
              ) : (
              <>
              <h2 id="transition-modal-title">Nova Consulta NFe Avulsa</h2>
              <input 
                placeholder="Digite aqui a NFe" 
                className="form-control mt-4" 
                value={nfe}
                disabled={loading}
                onChange={e => {
                  setNfe(e.target.value.replace(/\D/g, ''));
                }} />
              <div className="mt-4 center">
                <button 
                  type="button"
                  className="btn btn-primary kt-subheader__btn-options mr-4"
                  onClick={consultNfe}
                  disabled={loading || nfe.length === 0}
                >
                  {loading ? (
                    <>
                      <CircularProgress color="secondary" size={14}/> Consultando
                    </>
                  ) : (
                    <>
                      Consultar
                    </>
                  )}
                </button>
                <button 
                  type="button" 
                  className="btn btn-light kt-subheader__btn-options" 
                  onClick={() => { setOpen({ active: false, nfe: '' }); setNfe('');}}
                  disabled={loading}  
                >
                    Cancelar
                </button>
                </div>
              </>
            )}
          </div>
        </Fade>
      </Modal>
  );
}
