import React, { useState, useEffect } from "react";

import axios from "axios";

import { Link } from "react-router-dom";
import { Visibility } from "@material-ui/icons";
import { Button, Paper, TextField, CircularProgress } from "@material-ui/core";
import { Table, Pagination } from "react-bootstrap";

import { toast } from "react-toastify";

import { normalizeCpfCnpj, cnpjIsValid } from "./utlis";
import { format } from "date-fns";

function JuridicalProcessesSearchPage() {
  const [loading, setLoading] = useState(false);
  const [cnpj, setCnpj] = useState("");
  const [error, setError] = useState("");

  const [page, setPage] = useState(1);
  const [perPage] = useState(50);

  const [consults, setConsults] = useState([]);
  const [activeConsult, setActiveConsult] = useState({})
  const [juridicalProcesses, setJuridicalProcesses] = useState([]);

  useEffect(() => {
    async function loadConsults() {
      setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_GATEWAY_OVH}/consultJusbrasil/consults`,
          {
            params: {
              page,
              perPage,
            },
          }
        );

        setConsults(data);
      } catch (err) {
        console.log("loadConsults -> err", err);
        if (err.response && err.response.status === 500) {
          toast.error(
            "Ocorreu um erro em nossos servidores ao listar últimas consultas, contate a equipe técnica"
          );
        } else {
          toast.error(
            "Ocorreu um erro em nossa aplicação ao listar últimas consultas, contate a equipe técnica"
          );
        }
      } finally {
        setLoading(false);
      }
    }
    loadConsults();
  }, [page, perPage]);

  function verifyIfIsValid(value) {
    setError("");
    if (value.length === 18) {
      if (!cnpjIsValid(value)) {
        setError("CNPJ inválido");
      }
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    try {
      let companyName;

      const { data: companyDataResp } = await axios.get(
        `/registers?cnpj=${cnpj.replace(/[^\d]/g, "")}`
      );

      if (companyDataResp.length === 0) {
        const { data: baseCnpj } = await axios.get(
          `https://bfcdigital-cnpj.grupobfc.com.br/companies/${cnpj.replace(
            /[^\d]/g,
            ""
          )}`
        );

        companyName = baseCnpj.razao_social;
      } else {
        companyName = companyDataResp[0].name;
      }

      await axios.post(
        `${process.env.REACT_APP_GATEWAY_OVH}/consultJusbrasil/`,
        {
          cnpj: cnpj.replace(/[^\d]/g, ""),
          companyName,
        }
      );
      toast.info("Sua consulta poderá demorar um pouco, aguarde e retorne o resultado da consulta")

      const consultDate = new Date();

      const newConsult = { cnpj, companyName, consultDate };

      const filteredConsults = consults.filter(
        (consult) =>
          consult.cnpj.replace(/[^\d]/g, "") !== cnpj.replace(/[^\d]/g, "")
      );

      setConsults([newConsult, ...filteredConsults]);
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 500) {
        toast.error("Ocorreu um erro ao tentar consultar, tente novamente");
      } else {
        toast.error("Ocorreu um erro ao tentar consultar, tente novamente");
      }
    } finally {
      setCnpj("");
      setLoading(false);
    }
  }

  async function consultView(consult) {

    setLoading(true);
    try {
      const { cnpj } = consult

      const { data } = await axios.get(
        `${
          process.env.REACT_APP_GATEWAY_OVH
        }/consultJusbrasil/companies/${cnpj.replace(/[^\d]/g, "")}`
      );

      if (!data.length) {
        toast.info(
          "Empresa sem registros de processos júridicos, caso tenha feito a busca recentemente aguarde alguns minutos e tente novamente",
          { autoClose: false }
        );
      }

      setActiveConsult(consult)
      setJuridicalProcesses(data);
    } catch (err) {
      if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores ao listar últimas consultas, contate a equipe técnica"
        );
      } else {
        toast.error(
          "Ocorreu um erro em nossa aplicação ao listar últimas consultas, contate a equipe técnica"
        );
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Consulta de processos jurídicos
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/juridical-processes/search`}
              >
                Consulta de processos jurídicos
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper style={{ padding: "20px", marginBottom: "18px", width: "100%" }}>
        {!juridicalProcesses.length && (
          <>
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "25px",
              }}
              className="mb-4"
            >
              <TextField
                label="Digite o CNPJ"
                value={cnpj}
                onChange={(e) => {
                  verifyIfIsValid(normalizeCpfCnpj(e.target.value));
                  setCnpj(normalizeCpfCnpj(e.target.value));
                }}
                variant="outlined"
                error={error !== ""}
                helperText={error}
              />
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={
                  (cnpj.length > 14 ? cnpj.length < 18 : cnpj.length < 14) ||
                  loading ||
                  error !== ""
                }
                className="mt-3"
              >
                {loading && (
                  <CircularProgress
                    color="primary"
                    size="14px"
                    className="mr-2"
                  />
                )}
                Consultar
              </Button>
            </form>
            <div className="d-block mt-4" style={{ width: "100%" }}>
              <small className="d-block text-center">ÚLTIMAS CONSULTAS</small>
              <hr />
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Razão Social</th>
                    <th>CNPJ</th>
                    <th>Data da Consulta</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {consults.length > 0 && consults.map(consult => (
                    <tr key={consult._id}>
                      <td>{consult.companyName}</td>
                      <td>{normalizeCpfCnpj(consult.cnpj)}</td>
                      <td>{format(new Date(consult.consultDate), 'dd/MM/yyyy - HH:mm')}</td>
                      <td className="text-right">
                        <button type="button" style={{
                          padding: "0px",
                          margin: "0px",
                          background: "none",
                          border: "none",
                        }} disabled={loading} onClick={() => (consultView(consult))}>
                          <Visibility />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <hr />
              <div className="d-flex justify-content-center">
                <Pagination>
                  <Pagination.Prev
                    as="button"
                    onClick={() => setPage(page - 1)}
                    disabled={page === 1 || loading}
                  />
                  <Pagination.Item active>{page}</Pagination.Item>
                  <Pagination.Next
                    as="button"
                    onClick={() => setPage(page + 1)}
                    disabled={consults.length < perPage || loading}
                  />
                </Pagination>
              </div>
            </div>
          </>
        )}
        {juridicalProcesses.length > 0 && (
          <>
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={() => {
                setJuridicalProcesses([]);
                setActiveConsult({})
                setCnpj("");
              }}
              disabled={loading}
            >
              Voltar
          </Button>

          <div style={{margin: '30px 0px'}}>
              <div className="d-flex justify-content-between">
                <h5>Razão Social: {activeConsult.companyName}</h5>
                <h5>CNPJ: {normalizeCpfCnpj(String(activeConsult.cnpj))}</h5>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <h5>Processos: {juridicalProcesses.length}</h5>
                <h5>Data da Consulta: {format(new Date(activeConsult.consultDate), 'dd/MM/yyyy')}</h5>
              </div>
            </div>

            <div style={{ width: '100%' }}>
              <Table
                responsive="lg"
                className="table table-striped"
                style={{ width: "100%" }}
              >
                <thead className="bg-light" >
                  <tr>
                    <th>Título</th>
                    <th className="text-center">Descrição</th>
                    <th className="text-center">N.º do Processo</th>
                    <th className="text-center">Link de Detalheamento</th>
                  </tr>
                </thead>
                <tbody>
                  {juridicalProcesses.map(juridicalProcess => (
                    <tr key={juridicalProcess._id}>
                      <td>{juridicalProcess.title}</td>
                      <td>{juridicalProcess.description}</td>
                      <td>{juridicalProcess.number}</td>
                      <td><a target="_blank" rel="noopener noreferrer" href={juridicalProcess.detailLink}>{juridicalProcess.detailLink.slice(0, 45)}...</a></td>
                    </tr>
                  ))
                  }
                </tbody>
              </Table>
            </div>
          </>
        )}
      </Paper>
    </div>
  );
}

export default JuridicalProcessesSearchPage;
