import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => props.open ? 'block' : 'none'};
  position: fixed;
  right: 30px;
  bottom: 0;
  background: #fff;
  width: 100%;
  max-width: 600px;
  border-radius: 4px;
  box-shadow: 0px 0px 6px rgba(0,0,0,.3);
  z-index: 1000;
  div#header_send {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    h4 {
      margin: 0px;
    }
  }
  div#body_mail {
    section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      padding: 10px 15px;
      form { 
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
      }
      div {
        margin: 0px 6px;
        padding: 5px 10px;
        border-radius: 4px;
        background:#6993FF;
        color: #fff;
        display: flex;
        justify-content:center;
        align-items:center;
        font-size: 12px;
        button {
          margin-left: 6px;
          padding: 0px;
          font-size: 14px;
          svg {
            font-size: 14px;
          }
        }
      }
    }
    section + section {
      border-top: 1px solid #eee;
    }
    input, textarea {
      background: none;
      border:none;
      flex: 1;
    }
    textarea {
      resize: none !important;
    }
  }
  div#attachs {
    padding: 15px;
    div.attach_item + div.attach_item {
      margin-top: 6px;
    }
    div.attach_item {
      padding: 10px;
      background: #eee;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  div#footer_mail {
    border-top: 1px solid #eee;
    padding: 15px;
  }
`;
