import React from "react";

import { format } from "date-fns";

function NotificationSelect({
  select,
  index,
  item,
  handleSelectApprove,
  activeSelected,
}) {
  return (
    <button
      type="button"
      className="col-md-12"
      style={{
        padding: "10px",
        background: "none",
        border: "none",
      }}
      key={index}
      onClick={() => {
        if (activeSelected) {
          handleSelectApprove({ ...item, index });
        }
      }}
    >
      <div
        className="row"
        style={{
          flex: "1",
          border: "1px solid #eee",
          background: "#f9f9f9",
          padding: "10px",
        }}
      >
        <div
          className="col-md-12"
          style={{
            display: "grid",
            gridTemplateColumns: activeSelected ? "1fr 10fr 1fr" : "10fr 1fr",
            gridGap: "10px",
          }}
        >
          {activeSelected && (
            <span
              className={select ? "fa fa-check" : ""}
              style={{
                width: "20px",
                height: "20px",
                border: "0",
                background: "#dee2ea",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "5px",
                marginRight: "30px",
                marginBottom: "10px",
              }}
            ></span>
          )}
          <h4 style={{ color: "#5867dd", textAlign: "left" }}>
            NFe: {item.nfe}
          </h4>
        </div>
        <div className="col-md-12">
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>Sacado: {item.sacado || "-"}</p>
          </div>
          <h5 className="mt-2 text-left">Notificações</h5>
          <table className="table table-bordered mt-4 text-left">
            <thead>
              <tr className="bg-secondary text-dark">
                <td>Data da notificação</td>
              </tr>
            </thead>
            <tbody>
              {item.notifications.length > 0 &&
                item.notifications.map((n) => (
                  <tr key={n._id}>
                    <td>
                      {format(new Date(n.dataCriacao), "dd/MM/yyy 'às' HH:mm")}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <h5 className="mt-1 text-left">Eventos</h5>
          <table className="table table-bordered mt-4">
            <thead>
              <tr className="bg-secondary text-dark">
                <td>Evento</td>
                <td>Protocolo</td>
                <td>Data</td>
              </tr>
            </thead>
            <tbody>
              {item.events.map((event, index) => (
                <tr key={index}>
                  <td>
                    <small>{event.evento}</small>
                  </td>
                  <td>
                    <small>{event.protocolo}</small>
                  </td>
                  <td>
                    <small>{event.data_autorizacao}</small>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </button>
  );
}

export default NotificationSelect;
