import React, { memo, useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { makeStyles, Modal, Backdrop } from "@material-ui/core";
import validation from "../../utils/validatorCompany";

function ModalUpdateCompany({
  register,
  open,
  onClose,
  id,
  registerReload,
  onError
}) {
  const [oldRegister, setOldRegister] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [companyFields, setCompanyFields] = useState(0);
  const [newObjects, setNewObjects] = useState([])

  const verifyFields = () => {
    let count = 0;
    if (open) {
      onError({});
      oldRegister.dados &&
        register.dados &&
        oldRegister.dados.length > 0 &&
        register.dados.length > 0 &&
        oldRegister.dados.map((item, index) => {
          if (typeof item.value === "string") {
            if (
              register.dados[index].value !== oldRegister.dados[index].value
            ) {
              setCompanyFields(1 + companyFields);
              return count++;
            } else {
              return count;
            }
          } else {
              return item.value.map((subElement, subElementIndex) => {
                return subElement.map((_, subItemIndex) => {
                  if (
                    ((register.dados[index].value[subElementIndex] 
                      && register.dados[index].value[subElementIndex][subItemIndex]
                       && register.dados[index].value[subElementIndex][subItemIndex]
                      .value) || '') !==
                    oldRegister.dados[index].value[subElementIndex][subItemIndex]
                      .value
                  ) {
                    return count++;
                  } else {
                    return count;
                  }
                });
              });
          }
        });
        oldRegister.dados.map((item, index) => {
          if(item.value.length < register.dados[index].value.length) {
            if(!newObjects.find(item => item.name)) {
              setNewObjects([...newObjects, item.name]);
            }
            return count++;
          }else{
            return count;
          }
        })
      let values = {};
      register.dados.map(item => {
        if (typeof item.value === "string") {
          return (values = {
            ...values,
            [item.name]: item.value
          });
        } else {
          return values;
        }
      });
      const errors = validation(values);
      if (Object.keys(errors).length > 0) {
        toast.error("Há campos incorretos");
        onError(errors);
        onClose(false);
      } else {
        if (count === 0 && open) {
          onClose(false);
          toast.warn("Nenhum item foi alterado");
        } else if (open) {
          setOpenConfirm(true);
        }
      }
    }
  };

  useEffect(() => {
    async function loadRegister() {
      try {
        const response = await axios.get(`/registers/${id}`);
        let dataObj = JSON.parse(response.data.data);
        setOldRegister({ ...response.data, dados: dataObj });
        verifyFields();
      } catch (err) {
        toast.error(err.message);
      }
    }
    loadRegister();
  }, [id, open]); // eslint-disable-line

  async function handleUpdate() {
    try {
      await axios.post(`register_update/${id}`, {
        data_send: JSON.stringify(register.dados),
        data: JSON.stringify(oldRegister.dados),
        type: "company",
        origin: "update"
      });
      toast.success("Empresa Atualizada com Sucesso");
      onClose(false);
      setOpenConfirm(false);
      registerReload();
    } catch (err) {
      toast.error(err.message);
    }
  }

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      overflow: "auto"
    }
  }));
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={openConfirm}
      onClose={() => {
        onClose(false);
        setOpenConfirm(false);
        setNewObjects([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fade}>
        <div className="col-12">
          <div className="col-12 confirm">
            <i className="flaticon2-information"></i>
            <p>Tem certeza que deseja editar os seguintes dados?</p>
            {companyFields > 0 && (
              <>
                <h2 style={{ background: "#eee", width: "100%" }}>
                  Dados da Empresa
                </h2>
                <hr />
                <Row>
                  <Col lg={6}>
                    <h2>Antes</h2>
                  </Col>
                  <Col lg={6}>
                    <h2>Depois</h2>
                  </Col>
                </Row>
              </>
            )}
            {oldRegister.dados &&
              register.dados &&
              open &&
              oldRegister.dados.map((item, index) => {
                if (
                  item.name !== "produtos" &&
                  item.name !== "socios" &&
                  item.name !== "diretores" &&
                  item.name !== "fornecedores" &&
                  item.name !== "clientes" &&
                  item.name !== "referencias_comerciais" &&
                  item.name !== "referencias_pessoais" &&
                  item.name !== "bancos"
                ) {
                  if (item.value !== register.dados[index].value) {
                    return (
                      <Row key={index}>
                        <Col lg={6}>
                          <p>
                            <b>{item.title}: </b> {item.value || "-"}
                          </p>
                        </Col>
                        <Col lg={6}>
                          <p>
                            <b>{register.dados[index].value}</b>
                          </p>
                        </Col>
                      </Row>
                    );
                  } else {
                    return "";
                  }
                } else if (item.name !== "socios") {
                  return (
                    <div key={item.index}>
                      <h2
                        style={{
                          background: "#eee",
                          width: "100%",
                          marginTop: "10px"
                        }}
                      >
                        {item.title}
                      </h2>
                      <hr />
                      {item.value && 
                        item.value.length > 0 &&
                        item.value.map((subElement, subIndex) => (
                          <Row key={subIndex}>
                            {subElement && subElement.map((subItem, subItemIndex) => {
                              return (
                                subItem.value !==
                                  ((register.dados[index].value[subIndex] && register.dados[index].value[subIndex][
                                    subItemIndex
                                  ] && register.dados[index].value[subIndex][
                                    subItemIndex
                                  ].value) || '') && (
                                  <Col
                                    key={subItemIndex}
                                    style={{
                                      border: "1px solid #eee",
                                      padding: "5px"
                                    }}
                                  >
                                    <h3>{subItem.title}</h3>
                                    <Row>
                                      <Col lg={6}>
                                        <h4>Antes</h4>
                                        {subItem.value || "-"}
                                      </Col>
                                      <Col lg={6}>
                                        <h4>Depois</h4>
                                        {
                                          ((register.dados[index].value[subIndex] && register.dados[index].value[subIndex][
                                            subItemIndex
                                          ] && register.dados[index].value[subIndex][
                                            subItemIndex
                                          ].value) || '-')
                                        }
                                      </Col>
                                    </Row>
                                  </Col>
                                )
                              );
                            })}
                          </Row>
                        ))}
                    </div>
                  );
                } else {
                  return "";
                }
              })}
              {newObjects.length > 0 && (
                <Row>
                <Col lg={12}>
                  <h2>Novos Elementos</h2>
                </Col>
                {newObjects.map((item, index) => {
                  const before = oldRegister.dados.find(i => i.name === item);
                  const after = register.dados.find(i => i.name === item);
                  return (
                    <Col lg={4} key={index}>
                      {after.title}
                      <hr />
                      <Row>
                        <Col lg={6}>
                          Antes: {before.value.length}
                        </Col>
                        <Col lg={6}>
                          Depois: {after.value.length}
                        </Col>
                      </Row>
                    </Col>
                  )
                })}
              </Row>
              )}
            <div className="mt-4">
              <button
                onClick={() => handleUpdate()}
                className="btn btn-outline-success mr-3"
              >
                Sim
              </button>
              <button
                onClick={() => {
                  onClose(false);
                  setOpenConfirm(false);
                  setNewObjects([]);
                }}
                className="btn btn-outline-danger"
              >
                Não
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalUpdateCompany);
