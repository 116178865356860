import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import socketio from "socket.io-client";
import { Link, useHistory } from "react-router-dom";
import { format } from "date-fns";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableSortLabel,
  TableRow,
  Paper,
  LinearProgress,
  makeStyles,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Visibility } from "@material-ui/icons";
import ConfirmationView from "./ConfirmationView";
import ConfirmationDetail from "./ConfirmationDetail";
import ConfirmationNew from "./ConfirmationNew";
import ConfirmationNewByCnpj from "./ConfirmationNewByCnpj";
import ConfirmationSearch from "./ConfirmationSearch";
import { Dropdown, Pagination } from "react-bootstrap";
import verifyProfiles from "../../utils/verifyProfiles";

export default function ConfirmationIndex() {
  const [user, setUser] = useState({});
  const [open, setOpen] = useState({ active: false, bordero: "" });
  const [openDetail, setOpenDetail] = useState({ active: false, bordero: "" });
  const [openNew, setOpenNew] = useState(false);
  const [openNewCnpj, setOpenNewCnpj] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filter, setFilter] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('auth');
      setUser(data);
    }
    loadUser();
  }, []);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [total, setTotal] = useState(0);

  function progressRegister(item) {
    let percConfirmado = 0;
    let countConfirmado = 0;
    item.nfes.forEach((i) => {
      if (i.status === "confirmado") {
        countConfirmado += 1;
      }
    });
    percConfirmado = (countConfirmado / item.nfes.length) * 100;
    percConfirmado = Math.round(percConfirmado);

    let percAConfirmar = 0;
    let countAConfirmado = 0;
    item.nfes.forEach((i) => {
      if (i.status === "a confirmar") {
        countAConfirmado += 1;
      }
    });
    percAConfirmar = (countAConfirmado / item.nfes.length) * 100;
    percAConfirmar = Math.round(percAConfirmar);

    let percRejeitado = 0;
    let countRejeitado = 0;
    item.nfes.forEach((i) => {
      if (i.status === "rejeitado") {
        countRejeitado += 1;
      }
    });
    percRejeitado = (countRejeitado / item.nfes.length) * 100;
    percRejeitado = Math.round(percRejeitado);
    
    let percNaoConfirmado = 0;
    let countNaoConfirmado = 0;
    item.nfes.forEach((i) => {
      if (i.status === "nao confirmado") {
        countNaoConfirmado += 1;
      }
    });
    percNaoConfirmado = (countNaoConfirmado / item.nfes.length) * 100;
    percNaoConfirmado = Math.round(percNaoConfirmado);

    let percProgress = 0;
    let countProgress = 0;
    item.nfes.forEach((i) => {
      if (i.status === "") {
        countProgress += 1;
      }
    });
    percProgress = (countProgress / item.nfes.length) * 100;
    percProgress = Math.round(percProgress);

    let percNotFound = 0;
    let countNotFound = 0;
    item.nfes.forEach((i) => {
      if (i.status === "nfe nao encontrada") {
        countNotFound += 1;
      }
    });
    percNotFound = (countNotFound / item.nfes.length) * 100;
    percNotFound = Math.round(percNotFound);

    let countInvalid = 0;

    item.nfes.forEach((i) => {
      if (i.status === "invalida" || i.status === "inválida") {
        countInvalid += 1;
      }
    });

    return (
      <div
        className="text-center"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="progress" style={{ width: "100%", maxWidth: "300px" }}>
          <div
            className={`progress-bar kt-bg-success`}
            style={{ width: `${percConfirmado}%` }}
          >
            {percConfirmado > 0 && `${percConfirmado}%`}
          </div>
          <div
            className={`progress-bar kt-bg-brand`}
            style={{ width: `${percAConfirmar}%` }}
          >
            {percAConfirmar > 0 && `${percAConfirmar}%`}
          </div>
          <div
            className={`progress-bar kt-bg-danger`}
            style={{ width: `${percRejeitado}%` }}
          >
            {percRejeitado > 0 && `${percRejeitado}%`}
          </div>
          <div
            className={`progress-bar kt-bg-dark`}
            style={{ width: `${percNaoConfirmado}%` }}
          >
            {percNaoConfirmado > 0 && `${percNaoConfirmado}%`}
          </div>
          <div
            className={`progress-bar`}
            style={{ width: `${percNotFound}%`, background: '#ff7d00' }}
          >
            {percNotFound > 0 && `${percNotFound}%`}
          </div>
          <div
            className={`progress-bar`}
            style={{ width: `${percProgress}%`, background: 'none', color: '#444'}}
          >
            {percProgress > 0 && `${percProgress}%`}
          </div>
        </div>
        {countInvalid > 0 && <span className="text-danger">{countInvalid} Nota(s) Inválida(s)</span>}
      </div>
    );
  }

  async function loadConfirmation() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/bordero-report?page=${page}&perPage=${rowsPerPage}`
      );
      let rowsResponse = [];
      response.data.borderos.forEach(item => {
        rowsResponse.push({ ...item, created: format(new Date(item.updatedAt), "dd/MM/yyyy 'às' HH:mm") });
      })
      // rowsResponse.sort(function(a, b){return a.created - b.created});
      setRows(rowsResponse);
      setTotal(response.data.total)
    } catch (err) {
      toast.error("Ocorreu um erro na listagem de Borderôs NFe");
    }
    setLoading(false);
  }

  function insertBorderoSocket(res) {
    console.log(rows.find(i => i._id === res.bodero.id));
    console.log(rows[0]);
    if(rows.find(i => i._id === res.bodero.id)) {
      setRows(rows.map(i => {
        if (i._id === res.bodero.id) {
          return {
            ...i,
            nfes: i.nfes.map(n => {
              if(n.chNfe === res.bordero.nfe.chNfe) {
                return {
                  ...n,
                  status: res.bodero.nfe.status,
                }
              }
              return n;
            }),
            createdAt: res.bodero.created,
            created: format(new Date(res.bodero.created), "dd/MM/yyyy 'às' HH:mm"),
          }
        }
        return i;
      }))
    }else{
      loadConfirmation();
    }
  }

  useEffect(() => {
      async function connectWebSocket() {
        const user = await axios.get(`${process.env.REACT_APP_API_URL}/auth`);
        const socket = socketio(process.env.REACT_APP_API_BFC_DIGITAL_URL, {
          query: {
            user_id: user.data.id, 
          }
        });
        socket.on('msg', res => {
          if(res.type === 'bordero') {
            insertBorderoSocket(res);
          }
        });
      }
      connectWebSocket();
  }, []); // eslint-disable-line

  useEffect(() => {
    try {
      loadConfirmation();
    }catch(err) {
      history.goBack();
    }
  }, [page, rowsPerPage]); // eslint-disable-line

  async function searchConfirmation({ by, value }) {
    setLoading(true);
    try {
      let response;
      switch(by) {
        case 0 : {
          response = await axios.get(
            `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/bordero-report/cnpj/${value.replace(/[^\d]/g, "")}`
          );
          break;
        }
        case 2: {
          response = await axios.get(
            `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/bordero-report/bordero/${value}`
          );
          break;
        }
        default: {
          return toast.error('Ocorreu um erro');
        }
      }
      if (response.data.length === 0) {
        toast.info("Borderô NFe não encontrado");
      } else {
        if(by === 2) {
          if(response.data.bordero === null) {
            toast.info("Borderô NFe não encontrado");
          }else{
            setRows([{ _id: value, nfes: response.data.nfes, created: format(new Date(response.data.bordero.updatedAt), "dd/MM/yyyy 'às' HH:mm") }]);
          }
        }else{
          let list = [];
          response.data.forEach(item => {
            list.push({ ...item, _id: item.bordero, created: format(new Date(item.updatedAt), "dd/MM/yyyy 'às' HH:mm") })
          })
          setRows(list);
        }
        setPage(0);
        setFilter(true);
      }
    } catch (err) {
      if(err.response && err.response.status === 404) {
        toast.info("Borderô NFe não encontrado");
      }else{
        toast.error("Ocorreu um erro ao tentar pesquisar");
      }
    }
    setLoading(false);
  }

  const headRows = [
    {
      id: "bordero",
      numeric: false,
      disablePadding: true,
      align: "left",
      label: "Borderô NFe",
    },
    {
      id: "nfe`s",
      numeric: false,
      disablePadding: true,
      align: "center",
      label: "N° de Notas"
    },
    {
      id: "confirmed",
      numeric: false,
      disablePadding: true,
      align: "center",
      label: (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Status
          <div
            className="kt-widget__stats"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              marginTop: "10px",
              fontSize: "10px"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 6px"
              }}
            >
              <div
                className="kt-bg-success"
                style={{ width: "10px", height: "10px" }}
              ></div>
              Confirmadas
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 6px"
              }}
            >
              <div
                className="kt-bg-brand"
                style={{ width: "10px", height: "10px" }}
              ></div>
              A confirmar
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 6px"
              }}
            >
              <div
                className="kt-bg-danger"
                style={{ width: "10px", height: "10px" }}
              ></div>
              Rejeitadas
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 6px"
              }}
            >
              <div
                className="kt-bg-dark"
                style={{ width: "10px", height: "10px" }}
              ></div>
              Não confirmada
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 6px"
              }}
            >
              <div
                style={{ width: "10px", height: "10px", background: "#ff7d00" }}
              ></div>
              Não Encontrada
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 6px"
              }}
            >
              <div
                style={{ width: "10px", height: "10px", background: "#ebedf2" }}
              ></div>
              Em Análise
            </div>
          </div>
        </div>
      ),
    },
    {
      id: "date",
      numeric: false,
      disablePadding: true,
      align: "right",
      label: "Data de Processamento",
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      align: "right",
      label: "Ações",
    },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, /* onRequestSort */ } = props;
    /*
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    */
    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={() => {}}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleNextPage() {
    const nextPage = page + 1
    setPage(nextPage);
  }

  function handlePreviousPage() {
    const previousPage = page - 1
    setPage(previousPage);
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Borderôs NFe</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/agents`}>
                Borderôs NFe
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
              {filter && (
                <button type="button" className="btn btn-warning" onClick={() => {
                  setFilter();
                  loadConfirmation();
                }}>
                  Esvaziar Filtro
                </button>
              )}
              <Dropdown alignRight>
              <Dropdown.Toggle variant="light" id="dropdown-basic">
                Ações
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item as="button" onClick={() => setOpenSearch(true)}>Buscar</Dropdown.Item>
                {Object.keys(user).length > 0 && !verifyProfiles(user, 'analist_confirmation') && (
                  <>
                  <Dropdown.Item as="button" onClick={() => setOpenNew(true)}>Novo Borderô</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => setOpenNewCnpj(true)}>Novos Borderôs NFe por Cedente</Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationView
        open={open}
        setOpen={(e) => setOpen(e)}
        onReload={() => loadConfirmation()}
      />
      <ConfirmationNewByCnpj 
        open={openNewCnpj}
        setOpen={e => setOpenNewCnpj(e)}
        onReload={() => loadConfirmation()}
      />
      <ConfirmationNew
        open={openNew}
        setOpen={(e) => setOpenNew(e)}
        setView={(e) => setOpen(e)}
        borderos={rows}
        setUpDetail={e => setOpenDetail(e)}
      />
      <ConfirmationSearch 
        open={openSearch}
        setOpen={(e) => setOpenSearch(e)}
        getConsult={(e) => { 
          setOpenSearch(false);
          searchConfirmation(e);
        }}
      />
      <ConfirmationDetail open={openDetail} setOpen={(e) => setOpenDetail(e)} />
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows && rows.length > 0 && rows
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="left">{row._id}</TableCell>
                      <TableCell align="center">
                        {row.nfes.length}
                      </TableCell>
                      <TableCell align="left">
                        {progressRegister(row)}
                      </TableCell>
                      <TableCell align="right">
                        {row.created}
                      </TableCell>
                      <TableCell align="right">
                        <button
                          type="button"
                          className="btn btn-sm pl-2 pr-2"
                          onClick={() =>
                            setOpenDetail({ active: true, bordero: row._id })
                          }
                        >
                          <Visibility />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        {rows && rows.length > 0 && (
          <div className="align-items-end d-flex justify-content-between mt-5">
            <span>{total} Total</span>
            <div className="d-flex align-items-center">
              {loading && (
                <CircularProgress size={15} className="mr-2" />
              )}
            <Pagination>
              {page > 0 && (
                <>
                  <Pagination.First onClick={() => setPage(0)} disabled={page === 0 || loading} />
                  <Pagination.Prev onClick={() => handlePreviousPage()} disabled={loading}/>
                </>
              )}
              <Pagination.Item disabled>{page + 1}</Pagination.Item>
              {page < (Math.ceil(total/rowsPerPage) - 1) && (
                <>
                <Pagination.Next onClick={() => handleNextPage()} disabled={loading}/>
                <Pagination.Last onClick={() => setPage(Math.ceil(total/rowsPerPage) - 1)} disabled={loading}/>
                </>
              )}
            </Pagination>
            </div>
            <FormControl
                style={{ width: "100%", maxWidth: "120px", marginRight: "10px" }}
                disabled={loading}
              >
                <InputLabel htmlFor="results_per_page">Result. p/Página</InputLabel>
                <Select
                  id="results_per_page"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(e.target.value)}
                  disabled={loading}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </FormControl>
          </div>
        )}
      </Paper>
    </div>
  );
}
