export default function verifyTitleEvent(title) {
  switch(title) {
    case 'clicked': return 'Lido';
    case 'open': return 'Lido';
    case 'processed': return 'Processado';
    case 'dropped': return 'Inválido'; 
    case 'delivered': return 'Enviado';
    case 'deferred': return 'Erro, haverá uma nova tentativa';
    case 'bounce': return 'Inválido';
    default: return 'Enviado';
  }
}