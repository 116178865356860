import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Paper,
  FormControl,
  TextField,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Alert, Row, Col } from "react-bootstrap";
import CheckBox from "./CheckBox";

let permissionArrayAux;
let previous;
let finalPermissions;

export default function UserAdd({ history }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({
    name: "",
    slug: "",
    description: "",
    permissions: [],
  });
  const [error, setError] = useState([]);
  const [disabled, setDisabled] = useState(true);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  function createAux(array) {
    const arrayAux = array.map((item) => {
      const objAux = { per: item, bol: false };
      return objAux;
    });
    return arrayAux;
  }

  async function loadPermissions() {
    setLoading(true);
    const response = await axios.get(`/permissions`);
    setLoading(false);
    const rowsAux = createAux(response.data);
    setRows(rowsAux);
  }

  useEffect(() => {
    loadPermissions();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (
      values.name !== "" &&
      values.slug !== "" &&
      values.description !== "" &&
      values.permissions !== []
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values.description, values.permissions, values.name, values.slug]);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post("/roles", values);
      if (response.status === 203) {
        setError(response.data);
      } else {
        toast.success("Perfil cadastrado com sucesso");
        history.push("/roles");
      }
    } catch (err) {
      toast.error('Ocorreu um erro, tente novamente');
    }
  }

  function standardize(array) {
    array = array.filter((item) => {
      return item.bol;
    });

    array = array.map((item, index) => {
      return item.per.id;
    });

    return array;
  }

  React.useEffect(() => {
    if (rows === "okay") {
      setRows(permissionArrayAux);
    }
  }, [rows]);

  function whatToRender(rows) {
    if (rows === "okay") {
      return previous;
    } else {
      return rows;
    }
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Perfis</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/roles`}>
                Perfis
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/roles/add`}
              >
                Novo Perfil
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        {loading && <LinearProgress color="primary" />}
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          {error.length !== 0 && (
            <Alert variant="danger">
              <ul>
                {error.map((er) => (
                  <li key={er.message}>{er.message}</li>
                ))}
              </ul>
            </Alert>
          )}
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Name"
              value={values.name}
              onChange={(e) => {
                setValues({
                  ...values,
                  name: e.target.value,
                  slug: e.target.value,
                });
              }}
              required
            />
          </FormControl>
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Description"
              type="text"
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
              required
            />
          </FormControl>
          <FormControl>
            Permissões:
            <Row>
              {whatToRender(rows).map((item, index) => {
                return (
                  <Col xs="4" sm="3" key={index}>
                    <CheckBox
                      name={item.per.name}
                      checked={item.bol}
                      index={index}
                      array={whatToRender(rows)}
                      change={(array) => {
                        permissionArrayAux = array;
                        previous = whatToRender(rows);
                        setRows("okay");
                        finalPermissions = standardize(permissionArrayAux);
                        setValues({ ...values, permissions: finalPermissions });
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </FormControl>
          <FormControl>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={disabled}
            >
              Cadastrar
            </button>
            <Link to="/roles" className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}
