import React, { memo } from "react";
import { Paper } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import DocumentsNavBar from "./DocumentsNavBar";
import DocumentsTable from "./DocumentsTable";
import Repositorie from "./Repositorie";

function DocumentsSection(props) {
  return (
    <Paper className={props.classes.paper}>
      <div
        style={{
          justifyContent: "flex-end",
          display: "flex",
          width: "100%"
        }}
      >
        {props.register.documents &&
          props.register.documents.find(
            doc => doc.status !== "A" && doc.status !== "I"
          ) && (
            <>
              <DocumentsNavBar
                selectExempt={props.selectExempt}
                setSelectExempt={props.setSelectExempt}
                selectApprove={props.selectApprove}
                setSelectApprove={props.setSelectApprove}
                selectReprove={props.selectReprove.docs}
                setSelectReprove={props.setSelectReprove}
                setConfirmExemptedDocument={props.setConfirmExemptedDocument}
                setConfirmApprovedDocument={props.setConfirmApprovedDocument}
                setOpenReproveDocs={props.setOpenReproveDocs}
              />
            </>
          )}
      </div>
      {/* everything fine until here */}
      <div className={props.classes.container_list}>
        <Row>
          <Col lg={12}>
            <h3>Documentos</h3>
            <hr></hr>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <DocumentsTable
              register={props.register}
              docsReproved={props.docsReproved}
              docsApproved={props.docsApproved}
              docsExempted={props.docsExempted}
              statusDocument={props.statusDocument}
              exempt={{
                funcOnClick: props.handleSelectExemptDoc,
                select: props.selectExempt
              }}
              approve={{
                funcOnClick: props.handleSelectApprovedDoc,
                select: props.selectApprove
              }}
              reprove={{
                funcOnClick: props.handleSelectReprovedDoc,
                select: props.selectReprove.docs
              }}
            />
          </Col>
        </Row>
        <Repositorie
          documentsRepository={props.documentsRepository}
          reloadDocument={() => props.loadDocumentRepositories()}
          document_types={props.register.documents && props.register.documents}
          register_id={props.match.params.id}
          editable={props.percentage < 100}
        />
      </div>
    </Paper>
  );
}

export default memo(DocumentsSection);
