import React from 'react';
import verifyTitleMemberField from "./verifyTitleMemberField";
import { Col } from "react-bootstrap";

export default function loadPendencia(pendencie) {
  const p = pendencie.pendencie;
  switch (p.type) {
    case "company": {
      return (
        <>
          <Col lg={6}>
            <h2>Antes</h2>
            {pendencie.pendencie.data_json &&
              pendencie.pendencie.data_json.map(item => {
                if (typeof item.value === "string") {
                  return (
                    <p key={item.name}>
                      {item.title}: {item.value}
                    </p>
                  );
                } else if (typeof item.value === "object") {
                  return (
                    <>
                    <p>{item.title}:</p>
                    <ol key={item.name}>
                      {item.value.map((sub, index) => (
                        <li key={index} style={{ borderBottom: '1px solid #eee' }}>
                          <ul 
                          style={{ 
                            listStyle: 'none', 
                            borderBottom: '1px solid #eee', 
                            margin: '0', 
                            padding: '0'
                             }}>
                            {sub.map(intro => (
                              <li key={intro.title}>
                                {intro.title}: {intro.value}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ol>
                    </>
                  );
                }
                return '';
              })}
          </Col>
          <Col lg={6}>
            <h2>Depois</h2>
            {pendencie.pendencie.data_send_json !== null ?
              pendencie.pendencie.data_send_json.map(item => {
                if (typeof item.value === "string") {
                  return (
                    <p key={item.name}>
                      {item.title}: {item.value}
                    </p>
                  );
                } else if (typeof item.value === "object") {
                  return (
                    <>
                    <p>{item.title}:</p>
                    <ol key={item.name}>
                      {item.value.map((sub, index) => (
                        <li key={index} style={{ borderBottom: '1px solid #eee' }}>
                          <ul 
                          style={{ 
                            listStyle: 'none',
                            margin: '0', 
                            padding: '0'
                             }}>
                            {sub.map(intro => (
                              <li key={intro.title}>
                                {intro.title}: {intro.value}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ol>
                    </>
                  );
                }
                return '';
              }) : <p>Em pendência...</p>}
          </Col>
        </>
      );
    }
    case "member": {
      const keysData = Object.keys(pendencie.pendencie.data_json);
      let dataPendencie = [];
      for (let i = 0; i < keysData.length; i++) {
        if(keysData[i] !== 'memberSpouses') {
          dataPendencie.push({
            title: keysData[i],
            value: pendencie.pendencie.data_json[keysData[i]]
          });
        }else {
          let spousesFields = [];
          let spousesKeys = Object.keys(pendencie.pendencie.data_json['memberSpouses']);
          if(spousesKeys.length > 0) {
            for (let c = 0; c < spousesKeys.length; c++) {
              spousesFields.push({
                title: spousesKeys[c],
                value: pendencie.pendencie.data_json.memberSpouses[spousesKeys[c]],
              })
            }
            spousesFields = spousesFields.sort(function (a, b) {
              return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
            });
            dataPendencie.push({
              title: 'memberSpouses',
              value: spousesFields,
            });
          }
        }
      }
      let keysDataSend;
      if (pendencie.pendencie.data_send_json !== null) {
        keysDataSend = Object.keys(pendencie.pendencie.data_send_json);
      } else {
        keysDataSend = [];
      }
      let dataSendPendencie = [];
      for (let i = 0; i < keysDataSend.length; i++) {
        if(keysData[i] !== 'memberSpouses') {
          dataSendPendencie.push({
            title: keysDataSend[i],
            value: pendencie.pendencie.data_send_json[keysDataSend[i]]
          });
        }else {
          let spousesFields = [];
          let spousesKeys = Object.keys(pendencie.pendencie.data_send_json['memberSpouses']);
          if (spousesKeys.length > 0) {
            for (let c = 0; c < spousesKeys.length; c++) {
              spousesFields.push({
                title: spousesKeys[c],
                value: pendencie.pendencie.data_send_json.memberSpouses[spousesKeys[c]],
              })
            }
            spousesFields = spousesFields.sort(function (a, b) {
              return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
            });
            dataSendPendencie.push({
              title: 'memberSpouses',
              value: spousesFields,
            });
          }
        }
      }
      dataSendPendencie = dataSendPendencie.sort(function (a, b) {
        return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
      });
      dataPendencie = dataPendencie.sort(function (a, b) {
        return (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0);
      });
      return (
        <>
          <Col lg={6}>
            <h2>Antes</h2>
            {dataPendencie.map(item => {
              if(item.title !== 'memberSpouses') {
                return (
                  <p key={item.title}>
                    {verifyTitleMemberField(item.title)}: {item.value}
                  </p>
                );
              }else{
                return item.value.map(sub => (
                  <p key={sub.value}>
                    <strong>Cônjuge - </strong>{verifyTitleMemberField(sub.title)}: {sub.value}
                  </p>
                ))
              }
            })}
          </Col>
          <Col lg={6}>
            <h2>Depois</h2>
            {dataSendPendencie.length > 0 ? dataSendPendencie.map(item => {
              if(item.title !== 'memberSpouses') {
                return (
                  <p key={item.title}>
                    {verifyTitleMemberField(item.title)}: {item.value}
                  </p>
                )
              }else {
                  return item.value.map(sub => (
                    <p key={sub.value}>
                      <strong>Cônjuge - </strong>{verifyTitleMemberField(sub.title)}: {sub.value}
                    </p>
                  ))
              }
              }) : <p>Em pendência...</p>}
          </Col>
        </>
      );
    }
    case "document": {
      break;
    }
    default:
      break;
  }
}