import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Paper, FormControl, TextField, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";

export default function UserEdit({ history, match }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({});
  useEffect(() => {
    async function loadUser() {
      try {
        const response = await axios.get(`/permissions/${match.params.id}`);
        setValues(response.data);
      } catch (err) {
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    loadUser();
  }, [match.params.id]);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await axios.put(`/permissions/${match.params.id}`, values);
      toast.success("Permissão atualizada com sucesso");
      history.push("/permissions");
    } catch (err) {
      toast.error('Ocorreu um erro, tente novamente');
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Permissões</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/permissions`}
              >
                Permissões
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/permissions/${match.params.id}`}
              >
                Editar permissão
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Name"
              value={values.name || ""}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="description"
              type="text"
              value={values.description || ""}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
              required
            />
          </FormControl>
          <FormControl>
            <button
              type="submit"
              style={{ marginTop: 20 }}
              className="btn btn-primary mt-3"
            >
              Atualizar
            </button>
            <Link to="/permissions" className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}
