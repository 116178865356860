import React, { memo } from 'react';

import { Modal, Backdrop, makeStyles } from '@material-ui/core';
import { Row } from 'react-bootstrap';

function ConfirmAprove({ 
  open, setOpen, onSubmit
 }) {
  const useStyles = makeStyles(theme => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "470px",
      height: "300px",
      overflow: "auto"
    },
  }));
  const classes = useStyles();

  return (
    <Modal
        className={classes.modalsm}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.fadesm}>
          <Row>
            <div className="col-12">
              <div className="col-12 confirm">
                <i className="flaticon2-information"></i>
                <p>Tem certeza que deseja aprovar o Parecer?</p>
                <div className="mt-4">
                  <button
                    onClick={() => onSubmit()}
                    className="btn btn-outline-success mr-3"
                  >
                    Sim
                  </button>
                  <button
                    onClick={() => setOpen(false)}
                    className="btn btn-outline-danger"
                  >
                    Não
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Modal>
  );
}

export default memo(ConfirmAprove);