import React, { useState, memo } from "react";

import { Container } from "./styles";

import { Button, CircularProgress } from "@material-ui/core";
import { Close, Send, AttachFile } from "@material-ui/icons";

import axios from 'axios';
import { toast } from 'react-toastify';

function MailSender({ open, setOpen, onSend }) {
  const [addEmail, setAddEmail] = useState('');
  const [to, setTo] = useState([]);
  const [attachs, setAttachs] = useState([]);

  const [values, setValues] = useState({
    subject: '',
    message: '',
  });

  const [loading, setLoading] = useState(false);

  function handleAdd(e) {
    e.preventDefault();

    if(!to.find(i => i === addEmail) && addEmail.length > 3) {
      setTo([...to, addEmail]);
    }
    setAddEmail('');
  }
  function handleRemove(index) {
    setTo(to.filter((_, i) => i !== index));
  }

  function handleRemoveFile(index) {
    setAttachs(attachs.filter((_, i) => i !== index));
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const obj = new FormData();
      attachs.forEach(attachment => obj.append('attachs', attachment));
      obj.append('emails', to.map(e => e.split(' ').join('').toLowerCase()).join(','));
      obj.append('message', values.message);
      obj.append('subject', values.subject);
      obj.append('origin', 'notification');
      await axios.post(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-avulse/avulse`, obj, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      toast.info('E-mail enviado para processamento');
      resetForm();
      onSend();
    }catch(err){
      toast.error(err.message);
    }
    setLoading(false);
  }

  function resetForm() {
    setAddEmail('');
    setTo([]);
    setAttachs([]);
    setValues({
      subject: '',
      message: '',
    });
    setOpen(false);
  }

  const formatSize = (value) => {
    if (value < 1000) {
      return `${value} B`;
    } else if (value > 1000 && value < 1000000) {
      return `${Math.round(value/1000)} Kb`;
    } else if (value > 1000000) {
      return `${Math.round(value/1000000)} Mb`;
    } else {
      return `${Math.round(value/1000000)} Mb`;
    }
  }

  return (
    <Container open={open}>
      <div id="header_send">
        <h4>Enviar E-mail</h4>
        <button type="button" style={{ background: "none", border: "none" }} onClick={resetForm}>
          <Close />
        </button>
      </div>
      <div id="body_mail">
        <section>
          <form onSubmit={handleAdd}>
            <span>Para: </span>
            {to.map((email, index) => (
              <div>
                {email}
                <button type="button" style={{ background: "none", border: "none" }} onClick={() => handleRemove(index)}>
                  <Close />
                </button>
              </div>
            ))}
            <input type="text" value={addEmail} onBlur={handleAdd} onChange={e => setAddEmail(e.target.value)} />
          </form>
        </section>
        <section>
          <input type="text" placeholder="Assunto" value={values.subject} onChange={e => setValues({ ...values, subject: e.target.value })}/>
        </section>
        <section>
          <textarea placeholder="Mensagem" rows="10" value={values.message} onChange={e => setValues({ ...values, message: e.target.value })}></textarea>
        </section>
      </div>
      <div id="attachs">
        {attachs.map((file, index) => (
          <div className="attach_item" key={index}>
            <p className="mb-0">{file.name} <strong>({formatSize(file.size)})</strong></p>
            <button type="button" style={{ background: "none", border: "none" }} onClick={() => handleRemoveFile(index)}>
              <Close />
            </button>
          </div>
        ))}
      </div>
      <div id="footer_mail">
        <Button color="primary" variant="contained" onClick={handleSubmit} endIcon={<Send />} disabled={values.message === "" || values.subject === "" || to.length === 0 || loading}>
          {loading && (<CircularProgress size="16px" className="mr-2"/>)}
          Enviar
        </Button>
        <button
          type="button"
          style={{ background: "none", border: "none" }}
          className="text-muted ml-4"
          onClick={() => document.getElementById('input_attach').click()}
        >
          <AttachFile />
        </button>
      </div>
      <input type="file" id="input_attach" style={{ display: 'none' }} onChange={(e) => { 
        setAttachs([...attachs, ...e.target.files]);
      }} multiple/>
    </Container>
  );
}

export default memo(MailSender);
