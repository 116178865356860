import React from "react";
import { Route, Switch } from "react-router-dom";
import UserIndex from "./UserIndex";
import UserAdd from "./UserAdd";
import UserEdit from "./UserEdit";
import UserPassword from "./UserPassword";

export default function UserPage() {
  return (
    <Switch>
      <Route exact path="/users/" component={UserIndex} />
      <Route exact path="/users/add" component={UserAdd} />
      <Route exact path="/users/:id" component={UserEdit} />
      <Route exact path="/users/password/:id" component={UserPassword} />
    </Switch>
  );
}
