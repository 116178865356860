import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { toast } from "react-toastify";
import { format } from "date-fns";
import ListTransporterItem from "../components/ListTransporterItem";
import SelectTransporterItem from "../components/SelectTransporterItem";
import verifyProfiles from "../../utils/verifyProfiles";

import axios from "axios";

export default function ConfirmationView({ open, setOpen }) {
  const [user, setUser] = useState({});
  const [transporterData, setTransporterData] = useState({});
  const [ctes, setCtes] = useState([]);
  const [selected, setSelected] = useState([]);
  const [approve, setApprove] = useState(false);
  const [detail, setDetail] = useState(null);
  const [loading, setLoading] = useState(false);
  const [percentageToConfirm, setPercentageToConfirm] = useState(0);
  const [percentageInProgess, setPercentageInProgress] = useState(0);
  const [loadingPdf, setLoadingPdf] = useState(false);

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('auth');
      setUser(data);
    }
    loadUser();
  }, []);

  function handleSelectApprove(item) {
    const alreadyHave = selected.find((i) => i.index === item.index);
    if (!alreadyHave) {
      setSelected([...selected, item]);
    } else {
      const reproved = selected.filter((i) => i.index !== item.index);
      setSelected(reproved);
    }
  }

  async function loadTransporter() {
    setLoading(true);
    let response;
    let pdfInfo;
    try {
      pdfInfo = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-cte/pdf/status/${open.transporter}`
      );
    } catch (err) {
      pdfInfo = {
        data: {
          pdf: undefined,
        },
      };
    }
    try {
      response = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/bordero-report-cte/${open.transporter}`
      );
      setTransporterData({
        ...response.data.bordero,
        pdf: pdfInfo.data.pdf,
        count_pdf: pdfInfo.data.count,
      });
      setCtes(response.data.ctes);
      let countSuccess = 0;
      response.data.ctes.forEach((cte) => {
        if (cte.status === "a confirmar" || cte.status === "nao confirmado") {
          countSuccess += 1;
        }
      });
      let percent = (countSuccess / response.data.ctes.length) * 100;
      setPercentageToConfirm(Math.round(percent));
      let countInProgress = 0;
      response.data.ctes.forEach((cte) => {
        if (cte.status === "") {
          countInProgress += 1;
        }
      });
      let inProgess = (countInProgress / response.data.ctes.length) * 100;
      setPercentageInProgress(Math.round(inProgess));
    } catch (err) {
      toast.error("Nenhuma CTe encontrada.");
    }
    if (
      (response.data.bordero &&
        response.data.bordero.cedente &&
        response.data.bordero.cedente === "-") ||
      (response.data.bordero &&
        response.data.bordero.ufCedente &&
        response.data.bordero.ufCedente === "-") ||
        (response.data.bordero &&
          response.data.bordero.cnpj &&
          response.data.bordero.cnpj === "-")
    ) {
      try {
        const detail = await axios.get(
          `${process.env.REACT_APP_API_URL}/bordero-cte/${open.transporter}`
        );
        setTransporterData({
          cedente: detail.data.borderoData.cedente,
          ufCedente: detail.data.borderoData.uf_cedente,
          cnpj: detail.data.borderoData.cnpj,
          pdf: pdfInfo.data.pdf,
          count_pdf: pdfInfo.data.count,
        });
      } catch (err) {
        console.log(err);
      }
    }
    setLoading(false);
  }

  async function handleSubmitPdf() {
    setLoadingPdf(true);
    try {
      const { data } = await axios.get("auth");
      await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-cte/list`,
        {
          bordero: open.transporter,
          ctes,
          user: data.id,
          borderoData: transporterData,
        }
      );
      toast.info(
        "Notas foram enviadas para geramento de PDF, notificaremos quando estiver pronto"
      );
      setTransporterData({ ...transporterData, pdf: "unlocking" });
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar iniciar processo");
    }
    setLoadingPdf(false);
  }

  async function handleUnlockPdf() {
    setLoadingPdf(true);
    try {
      const { data } = await axios.get("auth");
      await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-cte/unlock`,
        {
          bordero: open.transporter,
          ctes,
          user: data.id,
          borderoData: transporterData,
        }
      );
      console.log(
        JSON.stringify({
          bordero: open.transporter,
          ctes,
          user: data.id,
          borderoData: transporterData,
        })
      );
      toast.info(
        "Notas foram enviadas para geramento de PDF, notificaremos quando estiver pronto"
      );
      setTransporterData({ ...transporterData, pdf: "unlocking" });
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar iniciar processo");
    }
    setLoadingPdf(false);
  }

  useEffect(() => {
    if (open.transporter !== "") {
      loadTransporter();
    } else {
      setOpen({ active: false, transporter: "" });
    }
  }, [open.transporter]); // eslint-disable-line

  async function handleSubmit() {
    try {
      const { data } = await axios.get("auth");
      const ctesSelected = [];
      selected.forEach((item) => {
        ctesSelected.push({ ...item, cte: item.chCte });
      });
      await axios.post(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/request-list-cte/list`,
        {
          bordero: open.transporter,
          ctes: ctesSelected,
          user: data.id,
          borderoData: transporterData,
        }
      );
      loadTransporter();
      setApprove(false);
      setSelected([]);
      toast.info(
        "CTe's foram enviadas para análise. Notificaremos quando todas forem analisadas"
      );
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar realizar análise");
    }
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "800px",
      height: "90%",
      overflow: "auto",
    },
    box: {
      flex: "1",
      border: "1px solid #eee",
      background: "#f9f9f9",
      padding: "10px",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.active}
      onClose={() => {
        setOpen({ active: false, transporter: "" });
        setCtes([]);
        setSelected([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open.active}>
        <div className={classes.fade}>
          <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Detalhamento do Borderô CTe</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen({ active: false, transporter: "" });
                    setCtes([]);
                    setSelected([]);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <h3
              className="mt-4"
              style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Borderô CTe: {open.transporter}
            </h3>
            {ctes.length > 0 && (
              <table className="table table-bordered">
                <thead>
                  <tr className="bg-secondary text-dark">
                    <td>Cedente</td>
                    <td>UF</td>
                    <td>N° de Notas</td>
                    <td>Data de Processamento</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {(transporterData && transporterData.cedente) || "-"}
                    </td>
                    <td>
                      {(transporterData && transporterData.ufCedente) || "-"}
                    </td>
                    <td>{ctes.length}</td>
                    <td>
                      {format(
                        new Date(ctes[0].createdAt),
                        "dd/MM/yyyy 'às' HH:mm"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            <div>
              <h4 className="mt-4">CTe's</h4>
              <div className="mt-4"></div>
              <hr />
              <div className="row">
                {!approve &&
                  ctes.length > 0 &&
                  percentageToConfirm > 0 &&
                  percentageInProgess === 0 &&
                  Object.keys(user).length > 0 && 
                  !verifyProfiles(user, 'analist_confirmation') && (
                    <div className="col-md-3">
                      <button
                        type="button"
                        onClick={() => setApprove(true)}
                        className="btn btn-primary btn-upper btn-sm"
                      >
                        Analisar Novamente
                      </button>
                    </div>
                  )}
                {percentageInProgess === 0 && !loading && ctes.length > 0 && (
                  <>
                    {transporterData.pdf === undefined && (
                      <div className="col-md-3">
                        <button
                          type="button"
                          onClick={() => handleSubmitPdf()}
                          disabled={loadingPdf}
                          className="btn btn-success btn-upper btn-sm"
                        >
                          {loadingPdf ? (
                            <CircularProgress size={12} color="#fff" />
                          ) : (
                            <>
                              <i class="fas fa-file-pdf"></i> Gerar PDF
                            </>
                          )}
                        </button>
                      </div>
                    )}
                    {transporterData.pdf === "progress" && (
                      <>
                        <div className="col-md-3">
                          <button
                            type="button"
                            disabled
                            className="btn btn-success btn-upper btn-sm"
                          >
                            <i class="fas fa-file-pdf"></i> Gerando PDF...
                          </button>
                        </div>
                        <div className="col-md-6">
                          <small className="d-block text-center">
                            <strong>{transporterData.count_pdf}</strong> PDF(s)
                            em processamento
                          </small>
                          <div className="row mt-2">
                            <div className="col-md-6">
                              <a
                                href={`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-cte/pdf/download/${open.transporter}`}
                                target="blank"
                                className="btn btn-info btn-upper btn-sm btn-block"
                              >
                                Baixar Incompleto
                              </a>
                            </div>
                            <div className="col-md-6">
                              <button
                                type="button"
                                onClick={handleUnlockPdf}
                                className="btn btn-warning btn-upper btn-sm btn-block"
                              >
                                Enviar Novamente
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {transporterData.pdf === "unlocking" && (
                      <div className="col-md-3">
                        <button
                          type="button"
                          disabled
                          className="btn btn-success btn-upper btn-sm"
                        >
                          <i class="fas fa-file-pdf"></i> Gerando PDF...
                        </button>
                      </div>
                    )}
                    {transporterData.pdf === "completed" && (
                      <div style={{ display: "flex" }} className="col-md-6">
                        <a
                          href={`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/generate-pdf-cte/pdf/download/${open.transporter}`}
                          target="blank"
                          className="btn btn-success btn-upper btn-sm"
                        >
                          <i class="fas fa-file-pdf"></i> Baixar PDF's
                        </a>
                        <button
                          type="button"
                          className="btn btn-warning btn-upper btn-sm ml-2"
                          onClick={() => handleSubmitPdf()}
                          disabled={loadingPdf}
                        >
                          Gerar Novamente
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
              {approve && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <button
                      type="button"
                      className="btn btn-primary btn-upper btn-sm mr-2"
                      disabled={selected.length === 0}
                      onClick={handleSubmit}
                    >
                      Enviar para Análise
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-upper btn-sm"
                      onClick={() => {
                        setApprove(false);
                        setSelected([]);
                      }}
                    >
                      Cancelar
                    </button>
                  </div>
                  {selected.length > 0 ? (
                    <button
                      type="button"
                      className="btn btn-light btn-sm btn-upper"
                      onClick={() => {
                        setSelected([]);
                      }}
                    >
                      <span
                        className={"fa fa-check mr-4"}
                        style={{
                          width: "18px",
                          height: "18px",
                          border: "0",
                          background: "#dee2ea",
                          display: "inline-block",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></span>
                      Esvaziar Seleção
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-light btn-sm btn-upper"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        const list = [];
                        ctes.forEach((item, index) => {
                          if (
                            item.status !== "confirmado" &&
                            item.status !== "rejeitado"
                          ) {
                            list.push({ ...item, index });
                          }
                        });
                        setSelected(list);
                      }}
                    >
                      <span
                        className={"mr-4"}
                        style={{
                          width: "18px",
                          height: "18px",
                          border: "0",
                          background: "#dee2ea",
                          display: "inline-block",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      ></span>
                      Selecionar Tudo
                    </button>
                  )}
                </div>
              )}
              <div className="row mt-4">
                {loading ? (
                  <>
                    <CircularProgress /> Buscando
                  </>
                ) : (
                  <>
                    {ctes.length > 0 ? (
                      ctes.map((item, index) => {
                        if (approve) {
                          const select = selected.find(
                            (i) => i.index === index
                          );
                          return (
                            <SelectTransporterItem
                              key={index}
                              index={index}
                              item={item}
                              select={select}
                              classes={classes}
                              handleSelectApprove={(e) =>
                                handleSelectApprove(e)
                              }
                            />
                          );
                        } else {
                          const expanded = detail === index;
                          return (
                            <ListTransporterItem
                              key={index}
                              index={index}
                              item={item}
                              expanded={expanded}
                              classes={classes}
                              setDetail={(e) => setDetail(e)}
                            />
                          );
                        }
                      })
                    ) : (
                      <h6>Nenhuma CTe encontrada...</h6>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options"
                onClick={() => {
                  setOpen({ active: false, transporter: "" });
                  setCtes([]);
                  setSelected([]);
                }}
              >
                Fechar
              </button>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
