import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";

export default function SalesBarChart({ title, desc, info }) {
  const ref = useRef();

  const data = useMemo(
    () => ({
      labels: info.length > 0 ? info.map(i => {
        return `Dia ${i.dia}`;
      }) : [],

      datasets: [
        {
          label: 'A Confirmar',
          backgroundColor: "#5867dd",
          data: info.length > 0 ? info.map(i => {
            return i.aConfirmar;
          }) : []
        },
        {
          label: 'Rejeitada',
          backgroundColor: "#fd397a",
          data: info.length > 0 ? info.map(i => {
            return i.rejeitadas;
          }) : []
        },
        {
          label: 'Não Confirmada',
          backgroundColor: "#ffb822",
          data: info.length > 0 ? info.map(i => {
            return i.naoConfirmadas;
          }) : []
        },
        {
          label: 'Não Encontradas',
          backgroundColor: "#ea7c01",
          data: info.length > 0 ? info.map(i => {
            return i.naoEncontradas;
          }) : []
        },
        {
          label: 'Confirmada',
          backgroundColor: "#1dc9b7",
          data: info.length > 0 ? info.map(i => {
            return i.confirmadas;
          }) : []
        },
      ]
    }),
    [info]
  );

  useEffect(() => {
    const chart = new Chart(ref.current, {
      data,
      type: "bar",
      options: {
        title: { display: false },
        tooltips: {
          intersect: true,
          mode: "nearest",
          xPadding: 10,
          yPadding: 10,
          caretPadding: 10
        },
        legend: { display: true },
        responsive: true,
        maintainAspectRatio: false,
        barRadius: 4,
        scales: {
          xAxes: [{ display: true, gridLines: { display: false }, stacked: true }],
          yAxes: [{ display: true, stacked: true, gridLines: { offsetGridLines: true } }]
        },
        layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } }
      }
    });

    return () => {
      chart.destroy();
    };
  }, [data]);

  return (
    <div className="kt-portlet kt-portlet--height-fluid">
      <div className="kt-portlet__head">
        <div className="kt-portlet__head-label">
          <h3 className="kt-portlet__head-title">{title}</h3>
          <span className="kt-widget14__desc"></span>
        </div>
      </div>
      <div className="kt-portlet__body" style={{ padding: '10px' }}>
        <canvas ref={ref} style={{ minHeight: '330px' }} />
      </div>
    </div>
  );
}
