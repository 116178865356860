import React, { memo, useState } from 'react';

import axios from 'axios';
import { Modal, Backdrop, makeStyles, TextField } from '@material-ui/core';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

function CreateOpinion({
  open,
  setOpen,
  onReload,
  id
}) {
  const [value, setValue] = useState('');
  const useStyles = makeStyles(theme => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: "700px",
      height: "40%",
      overflow: "auto"
    },
  }));
  const classes = useStyles();

  async function handleSubmit() {
    try {
      await axios.post(`opinions/${id}`, { message: value });
      toast.success('Parecer cadastrado');
      setOpen(false);
      setValue('');
      onReload();
    } catch(err) {
      toast.error('Ocorreu um erro... tente novamente');
    }
  }

  return (
    <Modal
      className={classes.modalsm}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fadesm}>
        <Row style={{ width: '100%' }}>
        <Col lg={12}>
          <h4>Adicionar Parecer</h4>
          <TextField
            multiline
            label="Mensagem"
            value={value}
            onChange={e => setValue(e.target.value)}
            style={{ width: '100%' }}
            rows={3}
          />
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-outline-success mr-3"
              onClick={() => handleSubmit()}
              disabled={value.length === 0}
            >
              Cadastrar
                </button>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => { setOpen(false); setValue('')}}
            >
              Cancelar
            </button>
          </div>
        </Col>
        </Row>
      </div>
    </Modal>
  );
}

export default memo(CreateOpinion);