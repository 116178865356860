import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { toast } from "react-toastify";
import SelectTransporterByCnpj from "../components/SelectTransporterByCnpj";

import axios from "axios";

export default function ConfirmationByCnpj({ open, setOpen, onReload }) {
  const [borderos, setBorderos] = useState([]);
  const [ctesLength, setCtesLength] = useState(0);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open.cnpj && open.active) {
      async function loadBordero() {
        setLoading(true);
        try {
          const response = await axios.get(
            `${
              process.env.REACT_APP_API_URL
            }/borderos-cte-cnpj/${open.cnpj.replace(/\D/g, "")}`
          );
          if (response.status === 204) {
            toast.error("Borderô com CTe's inválidas");
            setOpen({ active: false, cnpj: "" });
            setBorderos([]);
            setSelected([]);
          } else {
            setBorderos(response.data.borderos);
            setSelected([]);
          }
        } catch (err) {
          setBorderos([]);
          setOpen({ active: false, cnpj: "" });
          toast.error("Nenhum Boderô CTe encontrado.");
        }
        setLoading(false);
      }
      loadBordero();
    }
  }, [open.cnpj]); // eslint-disable-line

  function handleSelectApprove(item) {
    const alreadyHave = selected.findIndex(
      (i) => i.bordero === item.bordero
    );
    if (alreadyHave === -1) {
      setSelected([...selected, { ...item, bordero: item.borderoData.bord_id }]);
    } else {
      let lock = selected;
      if (selected[alreadyHave].ctes.find((i) => i.cte === item.ctes[0].cte)) {
        setSelected([]);
        lock[alreadyHave].ctes.filter(
          (i) => i.cte !== item.ctes[0].cte
        );
        setSelected(lock);
      } else {
        setSelected([]);
        lock[alreadyHave].ctes.push({ ...item.ctes[0] });
        setSelected(lock);
      }
    }
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const { data } = await axios.get("auth");
      for (let i = 0; i < selected.length; i++) {
        await axios.post(
          `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/request-list-cte/list`,
          {
            bordero: selected[i].borderoData.bord_id,
            borderoData: {
              ufCedente: selected[i].borderoData.uf_cedente,
              cedente: selected[i].borderoData.cedente,
              cnpj: selected[i].borderoData.cnpj,
            },
            ctes: selected[i].ctes,
            user: data.id,
          }
        );
      }
      setOpen({ active: false, cnpj: "" });
      toast.info(
        "Notas foram enviadas para análise. Notificaremos quando todas forem analisadas"
      );
      setBorderos([]);
      onReload();
      setSelected([]);
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar realizar análise");
    }
    setLoading(false);
  }

  useEffect(() => {
    if(selected.length > 0) {
      let count = 0;
      selected.forEach(i => count += i.ctes.length);
      setCtesLength(count);
    }else{
      setCtesLength(0);
    }
  }, [selected]);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "90%",
      overflow: "auto",
    },
    box: {
      flex: "1",
      border: "1px solid #eee",
      background: "#f9f9f9",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.active}
      onClose={() => {
        setOpen({ active: false, cnpj: "" });
        setSelected([]);
        setBorderos([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open.active}>
        <div className={classes.fade}>
          <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Selecionar Borderôs</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen({ active: false, bordero: "" });
                    setBorderos([]);
                    setSelected([]);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <h3
              className="mt-4"
              style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
              }}
            >
              Cedente: {borderos.length > 0 && borderos[0].borderoData.cedente}
            </h3>
            <div className={classes.box}>
              <span>CNPJ: {open.cnpj}</span>
              <span>UF: {borderos.length > 0 && borderos[0].borderoData.uf_cedente}</span>
            </div>
            <div>
              <h4 className="mt-4">Borderôs CTe</h4>
              <div className="mt-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="button"
                    disabled={!selected.length > 0 || loading}
                    className="btn btn-primary btn-sm btn-upper mr-2"
                    onClick={handleSubmit}
                  >
                    Enviar para análise
                  </button>
                  <div>
                  {selected.length > 0 && (
                    <strong>
                    {selected.length} Borderô(s) Selecionado(s)<br />
                    {ctesLength} CTe(s) no Total
                    </strong>
                  )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                {loading ? (
                  <>
                    <CircularProgress /> Buscando
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        padding: "10px 20px",
                        border: "1px solid #eee",
                      }}
                      className="col-md-12 mb-4 bg-primary text-white"
                    >
                      <h5>Borderô</h5>
                      <h5>N° de Notas</h5>
                      {borderos.length > 0 &&
                            selected.length === borderos.length ? (
                              <button
                                type="button"
                                className="btn btn-sm btn-upper"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "#fff"
                                }}
                                onClick={() => {
                                  setSelected([]);
                                }}
                              >
                                Esvaziar Seleção
                                <span
                                  className={"fa fa-check mr-4"}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    border: "0",
                                    background: "#dee2ea",
                                    color: '#222',
                                    display: "inline-block",
                                    borderRadius: "5px",
                                    marginLeft: "10px",
                                  }}
                                ></span>
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-sm btn-upper"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  color: "#fff"
                                }}
                                onClick={() => {
                                  const s = [];
                                  borderos.forEach(i => {
                                      s.push({ ...i, bordero: i.borderoData.bord_id });
                                  });
                                  setSelected(s);
                                }}
                              >
                                Selecionar Todos
                                <span
                                  className={"mr-4"}
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    border: "0",
                                    background: "#dee2ea",
                                    display: "inline-block",
                                    borderRadius: "5px",
                                    marginLeft: "10px",
                                  }}
                                ></span>
                              </button>
                            )}
                            <h5>Status</h5>
                            <div style={{ width: '33%' }}></div>
                            <div style={{ width: '47.5px', height: '39.06px' }}></div>
                    </div>
                    {borderos.length > 0 ? (
                      borderos.map((item, index) => {
                        return (
                          <SelectTransporterByCnpj
                            classes={classes}
                            item={item}
                            key={index}
                            index={index}
                            handleSelectApprove={(e) => handleSelectApprove(e)}
                            selected={selected}
                            setSelected={(e) => setSelected(e)}
                          />
                        );
                      })
                    ) : (
                      <h6>Nenhum Borderô encontrado...</h6>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options"
                onClick={() => {
                  setOpen({ active: false, bordero: "" });
                  setBorderos([]);
                  setSelected([]);
                }}
              >
                Fechar
              </button>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
