function validarCPF(strCPF) {
  var soma;
  var resto;
  soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(9, 10))) return false;

  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;

  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}

function emailIsValid(email) {
  if (email.indexOf("@") === -1) {
    return false;
  }
  if (email.indexOf(".") === -1) {
    return false;
  }
  return true;
}

function dateIsValid(value) {
  if (value.length === 10) {
    var date = value.split("/");

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    var _date = `${date[2]}${date[1]}${date[0]}`;
    today = `${yyyy}${mm}${dd}`;
    if (today < _date) {
      return false;
    }

    if (Number(date[0]) > 31) {
      return false;
    }

    if (Number(date[0] > 29 && Number(date[1]) === 2)) {
      return false;
    }

    if (Number(date[1]) > 12) {
      return false;
    }

    if (
      !(
        (Number(date[2]) % 4 === 0 && Number(date[2]) % 100 !== 0) ||
        Number(date[2]) % 400 === 0
      ) &&
      Number(date[1]) === 2
    ) {
      return date[0] <= 28;
    }

    if (
      Number(date[1]) === 1 ||
      Number(date[1]) === 3 ||
      Number(date[1]) === 5 ||
      Number(date[1]) === 7 ||
      Number(date[1]) === 8 ||
      Number(date[1]) === 10 ||
      Number(date[1]) === 12
    ) {
      return date[0] <= 31;
    } else {
      return date[0] <= 30;
    }
  } else {
    return false;
  }
}

const validate = (values) => {
  const errors = {};

  if (!values.fullname) {
    errors.fullname = "PREENCHA ESSE CAMPO.";
  }
  if (!values.type) {
    errors.type = "PREENCHA ESSE CAMPO.";
  } 

  if (values.cpf.length === 14) {
    if (!values.cpf) {
      errors.cpf = "PREENCHA ESSE CAMPO.";
    } else {
      if (values.cpf.length <= 14) {
        if (!validarCPF(values.cpf.replace(/\D/g, ""))) {
          errors.cpf = "PREENCHA UM CPF VÁLIDO.";
        }
      } else {
        errors.cpf = "PREENCHA ESSE CAMPO.";
      }
    }

    if (!values.nationality) {
      errors.nationality = "PREENCHA ESSE CAMPO.";
    }
    if (!values.profession) {
      errors.profession = "PREENCHA ESSE CAMPO.";
    }
    if (!values.mother_name) {
      errors.mother_name = "PREENCHA ESSE CAMPO.";
    }

    if (!values.zipcode) {
      errors.zipcode = "PREENCHA ESSE CAMPO.";
    } else if (values.zipcode.length < 9) {
      errors.zipcode = "PREENCHA ESSE CAMPO.";
    }
    if (!values.address) {
      errors.address = "PREENCHA ESSE CAMPO.";
    }
    if (!values.number) {
      errors.number = "PREENCHA ESSE CAMPO.";
    }
    if (!values.neighborhood) {
      errors.neighborhood = "PREENCHA ESSE CAMPO.";
    }
    if (!values.city) {
      errors.city = "PREENCHA ESSE CAMPO.";
    }

    if (!values.identity_doc) {
      errors.identity_doc = "PREENCHA ESSE CAMPO.";
    }
    if (!values.issuing_agency) {
      errors.issuing_agency = "PREENCHA ESSE CAMPO.";
    }

    if (!values.issuing_agency_uf) {
      errors.issuing_agency_uf = "PREENCHA ESSE CAMPO.";
    }

    if (!values.marital_status) {
      errors.marital_status = "PREENCHA ESSE CAMPO.";
    } else if (values.marital_status === "Casado(a)") {
      if (!values.wedding_registry) {
        errors.wedding_registry = "PREENCHA ESSE CAMPO.";
      }
      if (values.wedding_registry !== 'Separação Total de Bens') {
        errors.memberSpouses = {};
      if (
        values.memberSpouses.identity_uf === "" ||
        values.memberSpouses.identity_uf === null
      ) {
        errors.memberSpouses.identity_uf = "PREENCHA ESSE CAMPO.";
      }
      if (
        values.memberSpouses.fullname === "" ||
        values.memberSpouses.fullname === null
      ) {
        errors.memberSpouses.fullname = "PREENCHA ESSE CAMPO.";
      } else if (values.memberSpouses.fullname === values.fullname) {
        errors.memberSpouses.fullname = "PREENCHA UM CÔNJUGE VÁLIDO";
      }
      if (
        values.memberSpouses.date_of_birth === "" ||
        values.memberSpouses.date_of_birth === null
      ) {
        errors.memberSpouses.date_of_birth = "PREENCHA ESSE CAMPO.";
      } else if (values.memberSpouses.date_of_birth.length < 10) {
        errors.memberSpouses.date_of_birth = "PREENCHA ESSE CAMPO.";
      } else if (!dateIsValid(values.memberSpouses.date_of_birth)) {
        errors.memberSpouses.date_of_birth = "PREENCHA UMA DATA VÁLIDA.";
      }
      if (
        values.memberSpouses.email === "" ||
        values.memberSpouses.email === null
      ) {
        errors.memberSpouses.email = "PREENCHA ESSE CAMPO.";
      } else if (!emailIsValid(values.memberSpouses.email)) {
        errors.memberSpouses.email = "PREENCHA UM EMAIL VÁLIDO.";
      }

      if (
        values.memberSpouses.cpf === "" ||
        values.memberSpouses.cpf === null
      ) {
        errors.memberSpouses.cpf = "PREENCHA ESSE CAMPO.";
      } else {
        if (values.memberSpouses.cpf.length === 14) {
          if (!validarCPF(values.memberSpouses.cpf.replace(/\D/g, ""))) {
            errors.memberSpouses.cpf = "PREENCHA UM CPF VÁLIDO.";
          }
        } else {
          errors.memberSpouses.cpf = "PREENCHA ESSE CAMPO.";
        }
      }
      if (
        values.memberSpouses.identity === "" ||
        values.memberSpouses.identity === null
      ) {
        errors.memberSpouses.identity = "PREENCHA ESSE CAMPO.";
      }
      if (
        values.memberSpouses.identity_oe === "" ||
        values.memberSpouses.identity_oe === null
      ) {
        errors.memberSpouses.identity_oe = "PREENCHA ESSE CAMPO.";
      }

      if (
        values.memberSpouses.profession === "" ||
        values.memberSpouses.profession === null
      ) {
        errors.memberSpouses.profession = "PREENCHA ESSE CAMPO.";
      }

      if (Object.keys(errors.memberSpouses).length === 0) {
        delete errors.memberSpouses;
      }
      }
    } else {
      if (!values.in_stable_union) {
        errors.in_stable_union = "PREENCHA ESSE CAMPO.";
      }
    }

    if (!values.in_politically_exposed_person) {
      errors.in_politically_exposed_person = "PREENCHA ESSE CAMPO.";
    }
  }

  if (!values.email) {
    errors.email = "PREENCHA ESSE CAMPO.";
  } else if (!emailIsValid(values.email)) {
    errors.email = "PREENCHA UM EMAIL VÁLIDO.";
  }

  if (!values.phone) {
    errors.phone = "PREENCHA ESSE CAMPO.";
  } else if (values.phone.length < 14) {
    errors.phone = "PREENCHA ESSE CAMPO.";
  }

  return errors;
};

export default validate;
