import React from "react";
import { Route, Switch } from "react-router-dom";
import RegisterIncompleteIndex from "./RegisterIncompleteIndex";

export default function RegisterIncompletePage() {
  return (
    <Switch>
      <Route exact path="/register_incompletes/" component={RegisterIncompleteIndex} />
    </Switch>
  );
}
