import React, { memo } from "react";
import CancelButton from "../CancelButton";

function ExemptSelected(props) {
  return (
    <div>
      <button
        className="btn btn-info mr-3"
        onClick={() => props.setConfirmExemptedDocument(true)}
      >
        Isentar documentos
      </button>

      <CancelButton
        setSelectApprove={props.setSelectApprove}
        setSelectExempt={props.setSelectExempt}
        setSelectReprove={props.setSelectReprove}
      />
    </div>
  );
}

export default memo(ExemptSelected);
