import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { Alert } from 'react-bootstrap';
import clsx from "clsx";
import { injectIntl } from "react-intl";
import * as auth from "../../../store/ducks/auth.duck";
import { FormControl, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';

function ProfileEdit(props) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [error, setError] = useState({
    button: true,
    error: false,
    message: [],
  });

  useEffect(() => {
    if(values.fullname === "" || values.username === "" || values.email === "") {
      setError({
        button: true,
        error: true,
        message: [],
      });
    }else{
      setError({
        button: false,
        error: false,
        message: [],
      });
    }
  }, [values.email, values.fullname, values.username]);

  useEffect(() => {
    async function loadUser() {
      const response = await axios.get(`/auth`);
      setValues(response.data);
    }
    loadUser();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.put(`/users/profile/${values.id}`, values);
      if (response.status === 200){
        setLoading(false);
        props.updateUser(response.data);
        toast.success('Perfil atualizado com sucesso');
      }else{
        setLoading(false);
        setError({
          error: true,
          button: false,
          message: response.data,
        });
      }
    } catch(err) {
      console.log(err);
      setLoading(false);
      setError({
        error: true,
        button: false,
        message: 'Erro, tente novamente',
      });
    }
  }

  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Meu Perfil
            </h3>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
      <FormControl style={{ width: '100%', backgroundColor: '#fff', padding: '20px', borderRadius: '6px'}}>
        {error.message.length !== 0 && (
        <Alert variant="danger">
          <ul>
            {error.message.map(er => (
              <li key={er.message}>
                  {er.message}
              </li>
              ))}
          </ul>
        </Alert>
        )}
        <TextField 
          margin="normal"
          variant="outlined"
          label="Nome Completo"
          type="text"
          value={values.fullname || ''}
          onChange={e => setValues({...values, fullname: e.target.value})}
          required
        />
        <TextField 
          margin="normal"
          variant="outlined"
          label="Username"
          type="text"
          value={values.username || ''}
          onChange={e => setValues({...values, username: e.target.value})}
          required
        />
        <TextField 
          margin="normal"
          variant="outlined"
          label="Email"
          type="email"
          value={values.email || ''}
          onChange={e => setValues({...values, email: e.target.value})}
          required
        />
        <button
          type="submit"
          style={{ marginTop: 20 }}
          disabled={error.button || loading}
          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading})}`} // eslint-disable-line
          >Atualizar</button>
      </FormControl>
      </form>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(ProfileEdit)
);
