import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Paper,
  LinearProgress,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { format, isBefore, subDays } from 'date-fns';
import { normalizeCnpj } from "./components/utils";

import { toast } from 'react-toastify';

import ModalFilter from './components/ModalFilter';
import ModalCreate from './components/ModalCreate';
import ModalEdit from './components/ModalEdit';

export default function ECnpjIndex() {
  const [openEdit, setOpenEdit] = useState(false);
  const [editItem, setEditItem] = useState({});
  const [openNew, setOpenNew] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [filter, setFilter] = useState(false);
  const [hasEcnpj, setHasEcnpj] = useState('none');
  const history = useHistory();
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '500px'
    },
    close: {
      background: '#fff',
      fontSize: '20px',
      border: 0,
      textAlign: 'right',
      width: '100%',
    },
    wrap: {
      display: 'flex',
      justifyContent: 'space-beetwen',
      alignItems: 'center',
    }
  }));
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  
  function ecnpjStatus(row) {
    if(row.ecnpj.documents.length > 0) {
      if(isBefore(new Date(row.ecnpj.documents[row.ecnpj.documents.length - 1].expires_in), new Date())) {
        return 'Expirado';
      }else if (isBefore(subDays(new Date(row.ecnpj.documents[row.ecnpj.documents.length - 1].expires_in), 15), new Date())) {
        return 'Perto de Expirar';
      }
      return 'Instalado';
    }
    return 'Não instalado';
  }

  async function loadECnpj() {
    setLoading(true);
    const response = await axios.get(`/e-cnpj`);
    setLoading(false);
    setRows(response.data.map(ecnpj => {
      return {
        ...ecnpj,
        status: ecnpjStatus(ecnpj),
      }
    }));
  }

  async function searchFilter({ by, value }) {
    let response = [];
    if(by === 0) {
      const { data } = await axios.get(`/e-cnpj-filter?cnpj=${value.replace(/[^\d]/g, "")}`);
      response = data;
    }
    if(by === 2) {
      const { data } = await axios.get(`/e-cnpj-filter?email=${value}`);
      response = data;
    }
    if(response.length === 0) {
      toast.info('Nenhum cadastro encontrado');
    }
    setRows(response);
    setHasEcnpj(null);
    setFilter(true);
  }

  useEffect(() => {
    try {
      loadECnpj();
    }catch(err) {
      history.goBack();
    }
  }, []); // eslint-disable-line

  async function handleHasEcnpj(value) {
    setLoading(true);
    const response = await axios.get(`/e-cnpj`);
    const items = response.data.map(i => {
      return {
        ...i,
        status: ecnpjStatus(i),
      }
    })
    if(value === 'none') {
      setRows(items);
    }else if(value === 'has-ecnpj') {
      setRows(items.filter(i => i.status === 'Instalado'));
    }else if(value === 'no-ecnpj') {
      setRows(items.filter(i => i.status === 'Não instalado'));
    }else if (value === 'expired') {
      setRows(items.filter(i => i.status === 'Expirado'));
    }else if (value === 'close-to-expire') {
      setRows(items.filter(i => i.status === 'Perto de Expirar'));
    }
    setPage(0);
    setHasEcnpj(value);
    setLoading(false);
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
  const headRows = [
    { id: 'cnpj', numeric: false, disablePadding: true, label: 'CNPJ' },
    { id: 'email', numeric: false, disablePadding: true, label: 'Email' },
    { id: 'e_cnpj', numeric: false, disablePadding: false, label: 'e-CNPJ' },
    { id: 'date', numeric: true, disablePadding: false, label: 'Data de criação' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  function handleView(item) {
    setEditItem(item);
    setOpenEdit(true);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              e-CNPJ
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/e-cnpj`}>
                e-CNPJ
              </Link>
            </div>
          </div>
          <div className="kt-subheader__toolbar">
            <div className="bg-white mr-2" style={{ padding: '10px', borderRadius: '4px' }}>
              <FormControl style={{ minWidth: '150px' }} variant="outlined">
                <InputLabel>e-CNPJ instalado</InputLabel>
                <Select value={hasEcnpj} onChange={(e) => {
                  handleHasEcnpj(e.target.value);
                }}>
                  <MenuItem value={'none'}>Sem Filtro</MenuItem>
                  <MenuItem value={'has-ecnpj'}>Instalado</MenuItem>
                  <MenuItem value={'no-ecnpj'}>Não Instalado</MenuItem>
                  <MenuItem value={'close-to-expire'}>Perto de Expirar</MenuItem>
                  <MenuItem value={'expired'}>Expirados</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="d-flex flex-column kt-subheader__wrapper">
              <button type="button" className="btn btn-danger btn-sm btn-upper" onClick={() => {
                setOpenNew(true);
              }}>
                Novo Cadastro
              </button>
              {!filter && (
                <button
                type="button"
                onClick={() => setOpenSearch(true)}
                className="btn btn-light d-flex justify-content-between"
                style={{ width: '100%' }}
              >
                Buscar{" "}
                <i className="fa fa-search ml-4"></i>
              </button>
              )}
              {filter && (
                <button
                  type="button"
                  onClick={() => { 
                    setFilter(false);
                    loadECnpj();
                  }}
                  className="btn btn-warning"
                  style={{ width: '100%' }}
                >
                  Remover Filtro{" "}
                  <i className="fas fa-times" style={{ marginLeft: "10px" }}></i>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalFilter open={openSearch} setOpen={e => setOpenSearch(e)} getConsult={e => searchFilter(e)}/>
      <ModalEdit open={openEdit} setOpen={e => {
        setOpenEdit(e);
        setEditItem({});
      }} item={editItem} />
      <ModalCreate open={openNew} setOpen={e => setOpenNew(e)} openEcnpj={(e) => handleView(rows.find(i => i.ecnpj.cnpj === e))}/>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
        {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                      style={{ backgroundColor: row.status === 'Perto de Expirar' && '#fdee39' }}
                    >
                      <TableCell align="left">{normalizeCnpj(row.ecnpj.cnpj)}</TableCell>
                      <TableCell align="left">{row.ecnpj.email}</TableCell>
                      <TableCell align="left">
                        {row.status}
                        {row.status === 'Expirado' && (<i className="fas fa-times-circle"></i>)}
                        {row.status === 'Perto de Expirar' && (<i className="fas fa-times-circle"></i>)}
                        {row.status === 'Instalado' && (<i className="fas fa-check-circle"></i>)}
                        {row.status === 'Não instalado' && (<i className="fas fa-times-circle"></i>)}
                      </TableCell>
                      <TableCell align="right">{format(new Date(row.ecnpj.created_at), 'dd/MM/yyyy')}</TableCell>
                      <TableCell align="right">
                        <button
                          type="button"
                          className="btn btn-sm pl-2 pr-2"
                          onClick={() => handleView(row)}>
                          <VisibilityIcon />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
