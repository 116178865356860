function dateIsValid(value) {
  if (value.length === 10) {
    var date = value.split("/");

    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    var _date = `${date[2]}${date[1]}${date[0]}`;
    today = `${yyyy}${mm}${dd}`;
    if (today < _date) {
      return false;
    }

    if (Number(date[0]) > 31) {
      return false;
    }

    if (Number(date[0] > 29 && Number(date[1]) === 2)) {
      return false;
    }

    if (Number(date[1]) > 12) {
      return false;
    }

    if (
      !(
        (Number(date[2]) % 4 === 0 && Number(date[2]) % 100 !== 0) ||
        Number(date[2]) % 400 === 0
      ) &&
      Number(date[1]) === 2
    ) {
      return date[0] <= 28;
    }

    if (
      Number(date[1]) === 1 ||
      Number(date[1]) === 3 ||
      Number(date[1]) === 5 ||
      Number(date[1]) === 7 ||
      Number(date[1]) === 8 ||
      Number(date[1]) === 10 ||
      Number(date[1]) === 12
    ) {
      return date[0] <= 31;
    } else {
      return date[0] <= 30;
    }
  } else {
    return false;
  }
}

const validation = (values) => {
  let obj = {};
  Object.keys(values).map((item) => {
    if (typeof values[item] === "string") {
      if (
        (values[item] === "" || values[item] === null) &&
        item !== "insc_estadual" &&
        item !== "insc_municipal" &&
        item !== "complemento"
      ) {
        return (obj = { ...obj, [item]: "PREENCHA ESSE CAMPO" });
      } else if (item === "fone" && values[item].length < 14) {
        return (obj = { ...obj, [item]: "PREENCHA ESSE CAMPO" });
      } else if (item === "telefone_da_empresa" && values[item].length < 14) {
        return (obj = { ...obj, [item]: "PREENCHA ESSE CAMPO" });
      } else if (
        item === "email_empresa" &&
        (values[item].indexOf("@") === -1 || values[item].indexOf(".") === -1)
      ) {
        return (obj = { ...obj, [item]: "PREENCHA UM EMAIL VÁLIDO" });
      } else if (
        item === "data_fundacao" &&
        (values[item].length < 10 || !dateIsValid(values[item]))
      ) {
        return (obj = { ...obj, [item]: "PREENCHA UMA DATA VÁLIDA" });
      } else if (item === "cep" && values[item].length < 9) {
        return (obj = { ...obj, [item]: "PREENCHA ESSE CAMPO" });
      } else if (
        item === "email" &&
        (values[item].indexOf("@") === -1 || values[item].indexOf(".") === -1)
      ) {
        return (obj = { ...obj, [item]: "PREENCHA UM EMAIL VÁLIDO" });
      } else if (item === "capital_social" && values[item] === "R$ 0,00") {
        return (obj = { ...obj, [item]: "PREENCHA ALGUM VALOR" });
      } else if (item === "faturamento_mensal" && values[item] === "R$ 0,00") {
        return (obj = { ...obj, [item]: "PREENCHA ALGUM VALOR" });
      } else {
        return obj;
      }
    } else {
      return obj;
    }
  });
  return obj;
};

export default validation;
