import React, { memo, useState } from 'react';
import ModalPendencie from './Modais/ModalPendencie';
import { format, parseISO } from "date-fns";

function Pendencies({ pendencies, onClickDocument }) {
  const [pendencie, setPendencie] = useState({ open: false, pendencie: {} });
  function callBackPendencie(obj) {
    setPendencie({ open: true, pendencie: obj })
  }
  function iconPendencie(type) {
    switch (type) {
      case "company":
        return (
          <i className="flaticon2-list-3" style={{ color: "#48c0e8" }}></i>
        );
      case "member":
        return (
          <i className="flaticon-profile-1" style={{ color: "#93d952" }}></i>
        );
      case "document":
        return (
          <i className="flaticon2-document" style={{ color: "#e0da22" }}></i>
        );
      default:
        break;
    }
  }

  return (
    <>
    <ModalPendencie 
      pendencie={pendencie}
      setPendencie={e => setPendencie(e)}
    />
    <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">Pendências</h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div
                className="kt-notification"
                style={{ height: "340px", overflow: "auto" }}
              >
                {pendencies && pendencies.length > 0 && pendencies.find(item => item.origin !== `update`) ?
                  pendencies.map(pendencia => {
                    if(pendencia.origin !== 'update') {
                      return (
                        <div
                      className="kt-notification__item"
                      key={pendencia.id}
                      onClick={() => {
                        if(pendencia.type !== 'document') {
                          callBackPendencie({
                            ...pendencia,
                            data_json: JSON.parse(pendencia.data),
                            data_send_json: JSON.parse(pendencia.data_send),
                          });
                        } else {
                          onClickDocument();
                        }
                      }}
                      style={{ 
                        cursor: 'pointer',
                        background: pendencia.data_send ? 'rgba(0, 174, 255, 0.1)' : '#fff',
                       }}
                    >
                      <div className="kt-notification__item-icon">
                        {iconPendencie(pendencia.type)}
                      </div>
                      <div className="kt-notification__item-details">
                        <div className="kt-notification__item-title">
                          {pendencia.title}
                        </div>
                        <div className="kt-notification__item-time">
                          {format(parseISO(pendencia.created_at), "dd'/'MM'/'yyyy 'às' HH':'mm")}
                        </div>
                      </div>
                    </div>
                      );
                    }else{
                      return ``;
                    }
                  }) : <h4>Sem pendências...</h4>}
              </div>
            </div>
          </div>
        </>
  );
}

export default memo(Pendencies);
