import React, { memo, useState, useEffect } from "react";
import { Paper, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { toast } from "react-toastify";
import impressOpinion from "../../utils/impressOpinion";
import ConfirmAprove from "./ConfirmAprove";
import CreateOpinion from "./CreateOpinion";
import socketio from "socket.io-client";
import verifyProfiles from "../../../utils/verifyProfiles";

function OpinionContainer(props) {
  const [create, setCreate] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [opinions, setOpinions] = useState([]);
  const [approved, setApproved] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [loading, setLoading] = useState(false);

  async function loadOpinions() {
    const response = await axios.get(`/opinions/${props.register_id}`);

    setOpinions(response.data.opinions);
    setApproved(response.data.approved);
    setDirectors(response.data.directors);
  }

  async function handleApprove() {
    try {
      await axios.post(`/opinions/approve/${props.register_id}`);
      toast.success('Parecer aprovado');
      loadOpinions();
      setConfirm(false);
    } catch (err) {
      toast.error('Ocorreu um erro... tente novamente');
    }
  }

  useEffect(() => {
    const socket = socketio(process.env.REACT_APP_API_BFC_DIGITAL_URL);
    socket.on('msg', type => {
      if(type == props.register_id) { // eslint-disable-line
        loadOpinions();
      }
    })
  }, [props.register_id]); // eslint-disable-line

  useEffect(() => {
    setLoading(true);
    loadOpinions();
    setLoading(false);
  }, []); // eslint-disable-line

  return (
    <Paper className={props.classes.paper}>
      <ConfirmAprove
        open={confirm}
        setOpen={e => setConfirm(e)}
        onSubmit={() => handleApprove()}
      />
      <CreateOpinion
        open={create}
        setOpen={(e) => setCreate(e)}
        id={props.register_id}
        onReload={() => loadOpinions()}
      />
      <h2>Pareceres</h2>
      <hr />
      {opinions.length > 0 ? (
        opinions.map((opinion) => (
          <div className="opinion_master_container" key={opinion.id}>
            <div className="opinion_master">
              <div className="opinion_master_wrap">
                <span
                  className={`kt-media kt-media--sm kt-media--${opinion.user.id === props.user.id ? 'warning' : 'dark'}`}>
                  <span style={{ width: '40px', height: '40px' }}><i className="fas fa-user" style={{ fontSize: '24px' }}></i></span>
                </span>
                <div style={{ color: verifyProfiles(opinion.user, 'director') && '#366cf3' }}>
                  {opinion.user.fullname}<br />
                  <small>
                    {verifyProfiles(opinion.user, 'admin') && 'Administrador'}
                    {verifyProfiles(opinion.user, 'director') && 'Diretor'}
                    {verifyProfiles(opinion.user, 'manager') && 'Gerente'}
                    {verifyProfiles(opinion.user, 'analist') && 'Analista'}
                  </small>
                </div>
              </div>
            </div>
            <div className="kt-widget19__text">
              {opinion.message}
            </div>
            <small className="opinion_date">
              {format(parseISO(opinion.created_at), "dd'/'MM'/'yyyy 'às' HH':'mm")}
            </small>
          </div>
        ))
      ) : (
          <>
            {loading ? <CircularProgress /> : <h4>Nenhum parecer cadastrado...</h4>}
          </>
        )}
      <button
        type="button"
        className="btn btn-secondary btn-sm btn-upper"
        onClick={() => setCreate(true)}
      >
        Adicionar Parecer
      </button>
      {opinions.length > 0 &&
        !approved.find(ap => ap.user.id === props.user.id) &&
        verifyProfiles(props.user, 'director') && (
          <button
            type="button"
            className="btn btn-success btn-sm btn-upper"
            style={{ margin: '5px' }}
            onClick={() => setConfirm(true)}
          >
            Aprovar
          </button>
        )}
      {opinions.length > 0 && (
        <>
          <hr />
          <h2>Aprovações</h2>
          <div className="row" style={{ padding: '20px' }}>
            {approved.length > 0 && directors.length > 0 ? directors.map(director => {
              const approve = approved.find(item => item.user_id === director.id);
              return (
                <div className={`col-md-4 approve_item ${approve && 'approved'}`} key={director.id}>
                  <div>
                  <h5>{director.fullname}</h5>
                    {approve ? (
                      <small className="opinion_date">
                        {format(parseISO(approve.created_at), "dd'/'MM'/'yyyy 'às' HH':'mm")}
                      </small>
                    ) : (
                      <small className="opinion_date">
                        Aguardando Aprovação
                      </small>
                    )}
                  </div>
                </div>
              )
            }
            ) : (
              <>
                {loading ? <CircularProgress /> : <h4>Nenhuma aprovação...</h4>}
              </>
              )}
          </div>
          {approved.length > 0 && (
            <button
              type="button"
              className="btn btn-brand btn-sm btn-upper"
              onClick={() => impressOpinion({ opinions, approved })}
            >
              Imprimir Parecer
            </button>
          )}
        </>
      )}
    </Paper>
  );
}

export default memo(OpinionContainer);
