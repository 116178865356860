import React, { memo, useState } from 'react';

import { Modal, Backdrop, makeStyles, Button } from '@material-ui/core';
import { format } from 'date-fns';
import ItemDetail from './ItemDetail';
import ItemSelect from './ItemSelect';

import axios from 'axios';

import { toast } from 'react-toastify';

import ModalConfirmation from './ModalConfirmation';

function ModalDetailCharge({ 
  open,
  setOpen,
  item,
  onResend,
 }) {
  const [selected, setSelected] = useState([]);
  const [selectActive, setSelectActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);

  function handleSelect(item) {
    if(selected.find(i => i === item.saca_id.replace(/[^\d]/g, ''))) {
      setSelected(selected.filter(i => i !== item.saca_id.replace(/[^\d]/g, '')));
    }else{
      setSelected([...selected, item.saca_id.replace(/[^\d]/g, '')]);
    }
  }

  const useStyles = makeStyles(theme => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      padding: "30px",
      overflow: "auto"
    },
  }));

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/charge-list/operation/sacado`, {
        bordero: item.bordero,
        sacados: selected,
      });
      toast.info('E-mails enviados para processamento');
      setSelectActive(false);
      setSelected([]);
    }catch(err) {
      if(err.response && err.response.status === 500) {
        toast.error('Ocorreu um erro em nossos servidores, contate a equipe técnica');
      }else{
        toast.error('Ocorreu um erro, tente novamente');
      }
    }
    setLoading(false);
  }

  function resetForm() {
    setOpen(false);
    setSelected([]);
    setSelectActive(false);
  }

  const classes = useStyles();

  return (
    <Modal
        className={classes.modalsm}
        open={open}
        onClose={resetForm}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.fadesm}>
          <div className="d-flex justify-content-between align-items-start">
            <h2 className="mb-4">Detalhamento do Borderô</h2>
            <button type="button" onClick={resetForm} className="btn">
              <i className="fas fa-times"></i>
            </button>
          </div>
          {open && (
            <>
            <h2 style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>Borderô: {item.bordero}</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <table className="table table-bordered">
                <thead>
                  <tr className="bg-secondary text-dark">
                    <td>Cedente</td>
                    <td>N° de Sacados</td>
                    <td>Data de Processamento</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.cedente} - {item.cedente_id}</td>
                    <td>{item.sacados.length}</td>
                    <td>
                      {format(
                        new Date(item.createdAt),
                        "dd/MM/yyyy 'às' HH:mm"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ModalConfirmation open={openConfirm} setOpen={e => setOpenConfirm(e)} onSubmit={() => handleSubmit()}/>
            <div className="d-flex mt-2 mb-2 justify-content-start">
            {!selectActive && (
              <Button type="button" color="primary" variant="contained" onClick={() => setSelectActive(true)}>
                Selecionar para reenvio
              </Button>
            )}
            {selectActive && (
              <>
                <Button type="button" color="primary" variant="contained" disabled={selected.length === 0 || loading} onClick={() => setOpenConfirm(true)}>
                  Reenviar
                </Button>
                <Button  className="ml-2" type="button" color="secondary" variant="contained" onClick={() => {
                  setSelectActive(false);
                  setSelected([]);
                }}>
                  Cancelar
                </Button>
              </>
            )}
            </div>
            <hr />
            {item.sacados && item.sacados.length > 0 ? (
              <div style={{ padding: '10px', display: 'flex', flexDirection: 'column' }}>
                {item.sacados.map((e, index) => {
                  const check = selected.find(i => i === e.saca_id.replace(/[^\d]/g, ''));
                  if(selectActive) {
                    return (
                      <ItemSelect e={e} index={index} key={index} handleSelect={(e) => handleSelect(e)} check={check}/>
                    )
                  }
                  return (
                    <ItemDetail e={e} index={index} key={index} onResend={onResend} />
                  )
                }
              )}
              </div>
            ) : (
              <h4>Nenhum email encontrado...</h4>
            )}
            </>
          )}
        </div>
      </Modal>
  );
}

export default memo(ModalDetailCharge);