import React from "react";
import { Route, Switch } from "react-router-dom";
import ChargeDetailIndex from "./ChargeDetailIndex";

export default function ChargeDetailPage() {
  return (
    <Switch>
      <Route exact path="/charge/detail" component={ChargeDetailIndex} />
    </Switch>
  );
}
