import React from "react";
import { Route, Switch } from "react-router-dom";
import ProfileEdit from "./ProfileEdit";
import ProfilePassword from './ProfilePassword';

export default function ProfilePage() {
  return (
    <Switch>
      <Route exact path="/profile/" component={ProfileEdit} />
      <Route exact path="/profile/password" component={ProfilePassword} />
    </Switch>
  );
}
