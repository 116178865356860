import React from "react";
import { Route, Switch } from "react-router-dom";
import NewsletterIndex from "./NewsletterIndex";

export default function NewsletterPage() {
  return (
    <Switch>
      <Route exact path="/newsletters/" component={NewsletterIndex} />
    </Switch>
  );
}
