import React, { memo, useState, useEffect } from "react";

import { Link as Ancor } from "react-scroll";
import axios from "axios";
import { Row, Col, Dropdown } from "react-bootstrap";
import { Paper, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import verifyProfiles from "../../utils/verifyProfiles";
import SelectContractExceptions from "./SelectContractExceptions";
import ModalReopenRegister from "./Modais/ModalReopenRegister";
import ModalConfirmationRefuse from "./Modais/ModalConfirmationRefuse";
import { useHistory } from "react-router-dom";
import {
  printPjDocument,
  bankDeclaration,
  documentPf,
  singleDeclaration,
} from "../RegisterContract";

import { normalizeCnpj, normalizePhone } from "../utils/normalize";

function HeaderRegister({
  register,
  onSubmitFacta,
  loading,
  setCurrent,
  percentage,
  onReload,
  user,
}) {
  const history = useHistory();
  const [openExceptionContract, setOpenExceptionContract] = useState(false);
  const [open, setOpen] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  async function reopenRegister(reason) {
    try {
      await axios.put(`/registers/reopen/${register.id}`, {
        reason,
      });
      onReload();
      toast.success("Cadastro reaberto");
    } catch (err) {
      toast.error("Ocorreu um erro ao reabrir cadastro, tente novamente");
    }
  }

  async function refuseRegister(reason) {
    try {
      await axios.put(`/register/refuse/${register.id}`, {
        reason
      });
      toast.info("Cadastro Indeferido");
      setOpenConfirm(false);
      history.push("/registers");
    } catch (err) {
      toast.error("Ocorreu um erro ao indeferir o cadastro");
    }
  }

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      verifyProfiles(user, "manager") ? setCurrent(3) : setCurrent(1);
    }
  }, [user]); // eslint-disable-line

  return (
    <>
      <SelectContractExceptions
        register={register && register}
        open={openExceptionContract}
        onClose={() => setOpenExceptionContract(false)}
      />
      <ModalReopenRegister
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={(e) => reopenRegister(e)}
      />
      <Row>
        <Col>
          <Paper className={classes.paper}>
            <div className="kt-widget kt-widget--user-profile-3">
              <div className="kt-widget__top">
                <div className="kt-widget__pic text-uppercase kt-widget__pic--brand kt-font-brand kt-font-boldest kt-hidden-">
                  {register.avatar}
                </div>
                <div className="kt-widget__content">
                  <div className="kt-widget__head">
                    <p className="align-items-center d-flex kt-widget__username">
                      {register.dados &&
                        register.dados.map(
                          (i) => i.name === "nome_fantasia" && i.value
                        )}{" "}
                      - {normalizeCnpj(register.cnpj)}
                      {register.clie_id && (
                        <span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded ml-3">
                          {" "}
                          Enviado para o facta{" "}
                        </span>
                      )}
                    </p>
                    <div
                      className="kt-widget__action"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        flexWrap: "wrap",
                      }}
                    >
                      {register &&
                        register.finished === "A" &&
                        Object.keys(user).length > 0 &&
                        !verifyProfiles(user, "manager") &&
                        !register.members.find(
                          (member) => member.finished !== "A"
                        ) && (
                          <button
                            type="button"
                            className="btn btn-danger btn-sm btn-upper"
                            onClick={() => setOpen(true)}
                          >
                            REABRIR CADASTRO
                          </button>
                        )}
                      {register &&
                      register.clie_id &&
                      register.finished === "A" &&
                      Object.keys(user).length > 0 &&
                      !verifyProfiles(user, "manager") &&
                      !register.members.find(
                        (member) => member.finished !== "A"
                      ) ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm btn-upper"
                          onClick={() => onSubmitFacta()}
                        >
                          <i className="fa fa-sync-alt"></i>
                          {loading ? "Enviando..." : "Reenviar para o Facta"}
                        </button>
                      ) : null}
                      {Object.keys(user).length > 0 &&
                        !verifyProfiles(user, "manager") && (
                          <Dropdown drop={"left"}>
                            <Dropdown.Toggle className="btn btn-success btn-sm btn-upper">
                              Imprimir
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {register && register.clie_id === null ? (
                                <Dropdown.Item
                                  onClick={() =>
                                    toast.info(
                                      "Necessário empresa ser integrada ao facta."
                                    )
                                  }
                                >
                                  Contrato
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => setOpenExceptionContract(true)}
                                >
                                  Contrato
                                </Dropdown.Item>
                              )}
                              {register &&
                              register.members &&
                              (register.finished !== "A" ||
                                register.members.find(
                                  (item) => item.finished !== "A"
                                )) ? (
                                <Dropdown.Item
                                  onClick={() =>
                                    toast.info(
                                      "Necessário aprovar dados da empresa e de todos os Sócios/Representantes/Avalistas"
                                    )
                                  }
                                >
                                  Formulário de Pessoa Jurídica
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() =>
                                    printPjDocument(register && register)
                                  }
                                >
                                  Formulário de Pessoa Jurídica
                                </Dropdown.Item>
                              )}
                              {register && register.finished !== "A" ? (
                                <Dropdown.Item
                                  onClick={() =>
                                    toast.info(
                                      "Necessário aprovar dados da empresa"
                                    )
                                  }
                                >
                                  Declaração Bancária
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() =>
                                    bankDeclaration(register && register)
                                  }
                                >
                                  Declaração Bancária
                                </Dropdown.Item>
                              )}
                              <Dropdown.Divider />
                              {register.members &&
                                register.members.map((member) => {
                                  return (
                                    <Dropdown key={member.id}>
                                      <Dropdown.Toggle
                                        key={member.id}
                                        style={{
                                          background: "none",
                                          border: 0,
                                          color: "#444",
                                        }}
                                        disabled={member.cpf.length !== 14}
                                      >
                                        {member.fullname}
                                      </Dropdown.Toggle>
                                      {member.cpf.length === 14 && (
                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => {
                                              member.finished !== "A"
                                                ? toast.info(
                                                    "Membro ainda não aprovado"
                                                  )
                                                : documentPf(member);
                                            }}
                                          >
                                            Formulário de Pessoa Física
                                          </Dropdown.Item>
                                          {member.marital_status ===
                                            "Solteiro(a)" && (
                                            <Dropdown.Item
                                              onClick={() => {
                                                member.finished !== "A"
                                                  ? toast.info(
                                                      "Membro ainda não aprovado"
                                                    )
                                                  : singleDeclaration(member);
                                              }}
                                            >
                                              Declaração de Solteiro
                                            </Dropdown.Item>
                                          )}
                                        </Dropdown.Menu>
                                      )}
                                    </Dropdown>
                                  );
                                })}
                            </Dropdown.Menu>
                          </Dropdown>
                        )}
                      {register.refuse === 0 &&
                        register.completed === 0 && (
                          <button
                            type="button"
                            className="btn btn-danger btn-upper btn-sm"
                            onClick={() => setOpenConfirm(true)}
                          >
                            Indeferir Cadastro
                          </button>
                        )}
                    </div>
                  </div>
                  <ModalConfirmationRefuse
                    open={openConfirm}
                    setOpen={(e) => setOpenConfirm(e)}
                    onConfirm={(reason) => refuseRegister(reason)}
                  />
                  <div className="kt-widget__subhead">
                    <span>
                      <i className="flaticon2-new-email"></i>{" "}
                      {register.dados &&
                        ((register.dados.find((i) => i.name === "email") &&
                          register.dados.find((i) => i.name === "email")
                            .value) ||
                          (register.customer && register.customer.email))}
                    </span>{" "}
                    <span>
                      <i className="flaticon2-user"></i>{" "}
                      {register.dados &&
                        ((register.dados.find((i) => i.name === "contato") &&
                          register.dados.find((i) => i.name === "contato")
                            .value) ||
                          (register.customer &&
                            register.customer.customer_name))}
                    </span>{" "}
                    <span>
                      <i className="flaticon2-phone"></i>{" "}
                      {register.dados &&
                        ((register.dados.find((i) => i.name === "fone") &&
                          register.dados.find((i) => i.name === "fone")
                            .value) ||
                          (register.customer && normalizePhone(register.customer.phone)))}
                    </span>
                  </div>
                  <div className="kt-widget__info">
                    <div className="kt-widget__desc">
                      <i className="flaticon2-pin-1"></i>{" "}
                      {register.dados &&
                        register.dados.map(
                          (i) => i.name === "endereco" && i.value
                        )}
                      ,{" "}
                      {register.dados &&
                        register.dados.map(
                          (i) => i.name === "numero" && i.value
                        )}{" "}
                      {register.dados &&
                        register.dados.map(
                          (i) => i.name === "complemento" && "-" + i.value
                        )}
                      ,{" "}
                      {register.dados &&
                        register.dados.map(
                          (i) => i.name === "bairro" && i.value
                        )}
                      ,{" "}
                      {register.dados &&
                        register.dados.map(
                          (i) => i.name === "cidade" && i.value
                        )}{" "}
                      ,
                      {register.dados &&
                        register.dados.map(
                          (i) => i.name === "cep" && i.value
                        )}{" "}
                      -
                      {register.dados &&
                        register.dados.map((i) => i.name === "uf" && i.value)}
                    </div>
                    <div className="kt-widget__progress">
                      <div className="kt-widget__text">Progresso</div>
                      <div className="progress">
                        <div
                          className="progress-bar kt-bg-success"
                          style={{ width: `${percentage}%` }}
                        ></div>
                      </div>
                      <div className="kt-widget__stats">{percentage}%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-widget__bottom">
                {Object.keys(user).length > 0 &&
                verifyProfiles(user, "manager") ? (
                  <button
                    type="button"
                    style={{ background: "#fff", border: "0" }}
                    className="kt-widget__item"
                    onClick={() => setCurrent(3)}
                  >
                    <div className="kt-widget__icon">
                      <i
                        className="flaticon-network"
                        style={{ color: "#595d6e" }}
                      ></i>
                    </div>
                    <div className="kt-widget__details">
                      <span
                        className="kt-widget__title"
                        style={{ color: "#595d6e" }}
                      >
                        Pareceres
                      </span>
                      <span className="kt-widget__value"></span>
                    </div>
                  </button>
                ) : (
                  <>
                    <button
                      type="button"
                      style={{ background: "#fff", border: "0" }}
                      className="kt-widget__item"
                      onClick={() => setCurrent(1)}
                    >
                      <div className="kt-widget__icon">
                        <i
                          className="flaticon2-list-3"
                          style={{
                            color:
                              register.finished === "A"
                                ? "rgb(29, 201, 184)"
                                : "#595d6e",
                          }}
                        ></i>
                      </div>
                      <div className="kt-widget__details">
                        <span
                          className="kt-widget__title"
                          style={{
                            color:
                              register.finished === "A"
                                ? "rgb(29, 201, 184)"
                                : "#595d6e",
                          }}
                        >
                          Dados
                        </span>
                        <span className="kt-widget__value"></span>
                      </div>
                    </button>
                    <Ancor
                      to="members"
                      smooth={true}
                      className="kt-widget__item"
                    >
                      <div className="kt-widget__icon">
                        <i
                          className="flaticon-profile-1"
                          style={{
                            color:
                              register.members &&
                              register.members.find(
                                (mem) => mem.finished !== "A"
                              )
                                ? "#595d6e"
                                : "rgb(29, 201, 184)",
                          }}
                        ></i>
                      </div>
                      <div className="kt-widget__details">
                        <span
                          className="kt-widget__title"
                          style={{
                            color:
                              register.members &&
                              register.members.find(
                                (mem) => mem.finished !== "A"
                              )
                                ? "#595d6e"
                                : "rgb(29, 201, 184)",
                          }}
                        >
                          Sócios/Representantes/Avalistas
                        </span>
                        <span className="kt-widget__value"></span>
                      </div>
                    </Ancor>
                    <button
                      type="button"
                      style={{ background: "#fff", border: "0" }}
                      className="kt-widget__item"
                      onClick={() => setCurrent(2)}
                    >
                      <div className="kt-widget__icon">
                        <i
                          className="flaticon2-document"
                          style={{
                            color:
                              register.documents &&
                              register.documents.find(
                                (doc) =>
                                  doc.status !== "A" && doc.status !== "I"
                              )
                                ? "#595d6e"
                                : "rgb(29, 201, 184)",
                          }}
                        ></i>
                      </div>
                      <div className="kt-widget__details">
                        <span
                          className="kt-widget__title"
                          style={{
                            color:
                              register.documents &&
                              register.documents.find(
                                (doc) =>
                                  doc.status !== "A" && doc.status !== "I"
                              )
                                ? "#595d6e"
                                : "rgb(29, 201, 184)",
                          }}
                        >
                          Documentos
                        </span>
                        <span className="kt-widget__value"></span>
                      </div>
                    </button>
                    <button
                      type="button"
                      style={{ background: "#fff", border: "0" }}
                      className="kt-widget__item"
                      onClick={() => setCurrent(3)}
                    >
                      <div className="kt-widget__icon">
                        <i
                          className="flaticon-network"
                          style={{ color: "#595d6e" }}
                        ></i>
                      </div>
                      <div className="kt-widget__details">
                        <span
                          className="kt-widget__title"
                          style={{ color: "#595d6e" }}
                        >
                          Pareceres
                        </span>
                        <span className="kt-widget__value"></span>
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
          </Paper>
        </Col>
      </Row>
    </>
  );
}

export default memo(HeaderRegister);
