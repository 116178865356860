import React from "react";
import { Route, Switch } from "react-router-dom";
import PermissionsIndex from "./PermissionsIndex";
import PermissionsAdd from "./PermissionsAdd";
import PermissionsEdit from "./PermissionsEdit";

export default function UserPage() {
  return (
    <Switch>
      <Route exact path="/permissions/" component={PermissionsIndex} />
      <Route exact path="/permissions/add" component={PermissionsAdd} />
      <Route exact path="/permissions/:id" component={PermissionsEdit} />
    </Switch>
  );
}
