import React, { memo, useState } from 'react';

import { Modal, Backdrop, makeStyles, TextField } from '@material-ui/core';
import { Row } from 'react-bootstrap';

function ModalReopenRegister({
  onSubmit,
  open,
  onClose
}) {
  const [reason, setReason] = useState('');
  const useStyles = makeStyles(theme => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: "700px",
      height: "40%",
      overflow: "auto"
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      className={classes.modalsm}
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fadesm}>
        <Row>
        <div className="col-12 confirm">
          <h4>Tem certeza que deseja reabrir o cadastro?</h4>
          <h5>Informe o motivo para reabertura:</h5>
          <TextField
            multiline
            label="Motivo"
            value={reason}
            onChange={e => setReason(e.target.value)}
            style={{ width: '100%' }}
            rows={3}
          />
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-outline-success mr-3"
              onClick={() => { 
                onSubmit(reason);
                onClose();
              }}
              disabled={reason.length === 0}
            >
              Reabrir
                </button>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => onClose()}
            >
              Cancelar
                </button>
          </div>
        </div>
        </Row>
      </div>
    </Modal>
  );
}

export default memo(ModalReopenRegister);