import React from "react";
import { Route, Switch } from "react-router-dom";
import ECnpjIndex from "./ECnpjIndex";

export default function ECnpjPage() {
  return (
    <Switch>
      <Route exact path="/e-cnpj" component={ECnpjIndex} />
    </Switch>
  );
}
