import { format, parseISO } from "date-fns";

export default function impressOpinion(data) {
  var mywindow = window.open('', 'PRINT');

  const { opinions, approved } = data;

  function setProfile(profile) {
    switch(profile) {
      case 1: return 'Administrador';
      case 2: return 'Diretor';
      case 3: return 'Gerente';
      case 4: return 'Analista';
      default: return '-'; 
    }
  }

  mywindow.document.write(`
  <!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Parecer</title>
    <style>
      body {
        font-family: sans-serif;
      }
      div.opinion {
        padding:20px 10px;
      }
      div.opinion + div.opinion {
        border-top:1px solid #eee;
      }
      ul {
        list-style:none;
      }
    </style>
  </head>
  <body>
    <h2>Pareceres</h2>
    <div>
      ${opinions.length > 0 && opinions.map(opinion => {
        return `
          <div class='opinion'>
            <strong>${opinion.user.fullname} - ${setProfile(opinion.user.profile)}</strong> | ${format(parseISO(opinion.created_at), "dd'/'MM'/'yyyy 'às' HH':'mm")}<br />
            <p>${opinion.message}</p>
          </div>
        `;
      }).join('')}
    </div>
    <div>
      <h2>Aprovado por:</h2>
      <ul>
      ${approved.length > 0 && approved.map(approve => {
        return `
          <li>${approve.user.fullname}</li>
        `;
      }).join('')}
      </ul>
    </div>
  </body>
  </html>
  `);
  mywindow.document.close();
  mywindow.focus(); 

  mywindow.print();
  mywindow.close();
}