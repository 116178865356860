import React, { memo } from "react";

function VisualizingCompanyData(props) {
  const selected = props.itemsReproved.find((i) => i.name === props.e.name);
  const inException = props.itemsReproved.find((i) => i.name === 'bancos' || i.name === 'referencias_comerciais')
  return (
    <button
      type="button"
      style={{
        background: "none",
        border: 0,
        marginLeft: 0,
        paddingLeft: 0,
      }}
      disabled={
        (props.register.finished !== "A" ? false : true) ||
        !props.selectReprove.fields || (inException && inException.name !== props.e.name)
      }
      onClick={() => props.handleSelectReproved(props.e)}
      className={props.classes.card}
    >
      <p
        style={{
          fontSize: "16px",
          padding: "0",
          margin: "0",
          display: "flex",
          alignItems: "center",
        }}
      >
        {!inException ?
          (props.selectReprove.fields && (
            <span
              className={selected ? "fa fa-check" : ""}
              style={{
                width: "18px",
                height: "18px",
                border: "0",
                background: "#dee2ea",
                display: "inline-block",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            ></span>
          )) : (inException.name === props.e.name && props.selectReprove.fields && (
            <span
              className={selected ? "fa fa-check" : ""}
              style={{
                width: "18px",
                height: "18px",
                border: "0",
                background: "#dee2ea",
                display: "inline-block",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            ></span>
          ))}
        <strong style={{ marginRight: "10px" }}>{props.e.title}:</strong>{" "}
        {props.e.value === "nao"
          ? "Não"
          : props.e.value === "sim"
          ? "Sim"
          : props.e.value}
      </p>
    </button>
  );
}

export default memo(VisualizingCompanyData);
