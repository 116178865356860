import React, { useState } from "react";
import { format } from "date-fns";

export default function ListBorderoItem({ classes, item, index, hasEcnpj }) {
  const [itemNfe] = useState(item);

  const [expand, setExpand] = useState(false);

  return (
    <div className="col-md-12" style={{ padding: "10px" }} key={index}>
      <div className={classes.box}>
        <div className="row">
          <div className="col-md-8">
            <h5>NFe: {itemNfe.nfe}</h5>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flex: "0.7",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {itemNfe.status === "confirmado" && (
                <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded">
                  Confirmado
                </span>
              )}
              {itemNfe.status === "a confirmar" && (
                <span className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded">
                  A Confirmar
                </span>
              )}
              {itemNfe.status === "error" && (
                <span className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded">
                  Erro
                </span>
              )}
              {itemNfe.status === "rejeitado" && (
                <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">
                  Recusado
                </span>
              )}
              {itemNfe.status === "inválida" && (
                <span className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded">
                  Inválida
                </span>
              )}
              {itemNfe.status === "" && (
                <span className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded">
                  Em Análise
                </span>
              )}
              {itemNfe.status === "nao confirmado" && (
                <span className="kt-badge kt-badge--dark kt-badge--inline kt-badge--pill kt-badge--rounded">
                  Não confirmado
                </span>
              )}
              {itemNfe.status === "nfe nao encontrada" && (
                <span
                  className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded"
                  style={{ background: "#ff7d00" }}
                >
                  Não encontrado
                </span>
              )}
              {itemNfe.facta === 1 && (
                <div>
                  Facta{" "}
                  <i className="fas fa-check" style={{ color: "#1dc9b7" }}></i>
                </div>
              )}
            </div>
            {expand ? (
              <button
                type="button"
                onClick={() => setExpand(false)}
                style={{ background: "none", border: "none" }}
              >
                Ver menos <i className="fas fa-angle-up"></i>
              </button>
            ) : (
              <>
                {(itemNfe.status === "confirmado" ||
                  itemNfe.status === "a confirmar") && (
                  <button
                    type="button"
                    onClick={() => setExpand(true)}
                    style={{ background: "none", border: "none" }}
                  >
                    Ver mais <i className="fas fa-angle-down"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>Sacado: {itemNfe.sacado || "-"}</p>
          <p>UF: {itemNfe.ufSacado || "-"}</p>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>
            Origem:{" "}
            {itemNfe.origin ? (
            <>
              {itemNfe.origin === "SERPRO" && (
                <span
                  className="ml-2"
                  style={{
                    background: "#000",
                    border: "2px solid #000",
                    padding: "5px",
                    color: "#fff",
                    fontWeight: "900",
                  }}
                >
                  SERPRO
                </span>
              )}
              {itemNfe.origin !== "SERPRO" && itemNfe.origin}
            </>
            ) : (
              hasEcnpj ? (
                "CERTIFICADO"
              ) : (
                <span
                  className="ml-2"
                  style={{
                    background: "#000",
                    border: "2px solid #000",
                    padding: "5px",
                    color: "#fff",
                    fontWeight: "900",
                  }}
                >
                  SERPRO
                </span>
              )
            )}
          </p>
        </div>
        <div
          style={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <p>
            Início do Processo:{" "}
            {itemNfe.processDate
              ? format(new Date(itemNfe.processDate), "dd/MM/yyyy 'às' HH:mm")
              : "-"}
          </p>
          <p>
            Fim do Processo:{" "}
            {itemNfe.status === ""
              ? "-."
              : format(new Date(itemNfe.updatedAt), "dd/MM/yyyy 'às' HH:mm")}
          </p>
        </div>
        {itemNfe.count_task && itemNfe.count_task > 0 ? (
          <p>
            Nota reanalisada {itemNfe.count_task}{" "}
            {itemNfe.count_task > 1 ? "vezes" : "vez"}
          </p>
        ) : (
          ""
        )}
        {itemNfe.events && expand && (
          <div>
            <table className="table table-bordered mt-4">
              <thead>
                <tr className="bg-secondary text-dark">
                  <td>Evento</td>
                  <td>Protocolo</td>
                  <td>Data</td>
                </tr>
              </thead>
              <tbody>
                {JSON.parse(itemNfe.events).map((event, index) => (
                  <tr key={index}>
                    <td>
                      <small>{event.evento}</small>
                    </td>
                    <td>
                      <small>{event.protocolo}</small>
                    </td>
                    <td>
                      <small>{event.data_autorizacao}</small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
