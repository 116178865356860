import React, { memo, useEffect, useState } from "react";

import { normalizeCpfCnpj } from "../utils/normalize";
import ProtestItem from "./ProtestItem";

function ItemDetail({ e, index }) {
  const [protests, setProtests] = useState([]);

  useEffect(() => {
    let protestsArr = [];

    if (!e.protests) {
      setProtests(null)
      return
    }

    e.protests.forEach(p => {
      const stringP = JSON.stringify({ ...p, dateConsult: undefined, json: { ...p.json, dataConsulta: undefined }, _id: undefined });
      if (!protestsArr.find(i => i.string === stringP)) {
        protestsArr.push({
          dates: [p.json.dataConsulta.slice(0, 10)],
          obj: p,
          string: stringP,
        });
      } else {
        protestsArr = protestsArr.map(i => {
          if (i.string === stringP) {
            return {
              ...i,
              dates: [...i.dates, p.json.dataConsulta.slice(0, 10)]
            }
          }
          return i;
        })
      }
    });

    setProtests(protestsArr.map(p => {
      return {
        ...p.obj,
        dates: p.dates,
      }
    }));
  }, [e.protests]);

  return (
    <div
      key={index}
      style={{
        padding: "15px",
        border: "1px solid #eee",
        margin: "10px 0px",
        background: "#f9f9f9",
        position: "relative",
        boxShadow: "0px 0px 5px rgb(0 0 0 / 10%)"
      }}
    >
      <div
        className="d-flex justify-content-between align-items-start"
      >
        <div>
          <h4 className="d-flex align-items-center">
            {!e.protests ? (<span style={{ width: '12px', height: '12px', borderRadius: '50%', background: 'orange', display: 'block', marginRight: '6px' }} />) :
              e.protests.find(i => i.amountTitles > 0) ? (
                <span style={{ width: '12px', height: '12px', borderRadius: '50%', background: 'red', display: 'block', marginRight: '6px' }} />
              ) : (
                  <span style={{ width: '12px', height: '12px', borderRadius: '50%', background: 'green', display: 'block', marginRight: '6px' }} />
                )}
            {e.name} - {normalizeCpfCnpj(e.documentNumber)}
          </h4>
        </div>
      </div>
      {!e.protests ? (<p className="mb-0">
        Em processamento
      </p>) :
        protests.length > 0 && protests.map((protest, index) => {
          if (protest.amountTitles === 0) {
            return (
              <p key={index} className="mb-0">
                <strong><i className="far fa-calendar-alt mr-1"></i> {protest.json.dataConsulta.slice(0, 10)}</strong> Nenhum título em protesto
              </p>
            );
          }
          return (
            <ProtestItem key={index} protest={protest} />
          )
        })}
    </div>
  );
}

export default memo(ItemDetail);
