import React, { useState } from "react";
import axios from "axios";
import { format } from "date-fns";

export default function ListTransporterItem({ classes, item, index }) {
  const [itemCte, setItemCte] = useState(item);

  async function getEvents(cte) {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-cte/${cte}`
    );
    setItemCte({ ...item, events: data.listEvents });
  }

  return (
    <div className="col-md-12" style={{ padding: "10px" }} key={index}>
      <div className={classes.box}>
        <div className="row">
          <div className="col-md-8">
            <h5>CTe: {itemCte.chCte}</h5>
          </div>
          <div
            className="col-md-4"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ flex: '0.7', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            {itemCte.status === "confirmado" && (
              <span
                className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded"
              >
                Confirmado
              </span>
            )}
            {itemCte.status === "a confirmar" && (
              <span
                className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded"
              >
                A Confirmar
              </span>
            )}
            {itemCte.status === "error" && (
              <span
                className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded"
              >
                Erro
              </span>
            )}
            {itemCte.status === "rejeitado" && (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
              >
                Recusado
              </span>
            )}
            {itemCte.status === "nao confirmado" && (
              <span
                className="kt-badge kt-badge--dark kt-badge--inline kt-badge--pill kt-badge--rounded"
              >
                Não confirmado
              </span>
            )}
            {itemCte.status === "inválida" && (
              <span
                className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
              >
                Inválida
              </span>
            )}
            {itemCte.status === "" && (
              <span
                className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded"
              >
                Em Análise
              </span>
            )}
            {itemCte.status === "cte nao encontrada" && (
              <span
                className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded"
                style={{ background: '#ff7d00' }}
              >
                Não encontrado
              </span>
            )}
            {itemCte.facta === 1 && (
              <div>
                Facta <i className="fas fa-check" style={{ color: '#1dc9b7' }}></i>
              </div>
            )}
            </div>
            {itemCte.events ? (
              <button
                type="button"
                onClick={() => setItemCte(item)}
                style={{ background: "none", border: "none" }}
              >
                Ver menos <i className="fas fa-angle-up"></i>
              </button>
            ) : (
              <>
                {(itemCte.status === "confirmado" ||
                  itemCte.status === "a confirmar") && (
                  <button
                    type="button"
                    onClick={() => getEvents(itemCte.chCte)}
                    style={{ background: "none", border: "none" }}
                  >
                    Ver mais <i className="fas fa-angle-down"></i>
                  </button>
                )}
              </>
            )}
          </div>
        </div>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <p>Sacado: {itemCte.sacado || '-'}</p>
            <p>UF:{" "}
            {itemCte.ufSacado || '-'}</p>
        </div>
        <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
            <p>Início do Processo: {itemCte.processDate ? format(new Date(itemCte.processDate), "dd/MM/yyyy 'às' HH:mm") : '-'}</p>
            <p>Fim do Processo:{" "}
            {itemCte.status === "" ? "-." : format(new Date(itemCte.updatedAt), "dd/MM/yyyy 'às' HH:mm")}</p>
        </div>
        {itemCte.count_task && itemCte.count_task > 0 ? (
          <p>Nota reanalisada {itemCte.count_task} {itemCte.count_task > 1 ? 'vezes' : 'vez'}</p>
        ) : ""} 
        {itemCte.events && (
          <div>
            <table className="table table-bordered mt-4">
              <thead>
                <tr className="bg-secondary text-dark">
                  <td>Evento</td>
                  <td>Protocolo</td>
                  <td>Data</td>
                </tr>
              </thead>
              <tbody>
                {itemCte.events.map((event) => (
                  <tr>
                    <td>
                      <small>{event.evento}</small>
                    </td>
                    <td>
                      <small>{event.protocolo}</small>
                    </td>
                    <td>
                      <small>{format(new Date(event.data_autorizacao), "dd/MM/yyyy 'às' HH:mm")}</small>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}
