import React, { useState, memo } from "react";

import { makeStyles } from "@material-ui/core/";
import { Modal, Fade, Backdrop, Button, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import axios from "axios";
import { toast } from "react-toastify";
import { format } from "date-fns";

function ModalContact({ open, setOpen, detail }) {
  const [replyActive, setReplyActive] = useState(false);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [expandReplies, setExpandReplies] = useState(false);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "700px",
      maxHeight: "90%",
      overflow: "auto",
    },
    close: {
      background: "#fff",
      fontSize: "20px",
      border: 0,
      textAlign: "right",
      width: "100%",
    },
    wrap: {
      display: "flex",
      justifyContent: "space-beetwen",
      alignItems: "center",
      width: "100%",
    },
  }));
  const classes = useStyles();

  function resetModal() {
    setOpen(!open);
    setReplyActive("");
    setSubject("");
    setMessage("");
    setExpandReplies(false);
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      await axios.post("/reply_contact", {
        subject,
        message,
        contact_id: detail.id,
      });
      resetModal();
      toast.success("Contato respondido");
    } catch (err) {
      toast.error("Ocorreu um erro, tente novamente");
    }
    setLoading(false);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={() => resetModal()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.fade}>
          <div className={classes.wrap}>
            <h2 id="transition-modal-title">Contato</h2>
            <button className={classes.close} onClick={() => resetModal()}>
              <Close />
            </button>
          </div>
          {detail && (
            <div className="mt-3">
              <div
                className="d-flex justify-content-start align-items-center"
                style={{
                  padding: "10px",
                  background: "#f9f9f9",
                  borderRadius: "6px",
                  position: "relative",
                }}
              >
                <i
                  className="fas fa-user"
                  style={{
                    fontSize: "46px",
                    padding: "10px",
                    background: "#eee",
                    borderRadius: "6px",
                  }}
                ></i>
                <div className="ml-4">
                  <h4>{detail.name}</h4>
                  <div className="d-flex justify-content-between align-items-center">
                    <p>
                      <i className="fas fa-envelope mr-1" />
                      {detail.email}
                    </p>
                    <p className="ml-4">
                      <i className="fas fa-phone mr-1" />
                      {detail.phone}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="mt-4"
                style={{
                  padding: "10px",
                  background: "#f9f9f9",
                  borderRadius: "6px",
                  position: "relative",
                }}
              >
                <h5
                  style={{
                    background: "#eee",
                    padding: "10px",
                    borderRadius: "6px",
                  }}
                >
                  {detail.subject}
                </h5>
                <p className="mt-2" style={{ padding: "10px" }}>
                  {detail.message}
                </p>
              </div>
            </div>
          )}
          <hr />
          {replyActive ? (
            <div>
              <TextField
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                label="Assunto"
                disabled={loading}
                style={{ width: "100%" }}
              />
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                label="Mensagem"
                style={{ width: "100%" }}
                disabled={loading}
                multiline
                rows="4"
              />
              <div className="d-flex mt-4">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    message.length === 0 || subject.length === 0 || loading
                  }
                  onClick={() => handleSubmit()}
                >
                  Enviar
                </Button>
                <Button
                  className="ml-4"
                  variant="contained"
                  color="default"
                  disabled={loading}
                  onClick={() => {
                    setReplyActive(false);
                    setSubject("");
                    setMessage("");
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                type="button"
                className="btn btn-secondary btn-upper btn-sm"
                onClick={() => {
                  setReplyActive(true);
                  setSubject("");
                  setMessage("");
                  setExpandReplies(false);
                }}
              >
                Responder
              </button>
              {detail && detail.replies && detail.replies.length > 0 && (
                <button
                  type="button"
                  className="btn btn-info btn-upper btn-sm"
                  onClick={() => {
                    setExpandReplies(!expandReplies);
                  }}
                >
                  {expandReplies ? (
                    <>Minimizar Respostas</>
                  ) : (
                    <>Ver Respostas ({detail.replies.length})</>
                  )}
                </button>
              )}
            </div>
          )}
          {expandReplies && (
            <div style={{ padding: "10px" }} className="bg-secondary mt-4">
              {detail &&
                expandReplies &&
                detail.replies &&
                detail.replies.map((r) => (
                  <div
                    key={r.id}
                    className="bg-secondary d-flex justify-content-start align-items-center"
                    style={{
                      borderRadius: "6px",
                      padding: "10px",
                    }}
                  >
                    <div className="text-center mr-4" style={{ background: '#efeff5', borderRadius: '6px', padding: '10px', minWidth: '150px' }}>
                      <i className="fas fa-user" style={{ padding: '10px', borderRadius: '6px', fontSize: '34px' }}></i>
                      <h5>{r.user.fullname}</h5>
                      <p>{format(new Date(r.created_at), "dd/MM/yyyy")}</p>
                    </div>
                    <div style={{ padding: '10px' }}>
                      <strong style={{ fontSize: '18px' }}>
                        {r.subject}
                      </strong>
                      <p className="mt-2">
                        {r.message}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </Fade>
    </Modal>
  );
}

export default memo(ModalContact);
