import React, { memo } from 'react';

import { Modal, Backdrop, makeStyles } from '@material-ui/core';
import { Row } from 'react-bootstrap';

function ModalReopenRegister({
  onSubmit,
  open,
  setOpen
}) {
  const useStyles = makeStyles(theme => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "1px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      width: "100%",
      display:'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: "40px",
      maxWidth: "400px",
      overflow: "auto"
    },
  }));
  const classes = useStyles();

  return (
    <Modal
      className={classes.modalsm}
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fadesm}>
        <Row>
        <div className="col-12 confirm">
          <h4>Tem certeza que deseja reenviar?</h4>
          <div className="mt-4">
            <button
              type="button"
              className="btn btn-success mr-3"
              onClick={() => { 
                onSubmit();
                setOpen(false);
              }}
            >
              Sim
            </button>
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={() => setOpen(false)}
            >
              Cancelar
                </button>
          </div>
        </div>
        </Row>
      </div>
    </Modal>
  );
}

export default memo(ModalReopenRegister);