import React, { memo } from "react";
import { TextField } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";

function GroupedData(props) {
  // for all the data
  function decrementObject(name) {
    let tmpRegister = props.register;
    const indexElement = tmpRegister.dados.findIndex(item => item.name === name);
    tmpRegister.dados[indexElement].value = tmpRegister.dados[indexElement].value.splice(0, tmpRegister.dados[indexElement].value.length - 1);
    props.setRegister({ ...props.register, dados: [...tmpRegister.dados] });
  }
  function incrementObject(name) {
    let tmpRegister = props.register;
    const indexElement = tmpRegister.dados.findIndex(item => item.name === name);
    const newElement = [];
    tmpRegister.dados[indexElement].value[0].forEach(item => {
      newElement.push({ ...item, value: '' });
    })
    tmpRegister.dados[indexElement].value.push(newElement);
    props.setRegister({ ...props.register, dados: [...tmpRegister.dados] });
  }
  return (
    <>
      {props.register.dados &&
        props.register.dados.map((e, ind) => {
          const selected = props.itemsReproved.find(i => i.name === e.name);
          // for the specific data
          return (
            typeof e.value === "object" &&
            e.name !== "socios" && (
              <Row className={props.classes.container_list} key={e.name}>
                <Col lg={12}>
                  <h3>{e.title}</h3>
                  <hr></hr>
                </Col>
                <Col lg={12} key={e.title} style={{ padding: "5px" }}>
                  <button
                    type="button"
                    style={{
                      background: "none",
                      border: 0,
                      marginLeft: 0,
                      paddingLeft: 0,
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                    onClick={() => props.handleSelectReproved(e)}
                    disabled={
                      (props.register.finished !== "A" ? false : true) ||
                      !props.selectReprove.fields
                    }
                    className={props.classes.card}
                  >
                    {props.selectReprove.fields && (
                      <span
                        className={selected ? "fa fa-check" : ""}
                        style={{
                          width: "18px",
                          height: "18px",
                          border: "0",
                          background: "#dee2ea",
                          display: "inline-block",
                          borderRadius: "5px",
                          marginRight: "10px"
                        }}
                      ></span>
                    )}
                    <div style={{ width: "calc(100% - 40px)" }}>
                      {e.value &&
                        e.value.length > 0 &&
                        e.value.map((element, inde) => (
                          <div className="row" key={element.name}>
                            {element && element.length > 0 &&
                              element.map((elementItem, index) =>
                                props.editField.company ? (
                                  <div
                                    className={`col-${12 / element.length}`}
                                    key={elementItem.name}
                                  >
                                    <TextField
                                      type="text"
                                      value={elementItem.value || ""}
                                      onChange={event => {
                                        let dados = props.register.dados;
                                        dados[ind].value[inde][
                                          index
                                        ].value = props.normalizeCompany(
                                          elementItem.name,
                                          event.target.value
                                        );
                                        props.setRegister({
                                          ...props.register,
                                          dados
                                        });
                                      }}
                                      label={elementItem.title}
                                      variant="outlined"
                                      style={{
                                        width: "100%",
                                        margin: "5px 0"
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <p
                                    className={`col-${12 / element.length}`}
                                    style={{
                                      fontSize: "16px",
                                      padding: "0",
                                      margin: "0"
                                    }}
                                    key={elementItem.name}
                                  >
                                    <strong>{elementItem.title}:</strong>{" "}
                                    {elementItem.value}
                                  </p>
                                )
                              )}
                          </div>
                        ))}
                    </div>
                  </button>
                </Col>
                {props.editField.company && (
                  <>
                  {e.value.length < 5 && (
                    <button 
                      type="button" 
                      onClick={() => incrementObject(e.name)}
                      className="btn btn-label-dark btn-sm btn-upper mr-2">
                      +
                    </button>
                  )}
                  {e.value.length > 1 && (
                    <button 
                      type="button" 
                      onClick={() => decrementObject(e.name)}
                      className="btn btn-label-dark btn-sm btn-upper">
                      -
                    </button>
                  )}
                  </>
                )}
              </Row>
            )
          );
        })}
    </>
  );
}

export default memo(GroupedData);
