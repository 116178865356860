import { normalizeCurrency, normalizeCnpj } from './normalize';

import { subMonths, format } from "date-fns";
import pt_BR from "date-fns/locale/pt-BR";

export default function(data) {
  const { company, total, nfes } = data;
  var mywindow = window.open('', 'PRINT');
  const html = `<!DOCTYPE html>
  <html lang="pt-BR">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Faturamento</title>
  </head>
  <style>
    * {
      padding: 0;
      margin:0;
      box-sizing: border-box;
      font-family: sans-serif;
    }
  </style>
  <body>
  <div style="display: flex; align-items: center; justify-content: flex-start;">
      <img style="width: 60px" src="https://www.bfccapital.com.br/static/media/logo_bfc_header.756dea8a.png" alt="BFC Capital Partners"/>
      <div style="margin-left: 10px; padding: 20px 0px; color: #33405e; text-align: left;">
        <h3>BFC Capital Partners</h3>
        <small style="font-size: 10px; line-height: 14px; display: block;">
          Empresarial Alexandre de Castro e Silva<br />
          Av. Domingos Ferreira, 2589 - Sala 1001, 10° Andar<br />
          CEP: 51.020-031 - Boa Viagem, Recife - PE
        </small>
      </div>
      </div>
    <div style="padding: 30px 0px;">
      <h3 style="color: #33405e;">${company.name} ${normalizeCnpj(company.cnpj)} - Faturamento Parcial</h3>
    </div>
    <hr />
    <div style="padding: 30px 0px;">
      <h5 style="margin-bottom: 10px;">
        Resumo
      </h5>
      <div style="display: flex; align-items: center; ">
        <h2 style="margin-right: 6px;">
          R$ ${normalizeCurrency(total)}
        </h2>
        <small>${nfes[0] ? `entre ${format(subMonths(new Date(), 3), "dd/MM", {
          locale: pt_BR,
        })} - ${format(new Date(nfes[0].date), "dd/MM", { locale: pt_BR })}` : `Nenhuma nota confirmada ou a confirmar`}</small>
      </div>
      <h5 style="margin-top: 6px; color: #444;">
        231 Nfe's
      </h5>
      <hr style="margin-top: 30px; border-color: #fff;"/>
      <table style="width: 100%;">
        <thead>
          <tr>
            <th style="text-align: left; font-size: 10px; border-bottom: 1px solid #eee; padding: 10px;">Sacado</th>
            <th style="text-align: left; font-size: 10px; border-bottom: 1px solid #eee; padding: 10px; ">Chave NFe</th>
            <th style="text-align: left; font-size: 10px; border-bottom: 1px solid #eee; padding: 10px; ">Valor</th>
            <th style="text-align: left; font-size: 10px; border-bottom: 1px solid #eee; padding: 10px; ">Data de expedição</th>
            <th style="text-align: right; font-size: 10px; border-bottom: 1px solid #eee; padding: 10px;">Status</th>
          </tr>
        </thead>
        <tbody>
        ${nfes.map(nfe => `
        <tr>
          <td style="font-size: 8px; padding: 5px 10px; border-bottom: 1px solid #f0f0f0">${nfe.sacado && nfe.sacadoCnpj ? `${nfe.sacado}<br />${normalizeCnpj(nfe.sacadoCnpj)}` : '-'}</td>
          <td style="font-size: 8px; padding: 5px 10px; border-bottom: 1px solid #f0f0f0">${nfe.chNfe.slice(0, 22)}<br />${nfe.chNfe.slice(22, 44)}</td>
          <td style="font-size: 8px; padding: 5px 10px; border-bottom: 1px solid #f0f0f0">R$ ${normalizeCurrency(nfe.value)}</td>
          <td style="font-size: 8px; padding: 5px 10px; border-bottom: 1px solid #f0f0f0">${nfe.date !== "0000-00-00" &&
          format(new Date(nfe.date), "dd/MM/yyyy")}</td>
          <td style="text-align: right; font-size: 8px; padding: 5px 10px; border-bottom: 1px solid #f0f0f0">${nfe.status.toUpperCase()}</td>
        </tr>
        `).join('')}
        </tbody>
      </table>
    </div>
  </body>
  </html>`;
  mywindow.document.write(html);
  mywindow.document.close();
  mywindow.focus(); 

  setTimeout(() => {
    mywindow.print();
    mywindow.close();
  }, 1500);
}