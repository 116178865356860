import React from "react";
import { Route, Switch } from "react-router-dom";
import ContactIndex from "./ContactIndex";

export default function UserPage() {
  return (
    <Switch>
      <Route exact path="/contacts/" component={ContactIndex} />
    </Switch>
  );
}
