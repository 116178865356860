import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { toast } from "react-toastify";
import SelectItem from "../components/SelectItem";
import ListItem from "../components/ListItem";

import { isPast, format } from 'date-fns';

import axios from "axios";

export default function ConfirmationView({ open, setOpen, onReload }) {
  const [nfes, setNfes] = useState([]);
  const [borderoData, setBorderoData] = useState({});
  const [approve, setApprove] = useState(false);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const [hasEcnpj, setHasEcnpj] = useState({});

  useEffect(() => {
    if (open.bordero !== "") {
      async function loadBordero() {
        setLoading(true);
        try {
          const { data } = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/bordero-report/bordero/${open.bordero}`);
          if(data.bordero !== null) {
            setOpen({ active: false, bordero: "" });
            toast.info('Borderô já analisado');
          }else{
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/bordero-nfe/${open.bordero}`
            );
            if(response.status === 204){
              toast.warn("Borderô não encontrado no FACTA");
              setOpen({ active: false, bordero: "" })
              setNfes([]);
              setBorderoData({});
              setSelected([]);
            }else{
              try {
                const { data } = await axios.get(
                  `${
                    process.env.REACT_APP_API_URL
                  }/customer-register/e-cnpj/file/${response.data.borderoData.cnpj.replace(/\D/g, "")}`
                );
                setHasEcnpj(data);
              } catch (err) {
                if (err.response && err.response.status === 404) {
                  setHasEcnpj({});
                } else if (err.response && err.response.status === 500) {
                  toast.error(
                    "Ocorreu um erro em nossos servidores ao buscar e-CNPJ, contate a equipe técnica"
                  );
                } else {
                  toast.error(
                    "Ocorreu um erro em nossa aplicação ao buscar e-CNPJ, contate a equipe técnica"
                  );
                }
              }
              setNfes(response.data.nfes.map(n => {
                return {
                  ...n,
                  sacadoDocument: n.cnpj_cpf_sacado
                }
              }));
              setBorderoData({ 
                ufCedente: response.data.borderoData.uf_cedente,
                cedente: response.data.borderoData.cedente,
                cnpj: response.data.borderoData.cnpj,
              });
              setSelected([]);
            }
          }
        } catch(err) {
          if(err.response && err.response.status === 500) {
            toast.error('Ocorreu um erro, tente novamente');
          }else{
            setNfes([]);
            setOpen({ active: false, bordero: "" })
            toast.error('Nenhuma NFe encontrada.');
          }
        }
        setLoading(false);
      }
      loadBordero();
    }
  }, [open.bordero]); // eslint-disable-line

  function handleSelectApprove(item) {
    const alreadyHave = selected.find((i) => i.index === item.index);
    if (!alreadyHave) {
      setSelected([...selected, item]);
    } else {
      const reproved = selected.filter((i) => i.index !== item.index);
      setSelected(reproved);
    }
  }

  async function handleSubmit() {
    try {
      const { data } = await axios.get('auth');
      await axios.post(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/request-list/list`, {
        bordero: String(open.bordero),
        nfes: selected,
        borderoData,
        user: data.id,
      })
      setOpen({ active: false, bordero: "" })
      toast.info('Notas foram enviadas para análise. Notificaremos quando todas forem analisadas');
      setNfes([]);
      onReload();
      setSelected([]);
    }catch(err) {
      toast.error('Ocorreu um erro ao tentar realizar análise');
    }
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "800px",
      height: "90%",
      overflow: "auto",
    },
    box: {
      flex: "1",
      border: "1px solid #eee",
      background: "#f9f9f9",
      padding: "10px",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.active}
      onClose={() => {
        setOpen({ active: false, bordero: "" });
        setApprove(false);
        setSelected([]);
        setNfes([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open.active}>
        <div className={classes.fade}>
          <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Analisar Notas - Borderô NFe</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen({ active: false, bordero: "" });
                    setNfes([]);
                    setApprove(false);
                    setSelected([]);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <h3
              className="mt-4"
              style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
              }}
            >
              Borderô NFe: {open.bordero}
            </h3>
            {!loading && (
              <div
              className="d-flex justify-content-start align-items-center mt-2"
              style={{ padding: "15px", borderRadius: "4px", background: "#f9f9f9" }}
            >
              <i className="fas fa-key" style={{ fontSize: '28px' }}></i>
              {Object.keys(hasEcnpj).length > 0 ? (
                <div className={`ml-3 d-flex flex-column ${isPast(new Date(hasEcnpj.expires_in)) ? 'text-danger' : ''}`}>
                  <strong>e-CNPJ {isPast(new Date(hasEcnpj.expires_in)) ? 'Expirado' : 'Instalado'}</strong>
                  <small>Expira em: {format(new Date(hasEcnpj.expires_in), 'dd/MM/yyyy')}</small>
                </div>
              ) : (
                <div className="ml-3">
                  <strong>Nenhum e-CNPJ instalado</strong>
                </div>
              )}
            </div>
            )}
            <div>
              <h4 className="mt-4">NFe's</h4>
              <div className="mt-4">
                {approve ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <button
                        type="button"
                        disabled={!selected.length > 0}
                        className="btn btn-primary btn-sm btn-upper mr-2"
                        onClick={handleSubmit}
                      >
                        Enviar para análise
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm btn-upper"
                        onClick={() => {
                          setApprove(false);
                          setSelected([]);
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                    {selected.length === nfes.length ? (
                      <button
                        type="button"
                        className="btn btn-light btn-sm btn-upper"
                        onClick={() => {
                          setSelected([]);
                        }}
                      >
                        <span
                          className={"fa fa-check mr-4"}
                          style={{
                            width: "18px",
                            height: "18px",
                            border: "0",
                            background: "#dee2ea",
                            display: "inline-block",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        ></span>
                        Esvaziar Seleção
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-light btn-sm btn-upper"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          const list = [];
                          nfes.forEach((item, index) => {
                            list.push({ ...item, index });
                          });
                          setSelected(list);
                        }}
                      >
                        <span
                          className={"mr-4"}
                          style={{
                            width: "18px",
                            height: "18px",
                            border: "0",
                            background: "#dee2ea",
                            display: "inline-block",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        ></span>
                        Selecionar Tudo
                      </button>
                    )}
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary btn-sm btn-upper"
                    onClick={() => setApprove(true)}
                  >
                    Analisar
                  </button>
                )}
              </div>
              <hr />
              <div className="row">
                {loading ? (
                  <>
                    <CircularProgress /> Buscando
                  </>
                ) : (
                  <>
                    {nfes.length > 0 ? (
                      nfes.map((item, index) => {
                        if (approve) {
                          const select = selected.find(
                            (i) => i.index === index
                          );
                          return (
                            <SelectItem
                              select={select}
                              key={index}
                              index={index}
                              item={item}
                              classes={classes}
                              handleSelectApprove={(e) =>
                                handleSelectApprove(e)
                              }
                            />
                          );
                        } else {
                          return (
                            <ListItem
                              classes={classes}
                              item={item}
                              key={index}
                            />
                          );
                        }
                      })
                    ) : (
                      <h6>Nenhuma NFe encontrada...</h6>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options"
                onClick={() => {
                  setOpen({ active: false, bordero: "" });
                  setNfes([]);
                  setApprove(false);
                  setSelected([]);
                }}
              >
                Fechar
              </button>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
