import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Modal,
  Fade,
  Backdrop,
  Paper,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

export default function IndexPage() {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  //styles
  const [order, setOrder] = React.useState("desc");
  // the order
  const [orderBy, setOrderBy] = React.useState("id");
  //the order subject
  const [page, setPage] = React.useState(0);
  //page we are in
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  // rows per page
  const [deleteId, setDeleteId] = useState(null);

  async function loadUsers() {
    setLoading(true);
    const response = await axios.get(`/roles`);
    setLoading(false);
    setRows(response.data);
  }

  useEffect(() => {
    loadUsers();
  }, []); // eslint-disable-line

  async function handleDelete(id) {
    try {
      await axios.delete(`/roles/${id}`);
      setOpen(false);
      setDeleteId(null);
      toast.success("Perfil deletado com sucesso");
      await loadUsers();
    } catch (err) {
      toast.error('Ocorreu um erro, tente novamente');
    }
  }

  function handleDeleteModal(id) {
    setDeleteId(id);
    setOpen(true);
  }

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const headRows = [
    { id: "name", numeric: false, disablePadding: true, label: "Nome" },
    {
      id: "descrição",
      numeric: false,
      disablePadding: false,
      label: "Descrição",
    },
    { id: "actions", numeric: true, disablePadding: false, label: "Ações" },
  ];

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.numeric ? "right" : "left"}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Perfis</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/roles`}>
                Perfis
              </Link>
            </div>
          </div>

          <div className="kt-subheader__toolbar">
            <div className="kt-subheader__wrapper">
              <Link
                to="/roles/add"
                className="btn btn-danger kt-subheader__btn-options"
              >
                Novo perfil
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(!open);
          setDeleteId(null);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <h2 id="transition-modal-title">Confirmação</h2>
            <p id="transition-modal-description" style={{ marginBottom: 40 }}>
              Tem certeza que deseja excluir esse perfil?
            </p>
            <button
              type="button"
              className="btn btn-danger kt-subheader__btn-options"
              onClick={() => handleDelete(deleteId)}
            >
              Sim, excluir
            </button>
            <button
              type="button"
              className="btn btn-light kt-subheader__btn-options"
              onClick={() => {
                setOpen(false);
                setDeleteId(null);
              }}
            >
              Cancelar
            </button>
          </div>
        </Fade>
      </Modal>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows &&
                stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.id}>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="left">{row.description}</TableCell>
                        <TableCell align="right">
                          <Link
                            to={`/roles/${row.id}`}
                            className="btn btn-sm pl-2 pr-2"
                          >
                            <EditIcon />
                          </Link>
                          <button
                            type="button"
                            className="btn btn-sm pl-2 pr-2"
                            onClick={() => {
                              handleDeleteModal(row.id);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
