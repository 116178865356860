import React, { memo } from "react";

import { Modal } from "react-bootstrap";
import { Mail } from "@material-ui/icons";

import { format } from 'date-fns';

function Detail({ open, setOpen, detail }) {
  function resetModal() {
    setOpen(false);
  }

  const formatEvent = (event) => {
    switch(event) {
      case 'processed': return 'Enviado';
      case 'open': return 'Lido';
      case 'click': return 'Lido';
      case 'dropped': return 'Inválido';
      case 'deferred': return 'Inválido';
      case 'bounce': return 'Inválido';
      case 'delivered': return 'Enviado';
      default: return 'Enviando...';
    }
  }

  return (
    <Modal show={open} onHide={resetModal} centered>
      <Modal.Header closeButton>
        <h4 className="mb-0">{detail.subject}</h4>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex"
          style={{
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            padding: "10px",
            background: "#f9f9f9",
            borderRadius: "6px",
          }}
        >
          <Mail style={{ padding: '10px', fontSize: '46px', background: '#eee', borderRadius: '6px' }}/>
          <h5 className="ml-3">{detail.email}</h5>
        </div>
        <div
          style={{
            width: "100%",
            padding: "10px",
            background: "#f9f9f9",
            borderRadius: "6px",
            marginTop: "6px",
          }}
        >
          {detail.message}
        </div>
        <hr />
        <small className="d-block text-center">
          Hash: {detail.events && detail.events.length > 0 && detail.events[0].sg_message_id}
        </small>
        <hr />
        <h6 className="text-center">Eventos</h6>
        <div>
          {detail.events && detail.events.map((event, index) => (
            <div key={index} className="d-flex justify-content-between align-items-center mt-2 mb-2">
              <strong>{formatEvent(event.event)}</strong>
              <small>{format(new Date(event.createdAt), 'dd/MM/yyyy - HH:mm')}</small>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default memo(Detail);
