import React, { memo, useState } from 'react';

import { Modal, Backdrop, makeStyles } from '@material-ui/core';
import { Row, Form } from 'react-bootstrap';

function ModalCOnfirmationRefuse({ 
  open,
  setOpen,
  onConfirm,
 }) {
  const [reason, setReason] = useState('');
  const useStyles = makeStyles(theme => ({
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "470px",
      height: "300px",
      overflow: "auto"
    },
  }));
  const classes = useStyles();

  return (
    <Modal
        className={classes.modalsm}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.fadesm}>
          <Row>
            <div className="col-12">
              <div className="col-12 confirm">
                <i className="flaticon2-information"></i>
                <p>Tem certeza que deseja <strong>Indeferir</strong> este cadastro?</p>
                <Form.Control placeholder="Motivo" value={reason} onChange={e => setReason(e.target.value)}/>
                <div className="mt-4">
                  <button
                    onClick={() => {
                      onConfirm(reason);
                      setReason('');
                    }}
                    disabled={reason.length === 0}
                    className="btn btn-danger mr-3"
                  >
                    Sim
                  </button>
                  <button
                    onClick={() => setOpen(false)}
                    className="btn btn-secondary"
                  >
                    Não
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Modal>
  );
}

export default memo(ModalCOnfirmationRefuse);