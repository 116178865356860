import api from "../services/api";

export const LOGIN_URL = "/auth/sign-in";
export const REGISTER_URL = "/register";
export const REQUEST_PASSWORD_URL = "/request-password";

export const ME_URL = "/auth";

export async function login(email, password) {
  const { data } = await api.post(LOGIN_URL, { email, password }); // { data };
  return { data }
}

export function register(email, fullname, username, password) {
  return api.post(REGISTER_URL, { email, fullname, username, password });
}

export async function requestPassword(email) {
  return await api.post(REQUEST_PASSWORD_URL, { email });
}

export async function getUserByToken(token) {
  // Authorization head should be fulfilled in interceptor.
  const { data } = await api.get(ME_URL, {
    headers: {'Authorization': `Bearer ${token}`}
  }); // Retorno dos dados do usuário passando o token
  api.defaults.headers.Authorization = `Bearer ${token}`;
  return { data }
}
