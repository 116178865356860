import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import UserPage from "./user/UserPage";
import TeamPage from "./team/TeamPage";
import PostPage from "./post/PostPage";
import DemonstrativePage from "./demonstrative/DemonstrativePage";
import NewsletterPage from "./newsletter/NewsletterPage";
import ContactPage from "./contact/ContactPage";
import WorkPage from "./work/WorkPage";
import AgentPage from "./agent/AgentPage";
import RegisterPage from "./register/RegisterPage";
import ECnpjPage from "./e_cnpj/ECnpjPage";
import RegisterIncompletePage from "./register_incomplete/RegisterIncompletePage";
import RegisterRefusePage from "./register_refuse/RegisterRefusePage";
import ProfilePage from "./profile/ProfilePage";
import ConfirmationPage from "./confirmation/ConfirmationPage";
import { LayoutSplashScreen } from "../../../_metronic";
import PermissionsPage from "./permissions/PermissionsPage";
import RolesPage from "./roles/RolesPage";
import CnpjSearchPage from "./cnpj_search/CnpjSearchPage";
import ChargePage from "./charge/ChargePage";
import ChargeDetailPage from "./charge_detail/ChargeDetailPage";
import ProtestPage from "./protest/ProtestPage";
import ProtestPayerPage from "./protest-payer/ProtestPayerPage";
import RevenuePage from "./revenue/RevenuePage";
import MailPage from "./mail/MailPage";
import TemplatePage from "./templates/TemplatePage";
import MailProspectionPage from "./mail-prospection/MailPage";
import JuridicalProcessesSearchPage from "./juridical_processes_search/JuridicalProcessesSearchPage";


export default function MainPage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/users" component={UserPage} />
        <Route path="/roles" component={RolesPage} />
        <Route path="/permissions" component={PermissionsPage} />
        <Route path="/teams" component={TeamPage} />
        <Route path="/posts" component={PostPage} />
        <Route path="/demonstratives" component={DemonstrativePage} />
        <Route path="/newsletters" component={NewsletterPage} />
        <Route path="/contacts" component={ContactPage} />
        <Route path="/works" component={WorkPage} />
        <Route path="/agents" component={AgentPage} />
        <Route path="/e-cnpj" component={ECnpjPage} />
        <Route path="/registers" component={RegisterPage} />
        <Route
          path="/register_incompletes"
          component={RegisterIncompletePage}
        />
        <Route path="/register_refuses" component={RegisterRefusePage} />
        <Route path="/profile" component={ProfilePage} />
        <Route path="/confirmation" component={ConfirmationPage} />
        <Route path="/cnpj" component={CnpjSearchPage} />
        <Route path="/charge/operation" component={ChargePage} />
        <Route path="/charge/detail" component={ChargeDetailPage} />
        <Route path="/relato/protest" component={ProtestPage} />
        <Route path="/relato/protest-payer" component={ProtestPayerPage} />
        <Route path="/revenue" component={RevenuePage} />
        <Route path="/mail" component={MailPage} />
        <Route path="/mail-prospection" component={MailProspectionPage} />
        <Route path="/juridical-processes" component={JuridicalProcessesSearchPage} />
        <Route path="/templates" component={TemplatePage} />

        <Redirect to="/error" />
      </Switch>
    </Suspense>
  );
}
