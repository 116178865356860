import React from "react";
import { Route, Switch } from "react-router-dom";
import DemonstrativeIndex from "./DemonstrativeIndex";
import DemonstrativeAdd from "./DemonstrativeAdd";
import DemonstrativeEdit from "./DemonstrativeEdit";

export default function DemonstrativePage() {
  return (
    <Switch>
      <Route exact path="/demonstratives/" component={DemonstrativeIndex} />
      <Route exact path="/demonstratives/add" component={DemonstrativeAdd} />
      <Route exact path="/demonstratives/:id" component={DemonstrativeEdit} />
    </Switch>
  );
}
