import React, { useState, useEffect } from 'react';

import axios from 'axios';
import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export default function ModalViewCte({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  async function consultCte() {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-cte/${open.cte}`);
      setResult({
        ...response.data,
        open: true,
      });
    }catch(err) {
      toast.error('Ocorreu um erro ao consultar esta CTe')
    }
    setLoading(false);
  }
  useEffect(() => {
    if(open.cte !== '') {
      consultCte();
    }
  }, [open.cte]); // eslint-disable-line

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '800px',
      height: '90%',
      overflow: 'auto'
    },
    box: {
      flex: '1',
      border: '1px solid #eee',
      background: '#f9f9f9',
      padding: '10px'
    }
  }));
  const classes = useStyles();
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open.active}
        onClose={() => { setOpen({ active: false, cte: '' })}}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open.active}>
          <div className={classes.fade}>
            {loading && (
              <CircularProgress />
            )}
            {result.open && (
              <>
              <div className="row">
                <div className="col-md-11">
                  <h2 id="transition-modal-title">Detalhamento CTe</h2>
                </div>
                <div className="col-md-1">
                  <button 
                    className="btn btn-light btn-sm"
                    type="button" 
                    onClick={() => { setOpen({ active: false, cte: '' }); setResult({}); }}
                  >
                    X
                  </button>
                </div>
              </div>
              
              CTe: {result.cte.chCTe}
              <table className="table table-bordered mt-4">
              <thead>
                <tr>
                  <td>CNPJ</td>
                  <td>Status</td>
                  <td>Motivo</td>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>{result.cte.chCTe.slice(6, 8)}.{result.cte.chCTe.slice(8, 11)}.{result.cte.chCTe.slice(11, 14)}/{result.cte.chCTe.slice(14, 18)}-{result.cte.chCTe.slice(18, 20)}</td>
                  <td>{result.cte.cStat}</td>
                  <td>{result.cte.xMotivo}</td>
                </tr>
                </tbody>
              </table>
              <h4 className="mt-4">Registros</h4>
              <div className="row">
                {result.retEvents.length > 0 ? result.retEvents.map((event, index) => {
                  return (
                    <div className="col-md-6" key={index} style={{ padding: '10px' }}>
                      <div className={classes.box}>
                      <h5>{event.xEvento}</h5>
                      <p>Protocolo: {event.nProt}</p>
                      <hr />
                      <small>{format(new Date(event.dhRegEvento), 'dd/MM/yyyy')}</small>
                      </div>
                    </div>
                  )
                }) : (
                  <h6>Nenhum Registro encontrado...</h6>
                )}
              </div>
              <div className="mt-4 center">
                <button 
                  type="button" 
                  className="btn btn-secondary kt-subheader__btn-options" 
                  onClick={() => { setOpen({ active: false, cte: '' }); setResult({}); }}
                  disabled={loading}  
                >
                    Fechar
                </button>
                </div>
                </>
              )}
          </div>
        </Fade>
      </Modal>
  );
}
