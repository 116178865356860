import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Paper,
  LinearProgress,
  makeStyles,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import AgentView from './AgentView';

export default function AgenteIndex() {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '900px',
      height: '100%',
      maxHeight: '700px',
      overflow: 'auto'
    },
    close: {
      background: '#fff',
      fontSize: '20px',
      border: 0,
      textAlign: 'right',
      width: '100%',
    },
    wrap: {
      display: 'flex',
      justifyContent: 'space-beetwen',
      alignItems: 'center',
    }
  }));
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [detail, setDetail] = React.useState({});
  
  async function loadAgents() {
    setLoading(true);
    const response = await axios.get(`/agents`);
    setLoading(false);
    setRows(response.data);
  }

  useEffect(() => {
    loadAgents();
  }, []); // eslint-disable-line

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  async function handleView(id) {
    const response = await axios.get(`/agents/${id}`);
    setDetail(response.data)
    setOpen(!open);
    await loadAgents();
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
  const headRows = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Nome' },
    { id: 'phone', numeric: false, disablePadding: false, label: 'Telefone' },
    { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
    { id: 'cpf', numeric: false, disablePadding: false, label: 'CPF' },
    { id: 'city', numeric: false, disablePadding: false, label: 'Cidade' },
    { id: 'profession', numeric: false, disablePadding: false, label: 'Profissão' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Seja um agente
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/agents`}>
                Seja um agente
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AgentView detail={detail} open={open} setOpen={e => setOpen(e)} classes={classes}/>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
        {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={row.id}
                    >
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.cpf}</TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.profession}</TableCell>
                      <TableCell align="right">
                        <button
                          type="button"
                          className="btn btn-sm pl-2 pr-2"
                          onClick={() => handleView(row.id)}>
                          <VisibilityIcon />
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
