import React, { useState } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
} from "@material-ui/core";

import ModalDetail from "./ModalDetail";

export default function ConfirmationNewByCnpj({ open, setOpen }) {
  const [view, setView] = useState(false);
  const [cnpj, setCnpj] = useState("");

  async function consultcnpj() {
    setView(true);
    setOpen(false);
  }

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "600px",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <ModalDetail open={view} setOpen={(e) => setView(e)} cnpj={cnpj.replace(/[^\d]/g, "")} />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => {
          setOpen(false);
          setCnpj("");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <h2 id="transition-modal-title">Buscar empresa por CNPJ</h2>
            <hr />
              <input
                placeholder="CNPJ"
                className="form-control mt-4"
                value={cnpj}
                onChange={(e) => {
                  const onlyNums = e.target.value.replace(/[^\d]/g, "");
                  if (onlyNums.length <= 2) {
                    setCnpj(onlyNums);
                  } else if (onlyNums.length <= 5) {
                    setCnpj(`${onlyNums.slice(0, 2)}.${onlyNums.slice(2, 5)}`);
                  } else if (onlyNums.length <= 8) {
                    setCnpj(
                      `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                        2,
                        5
                      )}.${onlyNums.slice(5, 8)}`
                    );
                  } else if (onlyNums.length <= 12) {
                    setCnpj(
                      `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                        2,
                        5
                      )}.${onlyNums.slice(5, 8)}/${onlyNums.slice(8, 12)}`
                    );
                  } else {
                    setCnpj(
                      `${onlyNums.slice(0, 2)}.${onlyNums.slice(
                        2,
                        5
                      )}.${onlyNums.slice(5, 8)}/${onlyNums.slice(
                        8,
                        12
                      )}-${onlyNums.slice(12, 14)}`
                    );
                  }
                }}
              />
            <div className="mt-4 center">
                <button
                  type="button"
                  className="btn btn-primary kt-subheader__btn-options mr-4"
                  onClick={consultcnpj}
                  disabled={cnpj.length < 18}
                >
                  Buscar
                </button>
                <button
                  type="button"
                  className="btn btn-light kt-subheader__btn-options"
                  onClick={() => {
                    setOpen(false);
                    setCnpj("");
                  }}
                >
                  Cancelar
                </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
