import React, { useState, useEffect } from "react";

import {
  Modal,
  Fade,
  Backdrop,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";

import { toast } from "react-toastify";
import SelectBorderosByCnpj from "../components/SelectBorderosByCnpj";
import { format, isPast } from "date-fns";

import axios from "axios";

export default function ConfirmationByCnpj({ open, setOpen, onReload }) {
  const [borderos, setBorderos] = useState([]);
  const [nfesLength, setNfesLength] = useState(0);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const [hasEcnpj, setHasEcnpj] = useState({});

  useEffect(() => {
    if (open.cnpj && open.active) {
      async function loadBordero() {
        setLoading(true);
        try {
          const response = await axios.get(
            `${
              process.env.REACT_APP_API_URL
            }/borderos-nfe-cnpj/${open.cnpj.replace(/\D/g, "")}`
          );
          if (response.status === 204) {
            toast.error("Borderô com notas fiscais inválida");
            setOpen({ active: false, cnpj: "" });
            setBorderos([]);
            setSelected([]);
          } else {
            setBorderos(response.data.borderos.map(bordero => {
              return {
                ...bordero,
                nfes: bordero.nfes.map(nfe => {
                  return {
                    ...nfe,
                    sacadoDocument: nfe.cnpj_cpf_sacado
                  }
                })
              }
            }));
            setSelected([]);
          }
        } catch (err) {
          setBorderos([]);
          setOpen({ active: false, cnpj: "" });
          toast.error("Nenhum Boderô NFe encontrado.");
        }
        try {
          const { data } = await axios.get(
            `${
              process.env.REACT_APP_API_URL
            }/customer-register/e-cnpj/file/${open.cnpj.replace(/\D/g, "")}`
          );
          setHasEcnpj(data);
        } catch (err) {
          if (err.response && err.response.status === 404) {
            setHasEcnpj({});
          } else if (err.response && err.response.status === 500) {
            toast.error(
              "Ocorreu um erro em nossos servidores ao buscar e-CNPJ, contate a equipe técnica"
            );
          } else {
            toast.error(
              "Ocorreu um erro em nossa aplicação ao buscar e-CNPJ, contate a equipe técnica"
            );
          }
        }
        setLoading(false);
      }
      loadBordero();
    }
  }, [open.cnpj]); // eslint-disable-line

  function handleSelectApprove(item) {
    const alreadyHave = selected.findIndex((i) => i.bordero === item.bordero);
    if (alreadyHave === -1) {
      setSelected([
        ...selected,
        {
          ...item,
          bordero: item.borderoData.bord_id,
          nfes: item.nfes.filter((n) => n.nfe[0] + n.nfe[1] === "26"),
        },
      ]);
    } else {
      let lock = selected;
      if (selected[alreadyHave].nfes.find((i) => i.nfe === item.nfes[0].nfe)) {
        setSelected([]);
        lock[alreadyHave].nfes.filter((i) => i.nfe !== item.nfes[0].nfe);
        setSelected(lock);
      } else {
        setSelected([]);
        lock[alreadyHave].nfes.push({ ...item.nfes[0] });
        setSelected(lock);
      }
    }
  }

  async function handleSubmit() {
    setLoading(true);
    try {
      const { data } = await axios.get("auth");
      for (let i = 0; i < selected.length; i++) {
        await axios.post(
          `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/request-list/list`,
          {
            bordero: String(selected[i].borderoData.bord_id),
            borderoData: {
              ufCedente: selected[i].borderoData.uf_cedente,
              cnpj: selected[i].borderoData.cnpj,
              cedente: selected[i].borderoData.cedente,
            },
            nfes: selected[i].nfes,
            user: data.id,
          }
        );
      }
      setOpen({ active: false, cnpj: "" });
      toast.info(
        "Notas foram enviadas para análise. Notificaremos quando todas forem analisadas"
      );
      setBorderos([]);
      onReload();
      setSelected([]);
    } catch (err) {
      toast.error("Ocorreu um erro ao tentar realizar análise");
    }
    setLoading(false);
  }

  useEffect(() => {
    if (selected.length > 0) {
      let count = 0;
      selected.forEach((i) => (count += i.nfes.length));
      setNfesLength(count);
    } else {
      setNfesLength(0);
    }
  }, [selected]);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "90%",
      overflow: "auto",
    },
    box: {
      flex: "1",
      border: "1px solid #eee",
      background: "#f9f9f9",
      padding: "10px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open.active}
      onClose={() => {
        setOpen({ active: false, cnpj: "" });
        setSelected([]);
        setBorderos([]);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open.active}>
        <div className={classes.fade}>
          <>
            <div className="row">
              <div className="col-md-11">
                <h2 id="transition-modal-title">Selecionar Borderôs</h2>
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-light btn-sm"
                  type="button"
                  onClick={() => {
                    setOpen({ active: false, bordero: "" });
                    setBorderos([]);
                    setSelected([]);
                  }}
                >
                  X
                </button>
              </div>
            </div>
            <h3
              className="mt-4"
              style={{
                border: "1px solid rgb(100, 108, 154)",
                padding: "10px",
              }}
            >
              Cedente: {borderos.length > 0 && borderos[0].borderoData.cedente}
            </h3>
            <div className={classes.box}>
              <span>CNPJ: {open.cnpj}</span>
              <span>
                UF: {borderos.length > 0 && borderos[0].borderoData.uf_cedente}
              </span>
            </div>
            {!loading && (
              <div
              className="d-flex justify-content-start align-items-center mt-2"
              style={{ padding: "15px", borderRadius: "4px", background: "#f9f9f9" }}
            >
              <i className="fas fa-key" style={{ fontSize: '28px' }}></i>
              {Object.keys(hasEcnpj).length > 0 ? (
                <div className={`ml-3 d-flex flex-column ${isPast(new Date(hasEcnpj.expires_in)) ? 'text-danger' : ''}`}>
                  <strong>e-CNPJ {isPast(new Date(hasEcnpj.expires_in)) ? 'Expirado' : 'Instalado'}</strong>
                  <small>Expira em: {format(new Date(hasEcnpj.expires_in), 'dd/MM/yyyy')}</small>
                </div>
              ) : (
                <div className="ml-3">
                  <strong>Nenhum e-CNPJ instalado</strong>
                </div>
              )}
            </div>
            )}
            
            <div>
              <h4 className="mt-4">Borderôs NFe</h4>
              <div className="mt-4">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <button
                    type="button"
                    disabled={!selected.length > 0 || loading}
                    className="btn btn-primary btn-sm btn-upper mr-2"
                    onClick={handleSubmit}
                  >
                    Enviar para análise
                  </button>
                  <div>
                    {selected.length > 0 && (
                      <strong>
                        {selected.length} Borderô(s) Selecionado(s)
                        <br />
                        {nfesLength} NFe(s) no Total
                      </strong>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row">
                {loading ? (
                  <>
                    <CircularProgress
                      style={{ width: "20px", height: "20px" }}
                    />{" "}
                    Buscando
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        padding: "10px 20px",
                        border: "1px solid #eee",
                      }}
                      className="col-md-12 mb-4 bg-primary text-white"
                    >
                      <h5>Borderô</h5>
                      <h5>N° de Notas</h5>
                      {borderos.length > 0 &&
                      selected.length === borderos.length ? (
                        <button
                          type="button"
                          className="btn btn-sm btn-upper"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                          }}
                          onClick={() => {
                            setSelected([]);
                          }}
                        >
                          Esvaziar Seleção
                          <span
                            className={"fa fa-check mr-4"}
                            style={{
                              width: "18px",
                              height: "18px",
                              border: "0",
                              background: "#dee2ea",
                              color: "#222",
                              display: "inline-block",
                              borderRadius: "5px",
                              marginLeft: "10px",
                            }}
                          ></span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-upper"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#fff",
                          }}
                          onClick={() => {
                            const s = [];
                            borderos.forEach((i) => {
                              let nfes_filtered = i.nfes.filter((nfe) => {
                                if(nfe.status) {
                                  if (Object.keys(hasEcnpj).length > 0) {
                                    if (nfe.stauts === 'a confirmar') {
                                      return true;
                                    }else{
                                      return false;
                                    }
                                  }else{
                                    return false;
                                  }
                                }else{
                                  return true;
                                }
                              });
                              s.push({
                                ...i,
                                nfes: nfes_filtered,
                                bordero: i.borderoData.bord_id,
                              });
                            });
                            setSelected(s);
                          }}
                        >
                          Selecionar Todos
                          <span
                            className={"mr-4"}
                            style={{
                              width: "18px",
                              height: "18px",
                              border: "0",
                              background: "#dee2ea",
                              display: "inline-block",
                              borderRadius: "5px",
                              marginLeft: "10px",
                            }}
                          ></span>
                        </button>
                      )}
                      <h5>Status</h5>
                      <div style={{ width: "33%" }}></div>
                      <div style={{ width: "47.5px", height: "39.06px" }}></div>
                    </div>
                    {borderos.length > 0 ? (
                      borderos.map((item, index) => {
                        return (
                          <SelectBorderosByCnpj
                            classes={classes}
                            item={item}
                            key={index}
                            index={index}
                            handleSelectApprove={(e) => handleSelectApprove(e)}
                            selected={selected}
                            setSelected={(e) => setSelected(e)}
                          />
                        );
                      })
                    ) : (
                      <h6>Nenhum Borderô encontrado...</h6>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-secondary kt-subheader__btn-options"
                onClick={() => {
                  setOpen({ active: false, bordero: "" });
                  setBorderos([]);
                  setSelected([]);
                }}
              >
                Fechar
              </button>
            </div>
          </>
        </div>
      </Fade>
    </Modal>
  );
}
