import React from "react";

export default function SelectTransporterItem({
  select,
  index,
  item,
  classes,
  handleSelectApprove,
}) {
  return (
    <button
      type="button"
      className="col-md-12"
      style={{
        padding: "10px",
        background: "none",
        border: "none",
        opacity: (item.status === "confirmado" || item.status === "cte nao encontrada") && "0.6",
      }}
      key={index}
      disabled={item.status === "confirmado" || item.status === "cte nao encontrada"}
      onClick={() => handleSelectApprove({ ...item, index })}
    >
      <div
        className={classes.box}
        style={{
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {item.status !== "confirmado" && item.status !== "cte nao encontrada" && (
          <span
            className={select ? "fa fa-check" : ""}
            style={{
              width: "20px",
              height: "20px",
              border: "0",
              background: "#dee2ea",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px",
              marginRight: "30px",
              marginBottom: "10px",
            }}
          ></span>
        )}
        <h4 style={{ color: "#5867dd" }}>CTe: {item.chCte}</h4>
        {item.status === "confirmado" && (
          <span
            style={{ flex: "0.7" }}
            className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded"
          >
            Confirmado
          </span>
        )}
        {item.status === "a confirmar" && (
          <span
            style={{ flex: "0.7" }}
            className="kt-badge kt-badge--brand kt-badge--inline kt-badge--pill kt-badge--rounded"
          >
            A Confirmar
          </span>
        )}
        {item.status === "error" && (
          <span
            style={{ flex: "0.7" }}
            className="kt-badge kt-badge--warning kt-badge--inline kt-badge--pill kt-badge--rounded"
          >
            Erro
          </span>
        )}
        {item.status === "rejeitado" && (
          <span
            style={{ flex: "0.7" }}
            className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
          >
            Recusado
          </span>
        )}
        {item.status === "nao confirmado" && (
          <span
            style={{ flex: "0.7" }}
            className="kt-badge kt-badge--dark kt-badge--inline kt-badge--pill kt-badge--rounded"
          >
            Não confirmado
          </span>
        )}
        {item.status === "invalida" && (
          <span
            style={{ flex: "0.7" }}
            className="kt-badge kt-badge--danger kt-badge--inline kt-badge--pill kt-badge--rounded"
          >
            Inválida
          </span>
        )}
        {item.status === "cte nao encontrada" && (
              <span
                className="kt-badge kt-badge--primary kt-badge--inline kt-badge--pill kt-badge--rounded"
                style={{ background: '#ff7d00', flex: "0.7" }}
              >
                Não encontrado
              </span>
            )}
        {item.status === "" && (
          <span
            style={{ flex: "0.7" }}
            className="kt-badge kt-badge--secondary kt-badge--inline kt-badge--pill kt-badge--rounded"
          >
            Em Análise
          </span>
        )}
      </div>
    </button>
  );
}
