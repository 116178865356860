import React from "react";
import { Route, Switch } from "react-router-dom";
import RegisterIndex from "./RegisterIndex";
import RegisterView from "./RegisterView";

export default function RegisterPage() {
  return (
    <Switch>
      <Route exact path="/registers/" component={RegisterIndex} />
      <Route exact path="/registers/:id" component={RegisterView} />
    </Switch>
  );
}
