import React, { useState } from "react";

import { Modal, Fade, Backdrop, makeStyles } from "@material-ui/core";

export default function ConfirmationNewByCnpj({ open, setOpen, getConsult }) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);

  const resetModal = () => {
    setValue("");
    setLoading(false);
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "600px",
    },
  }));
  const classes = useStyles();
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => resetModal()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fade}>
            <h2 id="transition-modal-title">Buscar</h2>
            <hr />

            <h4 className="mt-4" style={{ textAlign: "center" }}>
              Buscar
            </h4>
            <input
              placeholder="Borderô"
              className="form-control mt-4"
              value={value}
              onChange={(e) => {
                setValue(e.target.value.replace(/[^\d]/g, ""));
              }}
            />
            <div className="mt-4 center">
              <button
                type="button"
                className="btn btn-primary kt-subheader__btn-options mr-4"
                onClick={() => {
                  resetModal();
                  getConsult({ value });
                }}
                disabled={value.length === 0 || loading}
              >
                Pesquisar
              </button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
