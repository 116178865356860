import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Paper,
  FormControl,
  TextField,
  makeStyles,
  MenuItem,
  Select,
  InputLabel
} from "@material-ui/core";
import { toast } from "react-toastify";

export default function UserEdit({ history, match }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({
    username: '',
    email: '',
    fullname: '',
    roles: [],
    gerente_id: 'null',
  });
  const [roles, setRoles] = useState([])
  const [gerentes, setGerentes] = useState([]);

  useEffect(() => {
    async function loadGerente() {
      try {
        const { data } = await axios.get("facta/gerente");
        setGerentes(data);
      } catch (error) {}
    }
    loadGerente();
  }, []);

  useEffect(() => {
    async function loadUser() {
      try {
        const response = await axios.get(`/users/${match.params.id}`);
        setValues({ ...response.data, gerente_id: response.data.gerente_id ? response.data.gerente_id : 'null', });
       
      } catch (err) {
        console.log(err);
      }
    }
    loadUser();
  }, [match.params.id]);

  useEffect(() => {
      axios.get('/roles').then(response => {
        setRoles(response.data)
      });
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await axios.put(`/users/${match.params.id}`, {...values, gerente_id: values.gerente_id !== 'null' ? values.gerente_id : null, roles: [values.roles[0].id]});
      toast.success("Usuário atualizado com sucesso");
      history.push("/users");
    } catch (err) {
      console.log(err);
    }
  }

  function handleSelectRoleChange(e){
    const filteredRole = roles.filter(role => role.id === e.target.value)

    setValues({ ...values, roles: filteredRole})
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Usuários</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/users`}>
                Usuários
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/users/${match.params.id}`}
              >
                Editar Usuário
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Username"
              value={values.username || ""}
              onChange={(e) =>
                setValues({ ...values, username: e.target.value })
              }
              required
            />
          </FormControl>
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Email"
              type="email"
              value={values.email || ""}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Nome Completo"
              value={values.fullname || ""}
              onChange={(e) =>
                setValues({ ...values, fullname: e.target.value })
              }
              required
            />
          </FormControl>
          <FormControl>
            <Select 
              labelId="select-perfil"
              variant="outlined"
              style={{ marginTop: '20px'}}
              value={values.roles[0] ? values.roles[0].id : ""} 
              onChange={(e) => handleSelectRoleChange(e)}
            > 
              {roles.map(role => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
            </Select>
          </FormControl>
            <FormControl>
              <InputLabel style={{padding: '15px 0 0 15px'}} id="select-agents">Gerente no Facta *</InputLabel>
              <Select 
                labelId="select-agents"
                variant="outlined"
                style={{ marginTop: '20px'}}
                value={values.gerente_id} 
                onChange={(e) => setValues({ ...values, gerente_id: e.target.value })}
              > 
                <MenuItem value={'null'}>
                  NÃO É GERENTE NO FACTA
                </MenuItem>
                {gerentes.map(gerente => (<MenuItem key={gerente.GERE_ID} value={gerente.GERE_ID}>{gerente.NOME}</MenuItem>))}
              </Select>
            </FormControl>
          <FormControl>
            <button
              type="submit"
              style={{ marginTop: 20 }}
              className="btn btn-primary mt-3"
            >
              Atualizar
            </button>
            <Link to="/users" className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}
