import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Paper,
  FormControl,
  TextField,
  makeStyles } from '@material-ui/core';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';

export default function UserPassword({ history, match }) {
  const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();
  const [values, setValues] = useState({
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState({
    button: true,
    error: false,
    message: [],
  });
  
  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get('auth')
      if(data.profile !== 1) {
        // history.push('/users');
      }
    }
    loadUser()
  }, []) // eslint-disable-line

  useEffect(() => {
    if(values.password.length === values.confirmPassword.length && values.password.length !== 0) {
      setError({
        button: !(values.password === values.confirmPassword),
        error: !(values.password === values.confirmPassword),
        message: [],
      });
    } else {
      setError({
        button: true,
        error: false,
        message: [],
      });
    }
  }, [values.password, values.confirmPassword]);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.put(`/users/password/${match.params.id}`, { password: values.password, });
      if (response.status === 203) {
        setError({ ...error, message: response.data, });
      } else {
        toast.success('Senha atualizada com sucesso');
        history.push('/users');
      }
    }catch(err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Usuários
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/users`}>
                Usuários
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/users/password/${match.params.id}`}>
                Editar Senha
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          {error.message.length !== 0 && (
            <Alert variant="danger">
              <ul>
                {error.message.map(er => (
                  <li key={er.message}>
                      {er.message}
                  </li>
                  ))}
              </ul>
            </Alert>
          )}
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Senha"
              type="password"
              defaultValue={values.password}
              onChange={(e) => setValues({ ...values, password: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Repetir Senha"
              type="password"
              defaultValue={values.confirmPassword}
              onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
              required
            />
          </FormControl>
          {error.error === true && <h2>Senhas não coincidem</h2>}
          <FormControl>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={error.button}
              >Salvar</button>
            <Link
              to="/users"
              className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}

