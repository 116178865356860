import React from "react";
import { Route, Switch } from "react-router-dom";
import PostIndex from "./PostIndex";
import PostAdd from "./PostAdd";
import PostEdit from "./PostEdit";

export default function PostPage() {
  return (
    <Switch>
      <Route exact path="/posts/" component={PostIndex} />
      <Route exact path="/posts/add" component={PostAdd} />
      <Route exact path="/posts/:id" component={PostEdit} />
    </Switch>
  );
}
