import React, { memo } from "react";

function CancelButton(props) {
  return (
    <button
      type="button"
      className="btn btn-secondary"
      onClick={() => {
        props.setSelectApprove(false);
        props.setSelectExempt(false);
        props.setSelectReprove({
          ...props.selectReprove,
          docs: false
        });
      }}
      style={{ marginLeft: "5px" }}
    >
      Cancelar
    </button>
  );
}

export default memo(CancelButton);
