import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Paper,
  FormControl,
  TextField,
  Select,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import { toast } from 'react-toastify';

export default function UserEdit({ history, match }) {
  const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();
  const [values, setValues] = useState({});
  useEffect(() => {
    async function loadUser() {
      try {
        const response = await axios.get(`/teams/${match.params.id}`);
        setValues(response.data);
      } catch(err) {
        console.log(err);
      }
    }
    loadUser();
  }, [match.params.id]);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await axios.put(`/teams/${match.params.id}`, values);
      toast.success('Colaborador atualizado com sucesso');
      history.push('/teams');
    }catch(err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Colaboradores
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/teams`}>
                Colaboradores
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/teams/${match.params.id}`}>
                Editar Colaborador
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Nome"
              value={values.name || ''}
              onChange={(e) => setValues({ ...values, name: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Cargo"
              value={values.office || ''}
              
              onChange={(e) => setValues({ ...values, office: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <Select 
              variant="outlined"
              id="sex"
              name="sex"
              value={values.sex || ''}
              onChange={(e) => setValues({ ...values, sex: e.target.value })}
              required
            >
              <MenuItem value="M">Masculino</MenuItem>
              <MenuItem value="F">Feminino</MenuItem>
            </Select>
          </FormControl>
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Local"
              value={values.locale || ''}
              onChange={(e) => setValues({ ...values, locale: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              >Atualizar</button>
            <Link
              to="/teams"
              className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}

