import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  TextField,
  Paper,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import { FindReplace } from '@material-ui/icons';
import ModalNewConsult from './ModalNewConsult';

export default function NfeDetachedIndex() {
  const [open, setOpen] = useState({ active: false, nfe: '' });
  const [nfe, setNfe] = useState('');
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: 'auto',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(0,0,0,.3)'
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: '100%',
      maxWidth: '500px'
    }
  }));
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  async function loadNFe() {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-nfe`);
      let nfes = [];

      response.data.nfes.forEach(n => {
        const json_data = JSON.parse(n.jsonData);
        return nfes.push({
          ...n,
          status: json_data.retConsSitNFe.cStat,
          xMotivo: json_data.retConsSitNFe.xMotivo,
        })
      })
      setRows(nfes);
    }catch(err) {
      toast.error('Ocorreu um erro ao tentar buscar NFe`s')
    }
    setLoading(false);
  }

  async function submitSearchNfe() {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BFC_DIGITAL_URL}/consult-nfe/${nfe}`);

      const json_data = JSON.parse(response.data.nfe.jsonData)
      
      let nfe_response = {
        ...response.data.nfe,
        status: json_data.retConsSitNFe.cStat,
        xMotivo: json_data.retConsSitNFe.xMotivo,
      }
      setRows([nfe_response]);
    }catch(err) {
      toast.info('Nenhuma Consulta encontrada')
    }
    setLoading(false);
  }

  useEffect(() => {
    loadNFe();
  }, []); // eslint-disable-line

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  
  function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
  }
  
  const headRows = [
    { id: 'nfe', numeric: false, disablePadding: true, label: 'NFe' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'motive', numeric: false, disablePadding: false, label: 'Motivo' },
    { id: 'date', numeric: false, disablePadding: false, label: 'Última Consulta' },
    { id: 'actions', numeric: true, disablePadding: false, label: 'Ações' },
  ];
  
  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          {headRows.map(row => (
            <TableCell
              key={row.id}
              align={row.numeric ? 'right' : 'left'}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };
  
  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Consulta NFe Avulsa
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/confirmation/nfe_detached`}>
                Consulta NFe Avulsa
              </Link>
            </div>
          </div>

          <div className="kt-subheader__toolbar">
            <div className="d-flex flex-row kt-subheader__wrapper">
            <div
                style={{
                  background: "#fff",
                  borderRadius: "5px",
                  padding: "5px 10px 10px",
                  display: "flex",
                  alignItems: "flex-end"
                }}
                className="mr-4"
              >
                <div>
                <TextField
                    placeholder="Pesquisar NFe"
                    name="nfe"
                    onChange={e => {
                      setNfe(e.target.value.replace(/\D/g, ''));
                    }}
                    style={{ width: '350px' }}
                    label="Buscar NFe"
                    value={nfe || ""}
                  />
                </div>
                <button className="btn" onClick={() => submitSearchNfe()}>
                  <i className="fa fa-search"></i>
                </button>
                <button
                  className="btn"
                  onClick={() => {
                    loadNFe();
                    setNfe('');
                  }}
                >
                  <i className="fa fa-times"></i>
                </button>
              </div>
              <button
                style={{ 
                  flex: '1',
                  padding: '20px',
                  background: '#fd397a',
                  color: '#fff',
                  borderRadius: '4px',
                  border: 'none'
                }}
                type="button"
                onClick={() => setOpen({ active: true, nfe: '' })}
                >
                Consulta Avulsa
              </button>
            </div>
          </div>
        </div>
      </div>
      <ModalNewConsult open={open} setOpen={e => setOpen(e)} onReload={() => loadNFe()}/>
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
        {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {

                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={index}
                    >
                      <TableCell align="left">{row.chNFe}</TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">{row.xMotivo}</TableCell>
                      <TableCell align="left">
                        {format(new Date(row.updatedAt), 'dd/MM/yyyy')}
                      </TableCell>
                      <TableCell align="right">
                        <button 
                          type="button" 
                          className="btn btn-secondary btn-sm"
                          onClick={() => setOpen({ active: true, nfe: row.chNFe })}
                        >
                          <FindReplace /> Consultar Novamente
                        </button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
