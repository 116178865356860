import React from "react";

function CheckBox(props) {
  return (
    <button
      type="button"
      style={{
        background: "none",
        border: 0,
        marginLeft: 0,
        paddingLeft: 0,
      }}
      onClick={() => {
        const aux = !props.checked;
        const array = props.array;
        array[props.index].bol = aux;
        props.change(array);
      }}
    >
      <p
        style={{
          padding: "0",
          margin: "0",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          className={props.checked ? "fa fa-check" : ""}
          style={{
            width: "18px",
            height: "18px",
            border: "0",
            background: "#dee2ea",
            display: "inline-block",
            borderRadius: "5px",
            marginRight: "10px",
          }}
        ></span>
        {props.name}
      </p>
    </button>
  );
}

export default CheckBox;
