import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import objectPath from "object-path";
import Header from "./header/Header";
// import SubHeader from "./sub-header/SubHeader";
import { withRouter } from "react-router-dom";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import LayoutInitializer from "./LayoutInitializer";
import KtContent from "./KtContent";
import axios from "axios";
import verifyProfiles from "../../app/pages/main/utils/verifyProfiles";
import "./assets/Base.scss";

const htmlClassService = new HTMLClassService();

function Layout({
  children,
  asideDisplay,
  subheaderDisplay,
  selfLayout,
  layoutConfig
}) {
  const [MenuConfig, setMenuConfing] = useState({
    header: {
      self: {},
      items: [],
    }
  });
  useEffect(() => {
    async function loadMenuConfig() {
      var items = [
        {
          title: "Painel",
          root: true,
          alignment: "left",
          page: "dashboard",
          translate: "MENU.DASHBOARD"
        }
      ];
    
      const menuItems = [
        {
          id: "registers",
          item: {
            title: "Cadastros",
            root: true,
            alignment: "left",
            submenu: ["register", "register_incompletes", "register_refuses", "e-CNPJ"],
          }
        },
        {
          id: "confirmation",
          item: {
            title: "Confirmação",
            root: true,
            alignment: "left",
            submenu: ["nfe_detached", "cte_detached", "bordero", "transporter", "notification"],
          }
        },
        {
          id: "charge",
          item: {
            title: "Notificação / Cobrança",
            root: true,
            alignment: "left",
            submenu: ["charge_operation", "charge_detail", "email"],
          }
        },
        {
          id: "services",
          item: {
            title: "Serviços",
            root: true,
            alignment: "left",
            submenu: ["cnpj", "protest", "revenue"]
          }
        },
        {
          id: "prospection",
          item: {
            title: "Prospecção",
            root: true,
            alignment: "left",
            submenu: ["cnpj", "protest", "email-prospection", "templates"]
          }
        },
        {
          id: "users",
          item: {
            title: "Usuários",
            root: true,
            alignment: "left",
            page: "users"
          }
        },
        {
          id: "site",
          item: {
            title: "Site",
            root: true,
            alignment: "left",
            submenu: ["teams","posts","demonstratives","agents","newsletters","contacts", "works"]
          }
        },
        {
          id: "acl",
          item: {
            title: "ACL",
            root: true,
            alignment: "left",
            submenu: ["roles", "permissions"]
          }
        },
      ];
    
      const subMenuItems = [
        {
          id: "nfe_detached",
          item: {
            title: "Consulta NFe Avulsa",
            root: true,
            alignment: "left",
            page: "confirmation/nfe_detached"
          }
        },
        {
          id: "cte_detached",
          item: {
            title: "Consulta CTe Avulsa",
            root: true,
            alignment: "left",
            page: "confirmation/cte_detached"
          }
        },
        {
          id: "bordero",
          item: {
            title: "Borderôs NFe",
            root: true,
            alignment: "left",
            page: "confirmation/bordero"
          }
        },
        {
          id: "transporter",
          item: {
            title: "Borderôs CTe",
            root: true,
            alignment: "left",
            page: "confirmation/transporter"
          }
        },
        {
          id: "notification",
          item: {
            title: "Notificação SERPRO",
            root: true,
            alignment: "left",
            page: "confirmation/notification"
          }
        },
        {
          id: "charge_operation",
          item: {
            title: "Operação",
            root: true,
            alignment: "left",
            page: "charge/operation"
          }
        },
        {
          id: "charge_detail",
          item: {
            title: "Relatório",
            root: true,
            alignment: "left",
            page: "charge/detail"
          }
        },
        {
          id: "register",
          item: {
            title: "Cadastros completos",
            root: true,
            alignment: "left",
            page: "registers"
          }
        },
        {
          id: "register_incompletes",
          item: {
            title: "Cadastros incompletos",
            root: true,
            alignment: "left",
            page: "register_incompletes"
          }
        },
        {
          id: "register_refuses",
          item: {
            title: "Cadastros indeferidos",
            root: true,
            alignment: "left",
            page: "register_refuses"
          }
        },
        {
          id: "e-CNPJ",
          item: {
            title: "Cadastros de e-CNPJ",
            root: true,
            alignment: "left",
            page: "e-cnpj"
          }
        },
        {
          id: "cnpj",
          item: {
            title: "Consulta de empresas",
            root: true,
            alignment: "left",
            page: "cnpj/search"
          }
        },
        {
          id: "juridical-processes",
          item: {
            title: "Consulta de processos jurídicos",
            root: true,
            alignment: "left",
            page: "juridical-processes/search"
          }
        },
        {
          id: "protest",
          item: {
            title: "Relato de Protestos",
            root: true,
            alignment: "left",
            page: "relato/protest"
          }
        },
        {
          id: "protest-payer",
          item: {
            title: "Relato de Sacado",
            root: true,
            alignment: "left",
            page: "relato/protest-payer"
          }
        },
        {
          id: "revenue",
          item: {
            title: "Faturamento Parcial",
            root: true,
            alignment: "left",
            page: "revenue"
          }
        },
        {
          id: "email",
          item: {
            title: "Envio manual",
            root: true,
            alignment: "left",
            page: "mail"
          }
        },
        {
          id: "email-prospection",
          item: {
            title: "Envio de e-mail",
            root: true,
            alignment: "left",
            page: "mail-prospection"
          }
        },
        {
          id: "templates",
          item: {
            title: "Templates de E-mail",
            root: true,
            alignment: "left",
            page: "templates"
          }
        },
        {
          id: "contacts",
          item: {
            title: "Fale conosco",
            root: true,
            alignment: "left",
            page: "contacts"
          }
        },
        {
          id: "works",
          item: {
            title: "Trabalhe conosco",
            root: true,
            alignment: "left",
            page: "works"
          }
        },
        {
          id: "teams",
          item: {
            title: "Colaboradores",
            root: true,
            alignment: "left",
            page: "teams"
          }
        },
        {
          id: "posts",
          item: {
            title: "Comunicados",
            root: true,
            alignment: "left",
            page: "posts"
          }
        },
        {
          id: "demonstratives",
          item: {
            title: "Demonstrativos",
            root: true,
            alignment: "left",
            page: "demonstratives"
          }
        },
        {
          id: "agents",
          item: {
            title: "Seja um agente",
            root: true,
            alignment: "left",
            page: "agents"
          }
        },
        {
          id: "newsletters",
          item: {
            title: "Newsletter",
            root: true,
            alignment: "left",
            page: "newsletters"
          }
        },
        {
          id: "roles",
          item: {
            title: "Perfil",
            root: true,
            alignment: "left",
            page: "roles"
          }
        },
        {
          id: "permissions",
          item: {
            title: "Permissão",
            root: true,
            alignment: "left",
            page: "permissions"
          }
        }
      ];

      var menuPermissions = [];
    
      const { data: user } = await axios.get('auth');

      if(verifyProfiles(user, 'analist')) {
        menuPermissions = [
          { item: "services", submenu: ["cnpj", "juridical-processes", "protest", "revenue"] },
          { item: "registers",  submenu: ["register", "register_incompletes", "register_refuses", "e-CNPJ"] },
          { item: "site", submenu: ["agents", "contacts", "works"] }
        ];
      }
      if(verifyProfiles(user, 'manager_confirmation')) {
        menuPermissions = [
          { item: "confirmation", submenu: ["bordero", "transporter"] }, 
          { item: "services", submenu: ["cnpj", "juridical-processes", "protest", "protest-payer", "revenue"] }, 
          { item: "registers",  submenu: ["register", "register_incompletes", "register_refuses", "e-CNPJ"] }, 
          { item: "charge", submenu: ["charge_operation", "charge_detail", "email"] }
        ];
      }
      if(verifyProfiles(user, 'analist_confirmation')) {
        menuPermissions = [
          { item: "registers",  submenu: ["register", "e-CNPJ"] },
          { item: "confirmation", submenu: ["bordero", "transporter"] }, 
          { item: "services", submenu: ["cnpj", "juridical-processes", "protest-payer", "protest"] }, 
          { item: "charge", submenu: ["charge_operation", "charge_detail", "email"] }
        ];
      }
      if(verifyProfiles(user, 'manager')) {
        menuPermissions = [
          { item: "registers", submenu: ["register"] },
          { item: "services", submenu: ["cnpj", "juridical-processes", "protest", "revenue"] }
        ];
      }
      if(verifyProfiles(user, 'analist_consult')) {
        menuPermissions = [
          { item: "registers",  submenu: ["register", "register_incompletes", "register_refuses"] },
          { item: "prospection", submenu: ["cnpj", "protest", "email-prospection"] },
        ];
      }
      if(verifyProfiles(user, 'company_consult')) {
        menuPermissions = [
          { item: "registers",  submenu: ["register", "e-CNPJ"] },
          { item: "services", submenu: ["cnpj", "protest"] }
        ];
      }
      if(verifyProfiles(user, 'director')) {
        menuPermissions = [{ item: "registers", submenu: ["register", "register_incompletes", "register_refuses", "e-CNPJ"] },
        { item: "confirmation", submenu: ["bordero", "transporter", "notification"] },
        { item: "charge", submenu: ["charge_operation", "charge_detail", "email"] },
        { item: "services", submenu: ["cnpj", "juridical-processes", "protest","protest-payer", "revenue"] },
        { item: "prospection", submenu: ["email-prospection"] },
        { item: "site", submenu: ["teams","posts","demonstratives","agents","newsletters","contacts", "works"] }];
      }
      if(verifyProfiles(user, 'admin')) {
        menuPermissions = [
          { item: "registers", submenu: ["register", "register_incompletes", "register_refuses", "e-CNPJ"] },
          { item: "confirmation", submenu: ["bordero", "transporter", "notification"] },
          { item: "charge", submenu: ["charge_operation", "charge_detail", "email"] },
          { item: "services", submenu: ["cnpj", "juridical-processes", "protest", "protest-payer", "revenue"] },
          { item: "prospection", submenu: ["cnpj", "protest", "email-prospection", "templates"] },
          { item: "users" },
          { item: "acl", submenu: ["roles", "permissions"] },
          { item: "site", submenu: ["teams","posts","demonstratives","agents","newsletters","contacts", "works"] }];
      }

      var i = 0;
    
      while (i < menuItems.length) {
        const _item = menuPermissions.find(e => e.item === menuItems[i].id); // eslint-disable-line
        if (_item !== undefined) {
          const item = menuItems.find(e => e.id === _item.item); // eslint-disable-line
          if (_item.submenu !== undefined) {
            var c = 0;
            var subItens = [];
            while (c < _item.submenu.length) {
              var filterSubMenu = subMenuItems.find(e => e.id === _item.submenu[c]); // eslint-disable-line
              subItens.push(filterSubMenu.item);
              c++;
            }
            items.push({ ...item.item, submenu: subItens });
          } else {
            items.push(item.item);
          }
        }
        i++;
      }
      setMenuConfing({
        header: {
          self: {},
          items,
        }
      })
    }
    loadMenuConfig();
  }, [])
  htmlClassService.setConfig(layoutConfig);
  // scroll to top after location changes
  window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  const contentContainerCssClasses = htmlClassService.classes.content_container.join(
    " "
  );
  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={[]}
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            <Header />
            {/* <!-- end:: Header --> */}
            <div
              className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch"
              id="kt_body"
            >
              {/* <!-- begin:: Aside Left --> */}
              {asideDisplay && (
                <>
                  <div
                    className={`kt-container ${contentContainerCssClasses} kt-container--fit kt-grid kt-grid--ver`}
                  >
                    <AsideLeft />
                    <div
                      className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                      id="kt_content"
                    >
                      <KtContent>{children}</KtContent>
                    </div>
                  </div>
                </>
              )}
              {!asideDisplay && (
                <>
                  {/* <!-- begin:: Content --> */}
                  <div
                    id="kt_content"
                    className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor`}
                  >
                    {/* <!-- begin:: Content Head --> */}
                    {/* {subheaderDisplay && <SubHeader />} */}
                    {/* <!-- end:: Content Head --> */}

                    {/* <!-- begin:: Content Body --> */}
                    <KtContent>{children}</KtContent>
                    {/*<!-- end:: Content Body -->*/}
                  </div>
                  {/* <!-- end:: Content --> */}
                </>
              )}

              {/* <!-- end:: Aside Left --> */}
            </div>
            <Footer />
          </div>
        </div>

        {/* <!-- end:: Body --> */}
      </div>
      <ScrollTop />
    </LayoutInitializer>
  ) : (
    // BLANK LAYOUT
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent>{children}</KtContent>
    </div>
  );
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display")
});

export default withRouter(connect(mapStateToProps)(Layout));
