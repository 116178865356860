import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FormControl, TextField } from '@material-ui/core';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import clsx from "clsx";

export default function ProfilePassword({ history, match }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({})
  const [values, setValues] = useState({
    oldPassword: '',
    password: '',
    confirmPassword: '',
  });
  const [error, setError] = useState({
    button: true,
    error: false,
    message: [],
  });
  
  useEffect(() => {
    if(values.password.length === values.confirmPassword.length && values.password.length !== 0 && values.oldPassword.length !== 0) {
      setError({
        button: !(values.password === values.confirmPassword),
        error: !(values.password === values.confirmPassword),
        message: [],
      });
    } else {
      setError({
        button: true,
        error: false,
        message: [],
      });
    }
  }, [values.password, values.confirmPassword, values.oldPassword.length]);

  useEffect(() => {
    async function loadUser() {
      const response = await axios.get(`/auth`);
      setUser(response.data);
    }
    loadUser();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await axios.put(`/users/profile/password/${user.id}`, {
        email: user.email,
        oldPassword: values.oldPassword.toString(),
        password: values.password.toString()
      });
      if (response.status === 203) {
        setError({ ...error, message: response.data, });
      } else {
        toast.success('Senha atualizada com sucesso');
      }
      setLoading(false);
    }catch(err) {
      console.log(err)
      setLoading(false);
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Editar Senha
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/profile/`}>
                Meu Perfil
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/profile/password`}>
                Editar Senha
              </Link>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
      <FormControl onSubmit={handleSubmit} style={{ width: '100%', backgroundColor: '#fff', padding: '20px', borderRadius: '6px'}}>
      {error.message.length !== 0 && (
        <Alert variant="danger">
          <ul>
            {error.message.map(er => (
              <li key={er.message}>
                  {er.message}
              </li>
              ))}
          </ul>
        </Alert>
        )}
        {error.error === true && <Alert variant="danger">Senhas não coincidem</Alert>}
        <TextField 
          margin="normal"
          variant="outlined"
          label="Senha Antiga"
          type="password"
          defaultValue={values.oldPassword}
          onChange={(e) => setValues({ ...values, oldPassword: e.target.value })}
          required
        />
        <TextField 
          margin="normal"
          variant="outlined"
          label="Senha"
          type="password"
          defaultValue={values.password}
          onChange={(e) => setValues({ ...values, password: e.target.value })}
          required
        />
        <TextField 
          margin="normal"
          variant="outlined"
          label="Repetir Senha"
          type="password"
          defaultValue={values.confirmPassword}
          onChange={(e) => setValues({ ...values, confirmPassword: e.target.value })}
          required
        />
        <button
          type="submit"
          disabled={error.button || loading}
          style={{ marginTop: 20 }}
          className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx({"kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading})}`} // eslint-disable-line
          >Atualizar</button>
      </FormControl>
      </form>
    </>
  );
}

