import React, { memo, useState } from "react";
import axios from "axios";
import { Paper, makeStyles, Modal, Backdrop } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { AddCircle, HighlightOff } from "@material-ui/icons";
import ModalAddMember from "./Modais/ModalAddMember";
import ModalMember from "./Modais/ModalMember";
import ModalUpdateMember from "./Modais/ModalUpdateMember";

function Members({
  members,
  onReload,
  register_id,
  setOpenSocioReproved,
  pendencies
}) {
  const [open, setOpen] = useState(false);
  const [deleteMember, setDeleteMember] = useState({
    id: null,
    open: false,
    member_name: null
  });
  const [openSocio, setOpenSocio] = useState({
    active: false,
    socio: {},
    index: null
  });
  const [memberError, setMemberError] = useState({});
  const [editConfirmField, setEditConfirmField] = useState(false);
  const [editField, setEditField] = useState(false);
  const useStyles = makeStyles(theme => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20
    },
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "470px",
      height: "300px",
      overflow: "auto"
    }
  }));

  async function removeMember(member_id) {
    try {
      await axios.delete(`member/${member_id}`);
      toast.success("Membro Excluído");
      onReload();
      setDeleteMember({ id: null, open: false, member_name: null });
    } catch (err) {
      if(err.response.status === 401) {
        toast.error(err.response.data.msg);
      }else {
        toast.error('Ocorreu um erro ao deletar membro');
      }
    }
  }

  const classes = useStyles();

  return (
    <Row id="members">
      <Modal
        className={classes.modalsm}
        open={deleteMember.open}
        onClose={() => {
          setDeleteMember({ id: null, open: false, member_name: null });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <div className={classes.fadesm}>
          <Row>
            <div className="col-12">
              <div className="col-12 confirm">
                <i className="flaticon2-information"></i>
                <p>
                  Tem certeza que deseja exluir o membro{" "}
                  {deleteMember.member_name}?
                </p>
                <strong>Esta ação não pode ser revertida.</strong>

                <div className="mt-4">
                  <button
                    onClick={() => removeMember(deleteMember.id)}
                    className="btn btn-outline-danger mr-3"
                  >
                    Sim
                  </button>
                  <button
                    onClick={() =>
                      setDeleteMember({
                        id: null,
                        open: false,
                        member_name: null
                      })
                    }
                    className="btn btn-outline-lingth"
                  >
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </div>
      </Modal>
      <ModalAddMember
        open={open}
        onClose={() => setOpen(false)}
        onReload={() => onReload()}
        id={register_id}
        members={members && members}
      />
      <ModalUpdateMember
        open={editConfirmField}
        onClose={v => setEditConfirmField(v)}
        id={register_id}
        member={openSocio.socio && openSocio.socio}
        members={members && members}
        onError={err => setMemberError(err)}
        registerReload={() => {
          onReload();
          setEditField(false);
        }}
      />
      <ModalMember
        id={register_id}
        openSocio={openSocio}
        setOpenSocio={e => setOpenSocio(e)}
        onReload={() => onReload()}
        setOpenSocioReproved={e => setOpenSocioReproved(e)}
        pendencies={pendencies}
        memberError={memberError}
        setMemberError={e => setMemberError(e)}
        setEditConfirmField={e => setEditConfirmField(e)}
        editField={editField}
        setEditField={e => setEditField(e)}
      />
      {members &&
        members.map((member, index) => (
          <Col lg={4} key={member.id} style={{ position: "relative" }}>
            <Paper className={`${classes.paper} text-center`}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap"
                }}
              >
                {member.finished === "A" && (
                  <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--success">
                    Aprovado
                  </span>
                )}
                {member.type === 1 && (
                  <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--brand">
                    Sócio
                  </span>
                )}
                {member.type === 2 && (
                  <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--brand">
                    Representante
                  </span>
                )}
                {member.type === 3 && (
                  <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--brand">
                    Avalista
                  </span>
                )}
                {member.type === 4 && (
                  <span className="kt-badge kt-badge--inline kt-badge--pill kt-badge--rounded kt-badge--brand">
                    Procurador
                  </span>
                )}
              </div>
              <h4 className=" mt-3">{member.fullname}</h4>
              <hr></hr>
              <div style={{ textAlign: "center" }}>
                <h6>Dados</h6>
                <i
                  className="flaticon-information"
                  style={{ fontSize: "60px", display: "block" }}
                ></i>
                <button
                  type="button"
                  className="btn btn-secondary btn-block"
                  onClick={() =>
                    setOpenSocio({ active: true, socio: member, index })
                  }
                >
                  Ver
                </button>
                {member.finished !== "A" && (
                  <button
                    type="button"
                    className="delete_document"
                    style={{ position: "absolute", top: "10px", right: "20px" }}
                    onClick={() =>
                      setDeleteMember({
                        id: member.id,
                        open: true,
                        member_name: member.fullname
                      })
                    }
                  >
                    <HighlightOff style={{ fontSize: `30px` }} />
                  </button>
                )}
              </div>
            </Paper>
          </Col>
        ))}
      {members && members.length < 5 && (
        <Col
          lg={4}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
            minHeight: "300px"
          }}
        >
          <button
            type="button"
            style={{
              background: "none",
              border: "0"
            }}
            onClick={() => setOpen(true)}
          >
            Adicionar Sócio/Representante/Avalista <br />
            <AddCircle style={{ fontSize: "50px" }} />
          </button>
        </Col>
      )}
    </Row>
  );
}

export default memo(Members);
