import React, { useState } from "react";

import { Modal, Form } from "react-bootstrap";
import { CircularProgress } from "@material-ui/core";

import axios from "axios";
import { toast } from "react-toastify";

function ModalByBordero({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [bordero, setBordero] = useState("");
  const resetModal = () => {
    setOpen(false);
    setBordero("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/bordero/sacados/${bordero}`
      );
      if (response.status === 204) {
        toast.warn("Borderô não encontrado no FACTA");
      } else {
        const { titles, borderoData } = response.data;
        let payers = [];

        titles.forEach((title) => {
          if (
            !payers.find(
              (relato) => relato.payerDocumentNumber === title.document_sacado
            )
          ) {
            payers.push({
              payerName: title.sacado,
              payerDocumentNumber: title.document_sacado,
            });
          }
        });
        await axios.post(`https://gateway-ovh.grupobfc.com.br/payerProtest`, {
          borderoId: bordero,
          borderoType: "not-paid",
          assignorName: borderoData.cedente,
          assignorDocumentNumber: borderoData.cnpj,
          payers,
          payersCount: payers.length
        });
        setBordero("");
        toast.success(
          `${payers.length} sacado(s) enviado(s) para relato de protesto`
        );
      }
    } catch (err) {
      if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe técnica"
        );
      } else {
        toast.error(
          "Ocorreu um erro em nossa aplicação, contate a equipe técnica"
        );
      }
    }
    setLoading(false);
  };

  return (
    <Modal show={open} size="sm" onHide={resetModal} centered>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <h5 className="text-center">Relato de Sacado por Borderô</h5>
          <Form.Control
            placeholder="Borderô"
            value={bordero}
            onChange={(e) => setBordero(e.target.value.replace(/[^\d]/g, ""))}
            disabled={loading}
            className="mb-3"
          />
          <div className="d-flex">
            <button
              type="submit"
              className="btn btn-sm btn-block btn-primary"
              disabled={loading || bordero === ""}
            >
              {loading && (
                <CircularProgress size={20} style={{ color: "#fff" }} />
              )}{" "}
              Enviar
            </button>
            <button
              type="button"
              onClick={resetModal}
              className="btn btn-sm btn-block btn-outline-primary ml-2 mt-0"
              disabled={loading}
            >
              Cancelar
            </button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default ModalByBordero;
