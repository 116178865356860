import React, { memo, useState, useEffect } from "react";
import axios from "axios";
import verifyTitleMemberField from "../../utils/verifyTitleMemberField";
import { Row, Col } from "react-bootstrap";
import { makeStyles, Modal, Backdrop } from "@material-ui/core";
import { toast } from "react-toastify";
import validation from "../../utils/validationUpdateMember";

function ModalUpdateCompany({
  member,
  open,
  onClose,
  registerReload,
  id,
  onError,
  members
}) {
  const [oldMember, setOldMember] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);

  useEffect(() => {
    async function loadRegister() {
      if (open) {
        try {
          const response = await axios.get(`/registers/${id}`);
          let index;
          if (Object.keys(member).length > 0) {
            index = response.data.members.findIndex(
              item => item.id === member.id
            );
          } else {
            index = 0;
          }
          let old = response.data.members[index];
          if (member.marital_status === "Casado(a)") {
            if (old.memberSpouses === null && member.memberSpouses !== null) {
              old.memberSpouses = {
                fullname: "",
                date_of_birth: "",
                cpf: "",
                identity: "",
                identity_oe: "",
                identity_uf: "",
                profession: "",
                phone: "",
                email: ""
              };
            }
          } else {
            member.wedding_registry = null;
          }
          setOldMember(old);
        } catch (err) {
          toast.error(err.message);
          setOldMember({});
        }
      }
    }
    loadRegister();
  }, [id, member, open]);

  useEffect(() => {
    try{
      let count = 0;
      if (open) {
        member !== null &&
          member !== undefined &&
          Object.keys(member).length > 0 &&
          Object.keys(oldMember).length > 0 &&
          Object.keys(member).map(item => {
            if (
              item === "memberSpouses" &&
              member[item] !== null &&
              oldMember[item] !== null
            ) {
              return Object.keys(member[item]).map(sub => {
                if (member[item][sub] !== oldMember[item][sub]) {
                  return count++;
                } else {
                  return count;
                }
              });
            } else {
              if (member[item] !== oldMember[item]) {
                return count++;
              } else {
                return count;
              }
            }
          });
      }
      if (count === 0 && open) {
        onClose(false);
        toast.warn("Nenhum campo foi alterado");
      } else if (open) {
        let cpf_duplicated = members.find(
          i => i.id !== member.id && i.cpf === member.cpf
        );
        let errors = validation(member);
        if (cpf_duplicated) {
          errors.cpf = "CPF/CNPJ DUPLICADO.";
        }
        if (Object.keys(errors).length > 0) {
          toast.error("Há campos incorretos");
          console.log(errors)
          onError(errors);
          setOldMember({});
          onClose(false);
        } else {
          setOpenConfirm(true);
        }
      }
    } catch (err){
      console.log(err)
    }
  }, [member, oldMember]); // eslint-disable-line

  async function handleUpdateMember() {
    try {
      await axios.post(`register_update/${id}`, {
        data_send: JSON.stringify(member),
        data: JSON.stringify(oldMember),
        type: "member",
        member_id: member.id,
        origin: "update"
      });
      toast.success("Membro atualizado com sucesso!");
      registerReload();
      onClose(false);
      setOpenConfirm(false);
      setOldMember({});
    } catch (err) {
      toast.error(err.message);
    }
  }

  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "700px",
      height: "80%",
      overflow: "auto"
    }
  }));
  const classes = useStyles();
  return (
    <Modal
      className={classes.modal}
      open={openConfirm}
      onClose={() => {
        onClose(false);
        setOpenConfirm(false);
        setOldMember({});
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fade}>
        <div className="col-12">
          <div className="col-12 confirm">
            <i className="flaticon2-information"></i>
            <h4>Tem certeza que deseja editar os seguintes dados?</h4>
            <p>Membro: {oldMember.fullname && oldMember.fullname}</p>
            <Row>
              <Col lg={6}>
                <h2>Antes</h2>
              </Col>
              <Col lg={6}>
                <h2>Depois</h2>
              </Col>
            </Row>
            {Object.keys(oldMember).length > 0 &&
              Object.keys(member).length > 0 &&
              Object.keys(oldMember).map(item => {
                if (item !== "memberSpouses") {
                  if (oldMember[item] !== member[item]) {
                    return (
                      <Row key={item}>
                        <Col lg={6}>
                          <b>{verifyTitleMemberField(item)}: </b>
                          {oldMember[item] || "-"}
                        </Col>
                        <Col lg={6}>{member[item]}</Col>
                      </Row>
                    );
                  } else {
                    return "";
                  }
                } else {
                  return (
                    oldMember[item] !== null &&
                    Object.keys(oldMember[item]).map(sub => {
                      if (
                        (oldMember[item][sub] !== null ||
                          member[item][sub] !== null) &&
                        oldMember[item][sub] !== member[item][sub]
                      ) {
                        return (
                          <Row key={`${item}_${sub}`}>
                            <Col lg={6}>
                              <b>Cônjuge - {verifyTitleMemberField(sub)}: </b>
                              {oldMember[item][sub] || "-"}
                            </Col>
                            <Col lg={6}>{member[item][sub]}</Col>
                          </Row>
                        );
                      } else {
                        return "";
                      }
                    })
                  );
                }
              })}
            <div className="mt-4">
              <button
                onClick={() => handleUpdateMember()}
                className="btn btn-outline-success mr-3"
              >
                Sim
              </button>
              <button
                onClick={() => {
                  setOpenConfirm(false);
                  onClose(false);
                  setOldMember({});
                }}
                className="btn btn-outline-danger"
              >
                Não
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalUpdateCompany);
