import React, { memo, useMemo } from "react";

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { normalizeCurrency } from "../utils/normalize";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { Table } from 'react-bootstrap';

function ProtestItem({ protest }) {
  const getAllValue = useMemo(() => {
    let count = 0;
    protest.json.cartorio &&
      protest.json.cartorio.forEach((c) => {
        c.dadosCartorio &&
          c.dadosCartorio.forEach((dC) => {
            dC.titulos &&
              dC.titulos.forEach((t) => {
                if (t.valorProtestado) {
                  count += Number(t.valorProtestado.replace(/[^\d]/g, ""));
                }
              });
          });
      });
      if(count === 0) {
        return '0,00'
      }
    return normalizeCurrency(count);
  }, [protest]);

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ width: "100%" }}
        >
          <div>
            <h5>{protest.amountTitles} Título(s)</h5>
            <h6 className="mb-0">R$ {getAllValue}</h6>
          </div>
          <div>
            {protest.dates.map(d => <h6 key={d}>
              <i className="far fa-calendar-alt mr-2"></i>
              {d}
            </h6>)}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div>
      {protest.json.qtdTitulos > 0 &&
            protest.json.cartorio.length > 0 &&
            protest.json.cartorio.map((cartorio, cIndex) => {
              if (
                cartorio.dadosCartorio.find((c) =>
                  c.titulos.find(
                    (t) => typeof t.valorProtestado !== "undefined"
                  )
                )
              ) {
                return (
                  <div
                    key={cIndex}
                    style={{
                      width: "100%",
                      padding: "15px",
                      background: "#f9f9f9",
                      margin: "20px 0px",
                      border: "1px solid #c9c9c9",
                    }}
                  >
                    <div
                      className="d-flex justify-content-between align-items-center"
                      key={cIndex}
                      style={{ width: "100%" }}
                    >
                      <h5>UF:</h5>
                      <h5>{cartorio.uf}</h5>
                    </div>
                    <hr />
                    {cartorio.dadosCartorio.length > 0 &&
                      cartorio.dadosCartorio.map((dadosCartorio, dIndex) => {
                        if (
                          dadosCartorio.titulos.find(
                            (titulo) =>
                              typeof titulo.valorProtestado !== "undefined"
                          )
                        ) {
                          return (
                            <div key={dIndex} style={{ width: "100%" }}>
                              <Table responsive="lg" style={{ width: "100%" }}>
                                <thead style={{ background: "#f0f0f0" }}>
                                  <tr>
                                    <th>{dadosCartorio.nomeCartorio}</th>
                                    <th>{dadosCartorio.telefone}</th>
                                    <th>
                                      {dadosCartorio.qtdTitulos} Título(s)
                                    </th>
                                  </tr>
                                </thead>
                              </Table>
                              <Table
                                responsive="lg"
                                hover
                                style={{
                                  marginTop: "-15px",
                                  marginBottom: "30px",
                                  width: "100%",
                                }}
                              >
                                <thead className="bg-light">
                                  <tr>
                                    <th>Data Protesto</th>
                                    <th>Data Vencimento</th>
                                    <th>Valor Protestado</th>
                                    <th>Apresentante</th>
                                    <th>Protestante</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {dadosCartorio.titulos.length > 0 &&
                                    dadosCartorio.titulos
                                      .filter(
                                        (titulo) =>
                                          typeof titulo.valorProtestado !==
                                          "undefined"
                                      )
                                      .map((titulo, tIndex) => (
                                        <tr key={tIndex}>
                                          <td>{titulo.dataProtesto}</td>
                                          <td>{titulo.dataVencimento}</td>
                                          <td>R$ {titulo.valorProtestado}</td>
                                          <td>{titulo.nomeApresentante}</td>
                                          <td>{titulo.nomeCedente}</td>
                                        </tr>
                                      ))}
                                </tbody>
                              </Table>
                            </div>
                          );
                        }
                        return "";
                      })}
                  </div>
                );
              }
              return "";
            })}
            </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default memo(ProtestItem);
