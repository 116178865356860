import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Paper,
  FormControl,
  TextField,
  makeStyles,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';

export default function UserAdd({ history }) {
  const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({
    username: '',
    email: '',
    fullname: '',
    roles: [],
    gerente_id: 'null',
  });
  const [error, setError] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [roles, setRoles] = useState([]);
  const [gerentes, setGerentes] = useState([]);

  useEffect(() => {
    async function loadGerente() {
      try {
        const { data } = await axios.get("facta/gerente");
        setGerentes(data);
      } catch (error) {}
    }
    loadGerente();
  }, []);

  const [currentRole, setCurrentRole] = useState('')

  useEffect(()=> {
      axios.get('/roles').then(response => {
        setRoles(response.data)
      });
  }, [])

  useEffect(() => {
    if(values.username !== '' && values.email !== '' && values.fullname !== '') {
      setDisabled(false);
    }else {
      setDisabled(true)
    }
  }, [values.email, values.fullname, values.username]);

  function handleSelectRoleChange(e){
    const filteredRole = roles.filter(role => role.id === e.target.value)

    setValues({ ...values, roles: [e.target.value]})
    setCurrentRole(filteredRole)
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post('/users', { ...values, gerente_id: values.gerente_id !== 'null' && values.gerente_id });
      if (response.status === 203) {
        setError(response.data);
      } else {
        toast.success('Usuário cadastrado com sucesso');
        history.push('/users');
      }
    }catch(err) {
      if(err.response.status === 403){
        history.goBack()
      }
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Usuários
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/users`}>
                Usuários
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/users/add`}>
                Novo Usuário
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
        {error.length !== 0 && (
          <Alert variant="danger">
            <ul>
              {error.map(er => (
                <li key={er.message}>
                    {er.message}
                </li>
                ))}
            </ul>
          </Alert>
          )}
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Username"
              value={values.username}
              onChange={(e) => setValues({ ...values, username: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Email"
              type="email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Nome Completo"
              value={values.fullname}
              onChange={(e) => setValues({ ...values, fullname: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
          <InputLabel style={{padding: '15px 0 0 15px'}} id="select-perfil">Perfil *</InputLabel>
            <Select 
              labelId="select-perfil"
              variant="outlined"
              style={{ marginTop: '20px'}}
              value={currentRole.name}
              onChange={(e) => handleSelectRoleChange(e)}
            > 
              {roles.map(role => (<MenuItem key={role.id} value={role.id}>{role.name}</MenuItem>))}
            </Select>
          </FormControl>
            <FormControl>
              <InputLabel style={{padding: '15px 0 0 15px'}} id="select-agents">Gerente no Facta *</InputLabel>
              <Select 
                labelId="select-agents"
                variant="outlined"
                style={{ marginTop: '20px'}}
                value={values.gerente_id} 
                onChange={(e) => setValues({ ...values, gerente_id: e.target.value })}
              > 
                <MenuItem value={'null'}>
                  NÃO É GERENTE NO FACTA
                </MenuItem>
                {gerentes.map(gerente => (<MenuItem key={gerente.GERE_ID} value={gerente.GERE_ID}>{gerente.NOME}</MenuItem>))}
              </Select>
            </FormControl>
          <FormControl>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={disabled}
              >Cadastrar</button>
            <Link
              to="/users"
              className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}

