import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Paper, FormControl, TextField, makeStyles } from "@material-ui/core";
import { toast } from "react-toastify";
import { Alert } from "react-bootstrap";

export default function UserAdd({ history }) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({
    name: "",
    slug: "",
    description: "",
  });
  const [error, setError] = useState([]);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (values.name !== "" && values.slug !== "" && values.description !== "") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [values.description, values.name, values.slug]);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post("/permissions", values);
      if (response.status === 203) {
        setError(response.data);
      } else {
        toast.success("Permissão cadastrada com sucesso");
        history.push("/permissions");
      }
    } catch (err) {
      toast.error('Ocorreu um erro, tente novamente');
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Permissões</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/permissions`}
              >
                Permissões
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/permissions/add`}
              >
                Nova permissão
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          {error.length !== 0 && (
            <Alert variant="danger">
              <ul>
                {error.map((er) => (
                  <li key={er.message}>{er.message}</li>
                ))}
              </ul>
            </Alert>
          )}
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Nome"
              value={values.name}
              onChange={(e) => {
                setValues({
                  ...values,
                  name: e.target.value,
                  slug: e.target.value,
                });
              }}
              required
            />
          </FormControl>
          <FormControl>
            <TextField
              margin="normal"
              variant="outlined"
              label="Descrição"
              type="text"
              value={values.description}
              onChange={(e) =>
                setValues({ ...values, description: e.target.value })
              }
              required
            />
          </FormControl>
          <FormControl>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={disabled}
            >
              Cadastrar
            </button>
            <Link to="/permissions" className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}
