import React, { memo } from "react";
import { Paper } from "@material-ui/core";
import { Col } from "react-bootstrap";
import CompanyData from "./companyDataSection/companyData/CompanyData";
import GroupedData from "./companyDataSection/GroupedData";
import CompanyDataNavBar from "./companyDataSection/CompanyDataNavBar";

function CompanyDataSection(props) {
  return (
    <Paper className={props.classes.paper}>
      <Col
        lg={12}
        style={{
          justifyContent: "space-between",
          display: "flex",
          width: "100%",
          borderBottom: "1px solid #eee"
        }}
      >
        <h3>Dados da Empresa</h3>
      </Col>
      <Col>
        <CompanyData
          classes={props.classes}
          register={props.register}
          itemsReproved={props.itemsReproved}
          editField={props.editField}
          setRegister={props.setRegister}
          companyError={props.companyError}
          normalizeCompany={props.normalizeCompany}
          selectReprove={props.selectReprove}
          handleSelectReproved={props.handleSelectReproved}
        />
        <GroupedData
          classes={props.classes}
          register={props.register}
          itemsReproved={props.itemsReproved}
          editField={props.editField}
          setRegister={props.setRegister}
          normalizeCompany={props.normalizeCompany}
          selectReprove={props.selectReprove}
          handleSelectReproved={props.handleSelectReproved}
        />
        <CompanyDataNavBar
          register={props.register}
          editField={props.editField}
          selectReprove={props.selectReprove}
          setEditField={props.setEditField}
          setEditConfirmField={props.setEditConfirmField}
          editConfirmField={props.editConfirmField}
          loadRegister={props.loadRegister}
          setOpenReprove={props.setOpenReprove}
          loading={props.loading}
          setConfirmApprovedCompany={props.setConfirmApprovedCompany}
          setSelectReprove={props.setSelectReprove}
        />
      </Col>
    </Paper>
  );
}

export default memo(CompanyDataSection);
