import React from "react";
import { Route, Switch } from "react-router-dom";
import CnpjSearchView from "./CnpjSearchView";

export default function CnpjSearchPage() {
  return (
    <Switch>
      <Route exact path="/cnpj/search" component={CnpjSearchView} />
    </Switch>
  );
}
