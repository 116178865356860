import React, { memo, useState } from "react";
import axios from "axios";
import { Close } from "@material-ui/icons";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import validator from "../../utils/validatorMember";
import normalizeMember from "../../utils/normalizeMember";
import { makeStyles, Modal, Backdrop } from "@material-ui/core";

function ModalAddMember({ open, onReload, onClose, id, members }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [type, setType] = useState(null);
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles(theme => ({
    root: {
      width: "100%"
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      overflow: "auto"
    },
    item: {
      padding: "5px"
    }
  }));

  function handleInputChange(event) {
    setErrors({});
    const { value, name } = event.target;
    setValues({
      ...values,
      [name]: value
    });
  }

  async function handleSubmit() {
    setLoading(true);
    let errors = validator(values);
    let cpf_duplicated = members.find(i => i.cpf === values.cpf);
    if (cpf_duplicated) {
      errors.cpf = "CPF/CNPJ DUPLICADO.";
    }
    if (Object.keys(errors).length > 0) {
      console.log(errors)
      toast.error("Campos inválidos");
      setLoading(false);
      return setErrors(errors);
    }
    try {
      await axios.post(`/member/create`, { ...values, register_id: id });
      onClose();
      onReload();
      setType(null);
      toast.success("Membro Cadastrado");
    } catch (err) {
      toast.error(err.message);
    }
    setLoading(false);
  }

  const classes = useStyles();

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={() => {}}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.fade}>
        <Row style={{ borderBottom: "1px solid #eee" }}>
          <Col lg={11}>
            <h2>Cadastrar Novo Sócio/Representante/Avalista</h2>
          </Col>
          <Col lg={1}>
            <button
              type="button"
              onClick={() => {
                onClose();
                setValues({});
                setErrors({});
                setType(null);
              }}
              style={{ background: "none", border: "0" }}
            >
              <Close />
            </button>
          </Col>
        </Row>
        <div>
          <h3 style={{ paddingTop: "15px" }}>
            Dados do Sócio/Representante/Avalista
          </h3>
          {type === "cnpj" && (
            <Row style={{ paddingTop: "15px" }}>
              <Col lg={6} className={classes.item}>
                <TextField
                  name="fullname"
                  type="text"
                  label="Razão Social *"
                  variant="outlined"
                  onChange={e => handleInputChange(e)}
                  style={{ width: "100%" }}
                  error={errors.fullname ? true : false}
                  helperText={errors.fullname && errors.fullname}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  variant="outlined"
                  style={{ width: "100%" }}
                  name="cpf"
                  type="text"
                  helperText={errors.cpf && errors.cpf}
                  error={errors.cpf ? true : false}
                  onChange={e => {
                    e.target.value = normalizeMember("cnpj", e.target.value);
                    handleInputChange(e);
                  }}
                  label="CNPJ *"
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <FormControl style={{ width: "100%" }} variant="outlined">
                  <InputLabel>Tipo *</InputLabel>
                  <Select
                    name="type"
                    style={{ width: "100%" }}
                    error={errors.type ? true : false}
                    value={values.type || ""}
                    onChange={e => handleInputChange(e)}
                  >
                    <MenuItem value={1}>Sócio</MenuItem>
                    <MenuItem value={2}>Representante</MenuItem>
                  </Select>
                </FormControl>
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  style={{ width: "100%" }}
                  name="email"
                  type="text"
                  label="E-mail *"
                  variant="outlined"
                  helperText={errors.email && errors.email}
                  error={errors.email ? true : false}
                  onChange={e => handleInputChange(e)}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  style={{ width: "100%" }}
                  name="phone"
                  type="text"
                  label="Telefone *"
                  variant="outlined"
                  helperText={errors.phone && errors.phone}
                  error={errors.phone ? true : false}
                  onChange={e => {
                    e.target.value = normalizeMember("phone", e.target.value);
                    handleInputChange(e);
                  }}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  name="zipcode"
                  type="text"
                  label="CEP *"
                  variant="outlined"
                  helperText={errors.zipcode && errors.zipcode}
                  error={errors.zipcode ? true : false}
                  onChange={e => {
                    e.target.value = normalizeMember("zipcode", e.target.value);
                    handleInputChange(e);
                  }}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  name="address"
                  type="text"
                  label="Endereço *"
                  variant="outlined"
                  style={{ width: "100%" }}
                  helperText={errors.address && errors.address}
                  error={errors.address ? true : false}
                  onChange={e => handleInputChange(e)}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  id="number"
                  name="number"
                  type="text"
                  label="Número *"
                  helperText={errors.number && errors.number}
                  error={errors.number ? true : false}
                  onChange={e => {
                    handleInputChange(e);
                  }}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  name="complement"
                  type="text"
                  label="Complemento"
                  variant="outlined"
                  helperText={errors.complement && errors.complement}
                  error={errors.complement ? true : false}
                  style={{ width: "100%" }}
                  onChange={e => handleInputChange(e)}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  name="neighborhood"
                  type="text"
                  label="Bairro *"
                  variant="outlined"
                  helperText={errors.neighborhood && errors.neighborhood}
                  error={errors.neighborhood ? true : false}
                  style={{ width: "100%" }}
                  onChange={e => handleInputChange(e)}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <TextField
                  name="city"
                  type="text"
                  label="Cidade *"
                  variant="outlined"
                  style={{ width: "100%" }}
                  helperText={errors.city && errors.city}
                  error={errors.city ? true : false}
                  onChange={e => handleInputChange(e)}
                />
              </Col>
              <Col lg={6} className={classes.item}>
                <FormControl style={{ width: "100%" }} variant="outlined">
                  <InputLabel>Estado *</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    name="uf"
                    label="Estado *"
                    error={errors.uf ? true : false}
                    value={values.uf || ""}
                    onChange={e => handleInputChange(e)}
                  >
                    <MenuItem value={"AC"}>Acre</MenuItem>
                    <MenuItem value={"AL"}>Alagoas</MenuItem>
                    <MenuItem value={"AP"}>Amapá</MenuItem>
                    <MenuItem value={"AM"}>Amazonas</MenuItem>
                    <MenuItem value={"BA"}>Bahia</MenuItem>
                    <MenuItem value={"CE"}>Ceará</MenuItem>
                    <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                    <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                    <MenuItem value={"GO"}>Goiás</MenuItem>
                    <MenuItem value={"MA"}>Maranhão</MenuItem>
                    <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                    <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                    <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                    <MenuItem value={"PA"}>Pará</MenuItem>
                    <MenuItem value={"PB"}>Paraíba</MenuItem>
                    <MenuItem value={"PR"}>Paraná</MenuItem>
                    <MenuItem value={"PE"}>Pernambuco</MenuItem>
                    <MenuItem value={"PI"}>Piauí</MenuItem>
                    <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                    <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                    <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                    <MenuItem value={"RO"}>Rondônia</MenuItem>
                    <MenuItem value={"RR"}>Roraima</MenuItem>
                    <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                    <MenuItem value={"SP"}>São Paulo</MenuItem>
                    <MenuItem value={"SE"}>Sergipe</MenuItem>
                    <MenuItem value={"TO"}>Tocantis</MenuItem>
                  </Select>
                </FormControl>
              </Col>
            </Row>
          )}
          {type === "cpf" && (
            <>
              <Row style={{ paddingTop: "15px" }}>
                <Col lg={6} className={classes.item}>
                  <TextField
                    name="fullname"
                    type="text"
                    label="Nome Completo *"
                    variant="outlined"
                    onChange={e => handleInputChange(e)}
                    style={{ width: "100%" }}
                    error={errors.fullname ? true : false}
                    helperText={errors.fullname && errors.fullname}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel>Tipo *</InputLabel>
                    <Select
                      name="type"
                      style={{ width: "100%" }}
                      error={errors.type ? true : false}
                      value={values.type || ""}
                      onChange={e => handleInputChange(e)}
                    >
                      <MenuItem value={1}>Sócio</MenuItem>
                      <MenuItem value={2}>Representante</MenuItem>
                      <MenuItem value={3}>Avalista</MenuItem>
                      <MenuItem value={4}>Procurador</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    style={{ width: "100%" }}
                    name="date_of_birth"
                    type="text"
                    label="Data de Nascimento *"
                    variant="outlined"
                    error={errors.date_of_birth ? true : false}
                    helperText={errors.date_of_birth && errors.date_of_birth}
                    onChange={e => {
                      e.target.value = normalizeMember(
                        "date_of_birth",
                        e.target.value
                      );
                      handleInputChange(e);
                    }}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    style={{ width: "100%" }}
                    name="email"
                    type="text"
                    label="E-mail *"
                    variant="outlined"
                    helperText={errors.email && errors.email}
                    error={errors.email ? true : false}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    style={{ width: "100%" }}
                    name="phone"
                    type="text"
                    label="Telefone *"
                    variant="outlined"
                    helperText={errors.phone && errors.phone}
                    error={errors.phone ? true : false}
                    onChange={e => {
                      e.target.value = normalizeMember("phone", e.target.value);
                      handleInputChange(e);
                    }}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Nacionalidade *"
                    name="nationality"
                    type="text"
                    variant="outlined"
                    helperText={errors.nationality && errors.nationality}
                    error={errors.nationality ? true : false}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    style={{ width: "100%" }}
                    name="profession"
                    type="text"
                    label="Profissão *"
                    variant="outlined"
                    helperText={errors.profession && errors.profession}
                    error={errors.profession ? true : false}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    style={{ width: "100%" }}
                    name="mother_name"
                    type="text"
                    label="Nome da Mãe *"
                    variant="outlined"
                    helperText={errors.mother_name && errors.mother_name}
                    error={errors.mother_name ? true : false}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    name="zipcode"
                    type="text"
                    label="CEP *"
                    variant="outlined"
                    helperText={errors.zipcode && errors.zipcode}
                    error={errors.zipcode ? true : false}
                    onChange={e => {
                      e.target.value = normalizeMember(
                        "zipcode",
                        e.target.value
                      );
                      handleInputChange(e);
                    }}
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    name="address"
                    type="text"
                    label="Endereço *"
                    variant="outlined"
                    style={{ width: "100%" }}
                    helperText={errors.address && errors.address}
                    error={errors.address ? true : false}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    id="number"
                    name="number"
                    type="text"
                    label="Número *"
                    helperText={errors.number && errors.number}
                    error={errors.number ? true : false}
                    onChange={e => {
                      e.target.value = normalizeMember(
                        "number",
                        e.target.value
                      );
                      handleInputChange(e);
                    }}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    name="complement"
                    type="text"
                    label="Complemento"
                    variant="outlined"
                    helperText={errors.complement && errors.complement}
                    error={errors.complement ? true : false}
                    style={{ width: "100%" }}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    name="neighborhood"
                    type="text"
                    label="Bairro *"
                    variant="outlined"
                    helperText={errors.neighborhood && errors.neighborhood}
                    error={errors.neighborhood ? true : false}
                    style={{ width: "100%" }}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    name="city"
                    type="text"
                    label="Cidade *"
                    variant="outlined"
                    style={{ width: "100%" }}
                    helperText={errors.city && errors.city}
                    error={errors.city ? true : false}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel>Estado *</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      name="uf"
                      label="Estado *"
                      error={errors.uf ? true : false}
                      value={values.uf || ""}
                      onChange={e => handleInputChange(e)}
                    >
                      <MenuItem value={"AC"}>Acre</MenuItem>
                      <MenuItem value={"AL"}>Alagoas</MenuItem>
                      <MenuItem value={"AP"}>Amapá</MenuItem>
                      <MenuItem value={"AM"}>Amazonas</MenuItem>
                      <MenuItem value={"BA"}>Bahia</MenuItem>
                      <MenuItem value={"CE"}>Ceará</MenuItem>
                      <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                      <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                      <MenuItem value={"GO"}>Goiás</MenuItem>
                      <MenuItem value={"MA"}>Maranhão</MenuItem>
                      <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                      <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                      <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                      <MenuItem value={"PA"}>Pará</MenuItem>
                      <MenuItem value={"PB"}>Paraíba</MenuItem>
                      <MenuItem value={"PR"}>Paraná</MenuItem>
                      <MenuItem value={"PE"}>Pernambuco</MenuItem>
                      <MenuItem value={"PI"}>Piauí</MenuItem>
                      <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                      <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                      <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                      <MenuItem value={"RO"}>Rondônia</MenuItem>
                      <MenuItem value={"RR"}>Roraima</MenuItem>
                      <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                      <MenuItem value={"SP"}>São Paulo</MenuItem>
                      <MenuItem value={"SE"}>Sergipe</MenuItem>
                      <MenuItem value={"TO"}>Tocantis</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    variant="outlined"
                    style={{ width: "100%" }}
                    name="cpf"
                    type="text"
                    helperText={errors.cpf && errors.cpf}
                    error={errors.cpf ? true : false}
                    onChange={e => {
                      e.target.value = normalizeMember("cpf", e.target.value);
                      handleInputChange(e);
                    }}
                    label="CPF *"
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel>Possui e-CPF? *</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      name="e_cpf"
                      error={errors.e_cpf ? true : false}
                      value={values.e_cpf || ""}
                      onChange={e => handleInputChange(e)}
                    >
                      <MenuItem value={"sim"}>Sim</MenuItem>
                      <MenuItem value={"nao"}>Não</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    variant="outlined"
                    style={{ width: "100%" }}
                    name="identity_doc"
                    type="text"
                    helperText={errors.identity_doc && errors.identity_doc}
                    error={errors.identity_doc ? true : false}
                    onChange={e => {
                      e.target.value = normalizeMember(
                        "identity_doc",
                        e.target.value
                      );
                      handleInputChange(e);
                    }}
                    label="Identidade *"
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <TextField
                    variant="outlined"
                    style={{ width: "100%" }}
                    name="issuing_agency"
                    type="text"
                    label="Órgão Expedidor *"
                    helperText={errors.issuing_agency && errors.issuing_agency}
                    error={errors.issuing_agency ? true : false}
                    onChange={e => handleInputChange(e)}
                  />
                </Col>
                <Col lg={6} className={classes.item}>
                  <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel>Estado *</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      name="issuing_agency_uf"
                      label="Estado *"
                      error={errors.issuing_agency_uf ? true : false}
                      value={values.issuing_agency_uf || ""}
                      onChange={e => handleInputChange(e)}
                    >
                      <MenuItem value={"AC"}>Acre</MenuItem>
                      <MenuItem value={"AL"}>Alagoas</MenuItem>
                      <MenuItem value={"AP"}>Amapá</MenuItem>
                      <MenuItem value={"AM"}>Amazonas</MenuItem>
                      <MenuItem value={"BA"}>Bahia</MenuItem>
                      <MenuItem value={"CE"}>Ceará</MenuItem>
                      <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                      <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                      <MenuItem value={"GO"}>Goiás</MenuItem>
                      <MenuItem value={"MA"}>Maranhão</MenuItem>
                      <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                      <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                      <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                      <MenuItem value={"PA"}>Pará</MenuItem>
                      <MenuItem value={"PB"}>Paraíba</MenuItem>
                      <MenuItem value={"PR"}>Paraná</MenuItem>
                      <MenuItem value={"PE"}>Pernambuco</MenuItem>
                      <MenuItem value={"PI"}>Piauí</MenuItem>
                      <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                      <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                      <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                      <MenuItem value={"RO"}>Rondônia</MenuItem>
                      <MenuItem value={"RR"}>Roraima</MenuItem>
                      <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                      <MenuItem value={"SP"}>São Paulo</MenuItem>
                      <MenuItem value={"SE"}>Sergipe</MenuItem>
                      <MenuItem value={"TO"}>Tocantis</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col lg={6} className={classes.item}>
                  <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel>Estado Civil *</InputLabel>
                    <Select
                      name="marital_status"
                      label="Estado Civil *"
                      style={{ width: "100%" }}
                      error={errors.marital_status ? true : false}
                      value={values.marital_status || ""}
                      onChange={e => handleInputChange(e)}
                    >
                      <MenuItem value={"Solteiro(a)"}>Solteiro(a)</MenuItem>
                      <MenuItem value={"Casado(a)"}>Casado(a)</MenuItem>
                      <MenuItem value={"Divorciado(a)"}>Divorciado(a)</MenuItem>
                      <MenuItem value={"Viúvo(a)"}>Viúvo(a)</MenuItem>
                      <MenuItem value={"Separado(a)"}>Separado(a)</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                {values.marital_status &&
                  values.marital_status === "Casado(a)" && (
                    <Col lg={6} className={classes.item}>
                      <FormControl style={{ width: "100%" }} variant="outlined">
                        <InputLabel>Reg. Casamento *</InputLabel>
                        <Select
                          style={{ width: "100%" }}
                          name="wedding_registry"
                          label="Reg. Casamento *"
                          error={errors.wedding_registry ? true : false}
                          value={values.wedding_registry || ""}
                          onChange={e => handleInputChange(e)}
                        >
                          <MenuItem value="Comunhão de Bens">Comunhão de Bens</MenuItem>
                          <MenuItem value="Comunhão Parcial de Bens">
                            Comunhão Parcial de Bens
                          </MenuItem>
                          <MenuItem value="Separação Total de Bens">
                            Separação Total de Bens
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                  )}
                {values.marital_status !== "Casado(a)" && (
                  <Col lg={6} className={classes.item}>
                    <FormControl style={{ width: "100%" }} variant="outlined">
                      <InputLabel>Possui União Estável?</InputLabel>
                      <Select
                        name="in_stable_union"
                        style={{ width: "100%" }}
                        error={errors.in_stable_union ? true : false}
                        value={values.in_stable_union || ""}
                        onChange={e => handleInputChange(e)}
                      >
                        <MenuItem value={"sim"}>Sim</MenuItem>
                        <MenuItem value={"nao"}>Não</MenuItem>
                      </Select>
                    </FormControl>
                  </Col>
                )}

                <Col lg={6} className={classes.item}>
                  <FormControl style={{ width: "100%" }} variant="outlined">
                    <InputLabel>Pessoa Pública? *</InputLabel>
                    <Select
                      style={{ width: "100%" }}
                      name="in_politically_exposed_person"
                      error={
                        errors.in_politically_exposed_person ? true : false
                      }
                      value={values.in_politically_exposed_person || ""}
                      onChange={e => handleInputChange(e)}
                    >
                      <MenuItem value={"sim"}>Sim</MenuItem>
                      <MenuItem value={"nao"}>Não</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <hr />
              {values.marital_status && values.marital_status === "Casado(a)" && (
                <>
                  <h3 style={{ paddingTop: "15px" }}>Dados do Cônjuge</h3>
                  <Row>
                    <Col lg={6} className={classes.item}>
                      <TextField
                        type="text"
                        name="spouses_fullname"
                        label="Nome Completo *"
                        style={{ width: "100%" }}
                        variant="outlined"
                        helperText={
                          errors.spouses_fullname && errors.spouses_fullname
                        }
                        error={errors.spouses_fullname ? true : false}
                        onChange={e => handleInputChange(e)}
                      />
                    </Col>
                    <Col lg={6} className={classes.item}>
                      <TextField
                        type="text"
                        name="spouses_date_of_birth"
                        helperText={
                          errors.date_of_birth && errors.date_of_birth
                        }
                        error={errors.date_of_birth ? true : false}
                        onChange={e => {
                          e.target.value = normalizeMember(
                            "date_of_birth",
                            e.target.value
                          );
                          handleInputChange(e);
                        }}
                        label="Data de Nascimento *"
                        style={{ width: "100%" }}
                        variant="outlined"
                      />
                    </Col>
                    <Col lg={6} className={classes.item}>
                      <TextField
                        type="text"
                        name="spouses_email"
                        label="E-mail *"
                        style={{ width: "100%" }}
                        helperText={
                          errors.spouses_email && errors.spouses_email
                        }
                        error={errors.spouses_email ? true : false}
                        onChange={e => handleInputChange(e)}
                        variant="outlined"
                      />
                    </Col>
                    <Col lg={6} className={classes.item}>
                    <TextField
                      style={{ width: "100%" }}
                      name="spouses_phone"
                      type="text"
                      label="Telefone *"
                      variant="outlined"
                      helperText={errors.spouses_phone && errors.spouses_phone}
                      error={errors.spouses_phone ? true : false}
                      onChange={e => {
                        e.target.value = normalizeMember("phone", e.target.value);
                        handleInputChange(e);
                      }}
                    />
                    </Col>
                    <Col lg={6} className={classes.item}>
                      <TextField
                        type="text"
                        name="spouses_cpf"
                        helperText={errors.spouses_cpf && errors.spouses_cpf}
                        error={errors.spouses_cpf ? true : false}
                        onChange={e => {
                          e.target.value = normalizeMember(
                            "cpf",
                            e.target.value
                          );
                          handleInputChange(e);
                        }}
                        label="CPF *"
                        style={{ width: "100%" }}
                        variant="outlined"
                      />
                    </Col>
                    <Col lg={6} className={classes.item}>
                    <FormControl style={{ width: "100%" }} variant="outlined">
                        <InputLabel>Possui e-CPF? *</InputLabel>
                        <Select
                          style={{ width: "100%" }}
                          name="spouses_e_cpf"
                          error={errors.spouses_e_cpf ? true : false}
                          value={values.spouses_e_cpf || ""}
                          onChange={e => handleInputChange(e)}
                        >
                          <MenuItem value={"sim"}>Sim</MenuItem>
                          <MenuItem value={"nao"}>Não</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col lg={6} className={classes.item}>
                      <TextField
                        type="text"
                        name="spouses_identity"
                        helperText={
                          errors.spouses_identity && errors.spouses_identity
                        }
                        error={errors.spouses_identity ? true : false}
                        onChange={e => {
                          e.target.value = normalizeMember(
                            "identity_doc",
                            e.target.value
                          );
                          handleInputChange(e);
                        }}
                        label="Identidade *"
                        style={{ width: "100%" }}
                        variant="outlined"
                      />
                    </Col>
                    <Col lg={6} className={classes.item}>
                      <TextField
                        type="text"
                        name="spouses_identity_oe"
                        label="Órgão Expedidor *"
                        style={{ width: "100%" }}
                        helperText={
                          errors.spouses_identity_oe &&
                          errors.spouses_identity_oe
                        }
                        error={errors.spouses_identity_oe ? true : false}
                        onChange={e => handleInputChange(e)}
                        variant="outlined"
                      />
                    </Col>
                    <Col lg={6} className={classes.item}>
                      <FormControl style={{ width: "100%" }} variant="outlined">
                        <InputLabel>Estado *</InputLabel>
                        <Select
                          name="spouses_identity_uf"
                          error={errors.spouses_identity_uf ? true : false}
                          value={values.spouses_identity_uf || ""}
                          label="Estado *"
                          onChange={e => handleInputChange(e)}
                          style={{ width: "100%" }}
                        >
                          <MenuItem value={"AC"}>Acre</MenuItem>
                          <MenuItem value={"AL"}>Alagoas</MenuItem>
                          <MenuItem value={"AP"}>Amapá</MenuItem>
                          <MenuItem value={"AM"}>Amazonas</MenuItem>
                          <MenuItem value={"BA"}>Bahia</MenuItem>
                          <MenuItem value={"CE"}>Ceará</MenuItem>
                          <MenuItem value={"DF"}>Distrito Federal</MenuItem>
                          <MenuItem value={"ES"}>Espírito Santo</MenuItem>
                          <MenuItem value={"GO"}>Goiás</MenuItem>
                          <MenuItem value={"MA"}>Maranhão</MenuItem>
                          <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                          <MenuItem value={"MS"}>Mato Grosso do Sul</MenuItem>
                          <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                          <MenuItem value={"PA"}>Pará</MenuItem>
                          <MenuItem value={"PB"}>Paraíba</MenuItem>
                          <MenuItem value={"PR"}>Paraná</MenuItem>
                          <MenuItem value={"PE"}>Pernambuco</MenuItem>
                          <MenuItem value={"PI"}>Piauí</MenuItem>
                          <MenuItem value={"RJ"}>Rio de Janeiro</MenuItem>
                          <MenuItem value={"RN"}>Rio Grande do Norte</MenuItem>
                          <MenuItem value={"RS"}>Rio Grande do Sul</MenuItem>
                          <MenuItem value={"RO"}>Rondônia</MenuItem>
                          <MenuItem value={"RR"}>Roraima</MenuItem>
                          <MenuItem value={"SC"}>Santa Catarina</MenuItem>
                          <MenuItem value={"SP"}>São Paulo</MenuItem>
                          <MenuItem value={"SE"}>Sergipe</MenuItem>
                          <MenuItem value={"TO"}>Tocantis</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                    <Col lg={6} className={classes.item}>
                      <TextField
                        style={{ width: "100%" }}
                        variant="outlined"
                        type="text"
                        name="spouses_profession"
                        helperText={
                          errors.spouses_profession && errors.spouses_profession
                        }
                        error={errors.spouses_profession ? true : false}
                        onChange={e => handleInputChange(e)}
                        label="Profissão/Atividade *"
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {type === null && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "20px 0"
              }}
            >
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setType("cpf")}
                style={{ marginRight: "5px", fontSize: "16px" }}
              >
                <i className="flaticon2-user"></i> Cadastrar Pessoa Física
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                style={{ fontSize: "16px" }}
                onClick={() => setType("cnpj")}
              >
                <i className="flaticon2-group"></i> Cadastrar Pessoa Jurídica
              </button>
            </div>
          )}
          <hr />
          {type !== null && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <button
                type="button"
                className="btn btn-success"
                style={{ marginRight: "5px" }}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? "Carregando..." : "Cadastrar Membro"}
              </button>
              <button
                type="button"
                className="btn btn-light"
                disabled={loading}
                onClick={() => {
                  setValues({});
                  setErrors({});
                  setType(null);
                }}
              >
                Cancelar
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default memo(ModalAddMember);
