import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableSortLabel,
  TableRow,
  Input,
  TextField,
  Paper,
  makeStyles,
  LinearProgress,
  Button,
  MenuItem,
} from "@material-ui/core";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import { format } from "date-fns";
import verifyProfile from "../utils/verifyProfiles";

import ModalConfirmationRefuse from "../register/components/Modais/ModalConfirmationRefuse";

import { normalizeCnpj, normalizePhone } from "../register/utils/normalize";

export default function RegisterIncompleteIndex() {
  const [open, setOpen] = useState(false);
  const [registerSelected, setRegisterSelected] = useState(null);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "500px",
    },
  }));
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("completed");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [toogleEmailInput, setToogleEmailInput] = useState([]);
  const [tooglePhoneInput, setTooglePhoneInput] = useState([]);

  const [users, setUsers] = useState([]);
  const [gerentes, setGerentes] = useState([]);
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function loadUsers() {
      const { data } = await axios.get(`/roles/users/8`);
      setUsers(data);
      const { data: userData } = await axios.get(`auth`);
      setUser(userData);
      console.log(userData);
    }
    loadUsers();
  }, []);

  useEffect(() => {
    async function loadGerente() {
      try {
        const { data } = await axios.get("facta/gerente");
        setGerentes(data);
      } catch (error) {}
    }
    loadGerente();
  }, []);

  async function handleGerenteChange(item, id) {
    let gerente_id = item.target.value;
    try {
      const response = await axios.put(
        `/registers/gerente/${id}`,
        {
          gerente_id,
        }
      );
      console.log('response', response)
      setRows(rows.map(r => {
        if (r.id === id) {
          return {
            ...r,
            gerente_id,
          }
        }
        return r;
      }));
    } catch (error) {}
  }

  async function loadRegisters() {
    setLoading(true);
    try {
      const query = [];
      if(user) {
        if(user.gerente_id) {
          query.push(`gerente_id=${user.gerente_id}`);
        }
      }
      const response = await axios.get(`/registers_incompletes?${query.map(q => q).join('&')}`);

      setRows(response.data);

      const loadEmails = response.data.map((e) => {
        return e.email;
      });

      const loadPhones = response.data.map((e) => {
        return normalizePhone(e.phone);
      });

      setEmails(loadEmails);
      setPhones(loadPhones);
    } catch (err) {
      history.goBack();
    }
    setLoading(false);
  }

  async function refuseRegister(reason) {
    setLoading(true);
    try {
      await axios.put(`/register/refuse/${registerSelected}`, {
        reason
      });
      toast.info("Cadastro Indeferido");
      setOpen(false);
      setRegisterSelected(null);
      loadRegisters();
    } catch (err) {
      toast.error("Ocorreu um erro ao indeferir o cadastro");
    }
    setLoading(false);
  }

  useEffect(() => {
    if(user) {
      loadRegisters();
    }
  }, [user]); // eslint-disable-line

  function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getSorting(order, orderBy) {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  }

  const headRows = [
    {
      id: "razao_social",
      numeric: false,
      align: "left",
      disablePadding: true,
      label: "Empresa",
    },
    {
      id: "contact",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Contato",
    },
    {
      id: "gerent/agent",
      numeric: false,
      align: "left",
      disablePadding: false,
      label: "Gerente/Agente",
    },
    {
      id: "createdat",
      numeric: false,
      align: "right",
      disablePadding: false,
      label: "Data de cadastro",
    },
    {
      id: "actions",
      numeric: false,
      align: "right",
      disablePadding: false,
      label: "Ações",
    },
  ];

  function formatName(name) {
    if (name.length < 40) {
      return name;
    }
    return (
      <>
        {name.slice(0, Math.floor(name.length / 2))}
        <br />
        {name.slice(Math.floor(name.length / 2), name.length)}
      </>
    );
  }

  function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {headRows.map((row) => (
            <TableCell
              key={row.id}
              align={row.align}
              sortDirection={orderBy === row.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === row.id}
                direction={order}
                onClick={createSortHandler(row.id)}
              >
                {row.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
  };

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  function handleToogleEmailInput(index) {
    setToogleEmailInput([...toogleEmailInput, index]);
  }

  function handleTooglePhoneInput(index) {
    setTooglePhoneInput([...tooglePhoneInput, index]);
  }

  async function handleOnBlurInputEmail(register, index) {
    function emailIsValid(email) {
      if (email.indexOf("@") === -1) {
        return false;
      }
      if (email.indexOf(".") === -1) {
        return false;
      }
      return true;
    }

    if (!emailIsValid(emails[index])) {
      toast.error("Digite um email válido");
    }

    if (emailIsValid(emails[index])) {
      if (emails[index] !== register.email) {
        register.email = emails[index];

        await axios.put(`/registers/email/${register.id}`, {
          email: emails[index],
        });
      }
    }

    setToogleEmailInput(toogleEmailInput.filter((e) => e !== index));
  }

  function handleEmailChange(e, index) {
    let updatedEmails = [...emails];
    let newEmail = { ...updatedEmails[index] };
    newEmail = e.target.value;
    updatedEmails[index] = newEmail;

    setEmails(updatedEmails);
  }

  async function handleOnBlurInputPhone(register, index) {
    function phoneIsValid(phone) {
      if (!phone || phone.length < 14) {
        return false;
      }
      return true;
    }

    if (!phoneIsValid(phones[index])) {
      toast.error("Digite um telefone válido");
    }

    if (phoneIsValid(phones[index])) {
      if (phones[index] !== register.phone) {
        register.phone = phones[index].replace(/\D/g, "");

        await axios.put(`/registers/phone/${register.id}`, {
          phone: phones[index].replace(/\D/g, ""),
        });
      }
    }

    setTooglePhoneInput(tooglePhoneInput.filter((e) => e !== index));
  }

  async function handleUpdateAgent(agent_id, company_id) {
    setLoading(true);
    try {
      await axios.put(`/registers/agent/${company_id}`, {
        user_id: agent_id,
      });
      setRows(
        rows.map((r) => {
          if (r.id === company_id) {
            return {
              ...r,
              user_id: agent_id,
            };
          } else {
            return r;
          }
        })
      );
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.message);
      } else {
        toast.error("Erro desconhecido, contate a equipe técnica");
      }
    }
    setLoading(false);
  }

  function handlePhoneChange(e, index) {
    e.target.value = normalizePhone(e.target.value);

    let updatedPhones = [...phones];
    let newPhone = { ...updatedPhones[index] };
    newPhone = e.target.value;
    updatedPhones[index] = newPhone;

    setPhones(updatedPhones);
  }

  async function handleContinue(id) {
    setLoading(true);
    try {
      const { data } = await axios.get(`/customers/incomplete/${id}`);
      toast.info("Redirecionando para o cadastro...");
      setTimeout(() => {
        document.location.href = data.token;
      }, 1500);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        toast.error("Cadastro não encontrado");
      } else if (err.response && err.response.status === 500) {
        toast.error(
          "Ocorreu um erro em nossos servidores, contate a equipe de TI."
        );
      }
    }
    setLoading(false);
  }

  return (
    <div className={classes.root}>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastros Incompletos</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/register_incompletes`}
              >
                Cadastros Incompletos
              </Link>
            </div>
          </div>
        </div>
      </div>
      <ModalConfirmationRefuse
        open={open}
        setOpen={(e) => {
          setOpen(e);
          setRegisterSelected(null);
        }}
        onConfirm={(reason) => refuseRegister(reason)}
      />
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          {loading && <LinearProgress color="primary" />}
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  let data = JSON.parse(row.data);
                  let razao_social = "";
                  if (
                    data &&
                    data.find((item) => item.name === "razao_social")
                  ) {
                    razao_social = data.find(
                      (item) => item.name === "razao_social"
                    ).value;
                  }
                  return (
                    <TableRow hover tabIndex={-1} key={index}>
                      <TableCell align="left" style={{ fontSize: "12px" }}>
                        {formatName(razao_social)}
                        <br></br>
                        <strong style={{ fontWeight: 500 }}>
                          {normalizeCnpj(row.cnpj)}
                        </strong>
                      </TableCell>
                      <TableCell align="left">
                        <div>
                          {toogleEmailInput.indexOf(index) >= 0 && (
                            <Input
                              value={emails[index]}
                              onChange={(e) => handleEmailChange(e, index)}
                              onBlur={() => handleOnBlurInputEmail(row, index)}
                            />
                          )}
                          {toogleEmailInput.indexOf(index) < 0 && row.email}
                          {toogleEmailInput.indexOf(index) < 0 && (
                            <EditIcon
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => handleToogleEmailInput(index)}
                            />
                          )}
                        </div>
                        <div>
                          {tooglePhoneInput.indexOf(index) >= 0 && (
                            <Input
                              value={phones[index]}
                              onChange={(e) => handlePhoneChange(e, index)}
                              onBlur={() => handleOnBlurInputPhone(row, index)}
                            />
                          )}
                          {tooglePhoneInput.indexOf(index) < 0 &&
                            normalizePhone(row.phone)}
                          {tooglePhoneInput.indexOf(index) < 0 && (
                            <EditIcon
                              style={{
                                marginLeft: "5px",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => handleTooglePhoneInput(index)}
                            />
                          )}
                        </div>
                      </TableCell>
                      <TableCell align="left">
                        <div className="d-flex align-items-center">
                        <TextField
                          select
                          label="Gerente"
                          value={row.gerente_id}
                          onChange={(e) =>
                            handleGerenteChange(e, row.id)
                          }
                          variant="outlined"
                          size="small"
                          style={{
                            minWidth: "100px",
                          }}
                          disabled={
                            (user &&
                              !verifyProfile(user, "admin") &&
                                !verifyProfile(user, "analist")) ||
                            loading
                          }
                        >
                          {gerentes.map((option) => (
                            <MenuItem key={option.GERE_ID} value={option.GERE_ID}>
                              {option.NOME}
                            </MenuItem>
                          ))}
                        </TextField>
                        /
                        <TextField
                          select
                          label="Agente"
                          value={row.user_id}
                          onChange={(e) =>
                            handleUpdateAgent(Number(e.target.value), row.id)
                          }
                          variant="outlined"
                          size="small"
                          style={{
                            minWidth: "100px",
                          }}
                          disabled={
                            (user &&
                              !verifyProfile(user, "admin") &&
                                !verifyProfile(user, "analist")) ||
                            loading
                          }
                        >
                          {users.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.fullname}
                            </MenuItem>
                          ))}
                        </TextField>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {format(
                          new Date(row.created_at),
                          "dd/MM/yyyy HH:ii:ss"
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          type="button"
                          size="small"
                          color="primary"
                          variant="contained"
                          onClick={() => handleContinue(row.id)}
                        >
                          Continuar
                        </Button>
                        <Button
                          type="button"
                          size="small"
                          variant="contained"
                          color="secondary"
                          className="ml-2 bg-danger"
                          onClick={() => {
                            setOpen(true);
                            setRegisterSelected(row.id);
                          }}
                        >
                          Indeferir
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 1000]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            "aria-label": "Previous Page",
          }}
          nextIconButtonProps={{
            "aria-label": "Next Page",
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
