import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Portlet,
  PortletBody,
  PortletHeader,
} from "../../partials/content/Portlet";
import { metronic } from "../../../_metronic";
import QuickStatsChart from "../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../widgets/OrderStatisticsChart";
import SalesBarChart from "../../widgets/SalesBarChart";
import PieChart from "../../widgets/PieChart";

export default function Dashboard() {
  useEffect(() => {
    async function loadChart() {
      const responseNew = await axios.get("dashboard/new");
      setChartNew(responseNew.data);

      const responseContact = await axios.get("dashboard/contact");
      setChartContact(responseContact.data);

      const responseWork = await axios.get("dashboard/work");
      setChartWork(responseWork.data);

      const responseAgent = await axios.get("dashboard/agent");
      setChartAgent(responseAgent.data);

      const responseRegister = await axios.get("dashboard/register");
      setChartRegister(responseRegister.data);

      const responseNFes = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/requestlist/dashboard/month`
      );
      setChartNFes(responseNFes.data);

      const responseCTes = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/bordero-report-cte/dashboard/month`
      );
      setChartCTes(responseCTes.data);

      /*
      const responseNFesDay = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/bordero-report/dashboard/day`
      );
      setChartNFesDay(responseNFesDay.data);

      const responseCTesDay = await axios.get(
        `${process.env.REACT_APP_API_BFC_DIGITAL_URL}/bordero-report-cte/dashboard/day`
      );
      setChartCTesDay(responseCTesDay.data);
      */
    }
    loadChart();
  }, []);

  const [chartNew, setChartNew] = useState({});
  const [chartContact, setChartContact] = useState({});
  const [chartWork, setChartWork] = useState({});
  const [chartAgent, setChartAgent] = useState({});
  const [chartRegister, setChartRegister] = useState({});
  const [chartNFes, setChartNFes] = useState([]);
  const [chartCTes, setChartCTes] = useState([]);
  // const [chartNFesDay, setChartNFesDay] = useState({});
  // const [chartCTesDay, setChartCTesDay] = useState({});

  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    (state) => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      ),
    })
  );

  const chartOptions = useMemo(
    () => ({
      chart1: {
        data: chartContact.data,
        color: brandColor,
        border: 3,
      },

      chart2: {
        data: chartWork.data,
        color: dangerColor,
        border: 3,
      },

      chart3: {
        data: chartAgent.data,
        color: successColor,
        border: 3,
      },

      chart4: {
        data: chartNew.data,
        color: primaryColor,
        border: 3,
      },
    }),
    [
      brandColor,
      chartAgent.data,
      chartContact.data,
      chartNew.data,
      chartWork.data,
      dangerColor,
      primaryColor,
      successColor,
    ]
  );

  return (
    <>
      <div className="row">
        <div className="col-xl-5">
          <div className="row">
            <div className="col-md-12">
              <SalesBarChart
                title={"NFe's analisadas do mês atual"}
                info={chartNFes}
              />
            </div>
            <div className="col-md-12">
              <SalesBarChart
                title={"CTe's analisadas do mês atual"}
                info={chartCTes}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-7">
          <div className="row">
            <div className="col-md-12">
              <Portlet fluidHeight={true}>
                <PortletHeader title="Estatística de Cadastros" />
                <PortletBody>
                  <OrderStatisticsChart
                    total={chartRegister.total}
                    total_c={chartRegister.total_c}
                    total_i={chartRegister.total_i}
                    data_c={chartRegister.data_c}
                    data_i={chartRegister.data_i}
                    legend={chartRegister.legend}
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <PieChart
                    title={"NFe's analisadas hoje"}
                    total={
                      chartNFes.length > 0
                        ? chartNFes[new Date().getDate() - 1].confirmadas +
                          chartNFes[new Date().getDate() - 1].aConfirmar +
                          chartNFes[new Date().getDate() - 1].rejeitadas +
                          chartNFes[new Date().getDate() - 1].naoConfirmadas +
                          chartNFes[new Date().getDate() - 1].naoEncontradas
                        : 0
                    }
                    info={
                      chartNFes.length > 0 &&
                      chartNFes[new Date().getDate() - 1]
                    }
                  />
                </div>
                <div className="col-md-6">
                  <PieChart
                    title={"CTe's analisadas hoje"}
                    total={
                      chartCTes.length > 0
                        ? chartCTes[new Date().getDate() - 1].confirmadas +
                          chartCTes[new Date().getDate() - 1].aConfirmar +
                          chartCTes[new Date().getDate() - 1].rejeitadas +
                          chartCTes[new Date().getDate() - 1].naoConfirmadas +
                          chartCTes[new Date().getDate() - 1].naoEncontradas
                        : 0
                    }
                    info={
                      chartCTes.length > 0 &&
                      chartCTes[new Date().getDate() - 1]
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-12">
          <div className="row row-full-height">
            <div className="col-sm-12 col-md-12 col-lg-3">
              <Portlet className="kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chartContact.total}
                    desc="Seja um agente"
                    data={chartOptions.chart1.data}
                    color={chartOptions.chart1.color}
                    border={chartOptions.chart1.border}
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3">
              <Portlet className="kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chartWork.total}
                    desc="Fale conosco"
                    data={chartOptions.chart2.data}
                    color={chartOptions.chart2.color}
                    border={chartOptions.chart2.border}
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3">
              <Portlet className="kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chartAgent.total}
                    desc="Trabalhe conosco"
                    data={chartOptions.chart3.data}
                    color={chartOptions.chart3.color}
                    border={chartOptions.chart3.border}
                  />
                </PortletBody>
              </Portlet>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-3">
              <Portlet className="kt-portlet--border-bottom-brand">
                <PortletBody fluid={true}>
                  <QuickStatsChart
                    value={chartNew.total}
                    desc="Newsletter"
                    data={chartOptions.chart4.data}
                    color={chartOptions.chart4.color}
                    border={chartOptions.chart4.border}
                  />
                </PortletBody>
              </Portlet>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
