import React, { memo, useState } from "react";

import {
  Modal,
  Backdrop,
  makeStyles,
  Fade,
  InputLabel,
  TextField,
  FormControl,
  Select,
  MenuItem
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import ModalConfirmApproveMember from "./ModalConfirmApproveMember";
import normalizeMember from "../../utils/normalizeMember";
import verifyTitleMemberField from "../../utils/verifyTitleMemberField";
import validationMember from "../../utils/validationUpdateMember";

function ModalMember({
  openSocio,
  setOpenSocio,
  onReload,
  setOpenSocioReproved,
  pendencies,
  id,
  editField,
  setEditField,
  setEditConfirmField,
  memberError,
  setMemberError
}) {
  const [selectReprove, setSelectReprove] = useState(false);
  const [memberFieldsReproved, setMemberFieldsReproved] = useState({});
  const [confirmApprovedMember, setConfirmApprovedMember] = useState({
    active: false,
    member: {}
  });
  const [loading, setLoading] = useState(false);
  const useStyles = makeStyles(theme => ({
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20
    },
    card: {
      padding: "10px 15px",
      width: "100%",
      border: "1px solid #eee",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      textAlign: "left",
      flexWrap: "wrap",
      flex: "1"
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)"
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      overflow: "auto"
    },
    close: {
      background: "#fff",
      fontSize: "20px",
      border: 0,
      textAlign: "right",
      width: "100%"
    },
    wrap: {
      display: "flex",
      justifyContent: "space-beetwen",
      alignItems: "center"
    }
  }));
  const classes = useStyles();

  async function ApprovedMember() {
    const data = {
      type: "member",
      member_id: openSocio.socio.id
    };

    const errors = validationMember(openSocio.socio);

    if (Object.keys(errors).length > 0) {
      setMemberError(errors);
      setConfirmApprovedMember({ active: false, member: {} });
      setEditField(true);
      console.log(errors);
      toast.error("Membro não está hábil para ser aprovado");
      return toast.warn("Verifique os campos indicados");
    }

    setLoading(true);
    try {
      await axios.post(`register_analysis/${id}/approve`, data);
      setConfirmApprovedMember({ active: false, member: {} });
      setMemberFieldsReproved({});
      setOpenSocio({ socio: {}, active: false });
      toast.success("Membro aprovado com sucesso");
      onReload();
    } catch (error) {
      if(error.response.status === 401) {
        toast.error(error.response.data.msg);
        setConfirmApprovedMember({ active: false, member: {} });
      }else{
        toast.error('Ocorreu um erro ao aprovar membro... tente novamente');
      }
    }
    setLoading(false);
  }

  function handleSelectReprovedMemberField(item) {
    const keys = Object.keys(item);
    const alreadyHave = memberFieldsReproved[keys[0]];
    if (keys[0] === "memberSpouses") {
      let keysSpouses = Object.keys(item[keys[0]]);
      let alreadyHaveKeySpouse =
        memberFieldsReproved.memberSpouses &&
        memberFieldsReproved.memberSpouses[keysSpouses[0]]
          ? true
          : false;
      if (alreadyHaveKeySpouse) {
        let reproved = memberFieldsReproved.memberSpouses;
        delete reproved[keysSpouses[0]];
        setMemberFieldsReproved({
          ...memberFieldsReproved,
          memberSpouses: reproved
        });
      } else {
        setMemberFieldsReproved({
          ...memberFieldsReproved,
          memberSpouses: {
            ...memberFieldsReproved.memberSpouses,
            [keysSpouses[0]]: item[keys[0]][keysSpouses[0]]
          }
        });
      }
    } else {
      if (!alreadyHave) {
        setMemberFieldsReproved({
          ...memberFieldsReproved,
          [keys[0]]: item[keys[0]]
        });
      } else {
        let oldObj = memberFieldsReproved;
        delete oldObj[keys[0]];
        const keysOldObj = Object.keys(memberFieldsReproved);
        let newObj = {};
        setMemberFieldsReproved({});
        for (let i = 0; i < keysOldObj.length; i++) {
          newObj = {
            ...newObj,
            [keysOldObj[i]]: oldObj[keysOldObj[i]]
          };
        }
        setMemberFieldsReproved(newObj);
      }
    }
  }

  return (
    <>
      <ModalConfirmApproveMember
        confirmApprovedMember={confirmApprovedMember}
        setConfirmApprovedMember={e => setConfirmApprovedMember(e)}
        pendencies={pendencies}
        ApprovedMember={() => ApprovedMember()}
      />
      <Modal
        className={classes.modal}
        open={openSocio.active}
        onClose={() => {
          setOpenSocio({ active: false, socio: {} });
          setSelectReprove(false);
          setMemberFieldsReproved({});
          setEditField(false);
          onReload();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openSocio.active}>
          <div className={classes.fade}>
            <Row>
              <Col lg={10}>
                <h2 id="transition-modal-title">{openSocio.socio.fullname}</h2>
              </Col>
              <Col lg={2}>
                <button
                  className={classes.close}
                  onClick={() => setOpenSocio({ active: false, socio: {} })}
                >
                  <Close />
                </button>
              </Col>
            </Row>
            <hr />
            <Row>
              {Object.keys(openSocio.socio).map(item => {
                if (openSocio.socio.cpf.length === 18) {
                  if (
                    item === "fullname" ||
                    item === "cpf" ||
                    item === "address" ||
                    item === "number" ||
                    item === "complement" ||
                    item === "neighborhood" ||
                    item === "city" ||
                    item === "uf" ||
                    item === "zipcode" ||
                    item === "email" ||
                    item === "phone"
                  ) {
                    return (
                      <Col lg={6} key={item} style={{ padding: "5px" }}>
                        {editField ? (
                          <>
                            {item === "uf" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                              >
                                <InputLabel>Estado</InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value={"AC"}>Acre</MenuItem>
                                  <MenuItem value={"AL"}>Alagoas</MenuItem>
                                  <MenuItem value={"AP"}>Amapá</MenuItem>
                                  <MenuItem value={"AM"}>Amazonas</MenuItem>
                                  <MenuItem value={"BA"}>Bahia</MenuItem>
                                  <MenuItem value={"CE"}>Ceará</MenuItem>
                                  <MenuItem value={"DF"}>
                                    Distrito Federal
                                  </MenuItem>
                                  <MenuItem value={"ES"}>
                                    Espírito Santo
                                  </MenuItem>
                                  <MenuItem value={"GO"}>Goiás</MenuItem>
                                  <MenuItem value={"MA"}>Maranhão</MenuItem>
                                  <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                                  <MenuItem value={"MS"}>
                                    Mato Grosso do Sul
                                  </MenuItem>
                                  <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                                  <MenuItem value={"PA"}>Pará</MenuItem>
                                  <MenuItem value={"PB"}>Paraíba</MenuItem>
                                  <MenuItem value={"PR"}>Paraná</MenuItem>
                                  <MenuItem value={"PE"}>Pernambuco</MenuItem>
                                  <MenuItem value={"PI"}>Piauí</MenuItem>
                                  <MenuItem value={"RJ"}>
                                    Rio de Janeiro
                                  </MenuItem>
                                  <MenuItem value={"RN"}>
                                    Rio Grande do Norte
                                  </MenuItem>
                                  <MenuItem value={"RS"}>
                                    Rio Grande do Sul
                                  </MenuItem>
                                  <MenuItem value={"RO"}>Rondônia</MenuItem>
                                  <MenuItem value={"RR"}>Roraima</MenuItem>
                                  <MenuItem value={"SC"}>
                                    Santa Catarina
                                  </MenuItem>
                                  <MenuItem value={"SP"}>São Paulo</MenuItem>
                                  <MenuItem value={"SE"}>Sergipe</MenuItem>
                                  <MenuItem value={"TO"}>Tocantis</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item !== "uf" && (
                              <TextField
                                type="text"
                                value={openSocio.socio[item] || ""}
                                onChange={event => {
                                  let socio_obj = openSocio.socio;
                                  socio_obj[item] = normalizeMember(
                                    item,
                                    event.target.value
                                  );
                                  setOpenSocio({
                                    ...openSocio,
                                    socio: socio_obj
                                  });
                                }}
                                error={memberError[item] ? true : false}
                                helperText={
                                  memberError[item] && memberError[item]
                                }
                                disabled={item === "cpf"}
                                label={
                                  item === "cpf"
                                    ? "CNPJ"
                                    : item === "date_of_birth"
                                    ? "Data de Fundação"
                                    : verifyTitleMemberField(item)
                                }
                                variant="outlined"
                                style={{ width: "100%" }}
                              />
                            )}
                          </>
                        ) : (
                          <button
                            type="button"
                            style={{
                              background: "none",
                              border: 0,
                              marginLeft: 0,
                              paddingLeft: 0
                            }}
                            disabled={!selectReprove}
                            onClick={() =>
                              handleSelectReprovedMemberField({
                                [item]: openSocio.socio[item]
                              })
                            }
                            className={classes.card}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                padding: "0",
                                margin: "0",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {selectReprove && (
                                <span
                                  className={
                                    memberFieldsReproved[item] !== undefined
                                      ? "fa fa-check"
                                      : ""
                                  }
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    border: "0",
                                    background: "#dee2ea",
                                    display: "inline-block",
                                    borderRadius: "5px",
                                    marginRight: "10px"
                                  }}
                                ></span>
                              )}
                              <strong style={{ marginRight: "10px" }}>
                                {item === "cpf" && "CNPJ"}
                                {item === "date_of_birth" && "Data de Fundação"}
                                {item !== "cpf" &&
                                  item !== "date_of_birth" &&
                                  verifyTitleMemberField(item)}
                                :
                              </strong>{" "}
                              {item === "e_cpf" ? (
                                <>
                                {openSocio.socio[item] === "0" && "Não"}
                                {openSocio.socio[item] === "1" && "Sim"}
                                </>
                              ) : openSocio.socio[item]}
                            </p>
                          </button>
                        )}
                      </Col>
                    );
                  } else {
                    return "";
                  }
                } else {
                  if (
                    item !== "id" &&
                    item !== "register_id" &&
                    item !== "created_at" &&
                    item !== "step" &&
                    item !== "finished" &&
                    item !== "updated_at" &&
                    item !== "memberSpouses" &&
                    item !== "profession_uf" &&
                    item !== "sex" &&
                    item !== "no_it_depends" &&
                    item !== "naturalness_uf" &&
                    item !== "issued_on" &&
                    item !== "naturalness" &&
                    item !== "res" &&
                    item !== "father_name" &&
                    item !== "partic"
                  ) {
                    return (
                      <Col lg={6} key={item} style={{ padding: "5px" }}>
                        {editField ? (
                          <>
                            {item === "wedding_registry" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                                disabled={
                                  openSocio.socio.marital_status !== "Casado(a)"
                                }
                              >
                                <InputLabel>Regime de Casamento</InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = event.target.value;
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value="Comunhão de Bens">Comunhão de Bens</MenuItem>
                                  <MenuItem value="Comunhão Parcial de Bens">
                                    Comunhão Parcial de Bens
                                  </MenuItem>
                                  <MenuItem value="Separação Total de Bens">
                                    Separação Total de Bens
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item === "marital_status" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                              >
                                <InputLabel>Estado Civil</InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value="Casado(a)">
                                    Casado(a)
                                  </MenuItem>
                                  <MenuItem value="Solteiro(a)">
                                    Solteiro(a)
                                  </MenuItem>
                                  <MenuItem value="Viúvo(a)">Viúvo(a)</MenuItem>
                                  <MenuItem value="Separado(a)">
                                    Separado(a)
                                  </MenuItem>
                                  <MenuItem value="Divorciado(a)">
                                    Divorciado(a)
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item === "type" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                              >
                                <InputLabel>Tipo</InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  style={{ width: "100%" }}
                                  error={memberError[item] ? true : false}
                                >
                                  <MenuItem value={1}>Sócio</MenuItem>
                                  <MenuItem value={2}>Representante</MenuItem>
                                  <MenuItem value={3}>Avalista</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item === "uf" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                              >
                                <InputLabel>Estado</InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value={"AC"}>Acre</MenuItem>
                                  <MenuItem value={"AL"}>Alagoas</MenuItem>
                                  <MenuItem value={"AP"}>Amapá</MenuItem>
                                  <MenuItem value={"AM"}>Amazonas</MenuItem>
                                  <MenuItem value={"BA"}>Bahia</MenuItem>
                                  <MenuItem value={"CE"}>Ceará</MenuItem>
                                  <MenuItem value={"DF"}>
                                    Distrito Federal
                                  </MenuItem>
                                  <MenuItem value={"ES"}>
                                    Espírito Santo
                                  </MenuItem>
                                  <MenuItem value={"GO"}>Goiás</MenuItem>
                                  <MenuItem value={"MA"}>Maranhão</MenuItem>
                                  <MenuItem value={"MT"}>Mato Grosso</MenuItem>
                                  <MenuItem value={"MS"}>
                                    Mato Grosso do Sul
                                  </MenuItem>
                                  <MenuItem value={"MG"}>Minas Gerais</MenuItem>
                                  <MenuItem value={"PA"}>Pará</MenuItem>
                                  <MenuItem value={"PB"}>Paraíba</MenuItem>
                                  <MenuItem value={"PR"}>Paraná</MenuItem>
                                  <MenuItem value={"PE"}>Pernambuco</MenuItem>
                                  <MenuItem value={"PI"}>Piauí</MenuItem>
                                  <MenuItem value={"RJ"}>
                                    Rio de Janeiro
                                  </MenuItem>
                                  <MenuItem value={"RN"}>
                                    Rio Grande do Norte
                                  </MenuItem>
                                  <MenuItem value={"RS"}>
                                    Rio Grande do Sul
                                  </MenuItem>
                                  <MenuItem value={"RO"}>Rondônia</MenuItem>
                                  <MenuItem value={"RR"}>Roraima</MenuItem>
                                  <MenuItem value={"SC"}>
                                    Santa Catarina
                                  </MenuItem>
                                  <MenuItem value={"SP"}>São Paulo</MenuItem>
                                  <MenuItem value={"SE"}>Sergipe</MenuItem>
                                  <MenuItem value={"TO"}>Tocantis</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item === "in_stable_union" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                              >
                                <InputLabel>Em União Estável</InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value="sim">Sim</MenuItem>
                                  <MenuItem value="nao">Não</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item === "in_politically_exposed_person" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                              >
                                <InputLabel>
                                  Pessoa Politicamente Exposta
                                </InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value="sim">Sim</MenuItem>
                                  <MenuItem value="nao">Não</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            {item === "e_cpf" && (
                              <FormControl
                                style={{ width: "100%" }}
                                variant="outlined"
                              >
                                <InputLabel>
                                  Possui e-CPF?
                                </InputLabel>
                                <Select
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  style={{ width: "100%" }}
                                >
                                  <MenuItem value="sim">Sim</MenuItem>
                                  <MenuItem value="nao">Não</MenuItem>
                                </Select>
                              </FormControl>
                            )}
                            { item !== "e_cpf" &&
                              item !== "naturalness_uf" &&
                              item !== "uf" &&
                              item !== "issuing_agency_uf" &&
                              item !== "marital_status" &&
                              item !== "in_politically_exposed_person" &&
                              item !== "wedding_registry" &&
                              item !== "type" &&
                              item !== "in_stable_union" && (
                                <TextField
                                  type="text"
                                  value={openSocio.socio[item] || ""}
                                  onChange={event => {
                                    let socio_obj = openSocio.socio;
                                    socio_obj[item] = normalizeMember(
                                      item,
                                      event.target.value
                                    );
                                    setOpenSocio({
                                      ...openSocio,
                                      socio: socio_obj
                                    });
                                  }}
                                  error={memberError[item] ? true : false}
                                  helperText={
                                    memberError[item] && memberError[item]
                                  }
                                  label={verifyTitleMemberField(item)}
                                  variant="outlined"
                                  style={{ width: "100%" }}
                                />
                              )}
                          </>
                        ) : (
                          <button
                            type="button"
                            style={{
                              background: "none",
                              border: 0,
                              marginLeft: 0,
                              paddingLeft: 0
                            }}
                            disabled={!selectReprove}
                            onClick={() =>
                              handleSelectReprovedMemberField({
                                [item]: openSocio.socio[item]
                              })
                            }
                            className={classes.card}
                          >
                            <p
                              style={{
                                fontSize: "16px",
                                padding: "0",
                                margin: "0",
                                display: "flex",
                                alignItems: "center"
                              }}
                            >
                              {selectReprove && (
                                <span
                                  className={
                                    memberFieldsReproved[item] !== undefined
                                      ? "fa fa-check"
                                      : ""
                                  }
                                  style={{
                                    width: "18px",
                                    height: "18px",
                                    border: "0",
                                    background: "#dee2ea",
                                    display: "inline-block",
                                    borderRadius: "5px",
                                    marginRight: "10px"
                                  }}
                                ></span>
                              )}
                              <strong style={{ marginRight: "10px" }}>
                                {verifyTitleMemberField(item)}:
                              </strong>{" "}
                              {item === "type"
                                && (
                                  <>
                                  {openSocio.socio[item] === 1
                                  && "Sócio"}
                                  {openSocio.socio[item] === 2
                                  && "Representante"}
                                  {openSocio.socio[item] === 3
                                  && "Avalista"}
                                  {openSocio.socio[item] === 4
                                  && "Procurador"}
                                  </>
                                  )  }

                              {item === "e_cpf"
                                && (
                                  <>
                                  {(openSocio.socio[item] === "nao" || openSocio.socio[item] === 0) && "Não"}
                                  {(openSocio.socio[item] === "sim" || openSocio.socio[item] === 1) && "Sim"}
                                  </>) }

                              {item !== "e_cpf" && item !== "type" && (openSocio.socio[item] ? openSocio.socio[item] : "-")  }
                            </p>
                          </button>
                        )}
                      </Col>
                    );
                  } else if (
                    item === "memberSpouses" &&
                    openSocio.socio["marital_status"] === "Casado(a)" && openSocio.socio["wedding_registry"] !== "Separação Total de Bens" &&
                    (openSocio.socio[item] === null
                      ? setOpenSocio({
                          ...openSocio,
                          socio: {
                            ...openSocio.socio,
                            in_stable_union: "nao",
                            memberSpouses: {
                              fullname: "",
                              date_of_birth: "",
                              cpf: "",
                              e_cpf: "",
                              identity: "",
                              identity_oe: "",
                              identity_uf: "",
                              profession: "",
                              phone: "",
                              email: ""
                            }
                          }
                        })
                      : true)
                  ) {
                    const keysSpouse = Object.keys(
                      openSocio.socio.memberSpouses
                    );
                    return (
                      <>
                        <Col lg={12}>
                          <hr />
                          <h3>{verifyTitleMemberField(item)}</h3>
                        </Col>
                        {keysSpouse.map(k => {
                          if (
                            k !== "id" &&
                            k !== "member_id" &&
                            k !== "register_id" &&
                            k !== "created_at" &&
                            k !== "finished" &&
                            k !== "updated_at" &&
                            k !== "current_employer" &&
                            k !== "remuneration" &&
                            k !== "identity_date"
                          ) {
                            return (
                              <Col lg={6} key={k} style={{ padding: "5px" }}>
                                {editField ? (
                                  <>
                                    {k === "identity_uf" && (
                                      <FormControl
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                      >
                                        <InputLabel>Estado do RG</InputLabel>
                                        <Select
                                          value={openSocio.socio[item][k] || ""}
                                          onChange={event => {
                                            let socio_obj = openSocio.socio;
                                            socio_obj[item][
                                              k
                                            ] = normalizeMember(
                                              k,
                                              event.target.value
                                            );
                                            setOpenSocio({
                                              ...openSocio,
                                              socio: socio_obj
                                            });
                                          }}
                                          error={
                                            memberError[item] &&
                                            memberError[item][k]
                                              ? true
                                              : false
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <MenuItem value={"AC"}>Acre</MenuItem>
                                          <MenuItem value={"AL"}>
                                            Alagoas
                                          </MenuItem>
                                          <MenuItem value={"AP"}>
                                            Amapá
                                          </MenuItem>
                                          <MenuItem value={"AM"}>
                                            Amazonas
                                          </MenuItem>
                                          <MenuItem value={"BA"}>
                                            Bahia
                                          </MenuItem>
                                          <MenuItem value={"CE"}>
                                            Ceará
                                          </MenuItem>
                                          <MenuItem value={"DF"}>
                                            Distrito Federal
                                          </MenuItem>
                                          <MenuItem value={"ES"}>
                                            Espírito Santo
                                          </MenuItem>
                                          <MenuItem value={"GO"}>
                                            Goiás
                                          </MenuItem>
                                          <MenuItem value={"MA"}>
                                            Maranhão
                                          </MenuItem>
                                          <MenuItem value={"MT"}>
                                            Mato Grosso
                                          </MenuItem>
                                          <MenuItem value={"MS"}>
                                            Mato Grosso do Sul
                                          </MenuItem>
                                          <MenuItem value={"MG"}>
                                            Minas Gerais
                                          </MenuItem>
                                          <MenuItem value={"PA"}>Pará</MenuItem>
                                          <MenuItem value={"PB"}>
                                            Paraíba
                                          </MenuItem>
                                          <MenuItem value={"PR"}>
                                            Paraná
                                          </MenuItem>
                                          <MenuItem value={"PE"}>
                                            Pernambuco
                                          </MenuItem>
                                          <MenuItem value={"PI"}>
                                            Piauí
                                          </MenuItem>
                                          <MenuItem value={"RJ"}>
                                            Rio de Janeiro
                                          </MenuItem>
                                          <MenuItem value={"RN"}>
                                            Rio Grande do Norte
                                          </MenuItem>
                                          <MenuItem value={"RS"}>
                                            Rio Grande do Sul
                                          </MenuItem>
                                          <MenuItem value={"RO"}>
                                            Rondônia
                                          </MenuItem>
                                          <MenuItem value={"RR"}>
                                            Roraima
                                          </MenuItem>
                                          <MenuItem value={"SC"}>
                                            Santa Catarina
                                          </MenuItem>
                                          <MenuItem value={"SP"}>
                                            São Paulo
                                          </MenuItem>
                                          <MenuItem value={"SE"}>
                                            Sergipe
                                          </MenuItem>
                                          <MenuItem value={"TO"}>
                                            Tocantis
                                          </MenuItem>
                                        </Select>
                                      </FormControl>
                                    )}
                                    {k === "e_cpf" && (
                                      <FormControl
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                      >
                                        <InputLabel>Possui e-CPF?</InputLabel>
                                        <Select
                                          value={openSocio.socio[item][k] || ""}
                                          onChange={event => {
                                            let socio_obj = openSocio.socio;
                                            socio_obj[item][
                                              k
                                            ] = normalizeMember(
                                              k,
                                              event.target.value
                                            );
                                            setOpenSocio({
                                              ...openSocio,
                                              socio: socio_obj
                                            });
                                          }}
                                          error={
                                            memberError[item] &&
                                            memberError[item][k]
                                              ? true
                                              : false
                                          }
                                          style={{ width: "100%" }}
                                        >
                                          <MenuItem value="sim">Sim</MenuItem>
                                          <MenuItem value="nao">Não</MenuItem>
                                        </Select>
                                      </FormControl>
                                    )}
                                    {(k !== "identity_uf" && k !== "e_cpf") && (
                                      <TextField
                                        type="text"
                                        value={openSocio.socio[item][k] || ""}
                                        onChange={event => {
                                          let socio_obj = openSocio.socio;
                                          socio_obj[item][k] = normalizeMember(
                                            k,
                                            event.target.value
                                          );
                                          setOpenSocio({
                                            ...openSocio,
                                            socio: socio_obj
                                          });
                                        }}
                                        error={
                                          memberError[item] &&
                                          memberError[item][k]
                                            ? true
                                            : false
                                        }
                                        helperText={
                                          memberError[item] &&
                                          memberError[item][k] &&
                                          memberError[item][k]
                                        }
                                        label={verifyTitleMemberField(k)}
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                      />
                                    )}
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    style={{
                                      background: "none",
                                      border: 0,
                                      marginLeft: 0,
                                      paddingLeft: 0
                                    }}
                                    disabled={!selectReprove}
                                    onClick={() =>
                                      handleSelectReprovedMemberField({
                                        memberSpouses: {
                                          [k]: openSocio.socio.memberSpouses[k]
                                        }
                                      })
                                    }
                                    className={classes.card}
                                  >
                                    <p
                                      style={{
                                        fontSize: "16px",
                                        padding: "0",
                                        margin: "0",
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                    >
                                      {selectReprove && (
                                        <span
                                          className={
                                            memberFieldsReproved.memberSpouses &&
                                            memberFieldsReproved.memberSpouses[
                                              k
                                            ] !== undefined
                                              ? "fa fa-check"
                                              : ""
                                          }
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                            border: "0",
                                            background: "#dee2ea",
                                            display: "inline-block",
                                            borderRadius: "5px",
                                            marginRight: "10px"
                                          }}
                                        ></span>
                                      )}
                                      <strong style={{ marginRight: "10px" }}>
                                        {verifyTitleMemberField(k)}:
                                      </strong>{" "}
                                      {k !== "e_cpf" ? openSocio.socio.memberSpouses[k] :
                                       (openSocio.socio.memberSpouses[k] === "nao" ? "Não" : "Sim")}
                                    </p>
                                  </button>
                                )}
                              </Col>
                            );
                          } else {
                            return "";
                          }
                        })}
                      </>
                    );
                  } else {
                    return "";
                  }
                }
              })}
            </Row>
            <hr />
            {openSocio.socio.finished !== "A" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center"
                }}
              >
                {!editField &&
                  !selectReprove &&
                  openSocio.socio.finished !== "A" && (
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => setEditField(true)}
                      style={{ marginRight: "5px" }}
                    >
                      Editar Campos
                    </button>
                  )}
                {editField && (
                  <>
                    <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => setEditConfirmField(true)}
                      style={{ marginRight: "5px" }}
                    >
                      Atualizar
                    </button>
                    <button
                      type="button"
                      className="btn btn-light"
                      onClick={() => {
                        setEditField(false);
                        onReload();
                      }}
                    >
                      Cancelar
                    </button>
                  </>
                )}
                {!editField && (
                  <>
                    {selectReprove ? (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => {
                          setOpenSocio({ ...openSocio, active: false });
                          setOpenSocioReproved({
                            openSocio,
                            openSocioReproved: {
                              active: true,
                              fields: memberFieldsReproved
                            }
                          });
                          setSelectReprove(false);
                          setMemberFieldsReproved({});
                        }}
                      >
                        Reprovar Campos
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={() => {
                          setConfirmApprovedMember({
                            active: true,
                            member: openSocio.socio
                          });
                        }}
                        disabled={loading}
                      >
                        {loading ? "Carregando..." : "Aprovar"}
                      </button>
                    )}
                    <button
                      style={{ marginLeft: "5px" }}
                      type="button"
                      className={`btn ${
                        selectReprove ? "btn-secondary" : "btn-danger"
                      }`}
                      onClick={() => setSelectReprove(!selectReprove)}
                    >
                      {selectReprove ? "Cancelar" : "Reprovar Campos"}
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </>
  );
}

export default memo(ModalMember);
