import React from "react";
import { Route, Switch } from "react-router-dom";
import RolesIndex from "./RolesIndex";
import RolesAdd from "./RolesAdd";
import RolesEdit from "./RolesEdit";

export default function UserPage() {
  return (
    <Switch>
      <Route exact path="/roles/" component={RolesIndex} />
      <Route exact path="/roles/add" component={RolesAdd} />
      <Route exact path="/roles/:id" component={RolesEdit} />
    </Switch>
  );
}
