import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles, Backdrop } from "@material-ui/core";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import verifyTitleMemberField from "./utils/verifyTitleMemberField";
import verifyProfiles from "../utils/verifyProfiles";
import normalizeCompany from "./utils/normalizeCompany";
import statusDocument from "./utils/statusDocument";
import HeaderRegister from "./components/HeaderRegister";
import History from "./components/History";
import Members from "./components/Members";
import Pendencies from "./components/Pendencies";
import ModalUpdateCompany from "./components/Modais/ModalUpdateCompany";
import validationCompany from "./utils/validatorCompany";
import DocumentsSection from "./components/DocumentsSection";
import CompanyDataSection from "./components/CompanyDataSection";
import OpinionContainer from "./components/opinionsSection/OpinionContainer";

import ModalConfirmApproveCompany from "./components/Modais/ModalConfirmApproveCompany";
import ModalConfirmApproveDocuments from "./components/Modais/ModalConfirmApproveDocuments";
import ModalConfirmIsentDocument from "./components/Modais/ModalConfirmIsentDocument";
import ModalConfirmReproveDocumnt from "./components/Modais/ModalConfirmReproveDocument";
import ModalConfirmReproveCompany from "./components/Modais/ModalConfirmReproveCompany";
import ModalConfirmReproveMember from "./components/Modais/ModalConfirmReproveMember";

export default function RegisterView({ history, match }) {
  const [user, setUser] = useState([]);
  const [pendencies, setPendencies] = useState([]);
  const [histories, setHistories] = useState([]);
  const [documentsRepository, setDocumentsRepository] = useState([]);
  const [confirmApprovedCompany, setConfirmApprovedCompany] = useState(false);
  const [confirmApprovedDocument, setConfirmApprovedDocument] = useState(false);
  const [confirmExemptedDocument, setConfirmExemptedDocument] = useState(false);
  const [register, setRegister] = useState({});
  const [loading, setLoading] = useState(false);
  const [reproved, setReproved] = useState({ title: null, description: null });
  const [reprovedMember, setReprovedMember] = useState({
    title: null,
    description: null,
  });
  const [current, setCurrent] = useState(null);
  const [openReprove, setOpenReprove] = useState(false);
  const [openReproveDocs, setOpenReproveDocs] = useState(false);
  const [openSocio, setOpenSocio] = useState({
    active: false,
    socio: {},
    index: null,
  });
  const [openSocioReproved, setOpenSocioReproved] = useState({
    active: false,
    fields: {},
  });
  const [percentage, setPercentage] = useState(0);
  const [docsReproved, setDocsReproved] = useState([]);
  const [docsApproved, setDocsApproved] = useState([]);
  const [docsExempted, setDocsExempted] = useState([]);
  const [itemsReproved, setItemsReproved] = useState([]);
  const [selectReprove, setSelectReprove] = useState({
    fields: false,
    docs: false,
    members_fields: false,
  });
  const [selectExempt, setSelectExempt] = useState(false);
  const [reproveActive, setReproveActive] = useState({
    fields: false,
    docs: false,
    members_fields: false,
  });
  const [selectApprove, setSelectApprove] = useState(false);
  const [editField, setEditField] = useState({ company: false, member: false });
  const [editConfirmField, setEditConfirmField] = useState({
    company: false,
    member: false,
  });
  const [companyError, setCompanyError] = useState({});
  const [gerentes, setGerentes] = useState([]);
  const [users, setUsers] = useState([]);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: 20,
    },
    card: {
      padding: "10px 15px",
      width: "100%",
      border: "1px solid #eee",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "14px",
      textAlign: "left",
      flexWrap: "wrap",
      flex: "1",
    },
    container_list: {
      padding: "0",
      borderRadius: "4px",
      margin: "10px 0",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    modalsm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,.3)",
    },
    fade: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "1000px",
      height: "80%",
      overflow: "auto",
    },
    fadesm: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      borderRadius: "5px",
      padding: theme.spacing(2, 4, 3),
      width: "100%",
      maxWidth: "470px",
      height: "300px",
      overflow: "auto",
    },
    close: {
      background: "#fff",
      fontSize: "20px",
      border: 0,
      textAlign: "right",
      width: "100%",
    },
    wrap: {
      display: "flex",
      justifyContent: "space-beetwen",
      alignItems: "center",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    async function loadUser() {
      const { data } = await axios.get("auth");
      setUser(data);
    }
    loadUser();
  }, []);

  useEffect(() => {
    async function loadGerente() {
      try {
        const { data } = await axios.get("facta/gerente");
        setGerentes(data);
      } catch (error) {}
    }
    loadGerente();
  }, []);

  useEffect(() => {
    async function loadUsers() {
      try {
        const { data } = await axios.get("roles/users/8");
        setUsers(data);
      } catch (error) {}
    }
    loadUsers();
  }, []);

  async function handleUpdateAgent(agent_id, company_id) {
    setLoading(true);
    try {
      await axios.put(`/registers/agent/${company_id}`, {
        user_id: agent_id,
      });
      setRegister({
        ...register,
        user_id: agent_id,
      })
    }catch(err) {
      if(err.response) {
        toast.error(err.response.data.message);
      }else{
        toast.error('Erro desconhecido, contate a equipe técnica');
      }
    }
    setLoading(false);
  }

  async function loadRegister() {
    try {
      const response = await axios.get(`/registers/${match.params.id}`);
      let dataObj = JSON.parse(response.data.data);
      let { value: avatar } = dataObj.find((i) => i.name === "nome_fantasia");
      avatar = avatar.split(" ");
      avatar =
        avatar.length > 1
          ? avatar[0].slice(0, 1) + avatar[1].slice(0, 1)
          : avatar[0].slice(0, 2);
      setRegister({ ...response.data, dados: dataObj, avatar });
      if (response.data.clie_id === null) {
        if (response.data.gerente_id !== null) {
          if (response.data.finished === "A") {
            if (response.data.members.find((item) => item.finished !== "A")) {
            } else {
              try {
                const respFact = await axios.post(
                  `integration/${match.params.id}`
                );
                if (respFact.status === 200) {
                  toast.success("Dados enviados para o Facta");
                  await loadRegister();
                } else if (respFact.status === 203) {
                  toast.error(
                    "Dados do cadastro inválidos para integração. Verifique, atualize e reenvie para o Facta"
                  );
                  if (respFact.data.type === "company") {
                    toast("Altere os campos da empresa indicados", {
                      autoClose: false,
                    });
                    let errors = {};
                    respFact.data.field.forEach((item) => {
                      return (errors = { ...errors, [item]: "CAMPO INVÁLIDO" });
                    });
                    setCompanyError(errors);
                    setEditField({ ...editField, company: true });
                  } else if (respFact.data.type === "member") {
                    toast(
                      `${respFact.data.field.length} Membro(s) com dados inválidos. Altere os dados indicados:`,
                      { autoClose: false }
                    );
                    respFact.data.field.map((item) => {
                      return item.field.map((sub) => {
                        if (typeof sub === "string") {
                          return toast.info(
                            `${
                              response.data.members.find((m) => m.id === item.id)
                                .fullname
                            } - ${verifyTitleMemberField(sub)},`,
                            {
                              autoClose: false,
                            }
                          );
                        } else {
                          const { spouses } = sub;
                          return spouses.map((spouseItem) => {
                            return toast.info(
                              `${
                                response.data.members.find(
                                  (m) => m.id === item.id
                                ).fullname
                              } - Dados do Cônjuge - ${verifyTitleMemberField(
                                spouseItem
                              )}`,
                              {
                                autoClose: false,
                              }
                            );
                          });
                        }
                      });
                    });
                  }
                  await loadRegister();
                } else {
                  toast.error("Erro ao enviar, tente novamente");
                }
              } catch (error) {
                if (error.response.status === 401) {
                  toast.error(error.response.msg);
                } else {
                  toast.error(
                    "Erro ao enviar para o facta. Entre em contato com o suporte"
                  );
                }
              }
            }
          }
        }
      }
    } catch (err) {
      toast.error("Erro ao enviar. Tente novamente");
    }
  }

  async function loadDocumentRepositories() {
    const response = await axios.get(
      `/document_repositories/register/${match.params.id}`
    );
    setDocumentsRepository(response.data);
  }

  // Carregando Registro
  useEffect(() => {
    loadRegister();
    loadRegisterPendencies();
    loadRegisterHistories();
  }, [match.params.id]); //eslint-disable-line

  // Documento Repositório
  useEffect(() => {
    loadDocumentRepositories();
  }, [match.params.id]); // eslint-disable-line

  // Calculando progresso
  useEffect(() => {
    let perc = 0;
    register.documents &&
      register.documents.map((doc) => {
        if (doc.status === "A" || doc.status === "I") {
          return (perc += 33 / register.documents.length);
        } else {
          return perc;
        }
      });
    register.members &&
      register.members.map((mem) => {
        if (mem.finished === "A") {
          return (perc += 33 / register.members.length);
        } else {
          return perc;
        }
      });
    if (register.finished === "A") {
      perc += 33;
    }
    if (perc === 99) {
      perc = 100;
    }
    perc = Math.round(perc);
    setPercentage(perc);
  }, [register]); // eslint-disable-line

  // Carregando pendencias
  async function loadRegisterPendencies() {
    const { data } = await axios.get(
      `/register_pendencies/register/${match.params.id}`
    );
    setPendencies(data);
  }

  // Carregando histórico
  async function loadRegisterHistories() {
    const { data } = await axios.get(
      `/register_histories/register/${match.params.id}`
    );
    setHistories(data);
  }

  // Selecionando itens reprovados da empresa
  function handleSelectReproved(item) {
    let alreadyHave;
    if (item.id) {
      alreadyHave = itemsReproved.findIndex((i) => i.id === item.id);
    } else {
      alreadyHave = itemsReproved.findIndex((i) => i.name === item.name);
    }
    if (alreadyHave === -1) {
      if(item.name === 'bancos' || item.name === 'referencias_comerciais') {
        setItemsReproved([item]);
        setReproveActive({ ...reproveActive, fields: true });
      }else{
        setItemsReproved([...itemsReproved, item]);
        setReproveActive({ ...reproveActive, fields: true });
      }
    } else {
      const reproved = itemsReproved.filter(
        (i, index) => index !== alreadyHave
      );
      setItemsReproved(reproved);
      reproved.length === 0 &&
        setReproveActive({ ...reproveActive, fields: false });
    }
  }

  // Selecionando documentos reprovados
  function handleSelectReprovedDoc(item) {
    const alreadyHave = docsReproved.findIndex((i) => i.id === item.id);
    if (alreadyHave === -1) {
      setDocsReproved([...docsReproved, item]);
      setReproveActive({ ...reproveActive, docs: true });
    } else {
      const reproved = docsReproved.filter((i, index) => index !== alreadyHave);
      setDocsReproved(reproved);
      reproved.length === 0 &&
        setReproveActive({ ...reproveActive, docs: false });
    }
  }

  // Documentos aprovados
  function handleSelectApprovedDoc(item) {
    const alreadyHave = docsApproved.findIndex((i) => i.id === item.id);
    if (alreadyHave === -1) {
      setDocsApproved([...docsApproved, item]);
    } else {
      const approved = docsApproved.filter((i, index) => index !== alreadyHave);
      setDocsApproved(approved);
    }
  }

  // Documentos isentos
  function handleSelectExemptDoc(item) {
    const alreadyHave = docsExempted.findIndex((i) => i.id === item.id);
    if (alreadyHave === -1) {
      setDocsExempted([...docsExempted, item]);
    } else {
      const approved = docsExempted.filter((i, index) => index !== alreadyHave);
      setDocsExempted(approved);
    }
  }

  // Selecionando gerente do cadastro
  async function handleGerenteChange(item) {
    let gerente_id = item.target.value;
    try {
      const response = await axios.put(
        `/registers/gerente/${match.params.id}`,
        {
          gerente_id,
        }
      );
      console.log('response', response)
      setRegister({ ...register, gerente_id });
    } catch (error) {}
  }

  // Empresa reprovada
  async function onSubmitReproved() {
    const data = {
      title: reproved.title,
      description: reproved.description,
      type: "company",
      data: JSON.stringify(itemsReproved),
    };
    setLoading(true);
    try {
      await axios.post(`register_analysis/${match.params.id}/deny`, data);
      setItemsReproved([]);
      setOpenReprove(false);
      setReproveActive({ ...reproveActive, fields: false });
      setSelectReprove({ ...selectReprove, fields: false });
      loadRegisterPendencies();
      loadRegisterHistories();
      toast.success("Pendência de Dados Enviada");
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  }

  // Sócio reprovado
  async function onSubmitReprovedMember() {
    const bodyData = openSocioReproved.fields.memberSpouses
      ? {
          ...openSocioReproved.fields,
          memberSpouses: openSocioReproved.fields.memberSpouses,
        }
      : {
          ...openSocioReproved.fields,
          memberSpouses: {},
        };
    const data = {
      title: reprovedMember.title,
      description: reprovedMember.description,
      type: "member",
      member_id: openSocio.socio.id,
      data: JSON.stringify(bodyData),
    };
    setLoading(true);
    try {
      await axios.post(`register_analysis/${match.params.id}/deny`, data);
      setReproveActive({ ...reproveActive, members_fields: false });
      setSelectReprove({ ...selectReprove, members_fields: false });
      toast.success("Pendência de Dados Enviada");
      setOpenSocioReproved({ fields: {}, active: false });
      loadRegisterPendencies();
      loadRegisterHistories();
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  }

  // Documento reprovado
  async function onSubmitReprovedDocument() {
    const data = {
      title: reproved.title,
      description: reproved.description,
      type: "document",
      data: `${docsReproved.map((doc) => `${doc.document_type_id}`).join(",")}`,
    };
    setLoading(true);
    try {
      await axios.post(`register_analysis/${match.params.id}/deny`, data);
      setDocsReproved([]);
      setOpenReproveDocs(false);
      setSelectReprove({ ...selectReprove, docs: false });
      toast.success("Pendência de Documentos Enviada");
      loadRegisterPendencies();
      loadRegisterHistories();
      loadRegister();
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
    setReproved({
      title: "",
      description: "",
    });
  }

  // Empresa aprovada
  async function ApprovedCompany() {
    const data = {
      type: "company",
    };
    let values = {};
    register.dados.map((item) => {
      if (typeof item.value === "string") {
        return (values = {
          ...values,
          [item.name]: item.value,
        });
      } else {
        return values;
      }
    });

    const errors = validationCompany(values);

    if (Object.keys(errors).length > 0) {
      setConfirmApprovedCompany(false);
      setCompanyError(errors);
      setEditField({ ...editField, company: true });
      toast.error("Dados da empresa não estão hábeis para serem aprovados");
      return toast.warn("Verifique os campos indicados");
    }

    setLoading(true);
    try {
      await axios.post(`register_analysis/${match.params.id}/approve`, data);
      setConfirmApprovedCompany(false);
      toast.success("Dados da Empresa aprovados com sucesso");
      loadRegister();
      loadRegisterHistories();
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  }

  // Documentos aprovados
  async function ApprovedDocument() {
    setLoading(true);
    const data = {
      type: "document",
      documents: `${docsApproved
        .map((doc) => `${doc.document_type_id}`)
        .join(",")}`,
    };
    try {
      await axios.post(`register_analysis/${match.params.id}/approve`, data);
      toast.success("Documentos aprovados com sucesso");
      setDocsApproved([]);
      loadRegisterHistories();
      loadRegister();
    } catch (error) {
      toast.error(error.message);
    }
    setSelectApprove(false);
    setConfirmApprovedDocument(false);
    setLoading(false);
  }

  // Documentos isentos
  async function ExemptedDocument() {
    setLoading(true);
    const data = {
      type: "document",
      documents: `${docsExempted
        .map((doc) => `${doc.document_type_id}`)
        .join(",")}`,
    };
    try {
      await axios.post(`register_analysis/${match.params.id}/exempt`, data);
      toast.success("Documentos isentos com sucesso");
      setDocsExempted([]);
      loadRegisterHistories();
      loadRegister();
    } catch (error) {
      toast.error(error.message);
    }
    setSelectExempt(false);
    setConfirmExemptedDocument(false);
    setLoading(false);
  }

  // Integração com o Facta
  async function onSubmitFacta() {
    setLoading(true);
    try {
      const respFact = await axios.post(`integration/${match.params.id}`);
      if (respFact.status === 200) {
        toast.success("Dados do cadastro enviado com sucesso");
        await loadRegister();
      } else if (respFact.status === 203) {
        toast.error(
          "Dados do cadastro inválidos para integração. Verifique, atualize e reenvie para o Facta"
        );
        if (respFact.data.type === "company") {
          toast("Altere os campos da empresa indicados", {
            autoClose: false,
          });
          let errors = {};
          respFact.data.field.forEach((item) => {
            return (errors = { ...errors, [item]: "CAMPO INVÁLIDO" });
          });
          setCompanyError(errors);
          setEditField({ ...editField, company: true });
        } else if (respFact.data.type === "member") {
          toast(
            `${respFact.data.field.length} Membro(s) com dados inválidos. Altere os dados indicados:`,
            { autoClose: false }
          );
          respFact.data.field.map((item) => {
            return item.field.map((sub) => {
              if (typeof sub === "string") {
                return toast.info(
                  `${
                    respFact.data.members.find((m) => m.id === item.id).fullname
                  } - ${verifyTitleMemberField(sub)},`,
                  {
                    autoClose: false,
                  }
                );
              } else {
                const { spouses } = sub;
                return spouses.map((spouseItem) => {
                  return toast.info(
                    `${
                      respFact.data.members.find((m) => m.id === item.id)
                        .fullname
                    } - Dados do Cônjuge - ${verifyTitleMemberField(
                      spouseItem
                    )}`,
                    {
                      autoClose: false,
                    }
                  );
                });
              }
            });
          });
        }
      } else {
        toast.error("Erro ao enviar, tente novamente");
      }
    } catch (err) {
      if (err.response.status === 401) {
        toast.error(err.response.data.msg);
        loadRegister();
      } else {
        toast.error("Erro ao enviar, tente novamente");
      }
    }
    setLoading(false);
  }

  return (
    <>
      {/*  Modal confirmação aprovação empresa */}
      <ModalConfirmApproveCompany
        confirmApprovedCompany={confirmApprovedCompany}
        setConfirmApprovedCompany={(e) => setConfirmApprovedCompany(e)}
        ApprovedCompany={() => ApprovedCompany()}
        pendencies={pendencies && pendencies}
      />
      {/* Modal confirmação documentos */}
      <ModalConfirmApproveDocuments
        confirmApprovedDocument={confirmApprovedDocument}
        setConfirmApprovedDocument={(e) => setConfirmApprovedDocument(e)}
        docsApproved={docsApproved && docsApproved}
        ApprovedDocument={() => ApprovedDocument()}
      />
      {/* Modal isenção documentos */}
      <ModalConfirmIsentDocument
        confirmExemptedDocument={confirmExemptedDocument}
        setConfirmExemptedDocument={(e) => setConfirmExemptedDocument(e)}
        docsExempted={docsExempted && docsExempted}
        ExemptedDocument={() => ExemptedDocument()}
      />
      {/* Modal Reprovar Campos Empresa */}
      <ModalConfirmReproveCompany
        classes={classes}
        openReprove={openReprove}
        setOpenReprove={setOpenReprove}
        setSelectReprove={setSelectReprove}
        selectReprove={selectReprove}
        setReproved={setReproved}
        reproved={reproved}
        itemsReproved={itemsReproved}
        onSubmitReproved={onSubmitReproved}
        loading={loading}
      />
      {/* Modal Documentos Reprovados */}
      <ModalConfirmReproveDocumnt
        classes={classes}
        openReproveDocs={openReproveDocs}
        setOpenReproveDocs={setOpenReproveDocs}
        setSelectReprove={setSelectReprove}
        selectReprove={selectReprove}
        setReproved={setReproved}
        reproved={reproved}
        docsReproved={docsReproved}
        onSubmitReprovedDocument={onSubmitReprovedDocument}
        loading={loading}
      />
      {/* Modal Campos Sócio Reprovados */}
      <ModalConfirmReproveMember
        classes={classes}
        openSocioReproved={openSocioReproved}
        setOpenSocioReproved={setOpenSocioReproved}
        setSelectReprove={setSelectReprove}
        selectReprove={selectReprove}
        Backdrop={Backdrop}
        setReprovedMember={setReprovedMember}
        reprovedMember={reprovedMember}
        openSocio={openSocio}
        verifyTitleMemberField={verifyTitleMemberField}
        onSubmitReprovedMember={onSubmitReprovedMember}
        loading={loading}
      />
      <ModalUpdateCompany
        open={editConfirmField.company}
        onClose={(v) =>
          setEditConfirmField({ ...editConfirmField, company: v })
        }
        register={register && register}
        id={match.params.id}
        onError={(err) => setCompanyError(err)}
        registerReload={() => {
          loadRegister();
          loadRegisterHistories();
          loadRegisterPendencies();
          setEditField({ ...editField, company: false });
        }}
      />

      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">Cadastros</h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/registers`}
              >
                Cadastros
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link
                className="kt-subheader__breadcrumbs-link"
                to={`/registers/:id`}
              >
                Detalhe do cadastro
              </Link>
            </div>
          </div>
        </div>
      </div>

      <HeaderRegister
        register={register && register}
        onSubmitFacta={() => onSubmitFacta()}
        loading={loading}
        setCurrent={(e) => setCurrent(e)}
        percentage={percentage}
        onReload={() => {
          loadRegister();
          loadRegisterHistories();
        }}
        user={user}
      />
      <Row>
        <Col
          lg={
            Object.keys(user).length > 0 && !verifyProfiles(user, "manager")
              ? 8
              : 12
          }
        >
          {/* Dados da empresa */}
          {current === 1 && (
            <CompanyDataSection
              classes={classes}
              register={register}
              itemsReproved={itemsReproved}
              editField={editField}
              setRegister={setRegister}
              companyError={companyError}
              normalizeCompany={normalizeCompany}
              selectReprove={selectReprove}
              handleSelectReproved={handleSelectReproved}
              setEditField={setEditField}
              setEditConfirmField={setEditConfirmField}
              editConfirmField={editConfirmField}
              loadRegister={loadRegister}
              setOpenReprove={setOpenReprove}
              loading={loading}
              setConfirmApprovedCompany={setConfirmApprovedCompany}
              setSelectReprove={setSelectReprove}
            />
          )}
          {/* Documentos */}
          {current === 2 && (
            <DocumentsSection
              classes={classes}
              register={register}
              selectExempt={selectExempt}
              setSelectExempt={setSelectExempt}
              selectApprove={selectApprove}
              setSelectApprove={setSelectApprove}
              selectReprove={selectReprove}
              setSelectReprove={setSelectReprove}
              setConfirmExemptedDocument={setConfirmExemptedDocument}
              setConfirmApprovedDocument={setConfirmApprovedDocument}
              setOpenReproveDocs={setOpenReproveDocs}
              docsReproved={docsReproved}
              docsApproved={docsApproved}
              docsExempted={docsExempted}
              statusDocument={statusDocument}
              handleSelectExemptDoc={handleSelectExemptDoc}
              handleSelectApprovedDoc={handleSelectApprovedDoc}
              handleSelectReprovedDoc={handleSelectReprovedDoc}
              documentsRepository={documentsRepository}
              loadDocumentRepositories={loadDocumentRepositories}
              match={match}
              percentage={percentage}
            />
          )}
          {current === 3 && (
            <OpinionContainer
              classes={classes}
              user={user}
              register_id={match.params.id}
            />
          )}
        </Col>
        {Object.keys(user).length > 0 && !verifyProfiles(user, "manager") && (
          <Col lg={4}>
            <div className="row">
              <div className="col-md-6">
              <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Gerente</h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <select
                  name="type"
                  style={{ width: "100%" }}
                  className="form-control"
                  value={register.gerente_id || ""}
                  disabled={register &&
                    register.clie_id &&
                    register.finished === "A" &&
                    Object.keys(user).length > 0 &&
                    !verifyProfiles(user, "manager") &&
                    !register.members.find(
                      (member) => member.finished !== "A"
                    )}
                  onChange={(e) => handleGerenteChange(e)}
                >
                  <option>Selecione o gerente</option>
                  {gerentes &&
                    gerentes.map((e, i) => {
                      return (
                        <option key={i} value={e.GERE_ID}>
                          {e.NOME}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
              </div>
              <div className="col-md-6">
              <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title">Agente</h3>
                </div>
              </div>
              <div className="kt-portlet__body">
                <select
                  name="type"
                  style={{ width: "100%" }}
                  className="form-control"
                  value={register.user_id || ""}
                  disabled={
                    Object.keys(user).length > 0 &&
                    (!verifyProfiles(user, 'admin') && !verifyProfiles(user, 'analist'))}
                  onChange={(e) => handleUpdateAgent(Number(e.target.value), register.id)}
                >
                  <option>Selecione o agente</option>
                  {users &&
                    users.map((e, i) => {
                      return (
                        <option key={i} value={e.id}>
                          {e.fullname}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
              </div>
            </div>
            <Pendencies
              pendencies={pendencies}
              onClickDocument={() => setCurrent(2)}
            />
            <History histories={histories} percentage={percentage} />
          </Col>
        )}
      </Row>
      {Object.keys(user).length > 0 && !verifyProfiles(user, "manager") && (
        <>
          <h2>Sócios/Representantes/Avalistas</h2>
          <hr></hr>
          <Members
            id="members"
            members={register.members && register.members}
            onReload={() => {
              loadRegister();
              loadRegisterPendencies();
              loadRegisterHistories();
            }}
            register_id={match.params.id}
            setOpenSocioReproved={(e) => {
              setOpenSocio(e.openSocio);
              setOpenSocioReproved(e.openSocioReproved);
            }}
            pendencies={pendencies}
          />
        </>
      )}
    </>
  );
}
