import React from 'react';

export default function iconDocument(url) {
  const arr = url.split('.')
  const icon = arr[arr.length - 1]
  switch (icon) {
    case "doc":
      return (<img src="/media/files/doc.svg" style={{ width: '2.5rem' }} alt="DOC" />)
    case "docx":
      return (<img src="/media/files/doc.svg" style={{ width: '2.5rem' }} alt="DOCX" />)
    case "jpg":
      return (<img src="/media/files/jpg.svg" style={{ width: '2.5rem' }} alt="JPG" />)
    case "pdf":
      return (<img src="/media/files/pdf.svg" style={{ width: '2.5rem' }} alt="PDF" />)
    case "zip":
      return (<img src="/media/files/zip.svg" style={{ width: '2.5rem' }} alt="ZIP" />)
    case "rar":
      return (<img src="/media/files/zip.svg" style={{ width: '2.5rem' }} alt="RAR" />)
    default:
      return (<img src="/media/files/pdf.svg" style={{ width: '2.5rem' }} alt="PDF" />)
  }
}