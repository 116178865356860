import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
  Paper,
  FormControl,
  TextField,
  makeStyles
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { Alert } from 'react-bootstrap';

export default function UserAdd({ history }) {
  const useStyles = makeStyles(theme => ({
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
      padding: 20,
    },
  }));
  const classes = useStyles();

  const [values, setValues] = useState({
    title: '',
    path: ''
  });
  const [error, setError] = useState([]);
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if(values.title !== '' && values.path !== '') {
      setDisabled(false);
    }else {
      setDisabled(true)
    }
  }, [values.title, values.path]);
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      const response = await axios.post('/posts', values);
      if (response.status === 203) {
        setError(response.data);
      } else {
        toast.success('Comunicado cadastrado com sucesso');
        history.push('/posts');
      }
    }catch(err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className="kt-subheader kt-grid__item">
        <div className="kt-container ">
          <div className="kt-subheader__main">
            <h3 className="kt-subheader__title">
              Comunicados
            </h3>
            <div className="kt-subheader__breadcrumbs">
              <Link to="/" className="kt-subheader__breadcrumbs-home">
                <i className="flaticon2-shelter" />
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/posts`}>
                Comunicados
              </Link>
              <span className="kt-subheader__breadcrumbs-separator" />
              <Link className="kt-subheader__breadcrumbs-link" to={`/posts/add`}>
                Novo Comunicado
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Paper className={classes.paper}>
        <form onSubmit={handleSubmit} className="d-flex flex-column">
          {error.length !== 0 && (
          <Alert variant="danger">
            <ul>
              {error.map(er => (
                <li key={er.message}>
                    {er.message}
                </li>
                ))}
            </ul>
          </Alert>
          )} 
          <FormControl> 
            <TextField 
              margin="normal"
              variant="outlined"
              label="Título"
              value={values.title}
              onChange={(e) => setValues({ ...values, title: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <TextField 
              margin="normal"
              variant="outlined"
              label="Arquivo"
              value={values.path}
              onChange={(e) => setValues({ ...values, path: e.target.value })}
              required
            />
          </FormControl>
          <FormControl>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              disabled={disabled}
              >Cadastrar</button>
            <Link
              to="/posts"
              className="btn btn-secondary mt-3">
              Voltar
            </Link>
          </FormControl>
        </form>
      </Paper>
    </>
  );
}

