import React from "react";

export default function statusRegisterCompleted(completed) {
  
  return completed !== 1 ? (
    <span className="kt-badge kt-badge--info kt-badge--inline kt-badge--pill kt-badge--rounded">
      Em análise
    </span>
  ) : (
    <span className="kt-badge kt-badge--success kt-badge--inline kt-badge--pill kt-badge--rounded">
      Finalizado
    </span>
  );
}
